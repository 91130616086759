import { WorkSheet, read, utils, write } from 'xlsx';

import { capitalizeFirstLetterOfEachWord } from '../../../../utils/common/Utilities';
import { jsonToExcel } from '../../../containers/Employees/ActiveRoasterTable/roasterToExcelService';
import { IEndoTableEntry } from './types';

interface EndoMemberDataExcelEntity {
  'Employee Name'?: string;
  'Employee Id'?: string;
  Relationship?: string;
  Operation?: string;
  Source: string;
  Cost?: string;
  'Added On': string;
}

export const endoMemberDataExcelDownload = (
  memberData: IEndoTableEntry[],
): void => {
  const processedData = memberData?.map(transformAddMemberDataExcelRow);
  const fileLink = jsonToExcel([
    {
      sheetName: 'Member Data',
      data: processedData,
    },
  ]);
  const link = document.createElement('a');
  link.href = fileLink;
  link.download = `Endo_Member_Data_${new Date().toLocaleDateString()}`;
  link.click();
  link.remove();
};

export const transformAddMemberDataExcelRow = (
  d: IEndoTableEntry,
): EndoMemberDataExcelEntity => ({
  'Employee Name': capitalizeFirstLetterOfEachWord(d.employeeName),
  'Employee Id': d.employeeId,
  Relationship: capitalizeFirstLetterOfEachWord(d.relationship),
  Operation: d.operation,
  Source: d.source,
  'Added On': d.addedOn,
  Cost: d.cost ?? '',
});
