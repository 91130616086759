import React from 'react';
import { Modal, Container } from 'react-bootstrap';
import './addModal.css';
import moment from 'moment';
import { getPolicyType } from '../../../utils/common/Utilities';

function ConfirmationModal({
  title,
  subTitle,
  buttonText1,
  onPressButton1,
  showModal,
  setShowModal,
  variant = 'success',
  buttonText2,
  onPressButton2,
  size = 'lg',
  formData,
  policyInfo = {},
  loadingStatus,
}) {
  return (
    <Modal
      className="modal"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showModal}
      onHide={setShowModal}
      backdrop={'static'}
    >
      <Modal.Header closeButton className="confirmModal_header">
        {title && (
          <Modal.Title id="contained-modal-title-vcenter">
            <span className="modalTitle">{title}</span>
            <span className="modalSubTitle">{subTitle}</span>
          </Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
          <div className="col-md-12">
            <div className="candidate_info_main">
              <h5>
                {formData?.firstName} {formData?.lastName}
              </h5>
              <span>{formData?.employeeId}</span>
              <label className="candidate_info">
                {formData?.gender} <span className="info_dot"/>{' '}
                {moment.unix(formData?.dob).format('DD/MM/YYYY')}{' '}
                <span className="info_dot"/> {formData?.mobile}{' '}
                <span className="info_dot"/> {formData?.email}
              </label>

              <div className="info_selected_policies">
                <label className="form-label">Selected policies</label>

                <div className="row">
                  <div className="selected_policy_tags">
                    {formData?.policyPreferences?.map((policy, i) => (
                      <div className="sp_tag_name" key={policy?.id ?? i}>
                        <span>
                          {getPolicyType(
                            policy?.policyType,
                            policy?.isTopUpPolicy,
                            policy?.isParentalPolicy
                          )}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <div className="modal_empDel_3_button confirmModal_footer_btn">
          <button
            className="modal_empDel_3_button_submit btn_green pull-right"
            onClick={onPressButton1}
          >
            {loadingStatus ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="sr-only">Loading...</span>
              </>
            ) : (
              buttonText1
            )}
          </button>

          {buttonText2 ? (
            <button
              className="modal_empDel_3_button_cancel margin_15px pull-right"
              onClick={onPressButton2}
            >
              {buttonText2}
            </button>
          ) : null}
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export { ConfirmationModal };
