import { IApiState, IEmployeesSlice } from './types';

export const apiState: IApiState = {
  data: null,
  status: 'idle',
  error: '',
};

export const pagingMetaData = {
  metaData: { pageNo: 0, hasPrev: false, hasNext: false },
};

export const initialState: IEmployeesSlice = {
  policyPrefData: {
    ...apiState,
  },
  addDeptRequest: {
    ...apiState,
    ...pagingMetaData,
  },
  employeeListData: {
    ...apiState,
    ...pagingMetaData,
  },
  updateHrRequest: {
    ...apiState,
  },
  updateHrDependentRequest: {
    ...apiState,
  },
  enrolPolicy: {
    ...apiState,
  },
  delPolicy: {
    ...apiState,
  },
  empCount: {
    ...apiState,
  },
};
