import { IButtonState } from '../../../containers/Bulk/types';
import { deleteMidtermDocument } from '../../../containers/MidTermAdditionModal/utils';
import {
  IBasicUserAddData,
  IBulkAddData,
  IPolicyRejectedEntries,
  IUploadedPolicywiseData,
} from './types';

export const getButtonStates = (
  currentStep: number,
  selectedLength: number,
  finalCorrectData: IUploadedPolicywiseData,
  rejectedEntries: IPolicyRejectedEntries,
): IButtonState => {
  const correctEntriesLength = getCorrectEntriesLength(finalCorrectData);
  switch (currentStep) {
    case 1:
      return {
        isNextEnabled: !!selectedLength,
        isBackEnabled: false,
        buttonText: 'Upload & Proceed',
        backButtonText: ''
      };
    case 2:
      return {
        isNextEnabled: true,
        isBackEnabled: !!correctEntriesLength,
        buttonText: correctEntriesLength
          ? `View Cost of ${correctEntriesLength} Correct Lives `
          : 'Re-Upload all Lives',
          backButtonText: 'Re - Upload all Lives'
      };
    case 3:
      return {
        isNextEnabled: true,
        isBackEnabled: true,
        buttonText: `Submit ${correctEntriesLength} Lives`,
        backButtonText:
          Object.keys(rejectedEntries).length > 0
            ? 'Review Again'
            : 'Re-Upload all Lives',
      };
    default:
      return { isNextEnabled: false, isBackEnabled: false, buttonText: '', backButtonText: '' };
  }
};

export const deleteMidtermDocumentsOnCancel = (
  uploadedData: IUploadedPolicywiseData,
) => {
  Object.keys(uploadedData).forEach((policyId) => {
    uploadedData[policyId].forEach((member) =>
      deleteMidtermDocument(member.midTermDocumentURL),
    );
  });
};

export const checkIfSameAddUser = (
  user1: IBasicUserAddData,
  user2: IBasicUserAddData,
) => {
  return (
    user1.employee_id === user2.employee_id &&
    user1.relationship_to_account_holders.toLowerCase() ===
      user2.relationship_to_account_holders.toLowerCase() &&
    user1.name?.toLowerCase()?.trim() === user2?.name?.toLowerCase().trim()
  );
};

export const getCorrectEntries = (
  uploadedData: IUploadedPolicywiseData,
  rejectedData: IPolicyRejectedEntries,
): IUploadedPolicywiseData => {
  const correctEntriesData: IUploadedPolicywiseData = {};

  Object.keys(uploadedData).forEach((policyId) => {
    const policyCorrectEntries: IBulkAddData[] = [];
    uploadedData[policyId].forEach((member) => {
      const rejectedEntry = rejectedData[policyId]?.find((rejectedMember) =>
        checkIfSameAddUser(member, rejectedMember),
      );
      if (!rejectedEntry) {
        policyCorrectEntries.push(member);
      }
    });
    if (policyCorrectEntries.length)
      correctEntriesData[policyId] = policyCorrectEntries;
  });
  return correctEntriesData;
};

export const getCorrectEntriesLength = (
  correctData: IUploadedPolicywiseData,
): number => {
  return Object.keys(correctData).reduce((prev, policyId) => {
    return prev + correctData[policyId].length;
  }, 0);
};


export const isCDLowTrue = (dataArray: any[]): boolean => {
  if (dataArray.length > 0) {
    for (const obj of dataArray) {
      if (obj.hasOwnProperty('isCDLow') && obj.isCDLow === true) {
        return true;
      }
    }
  }
  return false;
};
