import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './addEmployee.scss';
import { TagOption } from '../../../atoms/TagOption/TagOption';
import { DropdownData } from '../../../../utils/common/Dropdowndata';
import { selectSelectedPolicies } from '../../../../redux/slices/addEmpFormSlice';
import { ConfirmationModal } from '../../../containers/AlertModal/confirmationModal';
import useFormInput from '../../../../utils/hooks/useFormInput';
import { DatePicker } from '../../../atoms/datepicker/DatePicker';
import moment from 'moment';
import { ConfirmationNotifyModal } from '../../../containers/AlertModal/confirmationNotifyModal';
import {
  selectAddHrRequest,
  clearHrReqState,
} from '../../../../redux/slices/hrRequestSlice';
import { clearEmpReqState } from '../../../../redux/slices/employeesSlice';
import { selectHrUser } from '../../../../redux/slices/usersSlice';
import { AppConstants } from '../../../../utils/common/AppConstants';
import {
  getAmountInLacs,
  getPolicyType,
} from '../../../../utils/common/Utilities';
import { jsonStrParse } from '../../../../utils/common/Utilities';
import { registerMemberToPolicies } from './addEmployeeService';
import {selectSelectedCompany} from "../../../../redux/slices/hrdRevampRedux";
// import { toast } from 'react-toastify';

const MAX_DOB_DATE = moment().subtract(0, 'years').format('YYYY-MM-DD');
const MIN_DOB_DATE = '1900-01-01';
const MAX_DOJ_DATE = moment().add(3, 'months').format('YYYY-MM-DD');
const MIN_DOJ_DATE = moment().subtract(45, 'days').format('YYYY-MM-DD');

export const AddEmployees = () => {
  const hrData = useSelector(selectHrUser);
  const selectedCompany = useSelector(selectSelectedCompany);

  const history = useHistory();
  const dispatch = useDispatch();
  const goBack = () => {
    history.goBack();
  };
  const selectedPolicies = useSelector(selectSelectedPolicies);
  const isCtcRequired = selectedPolicies?.some(
    (p) =>
      p?.policyDetails?.policyTermsAndConditions?.sumInsuredStructure ===
      'CTC Multiplier',
  );

  const addHrRequest = useSelector(selectAddHrRequest);
  const [selectedPolicyIds, setSelectedPolicyIds] = useState([]);
  const [dobDateError, setDOBDateError] = useState(false);
  const [dojDateError, setDOJDateError] = useState(false);
  const [genderError, setGenderError] = useState(false);
  const [formLevelError, setFormLevelError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmNotifyModal, setConfirmNotifyModal] = useState(false);

  const [requestBody, setRequestBody] = useState({});
  const firstName = useFormInput('');
  const lastName = useFormInput('');
  const employeeId = useFormInput('');
  const mobile = useFormInput('');
  const email = useFormInput('');
  const ctc = useFormInput('');
  const dob = useFormInput('');
  const joiningDate = useFormInput('');
  const gender = useFormInput('');
  const [userId, setUserId] = useState(null);
  const choosePolicyHandler = useCallback(() => {
    history.push('/select-policies', { from: 'add-employee' });
    window.scrollTo(0, 0);
  }, [history]);

  useEffect(() => {
    dispatch(clearHrReqState({ data: AppConstants.ADD }));
    dispatch(clearEmpReqState({ data: AppConstants.ADD_DEPENDENT }));
  }, []);

  useEffect(() => {
    if (addHrRequest?.status === 'succeeded') {
      setConfirmModal(false);
      setConfirmNotifyModal(true);
    } else if (addHrRequest?.status === 'failed') {
      dispatch(clearHrReqState({ data: AppConstants.ADD }));
      dispatch(clearEmpReqState({ data: AppConstants.ADD_DEPENDENT }));
      let error =
        addHrRequest?.error?.message ??
        addHrRequest?.error?.name ??
        addHrRequest?.error;
      const deStringifiedError =
        typeof error === 'string' ? jsonStrParse(error) : error;
      if (typeof deStringifiedError === 'string') {
        setFormLevelError(deStringifiedError);
      } else if (Array.isArray(deStringifiedError)) {
        deStringifiedError.forEach((element) => {
          if (element.field === 'mobile') {
            mobile.setErrorText(element.message);
          }
          if (element.field === 'email') {
            email.setErrorText(element.message);
          }
          if (element.field === 'employeeId') {
            employeeId.setErrorText(element.message);
          }
          if (element.field === 'firstName') {
            firstName.setErrorText(element.message);
          }
          if (element.field === 'lastName') {
            lastName.setErrorText(element.message);
          }
          if (element.field === 'ctc') {
            ctc.setErrorText(element.message);
          }
          // if (element.field === 'relationship') {
          //   relationship.setErrorText(element.message);
          // }
        });
      }
      setConfirmModal(false);
    }
  }, [addHrRequest?.status]);

  const handleViewDetailPage = () => {
    setConfirmNotifyModal(false);
    const userID = userId;
    history.push('/view-employee', { userID });
    dispatch(clearHrReqState({ data: AppConstants.ADD }));
    dispatch(clearEmpReqState({ data: AppConstants.ADD_DEPENDENT }));
    window.scrollTo(0, 0);
  };

  const onGenderSelection = (e) => {
    const genderValue = e;
    gender.setValue(genderValue);
    setGenderError(!genderValue);
  };

  const onDOBDateBlur = (e) => {
    const DOB = e.target.value;
    const newDobDateError = !DOB || DOB > MAX_DOB_DATE || DOB < MIN_DOB_DATE;
    setDOBDateError(newDobDateError);
  };

  const onDOJDateChange = (e) => {
    const DOJ = e.target.value;
    const newDojDateError = !DOJ || DOJ > MAX_DOJ_DATE || DOJ < MIN_DOJ_DATE;
    setDOJDateError(newDojDateError);
  };

  const handleOnAddData = (e) => {
    e.preventDefault();
    setFormLevelError(false);
    const checkFirstValidation = firstName.finalvalidationCheck();
    const checkLastValidation = lastName.finalvalidationCheck();
    const checkEmpIDValidation = employeeId.finalvalidationCheck();
    const checkMobileNoValidation = mobile.finalvalidationCheck();
    const checkEmailValidation = email.finalvalidationCheck();

    let checkCTCValidation = true;
    if (isCtcRequired) {
      checkCTCValidation = ctc.finalvalidationCheck() && ctc.value > 0;
      if (ctc.value < 1) {
        ctc.setErrorText('Cannot be zero');
      }
    }

    const DOB = dob.value;
    const newDobDateError = !DOB || DOB > MAX_DOB_DATE || DOB < MIN_DOB_DATE;
    setDOBDateError(newDobDateError);

    const DOJ = joiningDate.value;
    const newDojDateError = !DOJ || DOJ > MAX_DOJ_DATE || DOJ < MIN_DOJ_DATE;
    setDOJDateError(newDojDateError);

    const newGenderError = !gender.value;
    setGenderError(newGenderError);

    if (
      checkFirstValidation &&
      checkLastValidation &&
      checkEmpIDValidation &&
      checkMobileNoValidation &&
      checkEmailValidation &&
      checkCTCValidation &&
      !newDobDateError &&
      !newDojDateError &&
      !newGenderError
    ) {
      let request = {};
      request.firstName = firstName.value;
      request.lastName = lastName.value;
      request.employeeId = employeeId.value;
      request.mobile = mobile.value;
      request.email = email.value;
      request.ctc = ctc.value;
      // const DateOfJoining = new Date(joiningDate.value);
      request.joiningDate = moment(joiningDate.value).unix();
      // const DateOfBirth = new Date(dob.value);
      request.dob = moment(dob.value).unix();
      request.gender = gender.value;
      request.ids = selectedPolicyIds?.map((p) => p.id);
      // request.relationship = 'Self';
      request.hrId = hrData?.data?.userId;
      request.employer = selectedCompany?.id;
      request.policyPreferences = selectedPolicyIds;
      setRequestBody(request);
      setConfirmModal(true);
    } else {
      window.scrollTo(0, 0);
    }
  };
  const processDataForApi = async () => {
    /** CREATE USER CALL */
    setLoading(true);
    try {
      const result = await registerMemberToPolicies({
        ...requestBody,
        relationship: 'self',
      });
      setLoading(false);
      /** HANDLE ERROR/SUCCESS STATES */
      if (result.data.success && result.data.success.length) {
        setUserId(result.data.success[0].userId);
        setConfirmModal(false);
        setConfirmNotifyModal(true);
      } else if (result.data.failure && result.data.failure.length) {
        const error = result.data.failure[0].errorMsg;
        setFormLevelError(error);
        setConfirmModal(false);
      } else {
        const error = result.message;
        setFormLevelError(error);
        setConfirmModal(false);
      }
    } catch (e) {
      const error = e.message;
      setLoading(false);
      setFormLevelError(error);
      setConfirmModal(false);
    }
  };

  useEffect(() => {
    const processPolicies = () => {
      // let self_policiesID = [];
      const selected_policiesID = selectedPolicies.map((policy) => {
        const sel_policies = {
          policyId: policy.id,
          sumInsured: String(policy.slab),
          policyType: policy.policyDetails.policyType,
          slabId: policy?.slabId ?? 1,
          policyStartDate: policy.policyDetails.policyStartDate,
        };

        // selected_policiesID.push(sel_policies);
        // let applicableDep = policy.supDependents.replace(/\s/g, '').split('+');
        // if (applicableDep.includes('Self')) {
        //   self_policiesID.push(policy.id);
        //   setSelfPolicyIds(self_policiesID);
        // }
        return sel_policies;
      });
      setSelectedPolicyIds(selected_policiesID);
    };
    if (selectedPolicies) {
      processPolicies();
    } else {
      choosePolicyHandler();
    }
  }, [choosePolicyHandler, selectedPolicies]);

  return (
    <>
      <div className="hr-dashboard">
        <div className="  mx-auto">
          <div className={`view-emp-header hover`} onClick={goBack}>
            <img
              className="arrow-left-emp-img"
              alt="back"
              src={'/assets/icons/chevron-right.svg'}
            />
            <span className="heading-bold-text">Add Single Employee</span>
          </div>
          <div className="row">
            <div className="add_emp_steps">
              <ul>
                <li onClick={choosePolicyHandler}>
                  <label>
                    <span className="">1</span>Choose Policies
                  </label>
                </li>
                <li>
                  <label className="active_label">
                    <span className="active_span">2</span>Add Employee and
                    Dependent information
                  </label>
                </li>
              </ul>
            </div>
          </div>
          <div className="row policy_checkbox">
            <div className="section_white mb_b_nav">
              <div className="internal_heading">
                <h3>Employee Information</h3>
              </div>
              <div className="addEmp_internal_form text-transform-initial">
                <div className="row">
                  <div className="col-md-4">
                    <label className="input-label-name">
                      First Name <span>*</span>
                    </label>
                    <input
                      placeholder="First Name"
                      name="fname"
                      {...firstName.htmlAttributes}
                      className={`form-control ${
                        firstName.errorText ? 'border-danger' : ''
                      }`}
                    />
                    <small className="error-message text-danger form-text">
                      {firstName.errorText}
                    </small>
                  </div>

                  <div className="col-md-4">
                    <label className="input-label-name">
                      Last Name <span>*</span>
                    </label>
                    <input
                      placeholder="Last Name"
                      name="fname"
                      {...lastName.htmlAttributes}
                      className={`form-control ${
                        lastName.errorText ? 'border-danger' : ''
                      }`}
                    />
                    <small className="error-message text-danger form-text">
                      {lastName.errorText}
                    </small>
                  </div>

                  <div className="col-md-4">
                    <label className="input-label-name">
                      Employee ID <span>*</span>
                    </label>
                    <input
                      placeholder="Employee ID"
                      name="empId"
                      {...employeeId.htmlAttributes}
                      className={`form-control ${
                        employeeId.errorText ? 'border-danger' : ''
                      }`}
                    />
                    <small className="error-message text-danger form-text">
                      {employeeId.errorText}
                    </small>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <TagOption
                      controlId="input-tag"
                      labelName="Gender"
                      fieldKey="gender"
                      field={gender}
                      value={gender.value}
                      option={DropdownData.genderData}
                      // onChange={gender.setValue}
                      errorMsg="Please select gender"
                      isInvalid={genderError}
                      onChange={onGenderSelection}
                    />
                  </div>

                  <div className="col-md-4">
                    <label className="input-label-name">
                      Mobile Number <span>*</span>
                    </label>
                    <div className=" input_mobile">
                      <input
                        type="text"
                        className="country_code"
                        value="+91"
                        disabled={true}
                      />
                      <input
                        placeholder="Mobile Number"
                        name="phone"
                        {...mobile.htmlAttributes}
                        className={`span_91 form-control ${
                          mobile.errorText ? 'border-danger' : ''
                        }`}
                      />
                    </div>
                    <small className="error-message text-danger form-text">
                      {mobile.errorText}
                    </small>
                  </div>
                  <DatePicker
                    labelName="Date of birth"
                    field={dob}
                    max={MAX_DOB_DATE}
                    min={MIN_DOB_DATE}
                    errorMsg="Invalid DOB date"
                    dateError={dobDateError}
                    onBlur={onDOBDateBlur}
                    onChange={() => setDOBDateError(false)}
                  />
                </div>

                <div className="row">
                  <div className="col-md-4">
                    <label className="input-label-name">
                      Official Email <span>*</span>
                    </label>
                    <input
                      placeholder="Official Email"
                      name="email"
                      {...email.htmlAttributes}
                      className={`form-control ${
                        email.errorText ? 'border-danger' : ''
                      }`}
                    />
                    <small className="error-message text-danger form-text">
                      {email.errorText}
                    </small>
                  </div>
                  <div className="col-md-4">
                    <label className="input-label-name">
                      Annual CTC {isCtcRequired ? <span>*</span> : ''}
                    </label>
                    <input
                      placeholder="Annual CTC"
                      name="ctc"
                      {...ctc.htmlAttributes}
                      className={`form-control ${
                        ctc.errorText ? 'border-danger' : ''
                      }`}
                    />
                    <small className="error-message text-danger form-text">
                      {ctc.errorText}
                    </small>
                  </div>

                  <DatePicker
                    labelName="Joining Date"
                    field={joiningDate}
                    max={MAX_DOJ_DATE}
                    min={MIN_DOJ_DATE}
                    errorMsg="Invalid Joining Date"
                    dateError={dojDateError}
                    onChange={onDOJDateChange}
                  />
                </div>
                <div className="row">
                  <div className="addEmp_internal_form_checkbox">
                    <label className="form-label">
                      Covered under policies <span>*</span>
                    </label>

                    <div className="row addEmp_checkbox_policyCard">
                      {selectedPolicies?.length === 0 ? (
                        <div className="col-md-5">
                          <div class="no_selected_relation">
                            <label>
                              No Policies Available for Selected Dependent
                              <span>Dependent Can't be added.</span>
                            </label>
                          </div>
                        </div>
                      ) : (
                        selectedPolicies?.map((policyInfo) => (
                          <div className=" col-md-4" key={policyInfo.id}>
                            <div className="box_card card_border">
                              <div className="card_top">
                                <div className="col-md-12">
                                  <div className="policy_info_left single_user_r2">
                                    <div className="policy_name single_user_pn">
                                      <h4 className="cardName">
                                        {getPolicyType(
                                          policyInfo?.policyDetails?.policyType,
                                          policyInfo?.policyDetails
                                            ?.isTopUpPolicy,
                                          policyInfo?.policyDetails
                                            ?.isParentalPolicy,
                                        )}
                                      </h4>
                                      <h5 className="health_insurer">
                                        {policyInfo?.policyDetails?.insurerName}
                                      </h5>
                                    </div>
                                  </div>
                                  <div className="policy_info_right" />
                                </div>
                              </div>
                              <div className="card_bottom">
                                <div className="policy_stats policy_row_one card_section_main">
                                  <div className="policy_sum_ins card_section_one">
                                    <p className="amountText">Sum Insured</p>

                                    <h5 className="amountPrice">
                                      {getAmountInLacs(policyInfo?.slab)}
                                    </h5>
                                  </div>
                                  <div className="policy_cd_balance card_section_two">
                                    <p className="amountText">
                                      Supported Dependents
                                    </p>
                                    <h5 className="amountPrice">
                                      {policyInfo?.supDependents
                                        ? policyInfo?.supDependents
                                        : '-'}
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="select_policy_footer">
              <button onClick={handleOnAddData} className="active">
                {' '}
                Confirm
              </button>
              {formLevelError && (
                <span className="button-error-text">{formLevelError}</span>
              )}
            </div>
          </div>
        </div>
      </div>

      <ConfirmationModal
        title="Confirm Employee Information"
        showModal={confirmModal}
        setShowModal={setConfirmModal}
        formData={requestBody}
        onPressButton1={() => processDataForApi()}
        onPressButton2={() => {
          setConfirmModal(false);
        }}
        buttonText2={'Continue editing'}
        buttonText1={'Confirm'}
        loadingStatus={loading}
      />

      <ConfirmationNotifyModal
        title="Success"
        showModal={confirmNotifyModal}
        setShowModal={setConfirmNotifyModal}
        onPressButton1={() => handleViewDetailPage()}
        buttonText1={'Go to employee detail'}
        // buttonText1={'Confirm'}
      />
    </>
  );
};
