import React, { FC } from 'react';
import { Typography } from '@loophealth/loop-ui-web-library';
import { StyledChipLabel, StyledChipWrapper } from './styles';
import { IChipProps } from './types';
import { getChipVariantStyles } from './utils';

const Chip: FC<IChipProps> = ({
  label,
  variant = 'filled',
  textVariant = 'extraSmall',
  color = '#bdbdbd',
  backgroundColor,
  borderRadius = '1rem',
  onClick,
}) => {
  const variantStyles = getChipVariantStyles(variant, backgroundColor, color);

  return (
    <StyledChipWrapper
      style={{ color, backgroundColor, borderRadius, ...variantStyles }}
      onClick={onClick}
    >
      <Typography
        variant={textVariant}
        color={typeof color === 'string' ? undefined : color}
      >
        <StyledChipLabel style={{ color }}>{label}</StyledChipLabel>
      </Typography>
    </StyledChipWrapper>
  );
};

export default Chip;
