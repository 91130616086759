import React from 'react';
import RedirectAuthRoute from '../../utils/hoc/RedirectAuthRoute';
import { Redirect, Route, Switch } from 'react-router-dom';

import { PrivateRoute } from '../../utils/hoc/PrivateRoute';

import Login from './Auth/Login';
import LegacyLogin from './Login-legacy';
import EnterOtp from './enterOtp/EnterOtp';
import Dashboard from './Dashboard';
import { isNewLoginEnabled } from '../../utils/featureFlag';
import OTPScreen from './Auth/OTPScreen';
import WaitListPage from './WaitList';

const MainRouter = () => {
  return (
    <Switch>
      <RedirectAuthRoute
        path="/login"
        component={isNewLoginEnabled ? Login : LegacyLogin}
      />
      <RedirectAuthRoute
        path="/enter-otp"
        component={isNewLoginEnabled ? OTPScreen : EnterOtp}
      />
      <Route path={'/waitlist'} component={WaitListPage} />
      {/* <RedirectAuthRoute path="/enter-id" component={EnterAuth} /> */}
      {/* // TODO: uncomment if want old flow */}
      <PrivateRoute path="/" component={Dashboard} />
      <Redirect to="/" />
    </Switch>
  );
};

export default MainRouter;
