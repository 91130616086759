import styled from 'styled-components';

export const ShadowWrapper = styled.div`
  z-index: 1;
`;

export const StyledContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 32px 56px;
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

export const StyledMainContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const StyledCostBalanceContainer = styled.div`
  display: flex;
  gap: 48px;
`;

export const StyledIconContent = styled.div`
  display: flex;
  gap: 12px;
`;

export const StyledCdBalanceContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledCdBalanceBox = styled.div`
  display: flex;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.surface0};
  width: 100%;
`;

export const StyledCdBalance = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 16px;
  flex: 1 0 0;
`;

export const StyledBalanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

export const StyledBalanceDetail = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const StyledEclipse = styled.div`
  width: 6px;
  height: 6px;
  background-color: ${({ theme }) => theme.colors.seafoamGray};
  border-radius: 50%;
`;
