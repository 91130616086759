import { addMemberToPolicies } from '../../../../adaptars';
import { convertToDate } from '../../../../utils/common/Utilities';
import { isPolicyStartDateResolverEnabled } from '../../../../utils/featureFlag';

export const registerMemberToPolicies = async (requestBody) => {
  const registerMemberRequest = {
    enableEnrolmentPortal: 'false',
    companyId: requestBody.employer,
    policies: requestBody.policyPreferences.map((policyData) => {
      return {
        id: policyData.policyId,
        slab: policyData.slabId,
        policyStartDate: isPolicyStartDateResolverEnabled
          ? undefined
          : requestBody.relationship === 'self'
          ? convertToDate({ seconds: requestBody.joiningDate })
          : convertToDate({ seconds: policyData.policyStartDate._seconds }),
      };
    }),
    member: {
      employee_id: requestBody.employeeId,
      relationship_to_account_holders: requestBody.relationship,
      name: `${requestBody.firstName} ${requestBody.lastName}`,
      policy_start_date: isPolicyStartDateResolverEnabled
        ? undefined
        : convertToDate({
            seconds: requestBody.joiningDate,
          }),
      joiningDate: convertToDate({ seconds: requestBody.joiningDate }),
      email_address: requestBody.email,
      mobile: requestBody.mobile,
      date_of_birth: convertToDate({ seconds: requestBody.dob }),
      gender: requestBody.gender,
      ctc: requestBody.ctc || 0,
      parentUserId: requestBody.parentId,
    },
  };
  const result = await addMemberToPolicies(registerMemberRequest);
  return result;
};
