import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Modal, Container } from 'react-bootstrap';
import CloseButton from 'react-bootstrap/CloseButton';
import './addModal.css';
import '../EmployeeItem/EmployeeItem.css';
import { DatePicker } from '../../atoms/datepicker/DatePicker';
import moment from 'moment';
import useFormInput from '../../../utils/hooks/useFormInput';
import { getPolicyType, getAmountInLacs } from '../../../utils/common/Utilities';
import useSegment from '../../../utils/hooks/useSegment';
import { getTrackClickObj } from '../../../utils/common/Utilities';


const MIN_LEAVING_DATE = moment().subtract(45, 'days').format('YYYY-MM-DD');
const MAX_LEAVING_DATE = moment().add(2, 'months').format('YYYY-MM-DD');

function AddModal({
  title,
  subTitle,
  buttonText1,
  onPressButton1,
  showModal,
  setShowModal,
  buttonText2,
  onPressButton2,
  showCards = false,
  showEmpDel = false,
  isDependent = false,
  showSlabs = false,
  showDependentDel = false,
  policy = {},
  selSlab = false,
  showEnrolMd = false,
  handleCloseBtn,
  checkboxHandleClose = false,
  loadingStatus,
  enrolStatus,
}) {
  const [leavingDateValue, setLeavingDateValue] = useState();
  const [selectedSlab, setSelectedSlab] = useState();
  const [leavingDateDisable, setLeavingDateDisable] = useState(true);
  const leavingDate = useFormInput('');
  const trackClick = useSegment('click');
  const location = useLocation();
  const callTrackClick = (action, record)=> trackClick(getTrackClickObj(
    action, location?.pathname, 'HRD EmployeePage Events', {record}));

  const onLeaveDateChange = (e) => {
    setLeavingDateDisable(e.target.value === '');
    setLeavingDateValue(e.target.value);
  };

  return (
    <Modal
      className="modal"
      size="lg"
      backdrop={'static'}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showModal}
      onHide={() => {
        const clickParms = isDependent ? [
          'VIEW_EMPLOYEE_DELETE_DEPENDENT_MODAL_CLOSE', {}, {
            // clicked_dependent_id: id ?? '',
            // clicked_dependent_name: firstName?.value ? firstName?.value + lastName?.value : lastName?.value,
            // clicked_dependent_parent_id: userData?.userId ?? id,
          }
        ] : [
          'VIEW_EMPLOYEE_DELETE_EMPLOYEE_MODAL_CLOSE', {
            // empid: userData?.userId ?? id, compId: companyId
          }
        ];
        callTrackClick(...clickParms);
        setShowModal(false)
      }}
      keyboard={false}
    >
      {checkboxHandleClose ? (
        <Modal.Header>
          <CloseButton onClick={() => handleCloseBtn()} />
          {title && (
            <Modal.Title id="contained-modal-title-vcenter">
              <span className="modalTitle">{title}</span>
              <span className="modalSubTitle">{subTitle}</span>
            </Modal.Title>
          )}
        </Modal.Header>
      ) : (
        <Modal.Header closeButton>
          {title && (
            <Modal.Title id="contained-modal-title-vcenter">
              <span className="modalTitle">{title}</span>
              <span className="modalSubTitle">{subTitle}</span>
            </Modal.Title>
          )}
        </Modal.Header>
      )}

      <Modal.Body className="show-grid">
        <Container>
          <div className="col-md-12 text_align">
            {showCards && (
              <div>
                <ModalCard
                  action={onPressButton1}
                  image="/assets/images/single_Add.svg"
                  title="Add an Employee"
                  description="Add a single employee along with dependents to their policies"
                />
                <ModalCard
                  action={onPressButton2}
                  image="/assets/images/bulk_add.svg"
                  title="Bulk Add/Delete Employees"
                  description="Add or delete multiple employees and dependents by uploading an excel doc"
                />
              </div>
            )}
            {showEmpDel ? (
              <div>
                <div className="modal_empDel_1">
                  <h3>{isDependent ? 'Delete Dependent': 'Delete Employee'}</h3>
                  <p>Are you sure you want to delete this entry?</p>
                  <p>
                    You can submit a request to delete and we will remove this
                    entry soon.
                  </p>
                  <p>
                    Please note that removing an employee will also remove all
                    the dependents.
                  </p>
                </div>
                {!isDependent ? (
                  <div className="modal_empDel_2">
                    <div className="model_empDel_2_form">
                      <DatePicker
                        labelName="Date of leaving"
                        source="delete_modal"
                        field={leavingDate}
                        min={MIN_LEAVING_DATE}
                        max={MAX_LEAVING_DATE}
                        onChange={onLeaveDateChange}
                      />
                    </div>
                  </div>
                ): null}
                <div className="modal_empDel_3">
                  <div className="modal_empDel_3_button">
                    {buttonText2 ? (
                      <button
                        className="modal_empDel_3_button_cancel"
                        onClick={onPressButton2}
                      >
                        {buttonText2}
                      </button>
                    ) : null}
                    <button
                      className="modal_empDel_3_button_submit"
                      disabled={(!isDependent && leavingDateDisable) || loadingStatus === 'loading'}
                      onClick={() =>
                        onPressButton1(leavingDateValue, 'employee')
                      }
                    >
                      {/* Submit Request */}
                      {loadingStatus === 'loading' ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          />
                          <span className="sr-only">Loading...</span>
                        </>
                      ) : (
                        'Submit Request'
                      )}
                    </button>
                  </div>
                </div>
              </div>
            ) : null}

            {showDependentDel && (
              <DependentDeleteModal
                buttonText2={buttonText2 ? buttonText2 : ''}
                onPressButton1={onPressButton1}
                onPressButton2={onPressButton2}
                loadingStatus={loadingStatus}
              />
            )}

            {showEnrolMd && (
              <EnrollModal
                buttonText2={buttonText2 ? buttonText2 : ''}
                buttonText1={buttonText1 ? buttonText1 : ''}
                onPressButton1={onPressButton1}
                onPressButton2={onPressButton2}
                loadingStatus={loadingStatus}
                enrolStatus={enrolStatus}
              />
            )}

            {showSlabs && (
              <div className="slabs_main">
                <div className="col-md-12">
                  <div className="left_image pull-left">
                    <img
                      src={policy?.insurerLogo ?? ''}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src="/assets/icons/icon_Care.svg";
                      }}
                      alt="Insurer Img"
                      className="insurer_img"
                    />
                  </div>
                  <div className="right_text pull-left">
                    <h4>
                      {getPolicyType(
                        policy?.policyType,
                        policy?.isTopUpPolicy,
                        policy?.isParentalPolicy
                      )}
                    </h4>
                    <span>{policy?.insurerName}</span>
                  </div>
                </div>
                <div className="modal_policy_slabs">
                  <label>Choose an amount</label>
                </div>
                <div className="slab_choose_form">
                  <ul>
                    {policy?.sumAssuredSlabs?.map((slab) => {
                      return (
                        <li
                          key={slab.slabId} 
                          onClick={() => setSelectedSlab(slab)}
                        >
                          <div className="slab_radio_left">
                            <div className="section_radio_one">
                              {slab.slabAmount === selSlab ? (
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  value={slab.slabAmount}
                                  defaultChecked
                                  readOnly
                                  checked={selectedSlab?.slabId === slab?.slabId}
                                />
                              ) : (
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  value={slab.sumInsured}
                                  readOnly
                                  checked={selectedSlab?.slabId === slab?.slabId}
                                />
                              )}
                              <div className="">
                                <label>
                                  Sum Insured
                                  <span>{getAmountInLacs(slab.sumInsured)}</span>
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="slab_dept_right">
                            <div className="">
                              <label>
                                Supported Dependents
                                <span>{slab?.familyDefinition?.value ? slab?.familyDefinition?.value : '-'}</span>
                              </label>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                  <div className="slab_selection_button">
                    <button
                      onClick={() =>
                        selectedSlab && onPressButton1(
                          policy?.id,
                          selectedSlab,
                          policy,
                        )
                      }
                      disabled={!selectedSlab}
                    >
                      Proceed
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}
const ModalCard = ({ action, image, title, description }) => {
  return (
    <div className="col-md-6 display_inline">
      <div className="container">
        <div className="card" onClick={action}>
          <div className="card-body">
            <img className="cardImage" src={image} alt="" />
            <h1 className="cardText">{title}</h1>
            <p className="cardDescription">{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const EnrollModal = ({
  buttonText1,
  buttonText2,
  onPressButton2,
  onPressButton1,
  loadingStatus,
  enrolStatus,
}) => {
  return (
    <div>
      <div className="modal_empDel_1">
        <h3>Are you sure?</h3>
        <p>Do you want to {buttonText1.toLowerCase()} for this policy?</p>
      </div>

      <div className="modal_empDel_3">
        <div className="modal_empDel_3_button">
          {buttonText2 ? (
            <button
              className="modal_empDel_3_button_cancel"
              onClick={onPressButton2}
            >
              {buttonText2}
            </button>
          ) : null}
          <button
            className="modal_empDel_3_button_enroll"
            onClick={onPressButton1}
            disabled={loadingStatus === 'loading'}
          >
            {loadingStatus === 'loading' || enrolStatus === 'loading' ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="sr-only">Loading...</span>
              </>
            ) : (
              buttonText1
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

const DependentDeleteModal = ({
  buttonText2,
  onPressButton2,
  onPressButton1,
  loadingStatus,
}) => {
  return (
    <div>
      <div className="modal_empDel_1">
        <h3>Delete Dependent</h3>
        <p>Are you sure you want to delete this entry?</p>
        <p>
          You can submit a request to delete and we will remove this entry soon.
        </p>
      </div>

      <div className="modal_empDel_3">
        <div className="modal_empDel_3_button">
          {buttonText2 ? (
            <button
              className="modal_empDel_3_button_cancel"
              onClick={onPressButton2}
            >
              {buttonText2}
            </button>
          ) : null}
          <button
            className="modal_empDel_3_button_submit"
            disabled={loadingStatus === 'loading'}
            onClick={() => onPressButton1('', 'dependent')}
          >
            {loadingStatus === 'loading' ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="sr-only">Loading...</span>
              </>
            ) : (
              'Submit Request'
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export { AddModal };
