import { createAsyncThunk } from '@reduxjs/toolkit';
import LoopApiService from '../../../adaptars/LoopApiService';

export const fetchCompanyById = createAsyncThunk(
  'company/getCompanyById',
  async (companyId: string) => {
    try {
      const result = await LoopApiService.getCompanyById(companyId);
      return { data: result.company };
    } catch (error) {
      throw error;
    }
  },
);
export const fetchCompanyConfig = createAsyncThunk(
  'company/fetchCompanyConfig',
  async (companyId: string) => {
    try {
      const result = await LoopApiService.getCompanyConfigById(companyId);
      return { data: result };
    } catch (error) {
      throw error;
    }
  },
);

// unused. kept for future use
export const fetchDependentList = createAsyncThunk(
  'company/fetchDependentList',
  async ({ employerId }: { employerId: string }) => {
    try {
      const result = await LoopApiService.fetchDependentApi(employerId);
      return { data: result && result.data ? result.data : [] };
    } catch (error) {
      throw error;
    }
  },
);
