import React from 'react';
import './Pagination.scss';

const ServerSidePagination = (
  {
    paginationComponentOptions: { filteredDataLength, noUiFilter, pageNo, onChangePage, hasNext, hasPrev },
    rowsPerPage,
  }) => {
  const nextDisabled = !hasNext || (noUiFilter && filteredDataLength !== rowsPerPage);
  const previosDisabled = !hasPrev;
  return (
    <div className='pagination-wrapper'>
      {/* <div>page {pageNo+1}</div> */}
      <ul className="pagination">
        <li className="page-item">
          <button
            className="page-link"
            onClick={()=> onChangePage(pageNo - 1)}
            disabled={previosDisabled}
            aria-disabled={previosDisabled}
            aria-label="previous page"
          >
            Previous
          </button>
        </li>
        <li className="page-item">
          <button
            className="page-link"
            onClick={()=> onChangePage(pageNo + 1)}
            disabled={nextDisabled}
            aria-disabled={nextDisabled}
            aria-label="next page"
          >
            Next
          </button>
        </li>
      </ul>
    </div>
  );
};
export default ServerSidePagination;
