import { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { IEmpTabs, transformEmpDepListForUI } from '../utils';
import { IEmpDetailsUI } from '../types';
import { parseResponse } from '../../../../utils/common/Utilities';
import EmployeeApi from '../../../../adaptars/employeeApi';
import LoopApiService from '../../../../adaptars/LoopApiService';
import { RelationConstants } from '../../../../utils/constants/constants';
import { useHistory } from 'react-router-dom';

export const useFetchEmployeeDetails = (referrer: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [employeeId, setEmployeeID] = useState<string | null>(null);
  const [employeeData, setEmployeeData] = useState<IEmpDetailsUI | null>(null);
  const history = useHistory();
  useEffect(() => {
    let isMounted = true;
    const urlSearchParams = new URLSearchParams(window.location.search);
    const empId = urlSearchParams.get('id');
    if (!empId) {
      toast.error('Emp Id not found');
      history.goBack();
      return;
    }

    const fetchEmpId = async (empId: string) => {
      const [error, result] = await parseResponse(
        LoopApiService.getUserDetails(empId),
      );

      if (error || !result.data?.user?.relationship) {
        toast.error(error?.message || 'Something went wrong!');
        history.goBack();
        return;
      }

      const fetchedEmpId =
        result.data?.user?.relationship?.toLowerCase() ===
        RelationConstants.self
          ? empId
          : result.data?.user?.parentId;

      return fetchedEmpId;
    };

    const fetchEmpDetails = async () => {
      if (!isMounted) {
        return;
      }
      let empIdResult = empId;
      setIsLoading(true);
      if ([IEmpTabs.Claims, 'GlobalSearch', 'EndoDetails'].includes(referrer)) {
        empIdResult = await fetchEmpId(empId);
      }

      const [error, result] = await parseResponse(
        EmployeeApi.getEmployeeDetails(empIdResult),
      );
      setIsLoading(false);

      if (error) {
        toast.error(error.message || 'Something went wrong!');
        return;
      }
      const empData: IEmpDetailsUI = transformEmpDepListForUI(
        result?.data?.policyWiseFamilyDetails,
      );
      setEmployeeID(empData.empOverviewDetails.employeeId ?? null);
      setEmployeeData(empData);
    };

    if (isMounted && empId) {
      fetchEmpDetails();
    }

    return () => {
      isMounted = false;
    };
  }, [history, referrer, window.location.search]);
  const memoizedEmployeeData = useMemo(() => employeeData, [employeeData]);
  return {
    isLoading,
    employeeData: memoizedEmployeeData,
    employeeId,
  };
};
