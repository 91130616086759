import React from 'react';
import { Modal, Container } from 'react-bootstrap';
import './addModal.css';
function ConfirmationNotifyModal({
  title,
  subTitle,
  buttonText1,
  onPressButton1,
  showModal,
  setShowModal,
  variant = 'success',
  buttonText2,
  onPressButton2,
  size = 'lg',

  policyInfo = {},
}) {
  return (
    <Modal
      className="modal"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showModal}
      // onHide={setShowModal}
      backdrop={'static'}
    >
      <Modal.Body className="show-grid">
        <Container>
          <div className="col-md-12 text_align">
            <div className="modal_empDel_1 cnm_body">
              <img src={'/assets/images/add_success.svg'} alt="Success" />
              <h3>Member Added Successfully</h3>
              <p>
                We will need sometime to prepare the e-card in the meantime you
                can also add dependents from the employee detail page.
              </p>
            </div>

            <button className="cnm_success_btn" onClick={onPressButton1}>
              {buttonText1}
            </button>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export { ConfirmationNotifyModal };
