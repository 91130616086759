import { remoteConfig } from '../../firebase';

const Feature = {
  ANALYTICS_DASHBOARD: 'REACT_APP_FEATURE_ANALYTICS_DASHBOARD',
  POLICY_START_DATE_RESOLVER: 'REACT_APP_POLICY_START_DATE_RESOLVER',
  ENDORSEMENTS: 'REACT_APP_FEATURE_ENDORSEMENTS',
  ENDO_NOTIFICATIONS: 'REACT_APP_ENDO_NOTIFICATIONS',
  NEW_HRD: 'REACT_APP_FEATURE_NEW_HRD',
  NEW_LOGIN: 'REACT_APP_FEATURE_NEW_LOGIN',
  DB_CALLS_DEPRECATED: 'REACT_APP_DB_CALLS_DEPRECATED',
  POLICY_PAL: 'REACT_APP_POLICY_PAL',
};

const isFeatureEnabled = (feature) => {
  const env = process.env;
  if (Object.keys(env).includes(feature.toString())) {
    return JSON.parse(env[feature.toString()]);
  }
  return false;
};
export const isClaimsDashboardEnabled = isFeatureEnabled(
  Feature.ANALYTICS_DASHBOARD,
);
export const isEndoVisible = isFeatureEnabled(Feature.ENDORSEMENTS);
export const isEndoNotificationEnabled = isFeatureEnabled(Feature.ENDO_NOTIFICATIONS);
export const isPolicyStartDateResolverEnabled = isFeatureEnabled(
  Feature.POLICY_START_DATE_RESOLVER,
);
export const isDbCallsDeprecated = isFeatureEnabled(
  Feature.DB_CALLS_DEPRECATED,
);
export const isHRDEnabled = isFeatureEnabled(Feature.NEW_HRD);
export const isNewLoginEnabled = isFeatureEnabled(Feature.NEW_LOGIN);
export const isPolicyPalEnabled = isFeatureEnabled(Feature.POLICY_PAL);

export const fetchRemoteConfig = async (key) => {
  try {
    await remoteConfig.fetchAndActivate();
    const policyPalHrAccessAsString = remoteConfig.getValue(key).asString();
    const policyPalHrAccess = JSON.parse(policyPalHrAccessAsString);
    return policyPalHrAccess;
  } catch (err) {
    throw err;
  }
};
