import { utils } from 'xlsx-js-style';
import {
  DataSchemaBody,
  DataSchemaHeader,
  GeneralInstructionsBody,
  GeneralInstructionsHeader,
  createCell,
  createErrorCell,
  createErrorDescriptionCell,
  createPoliciesBody,
  readmePolicyHeader,
} from './static';
import {
  COMMON_DATA_SCHEMA,
  CTC_MULTIPLIER_SCHEMA,
  FLAT_SUM_SCHEMA,
  GRADED_TIER_SCHEMA,
  SheetErrorKeyMap,
} from './constant';
import { capitalizeFirstLetterOfEachWord } from '../../../../../utils/common/Utilities';
import { IPolicyListItemType } from '../../../../../redux/slices/hrdRevampRedux/types';
import { IRejectedAddEntity, IPolicyRejectedEntries } from '../types';
import { KEY_NAME_MAP } from '../../../../containers/ValidationsScreen/constants';

export const getPolicyType = (policyData: IPolicyListItemType) => {
  let policyType = policyData.policyType;
  const topup = policyData.isTopUpPolicy ? 'TOPUP' : '';
  const parental = policyData.isParentalPolicy ? 'PARENTAL' : '';
  if (parental !== '') {
    policyType += ` ${parental}`;
  }
  if (topup !== '') {
    policyType += ` ${topup}`;
  }
  return policyType;
};

const getFileNames = (data: IPolicyListItemType[]): string[] => {
  return data.map((policy) => {
    const insurerName = policy.insurerName;
    const policyType = getPolicyType(policy);
    const name = policyType + ` (${insurerName})`;
    // excel sheets cannot have name > than 31 chars
    if (name.length > 31) {
      const difference = 31 - name.length;
      if (difference >= insurerName.length) {
        return policyType;
      } else {
        // -3 for ellipsis
        return (
          policyType + ` (${insurerName.slice(0, difference - 3).trim()}...)`
        );
      }
    }
    return name;
  });
};

export const createReadMeDataSheet = (policiesData: IPolicyListItemType[]) => {
  const PoliciesBody = createPoliciesBody(policiesData);
  const wsData = [
    GeneralInstructionsHeader,
    GeneralInstructionsBody,
    [],
    [],
    readmePolicyHeader,
    ...PoliciesBody,
    [],
    [
      {},
      {
        v: 'DATA SCHEMA',
        t: 's',
        s: {
          font: {
            color: { rgb: '000000' },
            bold: true,
          },
          fill: { fgColor: { rgb: 'ffffff' } },
          alignment: {
            vertical: 'center',
            horizontal: 'left',
            wrapText: true,
          },
        },
      },
    ],
    DataSchemaHeader,
    ...DataSchemaBody,
  ];

  return utils.aoa_to_sheet(wsData, {
    cellDates: true,
    dateNF: 'dd mmm yyyy',
  });
};

export const generateErrorTemplate = (
  rejectedEntries: IPolicyRejectedEntries,
  policiesData: IPolicyListItemType[],
) => {
  const wb = utils.book_new();
  const readMeSheet = createReadMeDataSheet(policiesData);
  readMeSheet['!cols'] = [
    { width: 10 },
    { width: 15 },
    { width: 25 },
    { width: 35 },
    { width: 25 },
    { width: 20 },
    { width: 20 },
  ];
  readMeSheet['!rows'] = [{ hpt: 22 }, { hpt: 40 }];
  const merge = [{ s: { r: 1, c: 2 }, e: { r: 1, c: 4 } }];
  readMeSheet['!merges'] = merge;
  utils.book_append_sheet(wb, readMeSheet, 'READ ME');
  getFileNames(policiesData).forEach((sheetName, i) => {
    const sheet = createErrorSheets(
      policiesData[i].id,
      policiesData[i].sumInsuredStructure,
      rejectedEntries,
    );
    if (sheet) {
      sheet['!cols'] = Array.from({ length: 13 }, (_, index) => ({
        width: index === 0 ? 10 : 35,
      }));
      sheet['!rows'] = [{ hpt: 22 }, { hpt: 30 }];
      utils.book_append_sheet(wb, sheet, sheetName);
    }
  });
  return wb;
};

export const getPolicySheetColumns = (sumInsuredStructure: string): any => {
  const common = COMMON_DATA_SCHEMA.filter(
    (v: { isMandatory: string }) => v.isMandatory !== 'Conditional',
  );
  switch (sumInsuredStructure) {
    case 'CTC Multiplier':
      common.push(...CTC_MULTIPLIER_SCHEMA);
      break;
    case 'Flat Sum Insured':
      common.push(...FLAT_SUM_SCHEMA);
      break;
    case 'Graded Tier':
      common.push(...GRADED_TIER_SCHEMA);
      break;
  }
  const infoHeader = common.map((column: any) => ({
    v: column.isMandatory,
    t: 's',
    s: {
      font: {
        color: {
          rgb:
            column.isMandatory === 'Optional'
              ? 'b45f07'
              : column.isMandatory === 'Informational'
              ? 'cc0100'
              : '274e12',
        },
        italic: true,
      },
      fill: {
        fgColor: {
          rgb:
            column.isMandatory === 'Optional'
              ? 'fce5cd'
              : column.isMandatory === 'Informational'
              ? 'f3cccb'
              : 'd9ead3',
        },
      },
      alignment: {
        vertical: 'center',
        horizontal: 'center',
        wrapText: true,
      },
    },
  }));
  const columnHeader = common.map((column: any) => ({
    v: column.name,
    t: 's',
    s: {
      font: { color: { rgb: 'ffffff' }, bold: true },
      fill: {
        fgColor: {
          rgb:
            column.isMandatory === 'Optional'
              ? 'b45f07'
              : column.isMandatory === 'Informational'
              ? `990001`
              : '274e12',
        },
      },
      alignment: {
        vertical: 'center',
        horizontal: 'center',
        wrapText: true,
      },
    },
  }));
  return { infoHeader, columnHeader };
};

const getFormattedItem = (item: any, sumInsuredStructure: string) => {
  let commonFields: {
    's.no': number;
    employee_id: string;
    name: string;
    relationship_to_account_holders: string;
    gender: string;
    policy_start_date: string;
    date_of_birth: string;
    mobile: string;
    email_address: string;
    errors?: string;
    error?: string;
    ctc?: string;
    sumInsured?: string;
    grade?: string;
  } = {
    's.no': item.index + 1,
    employee_id: item.employee_id ?? '',
    name: item.name ?? '',
    relationship_to_account_holders: item.relationship_to_account_holders ?? '',
    gender: item.gender ?? '',
    policy_start_date: item.policy_start_date ?? '',
    date_of_birth: item.date_of_birth ?? '',
    mobile: item.mobile ?? '',
    email_address: item.email_address ?? '',
  };
  if (sumInsuredStructure === 'CTC Multiplier') {
    commonFields.ctc = item.sumInsured ?? '';
    commonFields.errors = item.error ?? '';
  } else if (sumInsuredStructure === 'Flat Sum Insured') {
    commonFields.sumInsured = item.sumInsured ?? '';
    commonFields.errors = item.error ?? '';
  } else if (sumInsuredStructure === 'Graded Tier') {
    commonFields.grade = item?.grade ?? '';
    commonFields.sumInsured = item.sumInsured ?? '';
    commonFields.errors = item.error;
  }
  return commonFields;
};

const getFormattedData = (
  apiData: IRejectedAddEntity[] | null,
  sumInsuredStructure: string,
) => {
  return apiData?.map((item: IRejectedAddEntity, index: number) => {
    return getFormattedItem({ ...item, index }, sumInsuredStructure);
  });
};

const formatErrorsCell = (value: any) => {
  try {
    const parsedErrors = JSON.parse(value);
    return Object.entries(parsedErrors)
      .map(
        ([key, errorValue]) =>
          `${KEY_NAME_MAP[key] ?? key} - ${capitalizeFirstLetterOfEachWord(
            errorValue,
          )}`,
      )
      .join('\n');
  } catch (error: any) {
    return '';
  }
};

const mapDataToSheet = (formattedData: any[]) => {
  try {
    let result: any[] = [];
    formattedData.forEach((d: any) => {
      if (!d || typeof d !== 'object') {
        return;
      }
      let errors: any = null;
      const errorKey = Object.keys(d).find((key) => key.includes('errors'));
      if (errorKey) {
        if (typeof d[errorKey] !== 'undefined') {
          errors = JSON.parse(d[errorKey]);
        }
      }
      let temp: any = [];
      console.log('formatted data', formattedData);
      Object.keys(d).forEach((key: any, index: number) => {
        if (key === 'errors') {
          temp.push(createErrorDescriptionCell(formatErrorsCell(d[key])));
        } else {
          if (key in SheetErrorKeyMap) {
            let errorKey = SheetErrorKeyMap[key];
            if (errors && errors[errorKey]) {
              temp.push(
                createErrorCell(capitalizeFirstLetterOfEachWord(d[key])),
              );
            } else {
              temp.push(createCell(d[key]));
            }
          } else {
            temp.push(createCell(d[key]));
          }
        }
      });
      result.push(temp);
    });
    return result;
  } catch (error) {
    return [];
  }
};

export const createErrorSheets = (
  policyId: string,
  sumInsuredStructure: string,
  rejectedEntries: IPolicyRejectedEntries,
) => {
  const sheetHeaders = getPolicySheetColumns(sumInsuredStructure);
  let processedResponse = null;
  if (Object.keys(rejectedEntries).includes(policyId)) {
    processedResponse = rejectedEntries[policyId];
  }
  const formattedData = getFormattedData(
    processedResponse,
    sumInsuredStructure,
  );
  const sheetData = [
    sheetHeaders.infoHeader,
    sheetHeaders.columnHeader,
    ...mapDataToSheet(formattedData as any),
  ];
  return utils.aoa_to_sheet(sheetData, {
    cellDates: true,
    dateNF: 'dd mmm yyyy',
  });
};
