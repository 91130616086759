/* eslint-disable require-jsdoc */
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/analytics';
import LoopEndpoints from './LoopEndpoints';
import LoopHttpException from '../utils/exceptions/LoopHttpException';
import { removeEmpty } from '../utils/common/Utilities';
import { isDbCallsDeprecated } from '../utils/featureFlag';
import { nanoid } from 'nanoid';
import {
  FirebaseAuth,
  FirebaseCaptcha,
  FirebaseStorage,
  Firestore,
  del,
  get,
  post,
  put,
  uploadFile,
} from './providers';
import { write } from 'xlsx';

export const fieldValue = firebase.firestore.FieldValue;
export const timeStamp = firebase.firestore.Timestamp;

class LoopApiService {
  constructor() {
    this.captcha = FirebaseCaptcha;
    this.auth = FirebaseAuth;
    this.db = Firestore;
    this.storage = FirebaseStorage;
    this.fromDate = firebase.firestore.Timestamp.fromDate;
    // TODO: Need to add base URL from env file
    this.BASE_URL = process.env.REACT_APP_FIREBASE_FUNCTION_BASE_URL;
    // this.BASE_URL = 'http://localhost:3009/loophealth-patient-dev/asia-south1/backendApi';
    this.DEFAULT_TIMEOUT = 60;
    this.APP_NAME = process.env.REACT_APP_NAME;
  }

  getHttpConfig = (token, verb = 'GET') => ({
    method: verb,
    headers: {
      accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      'loop-source': this.APP_NAME ?? '',
      'trace-id': nanoid(),
    },
    // origin: "hr-app-dev.web.app",
  });

  getContentType = (res) => {
    const isJSON =
      res.headers.get('Content-Type')?.startsWith('application/json') || false;

    if (isJSON) {
      return 'JSON';
    }

    const isText = res.headers.get('Content-Type')?.startsWith('text') || false;
    if (isText) {
      return 'Text';
    }

    return 'Unsupported';
  };

  processResponse = async (res) => {
    const contentType = this.getContentType(res);

    if (res.ok) {
      if (contentType === 'JSON') {
        return await res.json();
      } else {
        return res;
      }
    }
    return this.doThrow(res, contentType);
  };

  doThrow = async (res, contentType) => {
    // Not 2XX

    if (contentType === 'JSON') {
      const result = await res.json();
      let error;
      if (Array.isArray(result) && result.length > 0) {
        error = result[0];
      } else {
        // error = result;
        error = 'message' in result ? JSON.stringify(result.message) : result;
      }
      throw new LoopHttpException(res.status, error, res.url, result);
    } else if (contentType === 'Text') {
      // TODO: Check API to see if handled differently
      const error = await res.text();
      const message = error ? error : '';
      throw new LoopHttpException(res.status, message, res.url);
    }

    // Not JSON and not Text
    throw new LoopHttpException(
      res.status,
      'Unsupported Content Type',
      res.url,
    );
  };

  fullUrlApi = async ({ fullUrl, token }) => {
    const reqConfig = this.getHttpConfig(token, 'GET');
    const finalConfig = {
      // signal: controller.signal,
      ...reqConfig,
    };
    const response1 = await fetch(fullUrl, finalConfig);
    return this.processResponse(response1);
  };

  api = async ({ endpoint, verb, token, timeout, data }) => {
    const reqConfig = this.getHttpConfig(token, verb);

    const fullUrl = `${this.BASE_URL}${endpoint}`;
    const reqTimeout = timeout || this.DEFAULT_TIMEOUT;
    const controller = new AbortController();
    let finalConfig;

    if (!data) {
      finalConfig = {
        signal: controller.signal,
        ...reqConfig,
      };
    } else {
      finalConfig = {
        signal: controller.signal,
        body: JSON.stringify(data),
        ...reqConfig,
      };
    }
    const abort = setTimeout(() => controller.abort(), reqTimeout * 1000);

    const response = await fetch(fullUrl, finalConfig);
    clearTimeout(abort);
    return this.processResponse(response);

    // return this.processResponse(response);
  };

  getCompanyById = async (companyId) => {
    try {
      const response = await get(LoopEndpoints.companyDetails(companyId));
      if (!response?.data) {
        throw new Error(response.message ?? 'getCompanyById failed');
      }
      return response.data;
    } catch (e) {
      throw e;
    }
  };
  getCompanyConfigById = async (companyId) => {
    try {
      const response = await get(LoopEndpoints.companyConfig(companyId));
      if (!response?.data) {
        throw new Error(response.message ?? 'getCompanyConfigById failed');
      }
      return response.data;
    } catch (e) {
      throw e;
    }
  };

  fetchClaimsAnalyticsData = async () => {
    try {
      const response = await get(LoopEndpoints.claimsAnalytics);
      if (!response?.data) {
        throw new Error(response.message ?? 'Unable to fetch claim data');
      }
      return response.data;
    } catch (e) {
      throw e;
    }
  };

  getEmployeeData = async (params) => {
    try {
      const searchParams = new URLSearchParams(removeEmpty(params)).toString();
      const endpoint =
        searchParams.length > 0
          ? LoopEndpoints.userV2 + '?' + searchParams
          : LoopEndpoints.userV2;
      const response = await get(endpoint);
      if (!response.data) {
        throw new Error(response.message ?? 'getEmployeeData /user failed');
      }
      return response.data;
    } catch (e) {
      throw e;
    }
  };

  fetchPolicyPreferenceByUserIdAndRel = async ({ userId, relationship }) => {
    try {
      const endpoint = LoopEndpoints.fetchPolicyPreferenceByUserIdAndRel(
        userId,
        relationship,
      );
      const response = await get(endpoint);
      if (!response.data) {
        throw new Error(response.message ?? 'fetchPolicyPreference failed');
      }
      return response.data;
    } catch (e) {
      throw e;
    }
  };

  fetchAllChangeRequestsAPI = async (filterChangeRequestOptions) => {
    try {
      const paramsString = Object.entries(filterChangeRequestOptions)
        .map(([k, v]) =>
          Array.isArray(v)
            ? `${k}=${v.join(',')}`
            : typeof v === 'string' || typeof v === 'number'
            ? `${k}=${v}`
            : ``,
        )
        .filter(Boolean)
        .join('&');
      const endpoint =
        paramsString !== ''
          ? LoopEndpoints.changeRequest + '?' + paramsString
          : LoopEndpoints.changeRequest;
      const response = await get(endpoint);
      if (!response.data) {
        throw new Error(response.message ?? 'ChangeRequestsAPI failed');
      }
      return response.data;
    } catch (e) {
      throw e;
    }
  };

  getHRDetails = async (userId, methodType, loginMethod) => {
    try {
      // NOTE: Method type like how the user going to login e.g. using phone or email.

      if (isDbCallsDeprecated) {
        const response = await get(LoopEndpoints.fetchUser(methodType, userId));
        if (!response) throw new Error('Cannot fetch user details');
        return (
          // (response.data && response.data.length && response.data[0]) || null
          response
        );
      } else {
        const data = [];
        const querySnapShot = await this.db
          .collection('hr')
          .where('active', '==', true)
          .where(methodType, '==', userId)
          .get();
        querySnapShot.forEach((doc) => {
          data.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        return (data.length && data[0]) || null;
      }
    } catch (error) {
      throw { loginMethod, ...error };
    }
  };

  updateHrData = async (data) => {
    if (isDbCallsDeprecated) {
      const response = await post(LoopEndpoints.updateHrData, data);
      if (!response) throw new Error('Cannot update details');
      return { data: response.data };
    } else {
      try {
        const result = await this.db
          .collection('hr')
          .doc(data.id)
          .update(data.data);
        return { data: result };
      } catch (error) {
        throw error;
      }
    }
  };
  addEmpHrRequest = async (data) => {
    return await post(LoopEndpoints.user, data);
  };
  addUserPolicy = async (data) => {
    return await post(
      LoopEndpoints.addUserPolicies(data.userId, data.policyId),
      data,
    );
  };
  addDepHrRequest = async (data) => {
    return await post(LoopEndpoints.savaDepDetails(), data);
  };

  enrolUserToPolicy = async (data) => {
    return await post(LoopEndpoints.enrollPolicy(), data);
  };

  updateUser = async (data) => {
    return await put(LoopEndpoints.fetchUserById(data.userId), data);
  };

  fetchDependentApi = async (employerId) => {
    return await get(LoopEndpoints.fetchDependentList(employerId));
  };

  getUserDetails = async (userId) => {
    return await get(LoopEndpoints.fetchUserById(userId));
  };

  getUserPoliciesByUserId = async (userId) => {
    return await get(LoopEndpoints.fetchUserPoliciesByUserId(userId));
  };

  getUserFamilyDetails = async (data) => {
    return await get(LoopEndpoints.fetchFamilyDetails());
  };

  fetchClaimsAPI = async (companyId, employeeId = '') => {
    return await get(LoopEndpoints.claimList(companyId, employeeId));
  };

  fetchClaimsFiltersAPI = async () => {
    return await get(LoopEndpoints.claimsFilters);
  };

  fetchClaimsListAPI = async (data) => {
    return await post(LoopEndpoints.claimsList, data);
  };

  fetchClaimDetailsAPI = async (claimId) => {
    return await get(LoopEndpoints.claimDetails(claimId));
  };

  fetchClaimTimelineAPI = async (claimId) => {
    return await get(LoopEndpoints.claimTimeline(claimId));
  };
  sendClaimsRecordEmail = async () => {
    return await post(LoopEndpoints.sendClaimsRecordEmail, {});
  };

  deleteEmpDetails = async (dataVal) => {
    const userId = dataVal.userId;
    const employer = dataVal.employer;
    const leavingDate = new Date(
      dataVal.leavingDate * 1000,
    ).toLocaleDateString();
    const data = {
      companyId: employer,
      membersList: [
        {
          userId,
          dateOfLeaving: leavingDate,
          policies: '',
          relationship: dataVal.relationship,
        },
      ],
    };
    return await post(LoopEndpoints.offboardUser, data);
  };

  getCompanyPolicyPlans = async (params) => {
    try {
      const searchParams = new URLSearchParams(removeEmpty(params)).toString();
      const endpoint =
        searchParams.length > 0
          ? LoopEndpoints.policyPlan + '?' + searchParams
          : LoopEndpoints.policyPlan;
      const response = await get(endpoint);
      if (!response.data) {
        throw new Error(response.message ?? 'getCompanyPolicyPlans failed');
      }
      return response.data;
    } catch (e) {
      throw e;
    }
  };

  getCompanyPolicyDetails = async (companyId, policyId) => {
    return await get(
      LoopEndpoints.fetchCompanyPoliciesDetails(companyId, policyId),
    );
  };
  // Function to get Employee & dependant count of particular Policy
  getPolicyEmpCount = async (companyId, policyId) => {
    return await get(LoopEndpoints.fetchPolicyEmpCount(companyId, policyId));
  };

  fetchEndorsements = async (companyId) => {
    return await get(LoopEndpoints.fetchEndorsements(companyId));
  };
  fetchEndorsementById = async (id) => {
    return await get(LoopEndpoints.fetchEndorsementById(id));
  };
  fetchPolicyListForEndorsement = async (id) => {
    return await get(LoopEndpoints.policyPlanForEndo);
  };

  fetchCDAccounts = async (companyId) => {
    return await get(LoopEndpoints.cdAccounts(companyId));
  };

  downloadCDStatement = async (cdAccountId) => {
    return await get(LoopEndpoints.downloadCDStatement(cdAccountId));
  };

  askPolicy = async (question) => {
    return await post(LoopEndpoints.askPolicy, {
      question,
    });
  };

  getHRDTemplate = async (policyId) => {
    try {
      const response = await get(LoopEndpoints.downloadTemplate(policyId));
      if (!response) {
        throw new Error(response.message ?? 'Something went wrong');
      }
      return response;
    } catch (e) {
      return e;
    }
  };

  // Employees Related APIs
  fetchEmployeesListAPI = async (companyId, policyId) => {
    return await get(
      LoopEndpoints.fetchCompanyEmployeesByPolicy(companyId, policyId),
    );
  };

  fetchECard = async (userId, policyId) => {
    try {
      const response = await get(LoopEndpoints.getECard(userId, policyId));
      if (!response) {
        throw new Error(response.message ?? 'Something went wrong');
      }
      return response;
    } catch (e) {
      throw e;
    }
  };

  uploadMidtermDocument = async (employeeId, name, fileName) => {
    return await put(LoopEndpoints.midtermDocument, {
      employeeId,
      name,
      fileName,
    });
  };

  deleteMidtermDocument = async (filePath) => {
    return await del(LoopEndpoints.midtermDocument, {
      filePath,
    });
  };

  validateBulkAdditionData = async (data) => {
    return await post(LoopEndpoints.validateBulkAddition, data);
  };

  validateBulkDeletionData = async (data) => {
    return await post(LoopEndpoints.validateBulkDeletion, data);
  };

  submitBulkAddRequest = async (data) => {
    return await post(LoopEndpoints.submitBulkAddRequest, data);
  };
  submitBulkDeleteRequest = async (data) => {
    return await post(LoopEndpoints.submitBulkDeleteRequest, data);
  };

  downloadBulkAddTemplate = async (companyId) => {
    return await get(LoopEndpoints.downloadAddTemplate(companyId));
  };
  downloadBulkDeleteTemplate = async (companyId) => {
    return await get(LoopEndpoints.downloadDeleteTemplate(companyId));
  };
  estimateEndoCost = (payload) => {
    return post(LoopEndpoints.estimateEndoCost, payload);
  };

  identifyMidterms = async (users, policyId, companyId) => {
    return await post(LoopEndpoints.identifyMidTerms, {
      users,
      policyId,
      companyId,
    });
  };

  validateMidterms = async (users) => {
    return await post(LoopEndpoints.validateMidTerms, { users });
  };

  fetchEndorsementsEndDate = async (companyId) => {
    return await get(LoopEndpoints.fetchEndoDateDetails(companyId));
  };
  endoStep2ExitTrigger = async (
    errorWorkbook,
    validLives,
    invalidLives,
    totalLives,
    companyId
  ) => {
    try {
      const fileBuffer = write(errorWorkbook.current, {
        type: 'array',
        bookType: 'xlsx',
      });
      const errorFile = new File([fileBuffer], 'workbook.xlsx', {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const formData = new FormData();
      formData.append('attachment', errorFile, 'Error-Sheetq.xlsx');
      formData.append('validLives', validLives);
      formData.append('invalidLives', invalidLives);
      formData.append('totalLives', totalLives);
      await uploadFile(LoopEndpoints.endoStep2ExitTrigger(companyId), formData);
    } catch (e) {
      console.log(e);
    }
  };

  lowCDEndoSubmissionEmailTrigger = async (uploadedFile, policies, companyId) => {
    const formData = new FormData();
    formData.append('attachment', uploadedFile[0]);
    formData.append('policies', JSON.stringify(policies));
    await uploadFile(LoopEndpoints.lowCDEndoSubmissionEmailTrigger(companyId), formData);
  };
  endoSubmissionEmailTrigger = async (uploadedFile, policies, companyId) => {
    const formData = new FormData();
    formData.append('attachment', uploadedFile[0]);
    formData.append('policies', JSON.stringify(policies));
    await uploadFile(LoopEndpoints.endoSubmissionEmailTrigger(companyId), formData);
  };
  endoStep3ExitEmailTrigger = async (
    uploadedFile,
    policiesToBeUpdated,
    endoCost,
    totalLives,
    validLives,
    invalidLives,
    companyId
  ) => {
    const formData = new FormData();
    formData.append('attachment', uploadedFile[0]);
    formData.append('policiesToBeUpdated', policiesToBeUpdated);
    formData.append('endoCost', endoCost);
    formData.append('totalLives', totalLives);
    formData.append('validLives', validLives);
    formData.append('invalidLives', invalidLives);
    await uploadFile(LoopEndpoints.endoStep3ExitTrigger(companyId), formData);
  };
}

export default new LoopApiService();
