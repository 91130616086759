import { createSlice } from '@reduxjs/toolkit';
import { apiState, initialState } from './initialState';
import { RootState } from '../../store';
import {
  fetchCompanyById,
  fetchCompanyConfig,
  fetchDependentList,
} from './thunks';

const companySlice = createSlice({
  name: 'companyProfile',
  initialState: initialState,
  reducers: {
    setDependentList(state, action) {
      state.dependentListData.data = action.payload.data;
    },
    addDependentList(state, action) {
      state.dependentListData.data = [
        ...(state.dependentListData.data ?? []),
        action.payload.data,
      ];
    },
    clearDependentState(state) {
      state.dependentListData.status = 'idle';
    },
  },
  extraReducers: {
    [fetchCompanyById.pending.toString()]: (state) => {
      state.companyDetails.status = 'loading';
      state.companyDetails.error = '';
    },
    [fetchCompanyById.fulfilled.toString()]: (state, action) => {
      state.companyDetails.status = 'succeeded';
      state.companyDetails.data = action.payload.data;
    },
    [fetchCompanyById.rejected.toString()]: (state, action) => {
      state.companyDetails.status = 'failed';
      state.companyDetails.error = action.error.message;
    },
    [fetchCompanyConfig.pending.toString()]: (state) => {
      state.companyConfig.status = 'loading';
      state.companyConfig.error = '';
    },
    [fetchCompanyConfig.fulfilled.toString()]: (state, action) => {
      state.companyConfig.status = 'succeeded';
      state.companyConfig.data = action.payload.data;
    },
    [fetchCompanyConfig.rejected.toString()]: (state, action) => {
      state.companyConfig.status = 'failed';
      state.companyConfig.error = action.error.message;
    },
    [fetchDependentList.pending.toString()]: (state) => {
      state.dependentListData.status = 'loading';
      state.dependentListData.error = '';
    },
    [fetchDependentList.fulfilled.toString()]: (state, action) => {
      state.dependentListData.status = 'succeeded';
      state.dependentListData.data = action.payload.data;
    },
    [fetchDependentList.rejected.toString()]: (state, action) => {
      state.dependentListData.status = 'failed';
      state.dependentListData.error = action.error.message;
      state.dependentListData.data = [];
    },

    'common/cleanup': (state, action) => {
      state.companyDetails = {
        ...apiState,
      };
      state.companyConfig = {
        ...apiState,
      };
      state.dependentListData = {
        ...apiState,
      };
    },
  },
});

export const selectCompanyData = (state: RootState) =>
  state.companyProfile.companyDetails;
export const selectCompanyConfig = (state: RootState) =>
  state.companyProfile.companyConfig?.data;
export const selectCompanyNameAndLogo = (state: RootState) => {
  const { companyLogo = '', name = '' } =
    state.companyProfile.companyDetails?.data ?? {};
  return { companyLogo, name };
};

export const selectDependentList = (state: RootState) =>
  state.companyProfile.dependentListData;

export const { setDependentList, addDependentList, clearDependentState } =
  companySlice.actions;

export default companySlice.reducer;
