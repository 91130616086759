import React from 'react';
import { Typography, LoopBadge } from '@loophealth/loop-ui-web-library';
import {
  StyledBullet,
  StyledStatusWidget,
  StyledContainer,
  StyledInfoWrapper,
  StyledIcon,
} from './styles';
import { IEndoStatusInfoProps } from './types';
import moment from 'moment';
import { InfoIconGray } from '../../../assets/images';
import { IEndoStatuses } from '../../pages/Endorsements/EndoDetails/types';

const EndoStatusInfoOverview: React.FC<IEndoStatusInfoProps> = ({
  status,
  submitDate,
}) => {
  return (
    <StyledStatusWidget>
      <StyledContainer>
        <Typography variant="large" weight="regular">
          Endorsement Status
        </Typography>
        <LoopBadge
          badgeType={status === 'ENDO_COMPLETE' ? 'success2' : 'warning'}
          variant="overflow"
          text={IEndoStatuses[status]}
        />
      </StyledContainer>
      <StyledContainer>
        <StyledInfoWrapper>
          <Typography variant="small" weight="semiBold">
            {`${moment(submitDate).format('MMM D, YYYY')}`}
          </Typography>
          <Typography
            variant="small"
            color="secondary"
          >{` (Submitted On)`}</Typography>
        </StyledInfoWrapper>
        <StyledBullet />
        <StyledInfoWrapper>
          <Typography variant="small" weight="semiBold">
            Takes 15 business days from the date of submission
            <Typography
              variant="small"
              color="secondary"
            >{` (Completion ETA)`}</Typography>{' '}
          </Typography>
          <StyledIcon src={InfoIconGray} />
        </StyledInfoWrapper>
      </StyledContainer>
    </StyledStatusWidget>
  );
};

export default EndoStatusInfoOverview;
