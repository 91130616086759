import {
  Form,
  LoopButton,
  Modal,
  Typography,
} from '@loophealth/loop-ui-web-library';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import LoopApiService from '../../../adaptars/LoopApiService';
import { ClaimEmailPersonIcon } from '../../../assets/images';
import { selectHrUser } from '../../../redux/slices/usersSlice';
import { parseResponse } from '../../../utils/common/Utilities';
import {
  StyledClaimsRecordEmailContainer,
  StyledEmailCtaContainer,
  StyledEmailHeaderTextContainer,
  StyledEmailModalBody,
  StyledEmailModalBodyContainer,
  StyledEmailModalHeader,
  StyledEmailTextContainer,
  StyledEmailTextboxContainer,
} from './styles';
import { ISendClaimsRecordEmailModal } from './types';

export default function SendClaimsRecordEmailModal({
  isVisible,
  setIsVisible,
}: ISendClaimsRecordEmailModal) {
  const hrData = useSelector(selectHrUser);
  const [processing, setProcessing] = useState(false);

  const onSendClaimsRecordsClicked = async () => {
    setProcessing(true);
    const [err, result] = await parseResponse(
      LoopApiService.sendClaimsRecordEmail(),
    );
    if (err) {
      toast.error(err.message || 'Some error occurred');
    } else {
      toast.success(result.message);
      setIsVisible(false);
    }
    setProcessing(false);
  };

  return (
    <Modal
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      disablePadding={true}
    >
      <StyledClaimsRecordEmailContainer>
        <StyledEmailModalHeader>
          <img src={ClaimEmailPersonIcon} />
          <StyledEmailHeaderTextContainer>
            <Typography variant="title1" weight="regular" color="tertiary">
              Just double-checking{' '}
            </Typography>
            <div>
              <Typography variant="title1" weight="regular" color="tertiary">
                your{' '}
              </Typography>
              <Typography variant="title1" weight="regular" color="green">
                Email{' '}
              </Typography>
              <Typography variant="title1" weight="regular" color="tertiary">
                {':)'}
              </Typography>
            </div>
          </StyledEmailHeaderTextContainer>
        </StyledEmailModalHeader>
        <StyledEmailModalBodyContainer>
          <StyledEmailModalBody>
            <StyledEmailTextContainer>
              <Typography variant="medium">
                We'll be sending the claim records to the email address shown
                below. You can download the file from there
              </Typography>
            </StyledEmailTextContainer>
            <StyledEmailCtaContainer>
              <StyledEmailTextboxContainer>
                <Form.Input
                  value={hrData?.data?.email || ''}
                  onChange={() => {}}
                  type="email"
                />
              </StyledEmailTextboxContainer>
              <div>
                <LoopButton
                  isLoading={processing}
                  variant="filled"
                  size="medium"
                  onClick={onSendClaimsRecordsClicked}
                >
                  Send claim records
                </LoopButton>
              </div>
            </StyledEmailCtaContainer>
          </StyledEmailModalBody>
        </StyledEmailModalBodyContainer>
      </StyledClaimsRecordEmailContainer>
    </Modal>
  );
}
