import styled from 'styled-components';

export const StyledAnalyticsContainer = styled.div`
  padding: 20px 10px;
  gap: 38px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledSummaryHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const StyledHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
`;
export const StyledDownloadReportContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
`;
export const StyledSummaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
`;
export const StyledLoaderContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(p) => p.theme.colors.mutedTransparent};
`;

export const StyledSummaryInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;
export const StyledStatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const StyledDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
`;
export const StyledSubStatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: 60px;
`;

export const StyledReportHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  white-space: nowrap;
  align-items: center;
  justify-content: space-between;
`;
export const StyledReportDropdownContainer = styled(StyledReportHeader)`
  width: 40%;
`;

export const StyledReportSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 20px;
`;
export const StyledReportGraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 600px;
  flex: 1;
  gap: 25px;
  padding: 25px 30px;
  border-radius: 13px;
  background: ${(p) => p.theme.colors.white};
  border: 1px solid ${(p) => p.theme.colors.border};
`;
export const HorizontalSeparator = styled.div`
  background: ${(p) => p.theme.colors.seafoamGray};
  height: 1px;
  width: 100%;
`;
export const ClaimDistributionContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  margin-bottom: 5px;
`;
export const CountValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  gap: 12px;
`;
export const StyledCountValueText = styled.div`
  padding: 8px 25px;
  border: 1px solid
    ${(p) => (p.isSelected ? p.theme.colors.primary : p.theme.colors.border)};
  background-color: ${(p) => (p.isSelected ? p.theme.colors.primary : '')};
  border-radius: 30px;
`;
export const StyledGraphImage = styled.img`
  height: 400px;
  width: fit-content;
  max-width: 100%;
  margin: auto;
`;
export const StyledReportTable = styled.div`
  flex: 1;
  border-radius: 13px;
  background: ${(p) => p.theme.colors.white};
  border: 1px solid ${(p) => p.theme.colors.border};
`;
