import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const StyledLeftSideContainer = styled.div`
  width: 100%;
  margin: 30px 0 160px;
`;

export const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 44px;
  padding: 70px 0 0 55px;
  justify-content: space-between;
`;
export const StyledDetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 20px;
  align-items: center;
  background-color: ${(p) => p.theme.colors.white};
  cursor: pointer;
`;
export const StyledBackIcon = styled.img`
  cursor: pointer;
`;

export const StyledDetailsSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 27px;
  gap: 20px;
  background-color: ${(p) => p.theme.colors.white};
  border-radius: 12px;
`;
export const StyledDetailsSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const StyledDataColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;
export const StyledDataRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  span {
    flex: 1;

    :last-child {
      text-align: right;
    }
  }
`;

export const StyledVerticalSpacer = styled.div`
  width: 1px;
  background-color: ${(p) => p.theme.colors.chineseWhite};
  margin: 0 75px;
`;

export const StyledRightSideContainer = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  padding: 24px 48px 24px 40px;
`;
