export const getFireBasePaginatedData = ({
    pageSize,
    argNextPageId,
    argPrevPageId,
    data,
    nextPrevSelector = 'id',
    prevPageNo,
}) => {
    const hasMore = data.length === (pageSize+1);
    let newData = data;
    if (hasMore) {
    if (!!argPrevPageId) {
        newData = data.slice(1, pageSize+1);
    } else {
        newData = data.slice(0, pageSize);
    }
    }
    const prevPageId = newData[0]?.[nextPrevSelector];
    const nextPageId = newData[pageSize-1]?.[nextPrevSelector];

    let newPageNo = prevPageNo;
    if (!!argPrevPageId) {
        newPageNo = prevPageNo-1;
    } else if (!!argNextPageId) {
        newPageNo = prevPageNo+1;
    } else if (!argPrevPageId && !argNextPageId) {
        newPageNo = 0;
    }

    let hasPrev = true;
    let hasNext = true;
    if ((newPageNo === 0 && hasMore) || (prevPageNo === 1 && !!argPrevPageId && !hasMore)) {
        hasPrev = false;
        hasNext = true;
    } else if (hasMore) {
        hasPrev = !!prevPageId;
        hasNext = !!nextPageId;
    } else if (newPageNo === 0 && !hasMore) {
        hasPrev = false;
        hasNext = false;
    } else {
        hasPrev = !argPrevPageId;
        hasNext = !argNextPageId;
    }
    
    return {
        newData, 
        newMetaData: {
            pageNo: newPageNo,
            hasPrev,
            hasNext,
            nextPageId,
            prevPageId
        }
    };
}