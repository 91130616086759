import {
  IEndoTableEntry,
  IEndorsementDataForUI,
  IEndorsementDetailsResponse,
  IEndorsementPremiumDetails,
  MemberDataEntity,
  MemberDataOperationType,
} from './types';
import { mapToPolicyTypeFullForm } from '../../../../utils/common/employee';
import { formatCurrencyAmount, textPlaceholder } from '../../../../utils/common/Utilities';
import { IStatus } from '../ListingPage/types';
import { ITimelineElement } from '../../../containers/ClaimsTimeline/types';
import { defaultEndoTimeline } from '../../../containers/EndoTimeline/constant';
import { IEndoTimelineElement } from '../../../containers/EndoTimeline/types';
import moment from 'moment';

export const transformEndorsementDetailsForUI = (
  endorsementData: IEndorsementDetailsResponse,
): IEndorsementDataForUI => {
  const policyType = endorsementData.endorsement.policyType;
  return {
    policyName: `${mapToPolicyTypeFullForm(policyType)} (${policyType})`,
    insurerName: endorsementData.endorsement.insurerName,
    insurerLogo: endorsementData.insurerLogo,
    policyId: endorsementData.endorsement.policyId,
    memberCount: endorsementData.endorsement.livesCount,
    memberDetails: getMemberDataCounts(endorsementData.endorsement.memberData),
    // endorsementCost: formatCurrencyAmount(
    //   endorsementData?.endorsement?.endorsementCost,
    // ),
    endorsementCost: formatCurrencyAmount(
      endorsementData?.endoPremiumDetails?.additionCost -
        endorsementData?.endoPremiumDetails?.deleteCost,
    ),
    endorsementCostDetails: getEndoCostDetails(
      endorsementData?.endoPremiumDetails,
    ),
    status: endorsementData.endorsement.status as IStatus,
    policyType,
    memberData:
      transformMemberData(endorsementData.endorsement.memberData) ?? [],
    submissionDate: endorsementData.endorsement.updatedAt ?? '',
    policyNumber: endorsementData.endorsement.policyNumber,
    nickName: endorsementData.endorsement.nickName ?? '',
  };
};

export const getMemberDataCounts = (
  memberData: MemberDataEntity[],
): Record<string, string | number> => {
  const Additions = memberData?.filter(
    (data) => data.operationType === 'ADD',
  )?.length;

  const Edits = Array.from(
    new Set(
      (memberData as MemberDataEntity[])
        ?.filter((data) => data.operationType === 'EDIT')
        .map((data: any) => data.userId),
    ),
  )?.length;

  const Deletions = memberData?.filter(
    (data) => data.operationType === 'DELETE',
  )?.length;
  return {
    'No. of Additions': Additions,
    'No. of Edits ': Edits,
    'No. of Deletions': Deletions,
  };
};

export const getEndoCostDetails = (
  endoPremiumDetails: IEndorsementPremiumDetails | null,
): Record<string, string | number> => {
  return {
    'Cost of Additions': endoPremiumDetails
      ? formatCurrencyAmount(endoPremiumDetails?.additionCost)
      : '--',
    'Cost of Edits': endoPremiumDetails
      ? formatCurrencyAmount(endoPremiumDetails?.editsCost)
      : '--',
    'Refund from Deletions': endoPremiumDetails
      ? formatCurrencyAmount(endoPremiumDetails?.deleteCost)
      : '--',
  };
};

export const transformMemberData = (
  inputData: MemberDataEntity[],
): IEndoTableEntry[] => {
  return inputData.map((item) => ({
    userId: item.userId,
    employeeId: textPlaceholder(item.employeeId),
    employeeName: `${item.firstName} ${item.lastName}`,
    addedOn: item.createdAt ? moment(item.createdAt).format('DD/MMM/YYYY') : '',
    relationship: textPlaceholder(item?.relationship),
    operation: item?.operationType
      ? MemberDataOperationType[item?.operationType]
      : '',
    source: item?.source === 'HRMS' ? 'HRD' : item?.source,
    cost: item?.totalProRatePremium.toFixed(2) ?? 0,
  }));
};

export const transformTimelineData = (
  currentStatus: string,
  policyType: string,
): IEndoTimelineElement[] => {
  // const defaultTimelineCopy = [...defaultEndoTimeline];
  const defaultTimelineCopy = JSON.parse(JSON.stringify(defaultEndoTimeline));
  let foundCurrentStatus = false;

  defaultTimelineCopy.forEach((item: IEndoTimelineElement) => {
    if (item.status === currentStatus) {
      foundCurrentStatus = true;
      item.displayActive = true;
    }
    if (currentStatus === 'endo_rejected') {
      if (item.id === 'endo_rejected') {
        item.displayDefault = true;
      } else if (item.id === 'data_sent') {
        item.displayDefault = false;
      }
    }
    if (policyType === 'GPA' || policyType === 'GTL') {
      if (item.id === 'ecard_received') {
        item.displayDefault = false;
      }
    }
    item.active = !foundCurrentStatus;
  });

  return defaultTimelineCopy.filter(
    (item: ITimelineElement) => item.displayDefault,
  );
};
