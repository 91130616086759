import { IApiState, IPolicyListSlice } from './types';

export const apiState: IApiState = {
  data: null,
  status: 'idle',
  error: '',
};

export const initialState: IPolicyListSlice = {
  policyEnrolData: {
    ...apiState,
  },
  dependentPolicyData: {
    ...apiState,
  },
  companyPolicyListData: {
    ...apiState,
  },
  compPolicyDetails: {
    ...apiState,
  },
  policyEmpCount: {
    ...apiState,
  },
};
