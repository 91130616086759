import { StatusHistoryItem } from '../../containers/ClaimsStatusTimeline/types';

export interface IClaimDetailsSections {
  title: string;
  firstSectionDetails: Record<string, string>;
  secondSectionDetails: Record<string, string>;
}
export interface IClaimDetailsUI {
  claimId: string;
  claimStatus: ClaimStatus;
  patientDetails: {
    'Patient Name': string;
    'Patient DOB': string;
    'Patient Gender': string;
    'Patient Relation': string;
    'Patient Age': string;
  };
  employeeDetails: {
    'Employee Name': string;
    'Employee ID': string;
    'Employee DOJ': string;
  };
  claimAmountDetails: {
    'Claim Type': string;
    'Claimed Amount ': string;
    'Patient Sum Insured': string;
  };
  paymentDetails: {
    'Paid Amount': string;
    'Deduction Amount': string;
  };
  intimationDetails: {
    'Claim ID': string;
    UHID: string;
    'Intimation Date': string;
  };
  intimationDocsDetails: {
    'Policy No.': string;
  };
  admissionDetails: {
    'Admission Date': string;
    'Date Discharged': string;
    'Hospital Name': string;
    'Hospital City': string;
  };
  admissionCodeDetails: {
    'Primary Diagnostic/Disease': string;
  };
  policyDetails: {
    'Policy Type': string;
    'Policy No.': string;
    'Policy Start Date': string;
  };
  tpaDetails: {
    'Insurer Name': string;
    'TPA Name': string;
  };
  statusHistory: StatusHistoryItem[];
}

// Define the type for status
export type ClaimStatus =
  | 'Claim Intimated'
  | 'Claim Under Query'
  | 'Claim Under Review'
  | 'Claim Paid'
  | 'Claim Closed Disabled'
  | 'Claim Under Review Disabled'
  | 'Claim Closed'
  | 'Claim Processed'
  | 'Claim Rejected';

export type IconType = string;

export type IBadgeType = 'success' | 'error' | 'warning' | 'success2';

export const claimDetailsDataSet = (claimData: IClaimDetailsUI) => [
  {
    title: 'Patient details',
    firstSectionDetails: claimData.patientDetails,
    secondSectionDetails: claimData.employeeDetails,
  },
  {
    title: 'Payment details',
    firstSectionDetails: claimData.claimAmountDetails,
    secondSectionDetails: claimData.paymentDetails,
  },
  {
    title: 'Intimation details',
    firstSectionDetails: claimData.intimationDetails,
    secondSectionDetails: claimData.intimationDocsDetails,
  },
  {
    title: 'Admission details',
    firstSectionDetails: claimData.admissionDetails,
    secondSectionDetails: claimData.admissionCodeDetails,
  },
  {
    title: 'Policy & TPA details',
    firstSectionDetails: claimData.policyDetails,
    secondSectionDetails: claimData.tpaDetails,
  },
];
