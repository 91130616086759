import React, { FC } from 'react';
import { StyledContainer, StyledColumn, StyledPolicyInfo } from './styles';
import { Typography } from '@loophealth/loop-ui-web-library';
import { ICDAccountPolicyData } from '../../pages/CDAccounts/types';
import { StyledEclipse } from '../CDAccountInfo/styles';
import { mapToPolicyTypeFullForm } from '../../../utils/common/employee';
import { textPlaceholder } from '../../../utils/common/Utilities';

const PolicyCard: FC<{ data: ICDAccountPolicyData }> = ({ data }) => {
  return (
    <StyledContainer>
      <StyledPolicyInfo>
        <Typography variant="small" weight="semiBold" color="primary">
          {mapToPolicyTypeFullForm(data.policyType)}
        </Typography>
        <StyledColumn>
          {data.policyNickname && (
            <>
              <Typography variant="small" weight="regular" color="secondary">
                {data.policyNickname}
              </Typography>
              <StyledEclipse />
            </>
          )}
          <Typography variant="small" weight="regular" color="secondary">
            Policy No: {textPlaceholder(data.policyNumber)}
          </Typography>
        </StyledColumn>
      </StyledPolicyInfo>
    </StyledContainer>
  );
};

export default PolicyCard;
