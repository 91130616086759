import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Modal, Container } from 'react-bootstrap';
import './addModal.css';
import useFormInput from '../../../utils/hooks/useFormInput';
import moment from 'moment';
import { TagOption } from '../../atoms/TagOption/TagOption';
import { DropdownData } from '../../../utils/common/Dropdowndata';
import {
  jsonStrParse,
  getAmountInLacs,
  getPolicyType,
} from '../../../utils/common/Utilities';
import { DatePicker } from '../../atoms/datepicker/DatePicker';
import { Dropdown } from '../../atoms/Dropdown';
import { toast } from 'react-toastify';
import Backdrop from '../../atoms/Backdrop/Backdrop';
import { useDispatch, useSelector } from 'react-redux';
import { AppConstants } from '../../../utils/common/AppConstants';
import PolicyCard from './formModal/policyCard';
import {
  clearHrReqState,
  updateUserDependent,
  updateUser,
} from '../../../redux/slices/hrRequestSlice';
import {
  fetchUserDependent,
  fetchUserDetails,
} from '../../../redux/slices/hrRequestSlice/thunks';
import { fetchPolicyPreference } from '../../../redux/slices/employeesSlice/thunks';
import {
  selectPolicyPrefData,
  selectAddDeptRequest,
  clearEmpReqState,
  selectUpdateHrRequest,
  selectUpdateHrDependentRequest,
} from '../../../redux/slices/employeesSlice';
import {
  fetchUserDependentPolicies,
  fetchEnrolledPolicy,
} from '../../../redux/slices/PolicyListSlice/thunks';
import { addDependentPolicyDataByUserId } from '../../../redux/slices/PolicyListSlice';
import useSegment from '../../../utils/hooks/useSegment';
import {
  getTrackClickObj,
  getTrackTaskObj,
} from '../../../utils/common/Utilities';
import { registerMemberToPolicies } from '../../pages/Employees/legacy/addEmployeeService';
import { updateMemberToPolicies } from '../../pages/Employees/legacy/editEmployeeService';

const MAX_DOB_DATE = moment().subtract(0, 'years').format('YYYY-MM-DD');
const MIN_DOB_DATE = '1900-01-01';

export const FormModal = ({
  title,
  subTitle,
  buttonText1,
  showModal,
  setShowModal,
  buttonText2,
  onPressButton2,
  isDependent,
  id,
  hrId,
  companyId,
  userData,
  userPolicies = [],
  operation: operationProp,
  userDetails,
}) => {
  const operation = operationProp
    ? operationProp
    : isDependent
    ? AppConstants.UPDATE_DEPENDENT
    : AppConstants.UPDATE;
  const dispatch = useDispatch();
  const [dobDateError, setDOBDateError] = useState(false);
  const [genderError, setGenderError] = useState(false);
  const [dropdownError, setDropdownError] = useState(false);
  const [selPolicyError, setSelPolicyError] = useState(false);
  const [dropdownValue, setDropdownValue] = useState('');
  const [selPolicies, setSelPolicies] = useState([]);
  const [saveType, setSaveType] = useState(1);
  const [formLevelError, setFormLevelError] = useState(false);
  const [isFormReady, setIsFormReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const firstName = useFormInput(userData?.firstName || '');
  const lastName = useFormInput(userData?.lastName || '');
  const mobileNo = useFormInput(userData?.mobile || '');
  const email = useFormInput(userData?.email || '');
  const ctc = useFormInput(userData?.ctc ? userData?.ctc.toString() : '');
  const employeeId = useFormInput(userData?.employeeId || '');
  let finDob = '';
  if (typeof userData?.dob === 'object') {
    finDob =
      userData?.dob?._seconds &&
      moment(userData?.dob?._seconds * 1000).format('YYYY-MM-DD');
  }
  const dob = useFormInput(finDob);
  const relation = useFormInput(userData?.relationShip || '');
  const gender = useFormInput(userData?.gender || '');
  const policyPrefData = useSelector(selectPolicyPrefData);
  const addDeptRequest = useSelector(selectAddDeptRequest);
  const updateHrRequest = useSelector(selectUpdateHrRequest);
  const updateHrDependentRequest = useSelector(selectUpdateHrDependentRequest);
  const isCtcRequired = userPolicies?.some(
    (p) =>
      p?.policyTermsAndConditions?.sumInsuredStructure === 'CTC Multiplier',
  );
  const trackClick = useSegment('click');
  const trackTask = useSegment('track');
  const location = useLocation();
  const callTrackClick = (action, record) =>
    trackClick(
      getTrackClickObj(action, location?.pathname, 'HRD EmployeePage Events', {
        record,
      }),
    );
  const callTrackTask = (action, record) =>
    trackTask(
      getTrackTaskObj(action, location?.pathname, 'HRD EmployeePage Events', {
        record,
      }),
    );

  const clearStateIdle = useCallback(() => {
    dispatch(clearHrReqState({ data: AppConstants.ADD }));
    dispatch(clearEmpReqState({ data: AppConstants.ADD_DEPENDENT }));
    dispatch(clearEmpReqState({ data: AppConstants.UPDATE }));
    dispatch(clearEmpReqState({ data: AppConstants.UPDATE_DEPENDENT }));
  }, []);

  const setError = (error) => {
    const deStringifiedError =
      typeof error === 'string' ? jsonStrParse(error) : error;
    if (typeof deStringifiedError === 'string') {
      setFormLevelError(deStringifiedError);
    } else if (Array.isArray(deStringifiedError)) {
      deStringifiedError.forEach((element) => {
        if (element.field === 'mobile') {
          mobileNo.setErrorText(element.message);
        }
        if (element.field === 'email') {
          email.setErrorText(element.message);
        }
        if (element.field === 'employeeId') {
          employeeId.setErrorText(element.message);
        }
        if (element.field === 'firstName') {
          firstName.setErrorText(element.message);
        }
        if (element.field === 'lastName') {
          lastName.setErrorText(element.message);
        }
        if (element.field === 'ctc') {
          ctc.setErrorText(element.message);
        }
        // if (element.field === 'relationship') {
        //   relationship.setErrorText(element.message);
        // }
      });
    }
  };

  useEffect(() => {
    if (updateHrRequest?.status === 'succeeded') {
      callTrackTask('VIEW_EMPLOYEE_UPDATE_EMPLOYEE_SUCCESS');
      dispatch(clearEmpReqState({ data: AppConstants.UPDATE }));
      dispatch(fetchUserDependentPolicies(updateHrRequest.data.userId));
      if (userData?.userId) {
        dispatch(fetchEnrolledPolicy(userData.userId));
      }
      dispatch(updateUser(updateHrRequest));
      toast.success('Details Updated Successfully', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      clearForm();
      setShowModal(false);
    }

    if (updateHrRequest?.status === 'failed') {
      callTrackTask('VIEW_EMPLOYEE_UPDATE_EMPLOYEE_FAIL');
      dispatch(clearEmpReqState({ data: AppConstants.UPDATE }));
      let error =
        updateHrRequest?.error?.message ??
        updateHrRequest?.error?.name ??
        updateHrRequest?.error;
      setError(error);
    }
  }, [updateHrRequest?.status]);

  useEffect(() => {
    if (updateHrDependentRequest?.status === 'succeeded') {
      callTrackTask('VIEW_EMPLOYEE_UPDATE_DEPENDENT_SUCCESS');
      dispatch(clearEmpReqState({ data: AppConstants.UPDATE_DEPENDENT }));
      dispatch(
        fetchUserDependentPolicies(updateHrDependentRequest.data.userId),
      );
      if (userData?.userId) {
        dispatch(fetchEnrolledPolicy(userData.userId));
      }
      dispatch(updateUserDependent(updateHrDependentRequest));
      toast.success('Details Updated Successfully', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      clearForm();
      setShowModal(false);
    }

    if (updateHrDependentRequest?.status === 'failed') {
      callTrackTask('VIEW_EMPLOYEE_UPDATE_DEPENDENT_FAIL');
      dispatch(clearEmpReqState({ data: AppConstants.UPDATE_DEPENDENT }));
      let error =
        updateHrDependentRequest?.error?.message ??
        updateHrDependentRequest?.error?.name ??
        updateHrDependentRequest?.error;
      setError(error);
    }
  }, [updateHrDependentRequest?.status]);

  useEffect(() => {
    setIsFormReady(
      () =>
        firstName.errorText === '' &&
        firstName.value !== '' &&
        lastName.errorText === '' &&
        lastName.value !== '' &&
        relation.value === '' &&
        genderError === false &&
        gender.value !== '' &&
        dobDateError === false &&
        dob.value !== '',
    );
  }, [
    firstName,
    lastName,
    relation,
    gender,
    dob,
    genderError,
    dobDateError,
    policyPrefData?.status,
  ]);

  const clearForm = () => {
    //dep fields
    firstName.setValue('');
    lastName.setValue('');
    mobileNo.setValue('');
    relation.setValue('');
    dob.setValue('');
    gender.setValue('');

    //emp fields
    email.setValue('');
    ctc.setValue('');
    employeeId.setValue('');
  };
  const handleOnAddingDep = (saveType) => {
    clearForm();
    if (saveType === 1) {
      setShowModal(false);
    }
  };
  useEffect(() => {
    if (addDeptRequest?.status === 'succeeded') {
      callTrackTask('VIEW_EMPLOYEE_ADD_DEPENDENT_SUCCESS');
      toast.success('Dependent Added Successfully', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      handleOnAddingDep(saveType);
      dispatch(clearEmpReqState({ data: AppConstants.POLICY_FETCH }));
      dispatch(clearEmpReqState({ data: AppConstants.ADD_DEPENDENT }));
      if (addDeptRequest?.data?.userId) {
        dispatch(fetchUserDependent({ userId: addDeptRequest.data.userId }));
        // dependent
        if (addDeptRequest?.metaData) {
          dispatch(
            addDependentPolicyDataByUserId({
              policies: addDeptRequest?.metaData,
              userId: addDeptRequest.data.userId,
            }),
          );
        }
      }
    } else if (addDeptRequest?.status === 'failed') {
      callTrackTask('VIEW_EMPLOYEE_ADD_DEPENDENT_FAIL');
      dispatch(clearEmpReqState({ data: AppConstants.ADD_DEPENDENT }));
      let error =
        addDeptRequest?.error?.message ??
        addDeptRequest?.error?.name ??
        addDeptRequest?.error;
      setError(error);
    }
  }, [addDeptRequest?.status]);

  useEffect(() => {
    if (policyPrefData?.data?.length === 0) return;
    if (policyPrefData?.status === 'succeeded')
      setSelPolicies(policyPrefData?.data);
  }, [policyPrefData?.status]);

  const onChangeRelation = (e) => {
    setDropdownError(false);
    setSelPolicies([]);
    if (policyPrefData?.data) {
      clearStateIdle();
    }
    let relationship = e.target.value;
    if (relationship) {
      // For Gender Handling
      // if (relationship === 'Spouse') {
      // }
      let userId = id;
      dispatch(fetchPolicyPreference({ userId, relationship }));
      setDropdownValue(relationship);
    } else {
      setDropdownValue('');
    }
  };

  const onGenderSelection = (e) => {
    const genderValue = e;
    gender.setValue(genderValue);
    setGenderError(!genderValue);
  };

  const onDOBDateBlur = (e) => {
    const DOB = e.target.value;
    const newDobDateError = !DOB || DOB > MAX_DOB_DATE || DOB < MIN_DOB_DATE;
    setDOBDateError(newDobDateError);
  };

  const handleAddDependent = async (e, type) => {
    if (!dropdownValue) {
      setDropdownError(true);
    }
    e.preventDefault();
    setFormLevelError(false);
    const checkFirstValidation = firstName.finalvalidationCheck();
    const checkLastValidation = lastName.finalvalidationCheck();
    let checkMobileNoValidation = true;
    if (mobileNo.value !== '') {
      checkMobileNoValidation = mobileNo.finalvalidationCheck();
    }
    let checkCTCValidation = true;
    if (isCtcRequired) {
      checkCTCValidation = ctc.finalvalidationCheck() && ctc.value > 0;
      if (ctc.value < 1) {
        ctc.setErrorText('Cannot be zero');
      }
    }

    const DOB = dob.value;
    const newDobDateError = !DOB || DOB > MAX_DOB_DATE || DOB < MIN_DOB_DATE;
    setDOBDateError(newDobDateError);

    const newPolicyError = selPolicies.length === 0;
    setSelPolicyError(newPolicyError);

    const newGenderError = !gender.value;
    setGenderError(newGenderError);

    if (
      checkFirstValidation &&
      checkLastValidation &&
      checkMobileNoValidation &&
      checkCTCValidation &&
      !newDobDateError &&
      !newPolicyError &&
      !newGenderError
    ) {
      let request = {};
      request.firstName = firstName.value;
      request.lastName = lastName.value;
      request.mobile = mobileNo.value;
      // const DateOfBirth = new Date(dob.value);
      request.dob = moment(dob.value).unix();
      request.gender = gender.value;
      request.ids = selPolicies;
      request.relationship = dropdownValue;
      request.parentId = id;
      request.hrId = hrId;
      request.patientType = 'dependent';
      // request.userId = docId;
      request.userType = 'patient';
      request.policyPreferences = selPolicies;
      // setConfirmModal(true);
      callTrackClick('VIEW_EMPLOYEE_ADD_DEPENDENT', {
        empid: request?.parentId,
      });
      /** CREATE DEPENDENT CALL */
      setLoading(true);
      const timeZone = Intl.DateTimeFormat()?.resolvedOptions().timeZone;
      const logData = {
        userId: request.userId,
        parentId: request.parentId,
        timeZone,
        requestData: {
          ...request,
          employeeId: userDetails.employeeId,
          employer: userDetails.employer,
          joiningDate:
            userDetails.joiningDate?._seconds || new Date().getTime() / 1000,
        },
      };
      console.log('===PROD_LOG_HRD===', JSON.stringify(logData));
      const result = await registerMemberToPolicies({
        ...request,
        employeeId: userDetails.employeeId,
        employer: userDetails.employer,
        joiningDate:
          userDetails.joiningDate?._seconds || new Date().getTime() / 1000,
      });
      setLoading(false);
      /** HANDLE ERROR/SUCCESS CASES */
      if (result.data.success && result.data.success.length) {
        handleOnAddingDep(saveType);
        dispatch(fetchUserDependent({ userId: result.data.success[0].userId }));
      } else if (result.data.failure && result.data.failure.length) {
        const error = result.data.failure[0].errorMsg;
        setFormLevelError(error);
      }
      if (type === 2) {
        setSaveType(2);
      } else {
        setSaveType(1);
      }
    } else {
      window.scrollTo(0, 0);
    }
  };

  const handleEditForEmpOrDep = async (e) => {
    e.preventDefault();
    setFormLevelError(false);
    const checkFirstValidation = firstName.finalvalidationCheck();
    const checkLastValidation = lastName.finalvalidationCheck();
    const checkEmpValidation = employeeId.finalvalidationCheck();
    const checkMobileNoValidation =
      !isDependent && mobileNo.finalvalidationCheck();
    let checkCTCValidation = true;
    if (isCtcRequired) {
      checkCTCValidation = ctc.finalvalidationCheck() && ctc.value > 0;
      if (ctc.value < 1) {
        ctc.setErrorText('Cannot be zero');
      }
    }
    const checkEmailValidation = email.finalvalidationCheck();

    const DOB = dob.value;
    const newDobDateError = !DOB || DOB > MAX_DOB_DATE || DOB < MIN_DOB_DATE;
    setDOBDateError(newDobDateError);

    const formFinalValidations = isDependent
      ? checkFirstValidation &&
        checkLastValidation &&
        checkEmpValidation &&
        !newDobDateError
      : checkFirstValidation &&
        checkLastValidation &&
        checkMobileNoValidation &&
        checkCTCValidation &&
        checkEmpValidation &&
        checkEmailValidation &&
        !newDobDateError;
    if (formFinalValidations) {
      const params = {
        firstName: firstName.value,
        lastName: lastName.value,
        employeeId: employeeId.value,
        mobile: mobileNo.value || undefined,
        email: email.value || undefined,
        ctc: ctc.value || undefined,
        dob: dob.value && moment(dob.value).unix(),
        gender: gender.value,
        userId: userData?.userId ?? id,
        userType: 'patient',
        hrId,
        employer: companyId,
        ...(isDependent
          ? {
              relationship: userData?.relationship,
              patientType: 'dependent',
            }
          : {
              relationship: 'self',
              patientType: 'membership',
            }),
      };
      /** UPDATE USER CALL */
      callTrackClick('VIEW_EMPLOYEE_UPDATE_EMPLOYEE', {
        empid: params?.userId,
        compId: params?.employer,
      });
      setLoading(true);
      let result;
      try {
        result = await updateMemberToPolicies({
          ...params,
          joiningDate: userData.joiningDate?._seconds || undefined,
        });
        setLoading(false);
      } catch (e) {
        const error = e.message;
        setLoading(false);
        setFormLevelError(error);
      }
      /** HANDLE SUCCESS & ERROR STATES */
      if (result.data && result.data.success && result.data.success.length) {
        dispatch(fetchEnrolledPolicy(result.data.success[0].userId));
        dispatch(fetchUserDetails(result.data.success[0].userId));
        toast.success('Details Updated Successfully', {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        clearForm();
        setShowModal(false);
      } else if (result?.data.failure && result.data.failure.length) {
        const error = result.data.failure[0].errorMsg;
        setFormLevelError(error);
      } else {
        const error = result.message;
        setFormLevelError(error);
      }
    }
  };

  return (
    <Modal
      className="modal"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showModal}
      onHide={() => {
        const clickParms = isDependent
          ? [
              'VIEW_EMPLOYEE_EDIT_DEPENDENT_MODAL_CLOSE',
              {},
              {
                clicked_dependent_id: id ?? '',
                clicked_dependent_name: firstName?.value
                  ? firstName?.value + lastName?.value
                  : lastName?.value,
                clicked_dependent_parent_id: userData?.userId ?? id,
              },
            ]
          : [
              'VIEW_EMPLOYEE_EDIT_EMPLOYEE_MODAL_CLOSE',
              {
                empid: userData?.userId ?? id,
                compId: companyId,
              },
            ];
        callTrackClick(...clickParms);
        setShowModal(false);
      }}
      backdrop={'static'}
      keyboard={false}
    >
      <Modal.Header closeButton className="confirmModal_header">
        {title && (
          <Modal.Title id="contained-modal-title-vcenter">
            <span className="modalTitle">{title}</span>
            <span className="modalSubTitle">{subTitle}</span>
          </Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body className="show-grid">
        <Container>
          {loading && <Backdrop />}
          {updateHrRequest?.status === 'loading' && <Backdrop />}
          <div className="col-md-12">
            <div className="form_modal_body">
              <div className="row">
                <div className="col-md-4">
                  <label className="input-label-name">
                    First Name <span>*</span>
                  </label>
                  <input
                    placeholder="First Name"
                    name="fname"
                    {...firstName.htmlAttributes}
                    className={`form-control ${
                      firstName.errorText ? 'border-danger' : ''
                    }`}
                  />
                  <small className="error-message text-danger form-text">
                    {firstName.errorText}
                  </small>
                </div>

                <div className="col-md-4">
                  <label className="input-label-name">
                    Last Name <span>*</span>
                  </label>
                  <input
                    placeholder="Last Name"
                    name="fname"
                    {...lastName.htmlAttributes}
                    className={`form-control ${
                      lastName.errorText ? 'border-danger' : ''
                    }`}
                  />
                  <small className="error-message text-danger form-text">
                    {lastName.errorText}
                  </small>
                </div>

                {operation === AppConstants.UPDATE ? (
                  <div className="col-md-4">
                    <label className="input-label-name">
                      Employee ID <span>*</span>
                    </label>
                    <input
                      placeholder="Employee ID"
                      name="empId"
                      {...employeeId.htmlAttributes}
                      disabled={true}
                      className={`form-control ${
                        employeeId.errorText ? 'border-danger' : ''
                      }`}
                    />
                    <small className="error-message text-danger form-text">
                      {employeeId.errorText}
                    </small>
                  </div>
                ) : (
                  <>
                    {operation === AppConstants.UPDATE_DEPENDENT ? (
                      <div className="col-md-4">
                        <label className="input-label-name">Relation</label>
                        <input
                          placeholder="Employee ID"
                          name="empId"
                          value={userData?.relationship}
                          disabled={true}
                          className={`form-control ${
                            employeeId.errorText ? 'border-danger' : ''
                          }`}
                        />
                      </div>
                    ) : (
                      <Dropdown
                        controlId="input-dropdown"
                        placeholder="Select"
                        labelName="Relationship"
                        value={relation.value}
                        fieldKey="relationship"
                        options={DropdownData.relationShip}
                        dispalyKey="name"
                        onChange={onChangeRelation}
                        invalidText="Please select relationship"
                        isInvalid={dropdownError}
                      />
                    )}
                  </>
                )}
              </div>

              <div className="row">
                <div className="col-md-4">
                  <TagOption
                    controlId="input-tag"
                    labelName="Gender"
                    fieldKey="gender"
                    field={gender}
                    value={gender.value}
                    option={DropdownData.genderData}
                    // onChange={gender.setValue}
                    errorMsg="Please select gender"
                    isInvalid={genderError}
                    onChange={onGenderSelection}
                  />
                </div>

                <div className="col-md-4">
                  <label className="input-label-name">
                    Mobile Number
                    {operation === AppConstants.UPDATE ? <span>*</span> : null}
                  </label>
                  <div className=" input_mobile">
                    <input
                      type="text"
                      className="country_code_modal"
                      value="+91"
                      disabled={true}
                    />
                    <input
                      placeholder="Mobile Number"
                      name={
                        operation === AppConstants.UPDATE
                          ? 'phone'
                          : 'dependent_phone'
                      }
                      {...mobileNo.htmlAttributes}
                      className={`span_91 form-control ${
                        mobileNo.errorText ? 'border-danger' : ''
                      }`}
                    />
                  </div>
                  <small className="error-message text-danger form-text">
                    {mobileNo.errorText}
                  </small>
                </div>

                <DatePicker
                  labelName="Date of birth"
                  field={dob}
                  max={MAX_DOB_DATE}
                  min={MIN_DOB_DATE}
                  errorMsg="Invalid DOB date"
                  dateError={dobDateError}
                  onBlur={onDOBDateBlur}
                  onChange={() => setDOBDateError(false)}
                />
              </div>
              {operation === AppConstants.UPDATE ? (
                <div className="row">
                  <div className="col-md-4">
                    <label className="input-label-name">
                      Official Email <span>*</span>
                    </label>
                    <input
                      placeholder="Official Email"
                      name="email"
                      {...email.htmlAttributes}
                      className={`form-control ${
                        email.errorText ? 'border-danger' : ''
                      }`}
                    />
                    <small className="error-message text-danger form-text">
                      {email.errorText}
                    </small>
                  </div>
                  <div className="col-md-4">
                    <label className="input-label-name">
                      Annual CTC {isCtcRequired ? <span>*</span> : ''}
                    </label>
                    <input
                      placeholder="Annual CTC"
                      name="ctc"
                      {...ctc.htmlAttributes}
                      className={`form-control ${
                        ctc.errorText ? 'border-danger' : ''
                      }`}
                    />
                    <small className="error-message text-danger form-text">
                      {ctc.errorText}
                    </small>
                  </div>
                </div>
              ) : (
                <>
                  <div className="row">
                    <div className="addEmp_internal_form_checkbox">
                      <label className="form-label">
                        Covered under policies<span>*</span>
                      </label>
                      {operation === AppConstants.UPDATE_DEPENDENT ? (
                        <div className="row addEmp_checkbox_policyCard">
                          {userPolicies?.length === 0 ? (
                            <div className="col-md-5">
                              <div className="no_selected_relation">
                                <label>
                                  No Policies Available for Selected Dependent
                                  <span>Dependent Can't be added.</span>
                                </label>
                              </div>
                            </div>
                          ) : (
                            userPolicies?.map((policy) => (
                              <div className=" col-md-6" key={policy.id}>
                                <div className="box_card card_border">
                                  <div className="card_top">
                                    <div className="col-md-12">
                                      <div className="policy_info_left single_user_r2">
                                        <div className="policy_name single_user_pn">
                                          <h4 className="cardName">
                                            {getPolicyType(
                                              policy?.policyType,
                                              policy?.isTopUpPolicy,
                                              policy?.isParentalPolicy,
                                            )}
                                          </h4>
                                          <h5 className="health_insurer">
                                            {policy?.insurerName}
                                          </h5>
                                        </div>
                                      </div>
                                      <div className="policy_info_right" />
                                    </div>
                                  </div>
                                  <div className="card_bottom">
                                    <div className="policy_stats policy_row_one card_section_main">
                                      <div className="policy_sum_ins card_section_one">
                                        <p className="amountText">
                                          Sum Insured
                                        </p>

                                        <h5 className="amountPrice">
                                          {getAmountInLacs(policy?.sumInsured)}
                                        </h5>
                                      </div>
                                      <div className="policy_cd_balance card_section_two">
                                        <p className="amountText">
                                          Supported Dependents
                                        </p>
                                        <h5 className="amountPrice">
                                          {policy?.familyDefinition || '-'}
                                        </h5>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          )}
                        </div>
                      ) : (
                        <div className="row addEmp_checkbox_policyCard">
                          {dropdownValue ? (
                            policyPrefData?.data?.length ? (
                              policyPrefData?.data?.map((policyInfo) => {
                                return (
                                  <PolicyCard
                                    key={policyInfo.policyId}
                                    policyInfo={policyInfo}
                                    selPolicyError={selPolicyError}
                                    setSelPolicyError={setSelPolicyError}
                                    selPolicies={selPolicies}
                                    setSelPolicies={setSelPolicies}
                                  />
                                );
                              })
                            ) : policyPrefData?.status === 'loading' ? (
                              <div className="col-md-5">
                                <div className="no_selected_relation">
                                  <label>Loading Policies</label>
                                </div>
                              </div>
                            ) : (
                              <div className="col-md-5">
                                <div className="no_selected_relation">
                                  <label>
                                    No Policies Available for Selected Dependent
                                    <span>Dependent Can't be added.</span>
                                  </label>
                                </div>
                              </div>
                            )
                          ) : (
                            <div className="col-md-5">
                              <div className="no_selected_relation">
                                <label>
                                  Please select relationship
                                  <span>
                                    Policies will appear here once you select
                                    the relationship
                                  </span>
                                </label>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <div className="modal_empDel_3_button confirmModal_footer_btn">
          {operation === AppConstants.ADD_DEPENDENT ? (
            <>
              <button
                className="modal_empDel_3_button_submit btn_green"
                onClick={() => {
                  const clickParms = isDependent
                    ? [
                        'VIEW_EMPLOYEE_EDIT_DEPENDENT_MODAL_CANCEL',
                        {},
                        {
                          clicked_dependent_id: id ?? '',
                          clicked_dependent_name: firstName?.value
                            ? firstName?.value + lastName?.value
                            : lastName?.value,
                          clicked_dependent_parent_id: userData?.userId ?? id,
                        },
                      ]
                    : [
                        'VIEW_EMPLOYEE_EDIT_EMPLOYEE_MODAL_CANCEL',
                        {
                          empid: userData?.userId ?? id,
                          compId: companyId,
                        },
                      ];
                  callTrackClick(...clickParms);
                  setShowModal(false);
                }}
              >
                Cancel
              </button>
              <button
                className="modal_empDel_3_button_submit btn_green pull-right"
                onClick={(e) => handleAddDependent(e, 1)}
                // disabled={updateHrRequest?.status === 'loading' || updateHrDependentRequest?.status === 'loading'}
                disabled={
                  policyPrefData?.status === 'loading' ||
                  selPolicies?.length === 0 ||
                  dropdownValue === '' ||
                  !isFormReady
                }
              >
                {buttonText1}
              </button>
              {/* <button
                className="modal_empDel_3_button_cancel margin_15px pull-right"
                onClick={(e) => handleAddDependent(e, 2)}
                disabled={policyPrefData?.status==='loading' || selPolicies?.length === 0}
              >
                {buttonText2}
              </button> */}
            </>
          ) : (
            <>
              <button
                className="modal_empDel_3_button_submit btn_green pull-right"
                onClick={(e) => handleEditForEmpOrDep(e)}
                disabled={
                  updateHrRequest?.status === 'loading' ||
                  updateHrDependentRequest?.status === 'loading'
                }
              >
                {buttonText1}
              </button>
              <button
                className="modal_empDel_3_button_cancel margin_15px pull-right"
                onClick={onPressButton2}
              >
                {buttonText2}
              </button>
            </>
          )}
          {typeof formLevelError === 'string' && (
            <span className="button-error-text pull-right">
              {formLevelError}
            </span>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};
