import { IFetchChangeRequests } from './types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import LoopApiService from '../../../adaptars/LoopApiService';
import { transformChangeRequests } from './utils';

export const fetchChangeRequests = createAsyncThunk(
  'company/fetchChangeRequests',
  async ({
    companyId,
    pageSize,
    prevPageId,
    nextPageId,
    pageNo,
  }: IFetchChangeRequests) => {
    const newPageSize =
      !!prevPageId || !!nextPageId || pageNo === 0 ? pageSize + 1 : pageSize;
    try {
      const data = await LoopApiService.fetchAllChangeRequestsAPI({
        companyId,
        pageSize: newPageSize,
        prevPageId,
        nextPageId,
      });
      const { filteredResponse, changeRequests } = data;
      const changeRequestsArray = Array.isArray(filteredResponse)
        ? filteredResponse
        : Array.isArray(changeRequests)
        ? changeRequests
        : [];
      return { data: transformChangeRequests(changeRequestsArray) };
    } catch (error) {
      throw error;
    }
  },
);
