import styled from 'styled-components';

export const StyledHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const StyledSearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 65%;
  gap: 20px;
  flex-wrap: wrap;

  > div {
    width: inherit;
  }

  @media (max-width: 1024px) {
    width: 100%;
    gap: 10px;
  }
`;

export const StyledDownloadWrapper = styled.div`
  width: fit-content;

  @media (max-width: 1024px) {
    width: 100%;
    margin-top: 10px;
  }
`;

export const StyledFilterSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
export const StyledFilterHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: fit-content;
  align-items: center;
  justify-content: flex-start;
`
export const StyledClearFilter = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
  cursor: pointer;
`
export const StyledFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;

  > div {
    flex: 1;
    width: 25%;
    max-width: 25%;
  }

  @media (max-width: 1024px) {
    flex-wrap: wrap;
    
    > div {
      flex: 1;
      width: 100%;
      max-width: 100%;
    }
  }
`;
