import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IEndorsements } from '../../../components/pages/Endorsements/ListingPage/types';
import {
  selectCDAccountsList,
  selectEndorsementList,
  selectSelectedCompany,
} from '../../../redux/slices/hrdRevampRedux';
import { fetchEndorsments } from '../../../redux/slices/hrdRevampRedux/thunk';
import { ICDAccount } from '../../../components/pages/CDAccounts/types';

interface IUseEndorsements {
  completedEndo: IEndorsements[] | null;
  pendingEndo: IEndorsements[] | null;
  isLoading: boolean;
}

const useEndorsements = (): IUseEndorsements => {
  const dispatch = useDispatch();
  const selectedCompany = useSelector(selectSelectedCompany);
  const { data, status } = useSelector(selectEndorsementList);
  const cdAccounts: ICDAccount[] =
    useSelector(selectCDAccountsList)?.data || [];
  const endorsementList = useMemo(() => {
    return data?.map((endoData: any) => {
      const account = cdAccounts.find((account) =>
        account.policies.includes(endoData.policyId),
      );
      return { ...endoData, cdBalance: account?.balance };
    });
  }, [cdAccounts, data]);
  const pendingEndo = useMemo(
    () => endorsementList.filter((endo) => endo.status !== 'ENDO_COMPLETE'),
    [endorsementList],
  );
  const completedEndo = useMemo(
    () => endorsementList.filter((endo) => endo.status === 'ENDO_COMPLETE'),
    [endorsementList],
  );

  useEffect(() => {
    if (selectedCompany?.id) {
      dispatch(fetchEndorsments(selectedCompany.id));
    }
  }, [dispatch, selectedCompany?.id]);

  return { pendingEndo, completedEndo, isLoading: status === 'loading' };
};

export default useEndorsements;
