import React from 'react';
import '../../pages/Login.scss';

export const SideLayout = () => {
  return (
    <div className="login sidebar">
      <div className="sidebar-content">
        <div className="desktop-view-container text-center">
          <img src="/assets/icons/loop.png" alt="" />
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 mx-auto text-center">
          <div className="text-center login-bg-side-container">
            <img
              className="lh-login-bottom-image"
              src="assets/images/login-sidebar.svg"
              alt=""
            />
          </div>
          <h4 className="sidebar-title">
            Making health insurance administration easy
          </h4>
          <p className="intro-subtitle text-center mt-2">
            with the help of Loop's admin dashboard, you can now simplify...
            health benefits administration
          </p>
        </div>
      </div>
    </div>
  );
};
