import React, { useEffect } from 'react';
import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { addLogoToPolicy, selectCompanyPolicyList } from '../../../redux/slices/PolicyListSlice';
import PolicyCardContent from './PolicyCardContent';

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className="faded_bg_btn">
            <div
                className={`${className} next_btn_custom`}
                style={{
                    ...style
                }}
                onClick={onClick}
            >
                <div
                    className="arrow_outer"
                    style={{
                        background: `url("/assets/icons/arrow.svg")`
                    }}
                ></div>
            </div>
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className} next_btn_custom`}
            style={{
                ...style
            }}
            onClick={onClick}
        >
            <div
                className="slider_left_arrow"
                style={{
                    background: `url("/assets/icons/arrow.svg")`
                }}
            ></div>
        </div>
    );
}

const settings = {
    dots: false,
    infinite: true,
    centerMode: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    // variableWidth: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                centerMode: false,
                slidesToScroll: 3,
                infinite: true,
                dots: false
            }
        },
        {
            breakpoint: 980,
            settings: {
                slidesToShow: 2,
                centerMode: false,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                centerMode: false,
                slidesToScroll: 1,
                dots: true
            }
        }
    ]
};

const PolicySlider = () => {
    // const dispatch = useDispatch();
    const companyPoliciesListing = useSelector(selectCompanyPolicyList);
    const policyPlans = companyPoliciesListing?.data?.policyPlans ?? [];

    // useEffect(() => {
    //     const policyInsurerLogo = policyPlans.map((p) => p.insurerLogo).filter(l=>!!l)
    //     if (policyInsurerLogo.length === 0) {
    //         const policyNames = policyPlans.map((p) => p.insurerName);
    //         policyNames.map((name)=> dispatch(addLogoToPolicy(name)));
    //     }
    // }, [policyPlans])

    return (
        <div>
            <div className="d-flex">
                <h4 className="heading-one margin_top_60">Policies</h4>
            </div>

            <div className="col-md-12 custom_row">
                <div className="policy_wrapper">
                    {policyPlans?.length < 3 ? (
                        <div className="card_margin_slider d-flex flex-wrap full_width">
                            {policyPlans?.map((policy) => (
                                <div key={policy.id} className="box_card m-3 rsp_nonSlider">
                                    <PolicyCardContent policy={policy}/>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <Slider {...settings}>
                            {policyPlans?.map((policy) => (
                                <div key={policy.id}>
                                    <div  className="m-3 box_card">
                                        <PolicyCardContent policy={policy}/>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PolicySlider;
