import {
  IAddDependentRequest,
  IEnrollUserPolicy,
  IFetchEmployeeDataList,
  IFetchPolicyPreference,
} from './types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import EmployeeApi from '../../../adaptars/employeeApi';
import LoopApiService from '../../../adaptars/LoopApiService';
import { addUserPolicyParallelly } from '../hrRequestSlice/thunks';

export const fetchEmployeeDataList = createAsyncThunk(
  'company/fetchEmployeeDataList',
  async ({
    firstName,
    email,
    mobile,
    relationship = 'self',
    companyId: employer,
    userId,
    active,
    parentId,
    responseType,
    compressed,
    paginated = true,
    pageNo,
    prevPageId,
    nextPageId,
    pageSize,
  }: IFetchEmployeeDataList) => {
    const newPageSize =
      !!prevPageId || !!nextPageId || pageNo === 0 ? pageSize + 1 : pageSize;
    try {
      const result: any = await LoopApiService.getEmployeeData({
        firstName: firstName?.toLowerCase(),
        email,
        mobile,
        relationship,
        employer,
        userId,
        active,
        parentId,
        responseType,
        compressed,
        paginated,
        nextPageId,
        prevPageId,
        pageSize: newPageSize,
      });
      const { users = [] } = result;
      return { data: users };
    } catch (error) {
      throw error;
    }
  },
);

export const fetchPolicyPreference = createAsyncThunk(
  'employees/fetchPolicyPreference',
  async ({ userId, relationship }: IFetchPolicyPreference) => {
    try {
      const result = await LoopApiService.fetchPolicyPreferenceByUserIdAndRel({
        userId,
        relationship: relationship.trim().toLowerCase(),
      });
      return { data: result };
    } catch (error) {
      throw error;
    }
  },
);

// unused. keeping for future use
export const addDependentRequest = createAsyncThunk(
  'hrRequest/addEmployeeRequest',
  async ({ data, policiesData = [] }: IAddDependentRequest) => {
    try {
      const result: any = await LoopApiService.addEmpHrRequest(data);
      let result2 = null;
      if (result.data.userId && policiesData.length !== 0) {
        const policyParams = policiesData?.map(
          ({ id, slabId, enrolmentCycle }: any) => ({
            userId: result.data.userId,
            ctc: data.ctc,
            policyId: id,
            slabId,
            enrolmentCycle: enrolmentCycle ?? 'At Inception',
            enrolmentStatus: 'PENDING',
            policyStatus: 'ENROLED',
          }),
        );
        result2 = await addUserPolicyParallelly(policyParams);
      }
      return {
        employeeData: result?.data ?? {},
        policyData: result2?.map((r) => r?.data) ?? [],
      };
    } catch (error) {
      throw error;
    }
  },
);

export const enrollUserPolicy = createAsyncThunk(
  'employees/enrollUserPolicy',
  async ({ data }: IEnrollUserPolicy) => {
    let result = null;
    try {
      // result = await EmployeeApi.enrolUserToPolicy(data);
      result = await LoopApiService.enrolUserToPolicy(data);
      return { data: result };
    } catch (error) {
      throw error;
    }
  },
);

export const fetchEmpCount = createAsyncThunk(
  'employees/fetchEmpCount',
  async ({ companyId }: { companyId: string }) => {
    let result = null;
    try {
      result = await EmployeeApi.getEmpCount(companyId);
    } catch (error) {
      throw error;
    }
    return { data: result };
  },
);

export const delUserPolicy = createAsyncThunk(
  'employees/delUserPolicy',
  async ({ data }: IEnrollUserPolicy) => {
    try {
      const result = await EmployeeApi.delUserFromPolicy(data);
      return { data: result };
    } catch (error) {
      throw error;
    }
  },
);
