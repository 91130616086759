import { useEffect, useState } from 'react';
import LoopApiService from '../../../../../adaptars/LoopApiService';
import moment from 'moment';
import { useSelector } from 'react-redux';
import {
  selectCDAccountsList,
  selectSelectedCompany,
} from '../../../../../redux/slices/hrdRevampRedux';
import { toast } from 'react-toastify';
import { ICDAccount } from '../../../CDAccounts/types';
import { prepareCostEndoData } from '../SubmitEndo/utils';

const useEstimateEndorsementCost = (
  correctData: Object,
  currentStep: number,
  mode: 'OFFBOARD' | 'ADD',
) => {
  const selectedCompany = useSelector(selectSelectedCompany);
  const cdAccounts: ICDAccount[] = useSelector(selectCDAccountsList)?.data;
  const [endoCostList, setEndoCostList] = useState<any[]>([]);
  const [totalLives, setTotalLives] = useState<number>(0);
  const [totalCost, setTotalCost] = useState<number>(0);

  const getEstimateRequestData = () => {
    const premiumPromises: any = [];
    Object.keys(correctData as any).forEach((policy) => {
      // @ts-ignore
      const membersList = correctData[policy].map((member: any) => {
        return {
          employeeId: member.employee_id,
          name: member.name,
          relationship: member.relationship_to_account_holders,
          dob: moment.utc(member.date_of_birth, 'DD/MM/YYYY').toDate(),
          slabId: member.slabId,
          coverageStartDate: moment(member.policy_start_date, 'DD/MM/YYYY')
            .toDate()
            .toISOString(),
        };
      });
      const memberDataPayload = {
        companyId: selectedCompany?.id,
        ADD: { policyId: policy, membersList },
      };
      premiumPromises.push(LoopApiService.estimateEndoCost(memberDataPayload));
    });
    return premiumPromises;
  };
  const getOffboardEstimateRequestData = () => {
    const premiumPromises: any = [];
    const membersList = (correctData as any[]).map((member: any) => ({
      employeeId: member.employeeId,
      dateOfLeaving: moment.utc(member.dateOfLeaving, 'DD/MM/YYYY').toDate(),
    }));
    const memberDataPayload = {
      companyId: selectedCompany?.id,
      OFFBOARD: { membersList },
    };
    premiumPromises.push(LoopApiService.estimateEndoCost(memberDataPayload));

    return premiumPromises;
  };
  const getCostEstimate = async () => {
    try {
      const [...response] = await Promise.all(
        mode === 'ADD'
          ? getEstimateRequestData()
          : getOffboardEstimateRequestData(),
      );
      return response;
    } catch (e) {
      console.error('=====', e);
      toast.error('Endo Cost estimation failed');
      return null;
    }
  };

  useEffect(() => {
    const estimateCost = async () => {
      if (Object.keys(correctData as Object).length && selectedCompany?.id) {
        const costEstimations = (await getCostEstimate()) || [];
        const { endoCostList, totalCost, totalLives } = prepareCostEndoData(
          cdAccounts,
          costEstimations,
          mode,
        );
        setEndoCostList(endoCostList);
        setTotalCost(totalCost);
        setTotalLives(totalLives);
      }
    };
    if (currentStep === 3) estimateCost();
  }, [currentStep]);

  return { endoCostList, totalCost, totalLives };
};
export default useEstimateEndorsementCost;
