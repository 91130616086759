import React, { useState, useEffect } from 'react';
import Navbar from '../../../atoms/Navbar';
import './policyDetails.css';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import DataTable from '../../../atoms/Datatables';
import Spinner from '../../../atoms/Spinner/Spinner';
import {
  selectPolicyEmpCount,
  selectCompanyPolicyDetails,
} from '../../../../redux/slices/PolicyListSlice';
import { fetchCompanyPolicyDetails } from '../../../../redux/slices/PolicyListSlice/thunks';
import { getPolicyType } from '../../../../utils/common/Utilities';
import { ILocationState } from './types';
import { IEmployee } from '../../../../redux/slices/PolicyListSlice/types';

export const PolicyDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation<{ body: ILocationState }>();
  useEffect(() => {
    if (location.state?.body)
      dispatch(fetchCompanyPolicyDetails(location.state.body));
  }, [dispatch, location.state?.body]);

  const policyEmp = useSelector(selectCompanyPolicyDetails);

  const completePolicyDetails = useSelector(selectPolicyEmpCount);
  const policyInfo = completePolicyDetails?.data?.[location.state?.body.id];

  const handleDeleteClick = (record: IEmployee) => {
    // Delete Action( Modal) to be done
    // handleDeleteBackdrop(record.data);
  };

  // const handleEditClick = (record) => {

  // dispatch(setEmployeeData({ data: record.data }));
  // history.push(`/view-employee`);
  // };
  const columns = [
    {
      name: 'Employee ID',
      selector: (row: IEmployee) => row.employeeId,
      sortable: true,
    },
    {
      name: 'Name',
      selector: (row: IEmployee) => row.firstName + ' ' + (row.lastName ?? ''),
      minWidth: '185px',
    },
    {
      name: 'Mobile Number',
      selector: (row: IEmployee) => row.mobile,
      sortable: true,
    },
    {
      name: 'Dependents',
      selector: (row: IEmployee) => row.dependents,
      sortable: true,
    },
    {
      name: 'Sum Insured',
      selector: (row: IEmployee) => row.sumInsured,
    },

    // {
    //   name: "Status",
    //   selector: (row: IEmployee) => row.status,
    //   conditionalCellStyles: [
    //     {
    //       when: (row: IEmployee) => row.status === "active",
    //       classNames: ["text-green"],
    //     },
    //     {
    //       when: (row: IEmployee) => row.status === "pending",
    //       classNames: ["text-orange"],
    //     },
    //   ],
    //   sortable: true,
    // },
    {
      name: 'Actions',
      cell: (record: IEmployee) => (
        <>
          {/* {record.status === "active" ? ( */}
          <>
            {/* <img
              src="/assets/icons/edit.svg"
              onClick={() => handleEditClick(record)}
              alt="edit-icon"
              className="edit_icon"
            /> */}
            <img
              src="/assets/icons/delete.svg"
              onClick={() => handleDeleteClick(record)}
              alt="edit-icon"
              className="delete_icon"
            />
          </>
          {/* ) : null} */}
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const [pending, setPending] = useState(true);
  //TODO: Will be used and uncomment in v2 release
  // const [rows, setRows] = useState([]);
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      //TODO: Will be used and uncomment in v2 release
      // setRows(filterText);
      setPending(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  const [filterText, setFilterText] = React.useState('');
  //TODO: Will be used and uncomment in v2 release
  // const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = policyEmp?.data?.filter(
    (item) =>
      item.firstName &&
      item.firstName.toLowerCase().includes(filterText.toLowerCase()),
  );
  const subHeaderComponentMemo = React.useMemo(() => {
    //TODO: Will be used and uncomment in v2 release
    // const handleClear = () => {
    //   if (filterText) {
    //     setResetPaginationToggle(!resetPaginationToggle);
    //     setFilterText("");
    //   }
    // };
    return (
      <input
        type="text"
        placeholder="Search by name"
        className="form-control search_input"
        onChange={(e) => setFilterText(e.target.value)}
        // onClear={handleClear}
        // filterText={filterText}
      />
    );
  }, []);

  return (
    <>
      <div className="hr-dashboard">
        <Navbar />
        <div className="container main-container col-md-10 mx-auto">
          <div className="d-flex justify-content-between align-items-center mt-4">
            <h4 className="heading-one">Policies Details</h4>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-9">
                <div className="row policy_details_Card">
                  <div className="col-md-5">
                    <div className="row">
                      <div className="col-md-3">
                        <div
                          className="policy_image"
                          style={{
                            backgroundImage: `url("${
                              policyInfo?.insurerLogo ??
                              '/assets/images/cardImage.svg'
                            }")`,
                            backgroundColor: 'initial',
                          }}
                        />
                      </div>
                      <div className="col-md-9">
                        <h5>
                          {getPolicyType(
                            policyInfo?.policyType,
                            policyInfo?.isTopUpPolicy,
                            policyInfo?.isParentalPolicy,
                          )}
                        </h5>
                        <p>{policyInfo?.insurerName}</p>
                        {/* <p>Valid Till : {policyInfo?.policyEndDate}</p> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="row">
                      <div className="col-md-3">
                        <label>Sum Insured</label>
                        {policyInfo?.sumInsuredSlabs &&
                        policyInfo?.sumInsuredSlabs?.length
                          ? policyInfo?.sumInsuredSlabs.map((policySlab) => {
                              return (
                                <ul>
                                  <li key={policySlab}>{policySlab}</li>
                                </ul>
                              );
                            })
                          : ''}
                      </div>
                      <div className="col-md-3">
                        <label>CD Balance</label>
                        <p>₹{policyInfo?.cdAmount}</p>
                      </div>
                      <div className="col-md-3">
                        <label>Employees</label>
                        <p>{policyInfo?.employees}</p>
                      </div>
                      <div className="col-md-3">
                        <label>Dependents</label>
                        <p>{policyInfo?.dependents}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <DataTable
                    // title="Insured Employees"
                    columns={columns}
                    data={filteredItems}
                    pagination
                    // paginationResetDefaultPage={resetPaginationToggle} //TODO: Will be used and uncomment in v2 release
                    subHeader={true}
                    subHeaderComponent={subHeaderComponentMemo}
                    persistTableHead
                    progressPending={pending}
                    progressComponent={
                      <div className="w-100 p-3 text-center spinner-bg-grey">
                        <Spinner />
                      </div>
                    }
                  />
                </div>
              </div>
              <div className="col-md-3">
                <h5>Policy Breakdown</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
