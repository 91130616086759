import { Tooltip, Typography } from '@loophealth/loop-ui-web-library';
import React from 'react';
import {
  ClipboardIcon,
  InfoIconGray,
  MediclaimCoverageIcon,
} from '../../../assets/images';
import PolicyCoverageDocuments from './PolicyCoverageDocuments';
import PolicyCoverageIncExc from './PolicyCoverageIncExc';
import PolicyCoverageSlabs from './PolicyCoverageSlabs';
import {
  StyledPolicyCoverageColumn,
  StyledPolicyCoverageHeader,
  StyledPolicyCoverageHeaderText,
  StyledPolicyCoverageIcon,
  StyledPolicyDetailsCoverage,
  StyledPolicyName,
  StyledTopUpHeader,
  StyledTopUpHeaderBasePolicy,
  StyledTopUpHeaderBasePolicyText,
  StyledTopUpHeaderDeductibleTitle,
  StyledTopUpHeaderDivider,
  StyledTopUpInfoIcon,
} from './styles';
import { IPolicyDetailsCoverage, IPolicyTypeForTerms } from './types';
import { getSlabBasedDeductibleText } from './utils';
import { useHistory } from 'react-router-dom';
import { PolicyTypeConstants } from '../../../utils/constants/constants';

const PolicyDetailsCoverage: React.FC<IPolicyDetailsCoverage> = ({
  policyDetails,
  basePolicyDetails,
}) => {
  const history = useHistory();
  const isCtcMultiplier =
    policyDetails?.policyTermsAndConditions?.sumInsuredStructure ===
    'CTC Multiplier';

  const handlePolicyClick = () => {
    const policyLink =
      'policy-details?policyId=' + policyDetails.basePolicyPlanId;
    history.push(policyLink);
  };
  return (
    <StyledPolicyDetailsCoverage>
      <StyledPolicyCoverageColumn>
        <StyledPolicyCoverageHeader>
          <StyledPolicyCoverageHeaderText>
            <StyledPolicyCoverageIcon src={ClipboardIcon} />
            <Typography variant="medium" weight="semiBold">
              Policy Coverage
            </Typography>
          </StyledPolicyCoverageHeaderText>

          <PolicyCoverageDocuments
            id={policyDetails.id}
            policyName={policyDetails.nickName}
            policyDocuments={policyDetails.policyDocuments}
          />
        </StyledPolicyCoverageHeader>

        {policyDetails.isTopUpPolicy && basePolicyDetails && (
          <StyledTopUpHeader>
            <StyledTopUpHeaderBasePolicy>
              <img
                src={MediclaimCoverageIcon}
                aria-hidden="true"
                alt="mediclaim coverage icon"
              />
              <StyledTopUpHeaderBasePolicyText>
                <Typography variant="small" color="secondary">
                  Base Policy
                </Typography>
                <StyledPolicyName onClick={handlePolicyClick}>
                  <Typography variant="medium" color="emerald">
                    Group medical cover
                  </Typography>
                </StyledPolicyName>
              </StyledTopUpHeaderBasePolicyText>
            </StyledTopUpHeaderBasePolicy>

            {basePolicyDetails.policyType === PolicyTypeConstants.GMC ? (
              <>
                <StyledTopUpHeaderDivider />

                <StyledTopUpHeaderBasePolicyText>
                  <StyledTopUpHeaderDeductibleTitle>
                    <Typography variant="small" color="secondary">
                      Deductible
                    </Typography>
                    <Tooltip
                      beak="top-center"
                      type="informative"
                      text="Initial amount an employee has to pay for medical expenses before your top up starts covering the costs"
                    >
                      <StyledTopUpInfoIcon
                        src={InfoIconGray}
                        alt="information icon"
                        aria-hidden="true"
                      />
                    </Tooltip>
                  </StyledTopUpHeaderDeductibleTitle>
                  <Typography variant="medium" color="primary" weight="medium">
                    {basePolicyDetails.sumAssuredSlabs.length > 1
                      ? "Equal to employee's base policy sum insured; "
                      : `${getSlabBasedDeductibleText(
                          basePolicyDetails,
                          isCtcMultiplier,
                        )}; `}
                    can be paid out of base health cover or out of pocket
                  </Typography>
                </StyledTopUpHeaderBasePolicyText>
              </>
            ) : null}
          </StyledTopUpHeader>
        )}

        {policyDetails.sumAssuredSlabs?.length && (
          <PolicyCoverageSlabs
            slabs={policyDetails.sumAssuredSlabs}
            isCtcMuliplier={!!isCtcMultiplier}
          />
        )}
      </StyledPolicyCoverageColumn>
      {policyDetails.policyTermsAndConditions && (
        <PolicyCoverageIncExc
          terms={policyDetails.policyTermsAndConditions}
          policyType={policyDetails.policyType as IPolicyTypeForTerms}
        />
      )}
    </StyledPolicyDetailsCoverage>
  );
};

export default PolicyDetailsCoverage;
