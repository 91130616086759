import React, { useState } from 'react';
import {IFormInput, IInputProps} from './types';

const useFormInput = (initalValue: string): IFormInput => {
  const [value, setValue] = useState<string>(initalValue);
  const [debounceValue, setDebounceValue] = useState<string>(value);
  const [typingTimeout, setTypingTimeout] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);
  const [errorText, setErrorText] = useState<string>('');

  const finalvalidationCheck = (): boolean => {
    let validity = true;
    if (!value.length) {
      setErrorText('This field cannot be empty');
      validity = false;
    }
    if (errorText) {
      validity = false;
    }
    return validity;
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formValue = e.currentTarget.value;
    const formName = e.currentTarget.name;
    if (typingTimeout) {
      clearTimeout(typingTimeout);
      setErrorText('');
    }
    setTypingTimeout(
      setTimeout(() => {
        setErrorText(formValidator(formName, formValue));
        setDebounceValue(formValue);
      }, 350),
    );
    setValue(formValue);
  };
  const htmlAttributes: IInputProps = {value, onChange}
  return {
    value,
    debounceValue,
    errorText,
    setErrorText,
    setValue,
    onChange,
    finalvalidationCheck,
    htmlAttributes
  };
};

const formValidator = (name: string, value: string): string => {
  let errorValue = '';
  switch (name) {
    case 'text': {
      if (!value.trim().length) {
        errorValue = 'Please enter a valid value';
      }
      break;
    }
    case 'fname': {
      if (!value.trim().length) {
        errorValue = 'Please enter a valid value';
      } else if (!regexValidations.fname.test(value.toLowerCase())) {
        errorValue = 'Please enter a valid value';
      }
      break;
    }
    case 'ctc': {
      if (!regexValidations.ctc.test(value.toLowerCase())) {
        errorValue = 'Please enter valid ctc';
      }
      break;
    }

    case 'empId': {
      if (!value.trim().length) {
        errorValue = 'Please enter valid Employee ID';
      } else if (!regexValidations.empId.test(value.toLowerCase())) {
        errorValue = 'Please enter valid Employee ID';
      }
      break;
    }

    case 'email': {
      if (!regexValidations.email.test(value.toLowerCase())) {
        errorValue = 'Please enter a valid email';
      }
      break;
    }
    case 'phone': {
      if (!value.trim().length) {
        errorValue = 'Phone number cannot be empty';
      } else if (!regexValidations.phone.test(value.toLowerCase())) {
        errorValue = 'Please enter a valid phone number';
      }
      break;
    }
    case 'dependent_phone': {
      if (!regexValidations.phone.test(value.toLowerCase())) {
        errorValue = 'Please enter a valid phone number';
      }
      break;
    }
    case 'password': {
      if (!value.length) {
        errorValue = 'Please enter a valid password';
      } else if (value.length < 6 || value.length > 32) {
        errorValue = 'Password should be at least from 6 to 32 characters';
      } else if (!regexValidations.password.test(value)) {
        errorValue = `Password should have to include 
                alpha-numeric and special characters`;
      }
      break;
    }
    default:
      break;
  }

  return errorValue;
};

const regexValidations = {
  fname: /^[a-zA-Z ]+$/,
  ctc: /^[0-9]+$/,
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  phone: /^[6789]\d{9}$/,
  password: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*?[#?!@$%^&*-]).{6,32}$/,
  empId: /^\S*$/,
};

export default useFormInput;
