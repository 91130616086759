import { SideNavigation } from '@loophealth/loop-ui-web-library';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Switch, useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import {
  selectCompanyConfig,
  selectCompanyData,
} from '../../../redux/slices/companySlice';
import {
  fetchCompanyById,
  fetchCompanyConfig,
} from '../../../redux/slices/companySlice/thunks';
import {
  selectCDAccountsList,
  selectClaimList,
  selectEndorsementList,
  selectPoliciesList,
  selectSelectedCompany,
  setSelectedCompany,
} from '../../../redux/slices/hrdRevampRedux';
import {
  fetchAllActivePolicies,
  fetchCDAccounts,
  fetchClaimList,
  fetchEndorsments,
} from '../../../redux/slices/hrdRevampRedux/thunk';
import { selectHrUser } from '../../../redux/slices/usersSlice';
import { cleanup } from '../../../redux/store';
import {
  getTrackTaskObj,
  showErrorToast,
} from '../../../utils/common/Utilities';
import {
  isClaimsDashboardEnabled,
  isEndoVisible,
  isHRDEnabled,
} from '../../../utils/featureFlag';
import { PrivateRoute } from '../../../utils/hoc/PrivateRoute';
import { useCompanyList } from '../../../utils/hooks/business-hooks/useCompanyList';
import Backdrop from '../../atoms/Backdrop/Backdrop';
import Navbar from '../../atoms/Navbar';
import Loader from '../../atoms/Spinner';
import TopNavigation from '../../containers/TopNavigation';
import Analytics from '../Analytics';
import { checkIfWhitelistedForNewHRD } from '../Auth/utils';
import { Benefits } from '../Benefits';
import CDAccounts from '../CDAccounts';
import ClaimDetails from '../ClaimDetails';
import ClaimsPage from '../Claims';
import ClaimList from '../ClaimsList';
import EmployeeDetails from '../EmployeeDetails';
import Employees from '../Employees';
import { Employees as EmployeesLegacy } from '../Employees/legacy';
import { AddEmployees } from '../Employees/legacy/addEmployee';
import { PolicyListCheckBox } from '../Employees/legacy/policyListCheckbox';
import EndoDetails from '../Endorsements/EndoDetails';
import Endorsements from '../Endorsements/ListingPage';
import HrDashboard from '../HrDashboard';
import '../HrDashboard.scss';
import PoliciesDashboard from '../Policies';
import { PoliciesList } from '../Policies/legacy/policiesList';
import { PolicyDetails as PolicyDetailsLegacy } from '../Policies/legacy/policyDetails';
import PolicyDetails from '../PolicyDetails';
import { SelfEnrol } from '../SelfEnroll';
import ViewEmployeeData from '../ViewEmployeeData';
import { getHRDMenuItems } from './constants';
import {
  StyledAppContainer,
  StyledChildComponent,
  StyledComponentContainer,
} from './styles';
import {
  ICompanyConfig,
  IComponentRoutes,
  IHRDApp,
  INewNavBarHRDApp,
  IOldNavBarHRDApp,
} from './types';
import { FirebaseAuth } from '../../../adaptars/providers';
import { extractPathName, trackClickEvent } from '../../../utils/segment/utils';
import useSegment from '../../../utils/segment/hooks/useSegment';
import BulkAddMembers from '../Endorsements/BulkAddMembers';
import BulkDeleteMembers from '../Endorsements/BulkDeleteMembers';

const ComponentRoutes: React.FC<IComponentRoutes> = ({
  isWhitelistedForNewHRD,
  isClaimsFeatureEnabled,
}) => {
  const isNewHRD = isHRDEnabled && isWhitelistedForNewHRD;

  return (
    <StyledChildComponent $isNewNavBarEnabled={isNewHRD}>
      <Switch>
        <PrivateRoute
          path="/dashboard"
          component={isNewHRD ? PoliciesDashboard : HrDashboard}
        />
        <PrivateRoute
          path="/policies"
          component={isNewHRD ? PoliciesDashboard : PoliciesList}
        />

        {isNewHRD ? (
          <PrivateRoute path="/employee-details" component={EmployeeDetails} />
        ) : (
          <PrivateRoute path="/view-employee" component={ViewEmployeeData} />
        )}

        <PrivateRoute
          path="/policy-details"
          component={isNewHRD ? PolicyDetails : PolicyDetailsLegacy}
        />
        <PrivateRoute
          path="/employees"
          component={isNewHRD ? Employees : EmployeesLegacy}
        />
        {!isNewHRD && [
          <PrivateRoute path="/benefits" component={Benefits} key="benefits" />,
          <PrivateRoute
            path="/self-enrol"
            component={SelfEnrol}
            key="self-enrol"
          />,
          <PrivateRoute
            path="/add-employee"
            component={AddEmployees}
            key="add-employee"
          />,
        ]}

        {isNewHRD ? (
          <PrivateRoute path="/claims" component={ClaimList} />
        ) : isClaimsFeatureEnabled ? (
          <PrivateRoute path="/claims" component={ClaimsPage} />
        ) : null}
        {!isNewHRD && isClaimsFeatureEnabled && (
          <PrivateRoute path="/claim-details" component={ClaimDetails} />
        )}
        {isNewHRD && (
          <PrivateRoute
            path="/claim-analytics"
            component={Analytics}
            key="claim-analytics"
          />
        )}

        {isEndoVisible && [
          <PrivateRoute
            path="/bulk-add"
            component={BulkAddMembers}
            key="bulk-add"
          />,
          <PrivateRoute
            path="/bulk-delete"
            component={BulkDeleteMembers}
            key="bulk-delete"
          />,
          <PrivateRoute
            path="/endorsements"
            component={Endorsements}
            key="endorsements"
          />,
          <PrivateRoute
            path="/endorsement-details"
            component={EndoDetails}
            key="endorsement-details"
          />,
        ]}
        {isNewHRD && (
          <PrivateRoute path="/cd-accounts" component={CDAccounts} />
        )}
        {!isNewHRD && (
          <PrivateRoute
            path="/select-policies"
            component={PolicyListCheckBox}
          />
        )}
        <Redirect to={isNewHRD ? '/policies' : '/dashboard'} />
      </Switch>
    </StyledChildComponent>
  );
};

const NewNavBarHRDApp: React.FC<INewNavBarHRDApp> = ({
  hrData,
  companyList,
  onClickLogout,
  companyConfig,
  onMenuClicked,
  isWhitelistedForNewHRD,
}) => {
  return (
    <>
      <TopNavigation
        hrData={hrData?.data}
        companies={companyList}
        onClickLogout={onClickLogout}
      />
      <StyledComponentContainer>
        <SideNavigation
          isExpended={true}
          isFixedSideBar={true}
          menuItems={getHRDMenuItems(companyConfig as ICompanyConfig)}
          onMenuClicked={onMenuClicked}
          closeNavigationDrawer={() => ''}
        />
        <ComponentRoutes isWhitelistedForNewHRD={isWhitelistedForNewHRD} />
      </StyledComponentContainer>
    </>
  );
};
const OldNavBarHRDApp: React.FC<IOldNavBarHRDApp> = ({
  isClaimsFeatureEnabled,
}) => {
  return (
    <StyledComponentContainer>
      <Navbar />
      <ComponentRoutes isClaimsFeatureEnabled={isClaimsFeatureEnabled} />
    </StyledComponentContainer>
  );
};
const HRDApp: React.FC<IHRDApp> = ({
  hrData,
  companyList,
  onClickLogout,
  companyConfig,
  onMenuClicked,
  isWhitelistedForNewHRD,
  isClaimsFeatureEnabled,
}) => {
  return companyList.length ? (
    isHRDEnabled && isWhitelistedForNewHRD ? (
      <NewNavBarHRDApp
        hrData={hrData}
        companyList={companyList}
        onClickLogout={onClickLogout}
        companyConfig={companyConfig}
        onMenuClicked={onMenuClicked}
        isWhitelistedForNewHRD={isWhitelistedForNewHRD}
      />
    ) : (
      <OldNavBarHRDApp isClaimsFeatureEnabled={isClaimsFeatureEnabled} />
    )
  ) : (
    <Backdrop />
  );
};

const Dashboard: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { loadingCompanyList, companyList } = useCompanyList();
  const selectedCompany = useSelector(selectSelectedCompany);
  const { logout, setLoggedInUser } = useAuth();
  const companyConfig = useSelector(selectCompanyConfig);
  const companyDetails = useSelector(selectCompanyData);
  const claimsData = useSelector(selectClaimList);
  const policiesData = useSelector(selectPoliciesList);
  const cdAccountData = useSelector(selectCDAccountsList);
  const endorsementData = useSelector(selectEndorsementList);

  const hrData = useSelector(selectHrUser);
  const trackClick = useSegment('click');
  const trackTask = useSegment('track');
  const callTrackClick = (action: string, category: string) =>
    trackClick(trackClickEvent(action, location?.pathname, category));
  const callTrackTask = (action: string, category: string) =>
    trackTask(getTrackTaskObj(action, location?.pathname, category));
  const isWhitelistedForNewHRD = useMemo(
    () => checkIfWhitelistedForNewHRD(companyList),
    [companyList],
  );

  useEffect(() => {
    if (!loadingCompanyList && !isWhitelistedForNewHRD) {
      performLogout('/waitlist');
    }
  }, [isWhitelistedForNewHRD, loadingCompanyList]);

  useEffect(() => {
    FirebaseAuth.onAuthStateChanged((user) => {
      if (!user) {
        performLogout();
      }
    });
  }, []);

  useEffect(() => {
    if (hrData.data?.userId) {
      window.plotline(
        'init',
        process.env.REACT_APP_PLOTLINE_KEY!,
        hrData.data.userId,
      );
    }
  }, [hrData.data?.userId]);

  useEffect(() => {
    if (!loadingCompanyList && !companyList?.length) {
      showErrorToast('No company is mapped with your account');
      performLogout();
    }
    if (!selectedCompany && companyList.length) {
      dispatch(setSelectedCompany({ selectedCompany: companyList[0] }));
    }
  }, [loadingCompanyList, selectedCompany, companyList, dispatch]);

  useEffect(() => {
    if (selectedCompany) {
      const isCompanyChanged =
        !companyDetails.data || companyDetails.data.id !== selectedCompany.id;
      if (isCompanyChanged && companyDetails.status !== 'loading') {
        dispatch(fetchCompanyById(selectedCompany.id));
        dispatch(fetchCompanyConfig(selectedCompany.id));
      }
      // Loading Claims & CD Accounts for Alerts -
      if (isWhitelistedForNewHRD) {
        location.pathname !== '/claims' &&
          claimsData.status !== 'loading' &&
          dispatch(fetchClaimList(selectedCompany.id));
        location.pathname !== '/cd-accounts' &&
          cdAccountData.status !== 'loading' &&
          dispatch(fetchCDAccounts(selectedCompany.id));
        location.pathname !== '/policies' &&
          policiesData.status !== 'loading' &&
          dispatch(fetchAllActivePolicies(selectedCompany.id));
        isEndoVisible && location.pathname !== '/endorsements' &&
          endorsementData.status !== 'loading' &&
          dispatch(fetchEndorsments(selectedCompany.id));
      }
    }
  }, [selectedCompany?.id]);

  const isClaimsFeatureEnabled =
    isClaimsDashboardEnabled &&
    companyConfig &&
    (companyConfig.isClaimListEnabled || companyConfig.isClaimsReportEnabled);

  const onClickLogout = async () => {
    try {
      if (window.confirm('Are you sure')) {
        callTrackClick('LOGOUT', 'HRD Login&Logout Events');
        try {
          await performLogout();
        } catch (error) {
          callTrackTask('LOGOUT_FAIL', 'HRD Login&Logout Events');
        }
      }
    } catch (error) {
      throw error;
    }
  };

  const performLogout = async (navigationRoute = '/login') => {
    setLoggedInUser(null);
    dispatch(cleanup());
    await logout();
    callTrackTask('LOGOUT_SUCCESS', 'HRD Login&Logout Events');
    history.replace(navigationRoute);
  };

  const onMenuClicked = (routeName: string) => {
    if (location.pathname !== routeName) {
      trackClick(
        trackClickEvent(extractPathName(routeName), location?.pathname),
      );
      history.push(routeName);
    }
  };

  return (
    <StyledAppContainer>
      {!loadingCompanyList && companyList && selectedCompany ? (
        <HRDApp
          hrData={hrData}
          companyList={companyList}
          onClickLogout={onClickLogout}
          companyConfig={companyConfig}
          onMenuClicked={onMenuClicked}
          isWhitelistedForNewHRD={isWhitelistedForNewHRD}
          isClaimsFeatureEnabled={isClaimsFeatureEnabled}
        />
      ) : (
        <Loader />
      )}
    </StyledAppContainer>
  );
};
export default Dashboard;
