import React from 'react';
import Form from 'react-bootstrap/Form';

import './TagOption.scss';

export const TagOption = ({
  value,
  onChange,
  placeholder,
  isInvalid,
  invalidText = 'Enter valid value',
  fieldKey = '',
  labelName,
  controlId,
  type = 'text',
  option = [],
  errorMsg,
}) => {
  return (
    <Form.Group controlId={controlId}>
      <Form.Label className="input-label-name">
        {labelName} <span>*</span>
      </Form.Label>

      <div className="tag-container">
        {option.map((item) => (
          <div
            key={item.name}
            className={`tag-item-container ${
              value.toLowerCase() === item.name.toLowerCase()
                ? 'tag-item-selected'
                : ''
            }`}
            // onClick={() => onChange(item.name, fieldKey)}
            onClick={(e) => {
              onChange(item.name);
            }}
          >
            {item.name}
          </div>
        ))}
      </div>
      {isInvalid && (
        <small className="error-message text-danger form-text">
          {errorMsg}
        </small>
      )}
    </Form.Group>
  );
};
