import { AppConstants } from './AppConstants';
import { customAlphabet } from 'nanoid';
import moment from 'moment';
import { SEGMENT_ACTIONS } from './SegmentActionConstants';
import { toast } from 'react-toastify';

export const getStatus = (operation, prefix = null) => {
  switch (operation) {
    case AppConstants.ADD:
      return `${prefix ? prefix + ' ' : ''}Addition Pending`;
    case AppConstants.UPDATE:
      return `${prefix ? prefix + ' ' : ''}Update Pending`;
    case AppConstants.DELETE:
      return `${prefix ? prefix + ' ' : ''}Deletion Pending`;
    case AppConstants.ADD_DEPENDENT:
      return 'Dependent Addition Pending';
    case AppConstants.UPDATE_DEPENDENT:
      return 'Dependent Update Pending';
    case AppConstants.DELETE_DEPENDENT:
      return 'Dependent Delete Pending';
    default:
      return null;
  }
};

export const processStatus = (status) => {
  switch (status) {
    case 'ENROLLED':
      return 'Active';
    case 'IN_PROGRESS':
      return 'In Progress';
    case 'PENDING':
      return 'Pending';
    case 'REJECTED':
      return 'Rejected';
    case 'DELETION_PENDING':
      return 'Deletion Pending';
    default:
      return null;
  }
};
export const getSegmentPageName = (pathName) => {
  const routeName = pathName?.split('/');
  switch (routeName?.[1]) {
    case 'login':
      return 'LOGIN_PAGE';
    case 'enter-otp':
      return 'OTP_PAGE';
    case 'employees':
      return 'EMPLOYEE_LISTING_PAGE';
    case 'policies':
      return 'POLICY_LISTING_PAGE';
    // case 'benefits':
    //   return 'BENEFITS_LISTING_PAGE';
    case 'select-policies':
      return 'POLICY_SELECTION_PAGE';
    case 'view-employee':
      return 'VIEW_EMPLOYEE_PAGE';
    case '':
      return 'DASHBOARD_PAGE';
    default:
      return 'UNKNOWN_PAGE';
  }
};

export const getTrackPageObj = (pathname = '') => {
  const page_id = getSegmentPageName(pathname);
  const currentPageAction = SEGMENT_ACTIONS.PAGE[page_id];
  return {
    name: currentPageAction?.name ?? page_id,
    properties: {
      ...(currentPageAction?.properties ?? {}),
      page_url: pathname,
      page_id,
    },
  };
};
const isObject = (value) =>
  typeof value === 'object' && (value !== null || typeof value === 'function');

const getClickPropertiesFromRecord = (record) =>
  isObject(record)
    ? {
        clicked_employee_id: record?.empid ?? '',
        clicked_employee_name: record?.name ?? '',
        clicked_employer_name: record?.compName ?? '',
        clicked_employer_id: record?.compId ?? '',
      }
    : {};

const getTrackObj = (
  action,
  page_category,
  page_title,
  clickProperties,
  otherProperties,
  currentPageAction,
  tried_value,
) => ({
  name: action,
  properties: {
    page_category,
    page_title,
    tried_value,
    ...(otherProperties ?? {}),
    ...clickProperties,
    ...(currentPageAction ?? {}),
  },
});

export const getTrackClickObj = (
  action,
  pathname,
  page_category = '',
  { record, ...otherProperties } = {},
) => {
  const currentPageAction = SEGMENT_ACTIONS.CLICK[action];
  const clickProperties = getClickPropertiesFromRecord(record);
  const page_title = (pathname ?? '').slice(1).replaceAll('/', '_');
  return getTrackObj(
    action,
    page_category,
    page_title,
    clickProperties,
    otherProperties,
    currentPageAction,
  );
};

export const getTrackTaskObj = (
  action,
  pathname,
  page_category = '',
  tried_value = '',
  { record, ...otherProperties } = {},
) => {
  const currentPageAction = SEGMENT_ACTIONS.TASK[action];
  const clickProperties = getClickPropertiesFromRecord(record);
  const page_title = (pathname ?? '').slice(1).replaceAll('/', '_');
  return getTrackObj(
    action,
    page_category,
    page_title,
    clickProperties,
    otherProperties,
    currentPageAction,
    tried_value,
  );
};
const taskByLoginMethod = {
  google: 'GOOGLE_LOGIN',
  outlook: 'OUTLOOK_LOGIN',
  mobile: 'MOBILE_LOGIN',
};
export const getTaskByLoginMethod = (loginMethod, status) =>
  taskByLoginMethod[loginMethod]
    ? taskByLoginMethod[loginMethod] + `${status ? '_SUCCESS' : '_FAIL'}`
    : 'UNKNOWN';

export const getSuccessMessage = (firstName, lastName, operation) => {
  switch (operation) {
    case AppConstants.ADD:
      return `Your request to add employee information for ${
        firstName + ' ' + lastName
      } has been submitted successfully! Our customer service team will get in touch with you within 24 hours`;
    case AppConstants.ADD_DEPENDENT:
      return `Your request to add dependent information for ${
        firstName + ' ' + lastName
      } has been submitted successfully! Our customer service team will get in touch with you within 24 hours`;
    case AppConstants.UPDATE:
      return `Your request to update employee information for ${
        firstName + ' ' + lastName
      } has been submitted successfully! Our customer service team will get in touch with you within 24 hours`;
    case AppConstants.UPDATE_DEPENDENT:
      return `Your request to update dependent information for ${
        firstName + ' ' + lastName
      } has been submitted successfully! Our customer service team will get in touch with you within 24 hours`;
    default:
      break;
  }
};

export const validateMobile = (value) => {
  if (!value || value.length !== 10) {
    return null;
  }
  var regex = /^[0-9]+$/;
  return regex.test(value);
};

export const validateEmail = (emailTxt) => {
  if (!emailTxt) {
    return null;
  }
  // var regEx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  var regEx = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/;
  return regEx.test(emailTxt);
};

export const validateName = (value) => {
  if (!value) {
    return null;
  }
  const regEx = /^[a-zA-Z ]+$/;
  return regEx.test(value);
};

export const getLoopId = (prefix = 'LPU') => {
  let nanoid_6 = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyz', 6);
  let documentId = `${prefix}-` + nanoid_6().toLocaleUpperCase();
  return documentId;
};

function ExcelDateToJSDate(serial) {
  var utc_days = Math.floor(serial - 25569);
  var utc_value = utc_days * 86400;
  var date_info = new Date(utc_value * 1000);
  var fractional_day = serial - Math.floor(serial) + 0.0000001;
  var total_seconds = Math.floor(86400 * fractional_day);
  var seconds = total_seconds % 60;
  total_seconds -= seconds;
  var hours = Math.floor(total_seconds / (60 * 60));
  var minutes = Math.floor(total_seconds / 60) % 60;
  return new Date(
    date_info.getFullYear(),
    date_info.getMonth(),
    date_info.getDate(),
    hours,
    minutes,
    seconds,
  );
}

const convertToISOFormat = (date) => {
  let nonISODateObject = new Date(date.toISOString());
  let isoDateObject =
    nonISODateObject.getFullYear() +
    '-' +
    (nonISODateObject.getMonth() + 1) +
    '-' +
    nonISODateObject.getDate();
  return isoDateObject;
};
//converts DD/MM/YYYY into timestamp
export const convertToTimestamp = (date) => {
  if (!date) {
    return null;
  }
  const [year, month, day] = date.split('-');
  const dobTimeStamp = new Date(year, month - 1, day);
  if (!month) {
    return ExcelDateToJSDate(parseInt(date, 10));
  }
  return convertToISOFormat(dobTimeStamp);
};
//converts timestamp into DD/MM/YYYY
export const convertToDate = (data, dateFormat = 'DD/MM/YYYY') => {
  if (!data) {
    return null;
  }
  const dateData = data.seconds || data._seconds;
  if (dateData) {
    return moment.unix(dateData).format(dateFormat);
  }
  return null;
};

export const getAgeFromTimestamp = (timestamp) => {
  const date = convertToDate(timestamp);
  if (!date) return null;
  return moment().diff(moment(date, 'DD/MM/YYYY'), 'years');
};

export const convertDateToFirebaseTimestamp = (date) => ({
  _seconds: new Date(date).getTime() / 1000,
  _nanoseconds: 0,
});

export const colorLibrary = [
  '#066066',
  '#cb3c3a',
  '#7f2341',
  '#009cc3',
  '#81a269',
  '#d67358',
  '#685f80',
  '#b2ab67',
  '#7cb9b2',
  '#877d8e',
];

export const removeEmpty = (obj) => {
  let newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] === Object(obj[key])) newObj[key] = removeEmpty(obj[key]);
    else if (obj[key] !== undefined) newObj[key] = obj[key];
  });
  return newObj;
};

export const getAmountInLacs = (
  amount,
  lacTextSingular = 'L',
  lacTextPlural = 'L',
) =>
  `₹${
    amount >= 100000
      ? `${amount / 100000}${
          amount / 100000 === 1 ? lacTextSingular : lacTextPlural
        }`
      : amount
  }`;

export const jsonStrParse = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
};

export const getDisplayTextFromUpper = (uppercaseText = '') =>
  capitalizeFirstLetter(uppercaseText.replaceAll('_', ' ').toLowerCase());
export const capitalizeFirstLetter = (word = '') =>
  (word.charAt(0)?.toUpperCase() ?? '') + (word.slice(1) ?? '');
export const capitalizeFirstLetterOfEachWord = (str) =>
  str
    ? str
        .toLowerCase()
        .split(' ')
        .map((word) => capitalizeFirstLetter(word))
        .join(' ')
    : '--';

export const textPlaceholder = (text) => (text ? text : '--');

export const getPolicyType = (name = '', isTopupPolicy, isParentalPolicy) => {
  if (name.toLowerCase() === 'gmc') {
    if (isTopupPolicy && isParentalPolicy) return 'GMC - Parental Topup';
    else if (isTopupPolicy) return 'GMC - Top-Up';
    else if (isParentalPolicy) return 'GMC - Parental';
    else return 'GMC';
  } else if (name.toLowerCase() === 'gpa') {
    return isTopupPolicy ? 'GPA - Top-Up' : 'GPA';
  } else if (name.toLowerCase() === 'gtl') {
    return isTopupPolicy ? 'GTL - Top-Up' : 'GTL';
  } else if (name.toLowerCase() === 'covid') {
    return isTopupPolicy ? 'Covid - Top-Up' : 'Covid';
  }
  return '';
};

export const downloadFile = (fileLink, fileName) => {
  const link = document.createElement('a');
  link.href = fileLink;
  link.download = fileName;
  link.click();
  link.remove();
};

export const parseResponse = async (promise) => {
  return promise
    .then((data) => {
      return [null, data];
    })
    .catch((err) => [err]);
};

export const formatCurrencyAmount = (value, symbol = '₹') => {
  value = value || 0;
  value = typeof value === 'string' ? value.replace(/,/g, '') : value;
  const formattedValue = new Intl.NumberFormat('en-IN', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
  return `${symbol}${formattedValue}`;
};

export const showErrorToast = (message) => {
  toast.error(message || 'Something went wrong!', {
    position: toast.POSITION.BOTTOM_CENTER,
  });
};

export const getCurrencyString = (cost) =>
  cost.toLocaleString('en-IN', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    style: 'currency',
    currency: 'INR',
  });

export const groupArrayOfObjectsByKey = (list, keyFunc) =>
  list.reduce(
    (hash, obj) => ({
      ...hash,
      [keyFunc(obj)]: (hash[keyFunc(obj)] || []).concat(obj),
    }),
    {},
  );

export const convertNumberWithSeprator = (cost) => cost.toLocaleString('en-IN');

export const convertToNearestThousand = (value) => {
  if (typeof value === 'string') value = Number(value);
  return Math.round(value / 1000) * 1000;
};
export const toUnderscoreName = (name = '') => {
  return name.replace(/[^a-zA-Z0-9]+/g, '_');
};

export const uploadSignedFile = async (URL, method, body) => {
  const response = await fetch(URL, {
    method,
    body,
    headers: {
      'x-goog-content-length-range': '0,26214400',
    },
  });
  if (!response.ok) {
    throw new Error('Some error occured while uploading file');
  }
};

export const deleteSignedFile = async (URL, method) => {
  const response = await fetch(URL, {
    method
  });
  if (!response.ok) {
    throw new Error('Some error occured while deleting file');
  }
};

export const downloadSignedFile = async (URL, method, fileName) => {
  try {
    const file = await fetch(URL, {
      method,
    });
    const link = document.createElement('a');
    link.href = file.url;
    link.download = fileName;
    if (fileName.toLowerCase().endsWith('.pdf')) link.target = '_blank';
    link.click();
    link.remove();
  } catch (error) {
    throw error;
  }
};
