import {
    StyledClearFilter,
    StyledDownloadWrapper,
    StyledFilterContainer, StyledFilterHeader,
    StyledFilterSection,
    StyledHeaderContainer,
    StyledSearchWrapper
} from "./styles";
import {
    Form,
    LoopButton,
    LoopRangeDatePicker,
    LoopTable,
    TableEmptyState,
    Typography
} from "@loophealth/loop-ui-web-library";
import {ClearIcon, EmailIcon, SearchIcon} from "../../../../assets/images";
import React, {ChangeEvent} from "react";
import {getPlaceholderData} from "./utils";
import '@loophealth/loop-ui-web-library/dist/index.css'
import {DateRange} from "@loophealth/loop-ui-web-library/dist/types/atoms/RangeDatePicker/types";
import moment from "moment";
import {IDateRange} from "./types";
import SendClaimsRecordEmailModal from "../../../containers/SendClaimsRecordEmailModal";
import TablePagination from "../../../atoms/TablePagination";
import {useHistory} from "react-router-dom";
import useDebouncedValue from "../../../../utils/hooks/useDebouncedValue";
import Loader from "../../../atoms/Spinner";
import useFetchClaims from "./hooks/useFetchClaims";
import useFetchClaimsFilters from "./hooks/useFetchClaimsFilters";
import usePaginatedClaimsList from "./hooks/usePaginatedClaimsList";
import {StyledContainer} from "../styles";

const headers = [
    'Claims ID',
    'Intimation date',
    'Employee name',
    'Employee ID',
    'Patient name',
    'Claim type',
    'Policy no.',
    'Status',
];

const defaultDates = {
    startDate: moment().startOf('month').toDate(),
    endDate: moment().endOf('month').toDate()
}
const ClaimsSearch = () => {
    const history = useHistory();
    const [searchString, setSearchString] = React.useState<string>('');
    const debouncedSearchTerm = useDebouncedValue(searchString, 1000);

    // filter states-
    const [selectedDateRange, setSelectedDateRange] = React.useState<IDateRange>(defaultDates);
    const [selectedStatus, setSelectedStatus] = React.useState<string[]>([]);
    const [selectedClaimsType, setSelectedClaimsType] = React.useState<string[]>([]);
    const [selectedPolicyNo, setSelectedPolicyNo] = React.useState<string[]>([]);

    const {isLoading, claimsList} = useFetchClaims(debouncedSearchTerm, selectedDateRange, selectedClaimsType);
    const {filters} = useFetchClaimsFilters();
    const claimsCount = claimsList?.length || 0;

    // Claims List Pagination states-
    const perPageCount = 10;
    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const {filteredClaimsList} = usePaginatedClaimsList(claimsList, currentPage, perPageCount)

    // Email claims states-
    const [isEmailModalVisible, setIsEmailModalVisible] = React.useState(false);

    const filterCount =
        (selectedStatus.length ? 1 : 0) + (selectedClaimsType.length ? 1 : 0) + (selectedPolicyNo.length ? 1 : 0);
    const placeholderData = getPlaceholderData(filterCount, debouncedSearchTerm);

    const onSearchStringChanged = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchString(e.currentTarget.value)
    }

    const onDateRangeChanged = (range: DateRange) => {
        const {startDate, endDate} = range;
        if (startDate && endDate)
            setSelectedDateRange({startDate, endDate})
    }

    const onDownloadReportClicked = () => {
        setIsEmailModalVisible(true);
    }

    const onClearFilterClicked = () => {
        setSelectedStatus([])
        setSelectedClaimsType([])
        setSelectedPolicyNo([])
        setSelectedDateRange(defaultDates);
    }

    function onClaimsClicked(rowData: Record<string, unknown>) {
        history.push(`claim-details?id=${rowData.id}`)
    }

    return (
        <StyledContainer>
            <SendClaimsRecordEmailModal isVisible={isEmailModalVisible} setIsVisible={setIsEmailModalVisible}/>
            <StyledHeaderContainer>
                <StyledSearchWrapper>
                    <Typography variant='title2' weight='medium'>All claims</Typography>
                    <Form.Input placeholder="Search by Employee name, Claims ID, Employee ID or Policy No"
                                value={searchString}
                                onChange={onSearchStringChanged} iconSrc={SearchIcon} iconAlign="left"/>
                </StyledSearchWrapper>
                <StyledDownloadWrapper>
                    <LoopButton variant='filled' onClick={onDownloadReportClicked}
                                iconSrc={EmailIcon} iconOrder='right'>
                        Get claim records
                    </LoopButton>
                </StyledDownloadWrapper>
            </StyledHeaderContainer>
            <StyledFilterSection>
                <StyledFilterHeader>
                    <Typography variant='medium' weight='medium'>Filters</Typography>
                    {!!filterCount &&
                        <StyledClearFilter onClick={onClearFilterClicked}>
                            <img src={ClearIcon} alt='clear-filter'/>
                            <Typography variant='small' weight='medium' color='emerald'>
                                {`Clear All (${filterCount} Applied)`}
                            </Typography>
                        </StyledClearFilter>}
                </StyledFilterHeader>
                <StyledFilterContainer>
                    <Form.FormField id='date-select' label='Claim intimation date'>
                        <LoopRangeDatePicker startDate={selectedDateRange.startDate} endDate={selectedDateRange.endDate}
                                             onChange={onDateRangeChanged}/>
                    </Form.FormField>
                    {/*<Form.FormField id='status' label='Claim status'>
                        <Form.MultiSelectDropdown listItems={filters?.status || []} handleChange={setSelectedStatus}
                                                  placeholder='All open'
                                                  selectedItems={selectedStatus}/>
                    </Form.FormField>*/}
                    <Form.FormField id='claim-type' label='Claim type'>
                        <Form.MultiSelectDropdown listItems={filters?.type || []} handleChange={setSelectedClaimsType}
                                                  placeholder='All Types'
                                                  selectedItems={selectedClaimsType}/>
                    </Form.FormField>
                    {/*<Form.FormField id='policy-type' label='Policy'>
                        <Form.MultiSelectDropdown listItems={filters?.policyType || []}
                                                  handleChange={setSelectedPolicyNo}
                                                  placeholder='All Policies'
                                                  selectedItems={selectedPolicyNo}/>
                    </Form.FormField>*/}
                </StyledFilterContainer>
            </StyledFilterSection>
            <div>
                <TablePagination currentPage={currentPage} rowsPerPage={perPageCount} count={claimsCount}
                                 onPageChange={(pageNumber) => setCurrentPage(pageNumber)}/>
                <LoopTable headers={headers} data={filteredClaimsList} onTableRowClicked={onClaimsClicked}/>
                {!filteredClaimsList.length ? (isLoading ? <Loader/> : <TableEmptyState {...placeholderData}/>) : null}
            </div>
        </StyledContainer>
    )
}
export default ClaimsSearch
