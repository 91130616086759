import React from 'react';
import {
  StyledBanner,
  StyledBannerText,
  StyledBannerVideo,
  StyledDemoBanner,
} from './styles';
import { Typography } from '@loophealth/loop-ui-web-library';

const DemoBanner = () => {
  return (
    <StyledDemoBanner id="video">
      <StyledBanner>
        <StyledBannerText>
          <Typography variant="title2" weight="medium" color="emerald">
            In the mean time, here&apos;s a sneak peek of the new and improved
            HR Dashboard.
          </Typography>
          <Typography variant="large">Thank you for your patience!</Typography>
        </StyledBannerText>

        <StyledBannerVideo
          src="https://www.youtube.com/embed/qOZ3V8ioDzo?si=vQEx_7aAKgqtyRqW"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen={true}
        />
      </StyledBanner>
    </StyledDemoBanner>
  );
};

export default DemoBanner;
