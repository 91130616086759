import styled from 'styled-components';

export const StyledMidTermModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 768px;
`;

export const StyledMidTermHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 32px 36px;
  gap: 2px;
`;

export const StyledMidTermFooter = styled.div`
  display: flex;
  padding: 20px 32px 24px;
  gap: 16px;
  border-top: 1px solid ${(p) => p.theme.colors.seafoamGray};
`;

export const StyledMidTermBody = styled.div`
  display: flex;
  padding-bottom: 40px;
  max-height: 500px;
  min-height: 500px;
`;

export const StyledFileContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
`;

export const StyledDeleteIcon = styled.img`
  width: 20px;
  aspect-ratio: 1;
  cursor: pointer;
`;

export const StyledFileName = styled.span`
  max-width: 190px;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  text-decoration: underline;
`;
