import { IStyledTabWithDetail, IStyledTabWithDetailColorIcon } from './types';
import styled from 'styled-components';

export const StyledTabWithDetail = styled.div<IStyledTabWithDetail>`
  display: flex;
  gap: 10px;
  padding: 16px;
  border-radius: 8px;
  background: ${(p) =>
    p.$isSelected
      ? p.theme.colors.supportingPlaneGreenDark
      : p.theme.colors.surfaceOne};
  /* width: 100%; */
  cursor: pointer;
`;

export const StyledTabWithDetailColorIcon = styled.div<IStyledTabWithDetailColorIcon>`
  width: 18px;
  height: 18px;
  background-color: ${(p) => p.color};
  border-radius: 50%;
  aspect-ratio: 1;
`;

export const StyledTabWithDetailText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const StyledTabWithDetailTextTooltip = styled.div`
  display: flex;
  gap: 4px;
  justify-content: space-between;
  align-items: flex-start;

  & > div {
    display: flex;
  }
`;

export const StyledIconImg = styled.img`
  width: 20px;
  aspect-ratio: 1;
`;
