import LoopEndpoints from './LoopEndpoints';
import { get } from './providers';

const GlobalSearchAPI = {
  getGlobalSearchData: (companyId, searchString) =>
    get(LoopEndpoints.fetchGlobalSearchData(companyId, searchString)),

  getCompanyList: () => get(LoopEndpoints.fetchCompanyList()),
};

export default GlobalSearchAPI;
