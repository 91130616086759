import {StyledContainer, StyledNavigationContainer} from "./styles";
import {LoopButton, Typography} from "@loophealth/loop-ui-web-library";
import React from "react";

interface ITablePagination {
    currentPage: number
    count: number
    rowsPerPage: number
    onPageChange: (pageNumber: number) => void
}

const TablePagination: React.FC<ITablePagination> = ({count, currentPage, rowsPerPage, onPageChange}) => {
    const startingRecord = (currentPage - 1) * rowsPerPage;
    const endingRecord = Math.min(startingRecord + rowsPerPage, count);
    const paginationText = `${startingRecord + 1}-${endingRecord} of ${count} records`;

    const previousButtonVariant = currentPage === 1 ? 'disabled' : 'outline';
    const nextButtonVariant = currentPage === Math.ceil(count / rowsPerPage) ? 'disabled' : 'outline';


    const onPreviousClick = () => {
        if (currentPage <= 1) return;
        onPageChange(currentPage - 1)
    }

    const onNextClick = () => {
        const totalPages = Math.ceil(count / rowsPerPage);
        if (currentPage >= totalPages) return;

        onPageChange(currentPage + 1)
    }

    return (
        <StyledContainer>
            <Typography variant='small' weight='medium'>{paginationText}</Typography>
            <StyledNavigationContainer>
                <LoopButton variant={previousButtonVariant} size='small' onClick={onPreviousClick}>Previous</LoopButton>
                <LoopButton variant={nextButtonVariant} size='small' onClick={onNextClick}>Next</LoopButton>
            </StyledNavigationContainer>
        </StyledContainer>
    )
}
export default TablePagination
