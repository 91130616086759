import LoopApiService from '../../../../../adaptars/LoopApiService';
import { parseResponse } from '../../../../../utils/common/Utilities';
import {
  IPayload,
  IUploadedPolicywiseData,
  PolicyWiseValidationRequest,
  PolicyWiseValidationResponse,
  IPolicyRejectedEntries,
} from '../types';
import { ICDAccount } from '../../../CDAccounts/types';

export const processValidationPayloadData = (
  correctEntries: IUploadedPolicywiseData,
  companyId: string,
): IPayload => {
  const policies: PolicyWiseValidationRequest[] = Object.keys(
    correctEntries,
  ).reduce(
    (prev, policyId) =>
      prev.concat({
        policyId,
        membersList: correctEntries[policyId],
      }),
    [] as PolicyWiseValidationRequest[],
  );
  return {
    companyId,
    policies,
  };
};

export const validateAddData = async (
  bulkAddData: IPayload,
): Promise<
  [uploadedDataSet: any, validationRejections: IPolicyRejectedEntries]
> => {
  const [error, response] = await parseResponse(
    LoopApiService.validateBulkAdditionData(bulkAddData),
  );
  if (error) throw error;
  const uploadedDataSet: any = {};
  response.data?.allMembers?.forEach(
    (data: any) => (uploadedDataSet[data.policyId] = data.membersList),
  );
  const validationRejections = (
    response.data?.invalidMembersPerPolicy as PolicyWiseValidationResponse[]
  ).reduce(
    (prev, current) => ({
      ...prev,
      [current.policyId]: current.invalidMembers.map((member) => ({
        ...member,
        error: member.errors,
      })),
    }),
    {} as IPolicyRejectedEntries,
  );
  return [uploadedDataSet, validationRejections];
};

export const concatMidtermAndValidationErrors = (
  policies: string[],
  rejectedMidterms: IPolicyRejectedEntries,
  rejectedValidations: IPolicyRejectedEntries,
) => {
  const finalRejections: IPolicyRejectedEntries = {};
  policies.forEach((policyId) => {
    const policyRejections = [];
    if (rejectedMidterms[policyId])
      policyRejections.push(...rejectedMidterms[policyId]);
    if (rejectedValidations[policyId])
      policyRejections.push(...rejectedValidations[policyId]);
    if (policyRejections.length) finalRejections[policyId] = policyRejections;
  });
  return finalRejections;
};
export const submitBulkAddEndorsement = async (
  companyId: string,
  correctData: IUploadedPolicywiseData,
) => {
  const policies: any = [];
  Object.keys(correctData).forEach((policy) => {
    const membersList = correctData[policy];
    policies.push({ policyId: policy, membersList });
  });
  const [error, response] = await parseResponse(
    LoopApiService.submitBulkAddRequest({
      companyId,
      policies: policies,
    }),
  );
  if (error || !response) {
    return;
  }
  return response;
};
export const submitBulkDeleteEndorsement = async (
  companyId: string,
  correctData: Object,
) => {
  const [error, response] = await parseResponse(
    LoopApiService.submitBulkDeleteRequest({
      companyId,
      memberList: correctData,
    }),
  );
  if (error || !response) {
    return;
  }
  return response;
};
export const sanitizeCostEstimationData = (
  costEstimationData: any,
  mode: 'OFFBOARD' | 'ADD',
) => {
  const sanitisedData: Record<string, any> = {};
  const listedPolicies: string[] = [];

  costEstimationData.forEach((data: any) => {
    Object.keys(data).forEach((policyID) => {
      listedPolicies.push(policyID);
      sanitisedData[policyID] = data[policyID][mode];
    });
  });
  return { listedPolicies, sanitisedData };
};

export const prepareCostEndoData = (
  cdAccounts: ICDAccount[],
  costEstimationData: any,
  mode: 'OFFBOARD' | 'ADD',
) => {
  const data: any[] = [];
  let totalCost = 0;
  let totalLives = 0;
  const { listedPolicies, sanitisedData } = sanitizeCostEstimationData(
    costEstimationData,
    mode,
  );
  cdAccounts.forEach((account) => {
    const hasMatchingPolicies = account.policies.some((policy) =>
      listedPolicies.includes(policy),
    );

    if (hasMatchingPolicies) {
      let accountWiseCost = 0;
      const policyData = account.policyData
        .map(({ policyType, insurerLogo, id }) => ({
          policyType,
          insurerLogo,
          ...sanitisedData[id],
        }))
        .filter((policyData) => !!policyData.lives);
      policyData.forEach(({ lives, costOfEndorsement }) => {
        totalLives += lives;
        totalCost += costOfEndorsement;
        accountWiseCost += costOfEndorsement;
      });
      data.push({
        balance: account.balance,
        accountWiseCost: accountWiseCost,
        isCDLow: mode === 'ADD'? accountWiseCost > account.balance : false,
        accountNumber: account.accountNumber,
        insurer: account.insurer,
        policyType: policyData[0]?.policyType,
        insurerLogo: account.policyData[0]?.insurerLogo,
        policyData,
      });
    }
  });

  return { endoCostList: data, totalCost, totalLives };
};
