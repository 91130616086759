import React from 'react';
import {
  StyledUploadContainer,
  StyledHeaderWrapper,
  StyledSubContainer,
  StyledUploaderContainer,
  StyledUploadWrapper,
  StyledDetailsWrapper,
  StyledUploadInfoWrapper,
} from './styles';
import {
  Dialog,
  Modal,
  Toast,
  Typography,
  UploadPillSelection,
} from '@loophealth/loop-ui-web-library';
import UploadInfoCard from '../../../../atoms/UploadInfoCard';
import EndoInfoCard from '../../../../containers/EndoInfoCard';
import { processFileUpload } from './utils';
import { IUploadDeletionProps } from './types';
import useTemplateDownload from '../hooks/useDownloadTemplate';
import { useSelector } from 'react-redux';
import { selectSelectedCompany } from '../../../../../redux/slices/hrdRevampRedux';
import { TrashErrorIcon } from '../../../../../assets/images';
import { VIDEO_URLS } from '../../../../containers/Bulk/constants';
import useSegment from '../../../../../utils/segment/hooks/useSegment';
import { useLocation } from 'react-router-dom';
import {
  extractPathName,
  trackClickEvent,
} from '../../../../../utils/segment/utils';
const UploadDeletion: React.FC<IUploadDeletionProps> = ({
  setSheetData,
  setSheetSelected,
  sheetSelected,
}) => {
  const trackClick = useSegment('click');
  const location = useLocation();
  const selectedCompany = useSelector(selectSelectedCompany);
  const companyId = selectedCompany?.id ?? '';
  const toast = Toast.useToast();
  const [isVisible, setIsVisible] = React.useState(false);
  const handleError = (errorType: string) => {
    toast?.error(errorType, '');
  };
  const handleDeleteIconClick = () => {
    setIsVisible(true);
    trackClick(
      trackClickEvent(
        'Delete_Button_del',
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
  };
  const handleDeleteButtonClick = () => {
    setSheetSelected([]);
    setIsVisible(false);
    trackClick(
      trackClickEvent(
        'Delete_del',
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
  };

  const handleCancelButtonClick = () => {
    setIsVisible(false);
    trackClick(
      trackClickEvent(
        'Cancel_Delete_del',
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
  };
  const handleFileUpload = (files: Record<string, unknown>[]) => {
    processFileUpload(files, setSheetData, setSheetSelected, toast);
  };

  const { startDownload, isDownloading } = useTemplateDownload(
    'Delete in Multiple Policies (Base Template)',
    companyId,
  );
  return (
    <StyledDetailsWrapper>
      <StyledSubContainer>
        <StyledUploaderContainer>
          <EndoInfoCard
            onClick={startDownload}
            isDownloading={isDownloading}
            videoUrl={VIDEO_URLS.DELETE}
            operation="DELETE"
          />
          <StyledUploadContainer>
            <StyledHeaderWrapper>
              <Typography variant="medium" weight="semiBold">
                Upload Deletion Data
              </Typography>
            </StyledHeaderWrapper>
            <StyledUploadWrapper>
              <UploadPillSelection
                formats={['.xls', '.xlsx']}
                onUpload={handleFileUpload}
                selected={sheetSelected}
                onError={(error) => handleError(error.message)}
                maxFiles={1}
                maxSize={25}
                onDelete={handleDeleteIconClick}
              />
              <StyledUploadInfoWrapper>
                <Typography variant="small" weight="semiBold" color="secondary">
                  Supported Formats: XLS, XLSX
                </Typography>
                <Typography variant="small" weight="regular" color="secondary">
                  Maximum Size: 25MB
                </Typography>
              </StyledUploadInfoWrapper>
            </StyledUploadWrapper>
          </StyledUploadContainer>
        </StyledUploaderContainer>
        <UploadInfoCard />
      </StyledSubContainer>
      <Modal isVisible={isVisible} setIsVisible={() => setIsVisible(false)}>
        <Dialog
          variant="vertical"
          title={`Deleting “${
            sheetSelected.length ? sheetSelected[0]?.name : ''
          }”?`}
          description="Are you sure you want to delete this file? You’ll have to re-upload it."
          icon={TrashErrorIcon}
          primaryButtonText="Yes, Delete"
          onPrimaryButtonClicked={handleDeleteButtonClick}
          onSecondaryButtonClicked={handleCancelButtonClick}
          secondaryButtonText="Cancel"
          buttonVariant="error"
        />
      </Modal>
    </StyledDetailsWrapper>
  );
};

export default UploadDeletion;
