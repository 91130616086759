import { useEffect, useState } from 'react';
import { IClickAction, ITaskAction } from '../../../enterOtp/types';
import {
  getTrackClickObj,
  getTrackTaskObj,
} from '../../../../../utils/common/Utilities';
import { useHistory, useLocation } from 'react-router';
import { auth } from '../../../../../firebase';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearHrUpdate,
  selectConfirmationResult,
  selectHrUser,
  selectUpdateUser,
  setClearHrData,
  setConfirmationResult,
} from '../../../../../redux/slices/usersSlice';
import { FirebaseError } from '@firebase/util';
import { fetchHrUser } from '../../../../../redux/slices/usersSlice/thunks';
import { setLoopIdToken } from '../../../../../utils/common/CustomFirebase';
import { useAuth } from '../../../../../contexts/AuthContext';
import { LoginErrorCode } from '../../types';
import useSegment from '../../../../../utils/segment/hooks/useSegment';

const useOTPValidation = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { setLoggedInUser } = useAuth();
  const trackTask = useSegment('track');
  const trackClick = useSegment('click');
  const hrData = useSelector(selectHrUser);
  const updateHrValue = useSelector(selectUpdateUser);
  const location = useLocation<{ mobile?: string }>();
  const confirmationResult = useSelector(selectConfirmationResult);

  const [error, setError] = useState('');
  const [otpCode, setOTPCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [validOtp, setValidOtp] = useState(false);
  const [invalidOtp, setInvalidOtp] = useState(false);
  const [resendingCode, setResendingCode] = useState(false);

  const mobile = (location && location.state && location.state.mobile) || '';

  const callTrackClick = (action: IClickAction) =>
    trackClick(
      getTrackClickObj(action, location?.pathname, 'HRD Login&Logout Events'),
    );
  const callTrackTask = (
    action: ITaskAction,
    category: string,
    tried_value = '',
  ) =>
    trackTask(
      getTrackTaskObj(action, location?.pathname, category, tried_value),
    );

  const handleChange = (otp: string) => {
    setOTPCode(otp);
  };

  const resendOTP = async () => {
    callTrackClick('RESEND_OTP');
    setOTPCode('');
    setInvalidOtp(false);
    setResendingCode(true);
    try {
      const confirmationData = await auth.signInWithPhoneNumber(
        '+91' + mobile,
        window.recaptchaVerifier,
      );

      dispatch(setConfirmationResult({ data: confirmationData }));
      setResendingCode(false);
    } catch (error) {
      setResendingCode(false);
      if ((error as FirebaseError).code === 'auth/too-many-requests') {
        setError('Too many attempts, try again later');
      } else {
        setError((error as FirebaseError).message);
      }
    }
  };

  const verifyOTP = () => {
    callTrackClick('VERIFY_OTP');
    setLoading(true);
    setInvalidOtp(false);
    setValidOtp(false);
    if (confirmationResult && confirmationResult.confirm) {
      confirmationResult
        .confirm(otpCode)
        .then((result) => {
          if (hrData && !hrData.data) {
            setLoading(true);
            dispatch(
              fetchHrUser({
                methodType: 'mobile',
                userId: mobile,
                loginMethod: 'mobile',
              }),
            );
          }
          setValidOtp(true);
          dispatch(setConfirmationResult({ data: null }));
        })
        .catch((error) => {
          setLoading(false);
          const errorMessage = error.message;
          if (error.code === LoginErrorCode.INVALID_VERIFICATION_CODE) {
            callTrackTask('MOBILE_OTP_FAIL', 'HRD Login&Logout Events', mobile);
            setInvalidOtp(true);
          } else if (errorMessage.indexOf('{') !== -1) {
            callTrackTask(
              'MOBILE_LOGIN_FAIL',
              'HRD Login&Logout Events',
              mobile,
            );
            // The error from the backend is thrown in this format
            // HTTP Cloud Function returned an error:
            // {"error":{"message":"The provided Email Id is not registered in Loop",
            // "status":"INVALID_ARGUMENT"}}
            // Extracting the message part of the error using the below code
            const jsonResponse = errorMessage.substring(
              errorMessage.indexOf('{'),
            );
            const errorObject = JSON.parse(jsonResponse);

            dispatch(setConfirmationResult({ data: null }));
            history.replace('/login', {
              errorMessage: errorObject.error.message,
            });
          } else {
            callTrackTask(
              'MOBILE_LOGIN_FAIL',
              'HRD Login&Logout Events',
              mobile,
            );
            throw error;
          }
        });
    } else {
      dispatch(setConfirmationResult({ data: null }));
      dispatch(setClearHrData());
      history.goBack();
    }
  };

  useEffect(() => {
    if (hrData.state === 'failed') {
      dispatch(setConfirmationResult({ data: null }));
      dispatch(setClearHrData());
      history.replace('/login', {
        errorMessage: `+91-${mobile}`,
      });
    } else if (hrData.data?.email && hrData.data.mobile) {
      setLoopIdToken(hrData.data.userId);
      //email is already present need to redirect to hr dashboard.
      setLoading(false);
      setLoggedInUser({ ...hrData.data });
      callTrackTask('MOBILE_LOGIN_SUCCESS', 'HRD Login&Logout Events');
      history.push('/');
    } else if (hrData?.data && !hrData.data.mobile && validOtp) {
      setLoading(false);
      //TODO: Need to register the mobile number and redirect to the dashboard screen.
      callTrackTask('MOBILE_LOGIN_FAIL', 'HRD Login&Logout Events', mobile);
      setValidOtp(false);
      // dispatch(updateHrData({ id: hrData.data.id, data: { mobile: mobile } })); // TODO: uncomment if you need old flow
      // TODO: comment if you need old flow
      history.replace('/login', {
        errorMessage: `+91-${mobile}`,
      });
    } else if (hrData?.data && !hrData.data.email) {
      //Navigates to add email screen when mobile is not present
      setLoading(false);
      callTrackTask('MOBILE_LOGIN_FAIL', 'HRD Login&Logout Events', mobile);
      // history.push('/enter-id', { showEmail: true }); // TODO: uncomment if you need old flow
      // TODO: comment if you need old flow
      history.push('/');
    }
  }, [
    dispatch,
    history,
    mobile,
    setLoggedInUser,
    hrData,
    hrData.data,
    validOtp,
  ]);

  useEffect(() => {
    if (updateHrValue && updateHrValue.state === 'succeeded') {
      dispatch(
        fetchHrUser({
          methodType: 'mobile',
          userId: mobile,
          loginMethod: 'mobile',
        }),
      );

      dispatch(clearHrUpdate());
    }
  }, [history, hrData.data, mobile, setLoggedInUser, dispatch, updateHrValue]);

  useEffect(() => {
    if (otpCode.length === 6) verifyOTP();
  }, [otpCode]);

  return {
    error,
    mobile,
    loading,
    otpCode,
    invalidOtp,
    resendingCode,
    resendOTP,
    handleChange,
  };
};

export default useOTPValidation;
