import { createAsyncThunk } from '@reduxjs/toolkit';
import LoopApiService from '../../../adaptars/LoopApiService';
import { IPolicy } from './types';

export const fetchAllCompanyPolicyList = createAsyncThunk(
  'policyPlan',
  async ({
    companyId,
    policyPlacementStatus = 'Handover to Ops',
  }: {
    companyId: string;
    policyPlacementStatus?: string;
  }) => {
    try {
      const result = await LoopApiService.getCompanyPolicyPlans({
        companyId,
        policyPlacementStatus,
        getLifeCount: true,
        active: true,
      });
      return { data: result };
    } catch (error) {
      throw error;
    }
  },
);

//  Get Policy Details of company API Called getCompanyPolicyPlans.
// export const addLogoToPolicy = createAsyncThunk(
//   'addLogoToPolicyPlan',
//   async (name) => {
//     try {
//       const result = await LoopApiService.getInsurerMasterData(name);
//       return { data: result };
//     } catch (error) {
//       throw error;
//     }
//   },
// );

export const fetchEnrolledPolicy = createAsyncThunk(
  'policyList/fetchEnrolledPolicy',
  async (userId: string) => {
    try {
      const result = await LoopApiService.getUserPoliciesByUserId(userId);
      return {
        data: result?.data?.userPolicies ?? [],
      };
    } catch (error) {
      throw error;
    }
  },
);

export const fetchUserDependentPolicies = createAsyncThunk(
  'policyList/fetchUserDependentPolicies',
  async (userId: string) => {
    try {
      const result = await LoopApiService.getUserPoliciesByUserId(userId);
      return {
        data: result?.data?.userPolicies ?? [],
      };
    } catch (error) {
      throw error;
    }
  },
);

export const fetchCompanyPolicyDetails = createAsyncThunk(
  'policyList/fetchCompanyPolicyDetails',
  async ({ companyId, policyId }: {companyId: string; policyId: string}) => {
    try {
      const result = await LoopApiService.getCompanyPolicyDetails(
        companyId,
        policyId,
      );
      return { data: result };
    } catch (error) {
      throw error;
    }
  },
);

export const fetchPolicyEmpCount = createAsyncThunk(
  'policyList/fetchPolicyEmpCount',
  async ({ companyId, policyId, policyData }: {companyId: string; policyId: string, policyData: IPolicy}) => {
    try {
      // TODO;
      const result = await LoopApiService.getPolicyEmpCount(
        companyId,
        policyId,
      );
      return { data: result, policyId, policyData };
    } catch (error) {
      throw error;
    }
  },
);