import { IUserDetails } from './types';

export const getUpdatedUserDependentDetails = (
  DependentDetails: IUserDetails,
  userDependentDetailsData: IUserDetails[],
) => {
  const updateDependentExistingDetails =
    userDependentDetailsData.find(
      (dep) => dep.userId === DependentDetails?.userId,
    ) ?? {};
  const updateDependentDetails = {
    ...updateDependentExistingDetails,
    ...DependentDetails,
  };
  const otherDependents =
    userDependentDetailsData?.filter(
      (dep) => dep.userId !== DependentDetails.userId,
    ) ?? [];
  return [...otherDependents, updateDependentDetails];
};
