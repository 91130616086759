import React from 'react';
import { Typography, DateFormatUtils } from '@loophealth/loop-ui-web-library';
import { ClaimStatus } from '../../pages/ClaimDetails/types';
import {
  statusIcon,
  statusInfoIcon,
  statusToBorderColorMap,
  statusToBGColorMap,
} from './constant';
import {
  StyledCardWrapper,
  StyledContainer,
  StyledStyledContainer,
  StyledSubStatusTitle,
  StyledStatusSubContent,
  StyledStatusSubContentWrapper,
  StyledHeadingIconWrapper,
  StyledCardContainer,
  StyledTimelineWidget,
  StyledVerticalSpacer,
} from './styles';
import { ClaimStatusTimelineProps, StatusHistoryItem } from './types';
import theme from '../../../theme';
import { hasOnlyOneStatusHistory } from '../../pages/ClaimDetails/utils';

const ClaimStatusTimeline: React.FC<ClaimStatusTimelineProps> = ({ data }) => {
  let isClaimClosedVisible = true;
  const dateFormatUtils = new DateFormatUtils();
  return (
    <StyledTimelineWidget data-testid="claim-timeline-widget">
      <Typography variant="large" weight="medium">
        Timeline
      </Typography>
      <StyledCardContainer>
        {data?.statusHistory?.map((row: StatusHistoryItem, index: number) => {
          isClaimClosedVisible = ![
            'Claim Closed',
            'Claim Paid',
            'Claim Rejected',
          ].includes(row?.claimStatus)
            ? false
            : true;

          return (
            <React.Fragment key={index}>
              <StyledCardWrapper
                data-testid="card-wrapper"
                $color={statusToBGColorMap[row?.claimStatus as ClaimStatus]}
                $border={
                  statusToBorderColorMap[row?.claimStatus as ClaimStatus]
                }
              >
                <StyledContainer>
                  <img
                    src={statusInfoIcon[row?.claimStatus as ClaimStatus]}
                    alt={statusInfoIcon[row?.claimStatus as ClaimStatus]}
                  />

                  <StyledStyledContainer>
                    <Typography variant="small" weight="medium">
                      {row?.claimStatus}
                    </Typography>

                    <Typography
                      variant="extraSmall"
                      weight="regular"
                      color="secondary"
                    >
                      {row?.statusText}
                    </Typography>
                  </StyledStyledContainer>
                  <img
                    src={statusIcon[row?.claimStatus as ClaimStatus]}
                    alt={statusIcon[row?.claimStatus as ClaimStatus]}
                  />
                </StyledContainer>
                {row?.statusReason && (
                  <StyledStatusSubContent
                    $color={statusToBGColorMap[row?.claimStatus as ClaimStatus]}
                  >
                    <StyledStatusSubContentWrapper>
                      {row?.subStatus && (
                        <StyledSubStatusTitle>
                          <Typography
                            variant="small"
                            weight="regular"
                            color="emerald"
                          >
                            {row?.subStatus}
                          </Typography>
                          {/* <Typography variant="small" weight="regular">
                            {dateFormatUtils.formatDateString(
                              row.claimStatusDate,
                            )}
                          </Typography> */}
                        </StyledSubStatusTitle>
                      )}
                      <Typography
                        variant="extraSmall"
                        weight="regular"
                        color="secondary"
                      >
                        {row?.statusReason}
                      </Typography>
                    </StyledStatusSubContentWrapper>
                  </StyledStatusSubContent>
                )}
              </StyledCardWrapper>
              {data.statusHistory.length - 1 !== index && (
                <StyledVerticalSpacer />
              )}
            </React.Fragment>
          );
        })}
        {!isClaimClosedVisible && (
          <>
            <StyledVerticalSpacer $backgroundColor={theme.colors.seafoamGray} />
            {hasOnlyOneStatusHistory(data?.statusHistory) &&
              data.statusHistory[0]?.claimStatus === 'Claim Intimated' && (
                <>
                  <StyledCardWrapper
                    $color={
                      statusToBorderColorMap['Claim Under Review Disabled']
                    }
                    $border={statusToBGColorMap['Claim Under Review Disabled']}
                  >
                    <StyledContainer>
                      <img
                        src={statusInfoIcon['Claim Under Review Disabled']}
                        alt={statusInfoIcon['Claim Under Review Disabled']}
                      />
                      <StyledStyledContainer>
                        <StyledHeadingIconWrapper $marginTop={'8px'}>
                          <Typography
                            variant="small"
                            weight="regular"
                            color="secondary"
                          >
                            Claim Under Review
                          </Typography>
                        </StyledHeadingIconWrapper>
                      </StyledStyledContainer>
                    </StyledContainer>
                  </StyledCardWrapper>
                  <StyledVerticalSpacer
                    $backgroundColor={theme.colors.seafoamGray}
                  />
                </>
              )}

            <StyledCardWrapper
              $color={statusToBGColorMap['Claim Closed Disabled']}
              $border={statusToBorderColorMap['Claim Closed Disabled']}
            >
              <StyledContainer>
                <img src={statusInfoIcon['Claim Closed Disabled']} alt="" />
                <StyledStyledContainer>
                  <StyledHeadingIconWrapper $marginTop={'8px'}>
                    <Typography
                      variant="small"
                      weight="regular"
                      color="secondary"
                    >
                      Claim Closed
                    </Typography>
                  </StyledHeadingIconWrapper>
                </StyledStyledContainer>
              </StyledContainer>
            </StyledCardWrapper>
          </>
        )}
      </StyledCardContainer>
    </StyledTimelineWidget>
  );
};

export default ClaimStatusTimeline;
