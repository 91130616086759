import styled from 'styled-components';

export const StyledChipWrapper = styled.span`
  max-width: 100%;
  font-size: 0.8125rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  white-space: nowrap;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  outline: 0;
  text-decoration: none;
  padding: 0;
  vertical-align: middle;
  box-sizing: border-box;
  cursor: pointer;
`;

export const StyledChipLabel = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 12px;
  padding-right: 12px;
  white-space: nowrap;
`;
