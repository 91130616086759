import LoopEndpoints from './LoopEndpoints';
import { get, post } from './providers';

const EmployeeApi = {
  getPolicyPref: (userId, relationship) =>
    get(LoopEndpoints.fetchPolicyPreference(userId, relationship)),

  addEmpHrRequest: (data) => post(LoopEndpoints.savaEmpDetails(), data),

  addDepHrRequest: (data) => post(LoopEndpoints.savaDepDetails(), data),

  enrolUserToPolicy: (data) => post(LoopEndpoints.enrollPolicy(), data),

  delUserFromPolicy: (data) => post(LoopEndpoints.deletePolicy(), data),

  getEmpCount: (companyId) => get(LoopEndpoints.fetchEmpCount(companyId)),

  getEmpDetails: (userId) => get(LoopEndpoints.fetchUserById(userId)),

  getEmpPolicies: (userId) =>
    get(LoopEndpoints.fetchUserPoliciesByUserId(userId)),

  getEmpDependents: (employeeId) =>
    get(LoopEndpoints.fetchEmpDependents(employeeId)),

  getEmployeeDetails: (employeeId) =>
    get(LoopEndpoints.fetchEmpFamilyDetails(employeeId)),

  fetchECard: (userId, policyId) =>
    get(LoopEndpoints.fetchECardDetails(userId, policyId)),
};

export default EmployeeApi;
