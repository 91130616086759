import React from 'react';
import './Pagination.scss';

const SearchPartialPagination = ({
    onChangePage,
    pageNo,
    filteredDataLength,
    noUiFilter,
    pageSize: rowsPerPage,
    hasPrev,
    hasNext
}) => {
    const nextDisabled = !hasNext || (noUiFilter && filteredDataLength !== rowsPerPage);
    const previosDisabled = !hasPrev;
  
    return (
        <div className="pagination-wrapper align-self-end">
            {/* <div>page {pageNo+1}</div> */}
            <ul class="pagination">
            <li class="page-item">
                <button
                    className="page-link"
                    onClick={()=>onChangePage(pageNo - 1)}
                    disabled={previosDisabled}
                    aria-disabled={previosDisabled}
                    aria-label="previous page"
                >
                    Previous
                </button>
            </li>
            <li class="page-item">
                <button
                    className="page-link"
                    onClick={()=>onChangePage(pageNo + 1)}
                    disabled={nextDisabled}
                    aria-disabled={nextDisabled}
                    aria-label="next page"
                >
                    Next
                </button>
            </li>
            </ul>
        </div>
    );
};
export default SearchPartialPagination;
