import { IPolicyListItemType } from '../../../../../redux/slices/hrdRevampRedux/types';
import { ADD_READ_ME } from './constant';
import { IReadMePolicies } from './types';
import { getPolicyType } from './utils';

export const GeneralInstructionsHeader = [
  {},
  {},
  {
    v: 'General Instructions',
    t: 's',
    s: {
      font: {
        color: { rgb: 'ffffff' },
        bold: true,
      },
      fill: { fgColor: { rgb: '274e12' } },
      alignment: {
        vertical: 'center',
        horizontal: 'center',
        wrapText: true,
      },
    },
  },
  {
    v: '',
    t: 's',
    s: {
      fill: { fgColor: { rgb: '274e12' } },
      alignment: {
        vertical: 'center',
        horizontal: 'center',
        wrapText: true,
      },
    },
  },
  {
    v: '',
    t: 's',
    s: {
      fill: { fgColor: { rgb: '274e12' } },
      alignment: {
        vertical: 'center',
        horizontal: 'center',
        wrapText: true,
      },
    },
  },
];

export const GeneralInstructionsBody = [
  {},
  {},
  {
    v: ADD_READ_ME.INSTRUCTIONS,
    t: 's',
    s: {
      font: {
        color: { rgb: '000000' },
      },
      fill: { fgColor: { rgb: 'ffffff' } },
      alignment: {
        vertical: 'center',
        horizontal: 'left',
        wrapText: true,
      },
    },
  },
  {
    v: '',
    t: 's',
    s: {
      fill: { fgColor: { rgb: 'ffffff' } },
      alignment: {
        vertical: 'center',
        horizontal: 'center',
        wrapText: true,
      },
    },
  },
  {
    v: '',
    t: 's',
    s: {
      fill: { fgColor: { rgb: 'ffffff' } },
      alignment: {
        vertical: 'center',
        horizontal: 'center',
        wrapText: true,
      },
    },
  },
];

const schemaHeaders = Object.keys(ADD_READ_ME.DATA_SCHEMA[0]);
const DataSchemaKeyMappings = {
  name: 'Column Name',
  type: 'Type',
  allowed: 'Allowed Values',
  isMandatory: 'Is Mandatory?',
};
export const DataSchemaHeader = [
  {},
  ...schemaHeaders.map((v) => ({
    v: (DataSchemaKeyMappings as any)[v],
    t: 's',
    s: {
      font: {
        color: { rgb: 'ffffff' },
        bold: true,
      },
      fill: { fgColor: { rgb: '274e12' } },
      alignment: {
        vertical: 'center',
        horizontal: 'center',
        wrapText: true,
      },
    },
  })),
];
export const DataSchemaBody = ADD_READ_ME.DATA_SCHEMA.map((schma: any) => {
  const keys = Object.keys(schma);
  return [
    {},
    ...keys.map((k) => ({
      v: schma[k],
      t: 's',
      s: {
        font: {
          color: { rgb: '000000' },
        },
        fill: { fgColor: { rgb: 'ffffff' } },
        alignment: {
          vertical: 'center',
          horizontal: 'left',
          wrapText: true,
        },
        border: {
          top: { style: 'thin', color: { rgb: '000000' } },
          right: { style: 'thin', color: { rgb: '000000' } },
          left: { style: 'thin', color: { rgb: '000000' } },
          bottom: { style: 'thin', color: { rgb: '000000' } },
        },
      },
    })),
  ];
});
const policyHeader = [
  'Policy Type',
  'Insurer Name',
  'Policy Number',
  'Policy ID',
  'Policy End Date',
];

export const readmePolicyHeader = [
  {},
  ...policyHeader.map((v) => ({
    v: [v],
    t: 's',
    s: {
      font: {
        color: { rgb: 'ffffff' },
        bold: true,
      },
      fill: { fgColor: { rgb: '274e12' } },
      alignment: {
        vertical: 'center',
        horizontal: 'center',
        wrapText: true,
      },
    },
  })),
];

const PolicySchemaKeyMappings = {
  policyType: 'Policy Type',
  insurerName: 'Insurer Name',
  policyNumber: 'Policy Number',
  id: 'Policy ID',
  policyEndDate: 'Policy End Date',
};

export const createCell = (value: any) => ({
  v: value,
  t: 's',
  s: {
    font: { color: { rgb: '000000' } },
    fill: { fgColor: { rgb: 'ffffff' } },
    alignment: { vertical: 'center', horizontal: 'left', wrapText: true },
    border: {
      top: { style: 'thin', color: { rgb: '000000' } },
      right: { style: 'thin', color: { rgb: '000000' } },
      left: { style: 'thin', color: { rgb: '000000' } },
      bottom: { style: 'thin', color: { rgb: '000000' } },
    },
  },
});

export const createErrorCell = (value: any) => ({
  v: value,
  t: 's',
  s: {
    font: { color: { rgb: '000000', bold: true } },
    fill: { fgColor: { rgb: 'eba2a2' } },
    alignment: { vertical: 'center', horizontal: 'left', wrapText: true },
    border: {
      top: { style: 'thin', color: { rgb: '000000' } },
      right: { style: 'thin', color: { rgb: '000000' } },
      left: { style: 'thin', color: { rgb: '000000' } },
      bottom: { style: 'thin', color: { rgb: '000000' } },
    },
  },
});
export const createErrorDescriptionCell = (value: any) => ({
  v: value,
  t: 's',
  s: {
    font: { color: { rgb: 'FF0000', bold: true } },
    fill: { fgColor: { rgb: 'ffffff' } },
    alignment: { vertical: 'center', horizontal: 'left', wrapText: true },
    border: {
      top: { style: 'thin', color: { rgb: '000000' } },
      right: { style: 'thin', color: { rgb: '000000' } },
      left: { style: 'thin', color: { rgb: '000000' } },
      bottom: { style: 'thin', color: { rgb: '000000' } },
    },
  },
});

const mapPolicy = (originalPolicy: IPolicyListItemType): IReadMePolicies => {
  const mappedPolicy: IReadMePolicies = {} as IReadMePolicies; // Correct initialization
  const policyType = getPolicyType(originalPolicy);
  Object.keys(PolicySchemaKeyMappings).forEach((key) => {
    mappedPolicy[key as keyof IReadMePolicies] =
      originalPolicy[key as keyof IReadMePolicies];
  });
  mappedPolicy.policyType = policyType;
  return mappedPolicy;
};

export const createPoliciesBody = (policyData: IPolicyListItemType[]): any[] => {
  const mappedPolicies = policyData.map(mapPolicy);
  return mappedPolicies.map((policy: IReadMePolicies) => {
    const cells = Object.values(policy).map(createCell);
    return [{}, ...cells];
  });
};
