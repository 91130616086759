import {
  DateFormatUtils,
  LoopBadge,
  LoopButton,
  Shadow,
  Typography,
} from '@loophealth/loop-ui-web-library';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  ArrowRightEmeraldIcon,
  ArrowRightIcon,
  CdBalYellowIcon,
  DownloadIcon,
  PersonIcon,
  TableContentYellowIcon,
} from '../../../assets/images';
import WithThemeProvider from '../../../theme/WithThemeProvider';
import { formatCurrencyAmount } from '../../../utils/common/Utilities';
import Loader from '../../atoms/Spinner';
import useFetchCDAccounts from '../../pages/CDAccounts/hooks/useFetchCDAccounts';
import { isCDBalanceLow } from '../../pages/CDAccounts/utils';
import useFetchClaimList from '../../pages/ClaimsList/hooks/useFetchClaimList';
import {
  calculateInsuranceCounts,
  checkIfGPAorGTLPolicy,
  getCategorizedList,
  initialStats,
} from '../../pages/Employees/utils';
import { roasterExcelDownload } from '../Employees/ActiveRoasterTable/roasterToExcelService';
import usePolicyMembers from './hooks/usePolicyMembers';
import {
  StyledPolicyActiveRoster,
  StyledPolicyActiveRosterCDContainerRow,
  StyledPolicyActiveRosterContainer,
  StyledPolicyActiveRosterContainerColumn,
  StyledPolicyActiveRosterContainerRow,
  StyledPolicyActiveRosterContainerSeperator,
  StyledPolicyActiveRosterItem,
  StyledPolicyActiveRosterItemBody,
  StyledPolicyActiveRosterItemHeader,
  StyledPolicyActiveRosterLogo,
  StyledPolicyItemBodyDetails,
} from './styles';
import { IPolicyActiveRoster } from './types';
import {
  getCdAccountFromList,
  getClaimCountByStatusAndPolicyId,
} from './utils';
import { StyledFitContentWrapper } from '../EmpPolicies/styles';
import { useSelector } from 'react-redux';
import { selectSelectedCompany } from '../../../redux/slices/hrdRevampRedux';
import useSegment from '../../../utils/segment/hooks/useSegment';
import { extractPathName, trackClickEvent } from '../../../utils/segment/utils';

const PolicyActiveRoster: React.FC<IPolicyActiveRoster> = ({
  policyDetails,
}) => {
  const history = useHistory();
  const trackClick = useSegment('click');
  const location = useLocation();
  const pathName = extractPathName(location?.pathname);
  const selectedCompany = useSelector(selectSelectedCompany);
  const { policyMembers, isLoading: isEmpCountLoading } = usePolicyMembers(
    policyDetails.id,
  );

  const updatedStats =
    policyMembers && calculateInsuranceCounts(policyMembers, initialStats);
  const { claims: claimsList, isLoading: isClaimDataLoading } =
    useFetchClaimList();
  const isGPAorGTL = checkIfGPAorGTLPolicy(policyDetails.policyType);
  const claimsByType = isGPAorGTL
    ? {}
    : getClaimCountByStatusAndPolicyId(claimsList, policyDetails.policyNumber);

  const underQueryLength = claimsByType['Under Query']?.length || 0;
  const ongoingLength = claimsByType['Ongoing']?.length || 0;
  const settledLength = claimsByType['Settled']?.length || 0;
  const isClaimTrackVisible =
    (underQueryLength | ongoingLength | settledLength) !== 0;
  const { cdAccounts, isLoading: isCdAccountLoading } = useFetchCDAccounts();
  const cdAccount = getCdAccountFromList(cdAccounts, policyDetails.id);
  const isLowCdBalance = cdAccount && isCDBalanceLow(cdAccount);

  const onDownloadButtonClick = () => {
    policyMembers &&
      roasterExcelDownload(
        getCategorizedList(policyMembers),
        selectedCompany?.name,
      );
    trackClick(
      trackClickEvent(
        'DOWNLOAD_ACTIVE_ROASTER_CTA',
        location?.pathname,
        pathName,
      ),
    );
  };

  const onRosterDetailsButtonClick = () => {
    trackClick(
      trackClickEvent('VIEW_ACTIVE_ROASTER_CTA', location?.pathname, pathName),
    );
    history.push('/employees?policyNo=' + policyDetails.policyNumber);
  };

  const onCdDetailsButtonClick = () => {
    trackClick(
      trackClickEvent('VIEW_CD_BALANCE_CTA', location?.pathname, pathName),
    );
    history.push('/cd-accounts#' + cdAccount?.accountNumber);
  };

  const onTrackClaimsButtonClick = () => {
    trackClick(trackClickEvent('CLAIM_CTA', location?.pathname, pathName));
    history.push('/claims?policyNo=' + policyDetails.policyNumber);
  };

  return (
    <StyledPolicyActiveRoster>
      <StyledPolicyActiveRosterItem>
        <StyledPolicyActiveRosterItemHeader>
          <StyledPolicyActiveRosterLogo src={PersonIcon} />
          <Typography variant="medium" weight="medium">
            Active Roster
          </Typography>
        </StyledPolicyActiveRosterItemHeader>
        <Shadow variant="smooth">
          <WithThemeProvider>
            <StyledPolicyActiveRosterItemBody>
              {isEmpCountLoading ? (
                <Loader />
              ) : (
                <>
                  <StyledPolicyActiveRosterContainer>
                    {!isGPAorGTL && (
                      <>
                        <StyledPolicyActiveRosterContainerColumn>
                          <Typography variant="small" color="secondary">
                            Total Lives
                          </Typography>
                          <Typography variant="title2" weight="medium">
                            {updatedStats?.total_lives_all ?? 0}
                          </Typography>
                        </StyledPolicyActiveRosterContainerColumn>
                        <StyledPolicyActiveRosterContainerSeperator />
                      </>
                    )}
                    <StyledPolicyActiveRosterContainerColumn>
                      <Typography variant="small" color="secondary">
                        Total Employees
                      </Typography>
                      <Typography variant="title2" weight="medium">
                        {updatedStats?.employee ?? 0}
                      </Typography>
                    </StyledPolicyActiveRosterContainerColumn>
                  </StyledPolicyActiveRosterContainer>
                  <StyledPolicyActiveRosterContainerRow>
                    <LoopButton
                      onClick={onDownloadButtonClick}
                      variant="secondary"
                      textColor="emerald"
                      iconOrder="right"
                      iconSrc={DownloadIcon}
                      size="small"
                    >
                      Download
                    </LoopButton>
                    <LoopButton
                      onClick={onRosterDetailsButtonClick}
                      variant="outline"
                      iconOrder="right"
                      iconSrc={ArrowRightIcon}
                      size="small"
                    >
                      View Details
                    </LoopButton>
                  </StyledPolicyActiveRosterContainerRow>
                </>
              )}
            </StyledPolicyActiveRosterItemBody>
          </WithThemeProvider>
        </Shadow>
      </StyledPolicyActiveRosterItem>
      <StyledPolicyActiveRosterItem>
        <StyledPolicyActiveRosterItemHeader>
          <StyledPolicyActiveRosterLogo src={CdBalYellowIcon} />
          <Typography variant="medium" weight="medium">
            Cash Deposit (CD) Balance
          </Typography>
        </StyledPolicyActiveRosterItemHeader>
        <Shadow variant="smooth">
          <WithThemeProvider>
            <StyledPolicyActiveRosterItemBody>
              {isCdAccountLoading ? (
                <Loader />
              ) : (
                <>
                  <StyledPolicyItemBodyDetails>
                    <StyledPolicyActiveRosterCDContainerRow>
                      <StyledPolicyActiveRosterContainerColumn>
                        <Typography variant="small" color="secondary">
                          Available Balance
                        </Typography>
                        <Typography
                          variant="title2"
                          weight="medium"
                          color={isLowCdBalance ? 'planRed' : 'primary'}
                        >
                          {formatCurrencyAmount(cdAccount?.balance)}
                        </Typography>
                      </StyledPolicyActiveRosterContainerColumn>
                      {(!cdAccount || isLowCdBalance) && (
                        <LoopBadge
                          variant="overflow"
                          badgeType="error"
                          text={cdAccount ? 'Low' : 'Missing'}
                        />
                      )}
                    </StyledPolicyActiveRosterCDContainerRow>
                    <Typography
                      variant="extraSmall"
                      weight="regular"
                      color="secondary"
                    >
                      {`Last updated: ${
                        cdAccount?.updatedAt
                          ? new DateFormatUtils().formatDate(
                              cdAccount.updatedAt,
                            )
                          : '--'
                      }`}
                    </Typography>
                  </StyledPolicyItemBodyDetails>

                  {cdAccount && (
                    <StyledPolicyActiveRosterContainerRow>
                      <StyledFitContentWrapper>
                        <LoopButton
                          onClick={onCdDetailsButtonClick}
                          variant="secondary"
                          textColor="emerald"
                          iconOrder="right"
                          iconSrc={ArrowRightEmeraldIcon}
                          size="small"
                        >
                          View Details
                        </LoopButton>
                      </StyledFitContentWrapper>
                    </StyledPolicyActiveRosterContainerRow>
                  )}
                </>
              )}
            </StyledPolicyActiveRosterItemBody>
          </WithThemeProvider>
        </Shadow>
      </StyledPolicyActiveRosterItem>
      {!isGPAorGTL && (
        <StyledPolicyActiveRosterItem>
          <StyledPolicyActiveRosterItemHeader>
            <StyledPolicyActiveRosterLogo src={TableContentYellowIcon} />

            <Typography variant="medium" weight="medium">
              Claims
            </Typography>
          </StyledPolicyActiveRosterItemHeader>
          <Shadow variant="smooth">
            <WithThemeProvider>
              <StyledPolicyActiveRosterItemBody>
                {isClaimDataLoading ? (
                  <Loader />
                ) : (
                  <>
                    <StyledPolicyItemBodyDetails>
                      <StyledPolicyActiveRosterContainer>
                        <StyledPolicyActiveRosterContainerColumn>
                          <Typography variant="small" color="secondary">
                            Settled
                          </Typography>
                          <Typography variant="title2" weight="medium">
                            {settledLength}
                          </Typography>
                        </StyledPolicyActiveRosterContainerColumn>
                        <StyledPolicyActiveRosterContainerSeperator />
                        <StyledPolicyActiveRosterContainerColumn>
                          <Typography variant="small" color="secondary">
                            Ongoing
                          </Typography>
                          <Typography variant="title2" weight="medium">
                            {ongoingLength}
                          </Typography>
                        </StyledPolicyActiveRosterContainerColumn>
                        <StyledPolicyActiveRosterContainerSeperator />
                        <StyledPolicyActiveRosterContainerColumn>
                          <Typography variant="small" color="secondary">
                            Under Query
                          </Typography>
                          <Typography
                            variant="title2"
                            weight="medium"
                            color={underQueryLength > 0 ? 'planRed' : 'primary'}
                          >
                            {underQueryLength}
                          </Typography>
                        </StyledPolicyActiveRosterContainerColumn>
                      </StyledPolicyActiveRosterContainer>
                      <Typography
                        variant="extraSmall"
                        weight="regular"
                        color="secondary"
                      >
                        Updated real time
                      </Typography>
                    </StyledPolicyItemBodyDetails>
                    {isClaimTrackVisible && (
                      <StyledPolicyActiveRosterContainerRow>
                        <StyledFitContentWrapper>
                          <LoopButton
                            onClick={onTrackClaimsButtonClick}
                            variant="secondary"
                            textColor="emerald"
                            iconOrder="right"
                            iconSrc={ArrowRightEmeraldIcon}
                            size="small"
                          >
                            Track Claims
                          </LoopButton>
                        </StyledFitContentWrapper>
                      </StyledPolicyActiveRosterContainerRow>
                    )}
                  </>
                )}
              </StyledPolicyActiveRosterItemBody>
            </WithThemeProvider>
          </Shadow>
        </StyledPolicyActiveRosterItem>
      )}
    </StyledPolicyActiveRoster>
  );
};

export default PolicyActiveRoster;
