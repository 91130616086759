import { combineReducers } from 'redux';
import addEmpFormSlice from './addEmpFormSlice';
import companySlice from './companySlice';
import hrRequestSlice from './hrRequestSlice';
import policyListSlice from './PolicyListSlice';
import usersSlice from './usersSlice';
import employeesSlice from './employeesSlice';
import changeRequestSlice from './changeRequestSlice';
import hrdRevampReduxSlice from './hrdRevampRedux';

const rootReducer = combineReducers({
  companyProfile: companySlice,
  users: usersSlice,
  addEmpForm: addEmpFormSlice,
  hrRequest: hrRequestSlice,
  policyList: policyListSlice,
  employees: employeesSlice,
  changeRequest: changeRequestSlice,
  hrdRevamp: hrdRevampReduxSlice,
});

export default rootReducer;
