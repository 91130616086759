import { ClaimStatus, IClaimDetailsUI } from './types';
import {
  capitalizeFirstLetterOfEachWord,
  formatCurrencyAmount,
  textPlaceholder,
} from '../../../utils/common/Utilities';
import { DateFormatUtils } from '@loophealth/loop-ui-web-library';
import { StatusHistoryItem } from '../../containers/ClaimsStatusTimeline/types';

export const prepareClaimsDetails = (
  data: Record<string, unknown>,
): IClaimDetailsUI => {
  return {
    claimId: data.tpaClaimId as string,
    claimStatus: data?.claimStatus as ClaimStatus,
    patientDetails: {
      'Patient Name': capitalizeFirstLetterOfEachWord(data.name),
      'Patient DOB': data.patientDob
        ? new DateFormatUtils().formatDateString(data.patientDob as string)
        : '--',
      'Patient Gender': capitalizeFirstLetterOfEachWord(data.gender),
      'Patient Relation': capitalizeFirstLetterOfEachWord(data.relation),
      'Patient Age': data.age as string,
    },
    employeeDetails: {
      'Employee Name': capitalizeFirstLetterOfEachWord(data.employeeName),
      'Employee ID': capitalizeFirstLetterOfEachWord(
        data.employeeCode as string,
      ),
      'Employee DOJ': data.employeeDoj
        ? new DateFormatUtils().formatDateString(data.employeeDoj as string)
        : '--',
    },
    claimAmountDetails: {
      'Claim Type': capitalizeFirstLetterOfEachWord(data.claimType),
      'Claimed Amount ': formatCurrencyAmount(data.claimAmount),
      'Patient Sum Insured': formatCurrencyAmount(data.sumInsured),
    },
    paymentDetails: {
      'Paid Amount': data.disbursedAmount
        ? formatCurrencyAmount(data.disbursedAmount)
        : '--',
      'Deduction Amount': formatCurrencyAmount(data.deductionAmount),
    },
    intimationDetails: {
      'Claim ID': data.tpaClaimId as string,
      UHID: data.uhid as string,
      'Intimation Date': new DateFormatUtils().formatDateString(
        data.claimReceivedAt as string,
      ),
    },
    intimationDocsDetails: {
      'Policy No.': data.policyNo as string,
    },
    admissionDetails: {
      'Admission Date': new DateFormatUtils().formatDateString(
        data.admittedOn as string,
      ),
      'Date Discharged': new DateFormatUtils().formatDateString(
        data.dischargedOn as string,
      ),
      'Hospital Name': capitalizeFirstLetterOfEachWord(data.hospitalName),
      'Hospital City': capitalizeFirstLetterOfEachWord(data.hospitalCity),
    },
    admissionCodeDetails: {
      'Primary Diagnostic/Disease': capitalizeFirstLetterOfEachWord(
        data.disease,
      ),
    },
    policyDetails: {
      'Policy Type': textPlaceholder(data.insuranceType),
      'Policy No.': data.policyNo as string,
      'Policy Start Date': new DateFormatUtils().formatDateString(
        data.policyStartDate as string,
      ),
    },
    tpaDetails: {
      'Insurer Name': capitalizeFirstLetterOfEachWord(data.insuranceName),
      'TPA Name': capitalizeFirstLetterOfEachWord(data.tpaName),
    },
    statusHistory: data.statusHistory as StatusHistoryItem[] | [],
  };
};

export const hasOnlyOneStatusHistory = (
  statusHistory: StatusHistoryItem[],
): boolean => {
  return statusHistory.length == 1;
};
