import React, { useState } from 'react';
import './Dropdown.scss';

export const Dropdown = ({
  controlId,
  labelName,
  type,
  placeholder,
  value,
  onChange,
  options,
  feedbackType = 'invalid',
  feedbackText,
  dispalyKey = 'title',
  disabled = false,
  fieldKey,
  invalidText,
  isInvalid,
}) => {
  const [selectedRelation, setSelectedRelation] = useState('');
  const handleOnchange = (e) => {
    setSelectedRelation(e.target.value);
    onChange(e);
  };
  return (
    <div className="col-md-4">
      <label className="input-label-name">
        {labelName} <span>*</span>
      </label>
      <select
        className={`form-control ${isInvalid ? 'border-danger' : ''}`}
        type={type}
        placeholder="Select"
        value={selectedRelation || 'Select'}
        onChange={handleOnchange}
        disabled={disabled}
        as="select"
        required
      >
        <option value="">Select</option>
        {options.map((item) => (
          <option key={item.id} value={item[dispalyKey]}>
            {item[dispalyKey]}
          </option>
        ))}
      </select>
      {isInvalid && (
        <small className="error-message text-danger form-text">
          {invalidText}
        </small>
      )}
    </div>
  );
};
