import LoopEndpoints from './LoopEndpoints';
import { get } from './providers';

const PolicyApi = {
  getPolicyDetail: (policyId) => get(LoopEndpoints.fetchPolicyPlan(policyId)),

  getPolicies: (companyId) => get(LoopEndpoints.fetchPolicies(companyId)),

  getPolicyMembers: (policyId, companyId) =>
    get(LoopEndpoints.fetchCompanyEmployeesByPolicy(companyId, policyId)),

  downloadPolicyDocument: async (policyId, fileIdentifier) =>
    await get(LoopEndpoints.downloadPolicyDocument(policyId, fileIdentifier)),
};

export default PolicyApi;
