/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useFormInput from '../../../../utils/hooks/useFormInput';
// import DataTable from "react-data-table-component";
import DataTable from '../../../atoms/Datatables';
import './Employees.scss';
import { toast } from 'react-toastify';
import Navbar from '../../../atoms/Navbar';
import { useDispatch, useSelector } from 'react-redux';
import { selectHrUser } from '../../../../redux/slices/usersSlice';
import { AddModal } from '../../../containers/AlertModal/addModal';
import {
  clearHrReqState,
  deleteUserDetailsSelector,
} from '../../../../redux/slices/hrRequestSlice';
import { deleteUserDetails } from '../../../../redux/slices/hrRequestSlice/thunks';
import { fetchCompanyById } from '../../../../redux/slices/companySlice/thunks';
import { selectCompanyData } from '../../../../redux/slices/companySlice';
import {
  // fetchEmpCount,
  fetchEmployeeDataList,
} from '../../../../redux/slices/employeesSlice/thunks';
import {
  // selectEmpCount,
  selectEmployeeListData,
} from '../../../../redux/slices/employeesSlice';
import { AppConstants } from '../../../../utils/common/AppConstants';
// import { setEditForm } from '../../../redux/slices/addEmpFormSlice';
import { setLoopIdToken } from '../../../../utils/common/CustomFirebase';
//Note: Will uncomment segment action when we'll add events trigger
// import useSegment from '../../../utils/hooks/useSegment';
// import { SEGMENT_ACTIONS } from '../../../utils/common/SegmentActionConstants';

import ServerSidePagination from '../../../atoms/Pagination/ServerSidePagination';
import SearchPartialPagination from '../../../atoms/Pagination/SearchPartialPagination';
import Spinner from '../../../atoms/Spinner/Spinner';
import useSegment from '../../../../utils/hooks/useSegment';
import { getTrackClickObj } from '../../../../utils/common/Utilities';
import { getDataTableColumns } from '../../../../utils/common/columns';
import { transformEmployeeListData } from '../../../../utils/common/employee';
import { selectSelectedCompany } from '../../../../redux/slices/hrdRevampRedux';

export const Employees = () => {
  const [showDeleteBackdrop, setShowDeleteBackdrop] = useState(false);
  // const [addEmployee, setAddEmployee] = useState(false);
  const [requestBody, setRequestBody] = useState({});
  const searchByName = useFormInput('');

  const [activeAdd, setActiveAdd] = useState(2);
  const [currentPage, setCurrentPage] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const hrData = useSelector(selectHrUser);
  const selectedCompany = useSelector(selectSelectedCompany);
  const companyId = selectedCompany?.id;
  const userId = hrData?.data?.userId;
  const employeeListData = useSelector(selectEmployeeListData);
  const { status, error, data, metaData } = employeeListData ?? {};
  const {
    pageNo = 0,
    hasPrev = false,
    hasNext = false,
    nextPageId,
    prevPageId,
  } = metaData ?? {};
  const deletedUserData = useSelector(deleteUserDetailsSelector);
  const companyDetails = useSelector(selectCompanyData);
  const { dependentCount = 0, selfCount = 0 } = companyDetails?.data || {};
  const companyCountstatus = companyDetails?.status;
  const firstName = searchByName.value !== '' ? searchByName.value : undefined;
  const trackClick = useSegment('click');
  const location = useLocation();
  const callTrackClick = (action, record) =>
    trackClick(
      getTrackClickObj(action, location?.pathname, 'HRD EmployeePage Events', {
        record,
      }),
    );

  useEffect(() => {
    if (userId) {
      setLoopIdToken(userId);
    }
    if (companyCountstatus !== 'succeeded' && companyId) {
      dispatch(fetchCompanyById(companyId));
    }
  }, [userId, companyId]);

  useEffect(() => {
    if (companyId) {
      dispatch(
        fetchEmployeeDataList({
          companyId,
          pageSize: 10,
          pageNo: 0,
          firstName,
        }),
      );
    }
  }, [companyId]);

  useEffect(() => {
    if (companyId) {
      const firstName =
        searchByName.debounceValue !== ''
          ? searchByName.debounceValue
          : undefined;
      if (firstName) {
        callTrackClick('EMPLOYEE_TABLE_SEARCHBAR');
      }
      dispatch(
        fetchEmployeeDataList({
          companyId,
          pageSize: 10,
          pageNo: 0,
          firstName,
        }),
      );
    }
  }, [searchByName.debounceValue]);

  useEffect(() => {
    if (deletedUserData?.status === 'succeeded') {
      setShowDeleteBackdrop(false);
      toast.success('Employee Deleted Successful', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      dispatch(clearHrReqState({ data: AppConstants.DELETE }));
      dispatch(fetchCompanyById(companyId));
      dispatch(
        fetchEmployeeDataList({
          companyId,
          pageSize: 10,
          pageNo: 0,
          firstName,
        }),
      );
    } else if (deletedUserData?.status === 'failed') {
      setShowDeleteBackdrop(false);
      toast.error(
        `${deletedUserData?.error ? ` ${deletedUserData.error}` : ''}`,
        {
          position: toast.POSITION.BOTTOM_CENTER,
        },
      );
      dispatch(clearHrReqState({ data: AppConstants.DELETE }));
      dispatch(
        fetchEmployeeDataList({
          companyId,
          pageSize: 10,
          pageNo: 0,
          firstName,
        }),
      );
    }
  }, [
    companyId,
    deletedUserData.error,
    deletedUserData?.status,
    dispatch,
    firstName,
  ]);

  useEffect(() => {
    if (status === 'failed') {
      toast.error(`Employee list api failed${error ? ` ${error}` : ''}`, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
  }, [status, error]);

  const handleDeleteUser = (leavingDate, type) => {
    if (type === 'employee' && deletedUserData?.status !== 'loading') {
      const lDate = new Date(leavingDate);
      requestBody.hrId = hrData?.data?.userId;
      requestBody.employer = selectedCompany?.id;
      requestBody.leavingDate = lDate.getTime() / 1000;
      requestBody.relationship = 'self';
      dispatch(deleteUserDetails(requestBody));
    }
  };

  const handleDeleteClick = (record) => {
    const request = {};
    request.userId = record?.userId;
    if (request.userId) {
      callTrackClick('EMPLOYEE_TABLE_DELETE_EMPLOYEE', record);
      setRequestBody(request);
      setShowDeleteBackdrop(true);
    }
  };

  const handleEditClick = (record) => {
    const userID = record?.userId;
    if (userID) {
      callTrackClick('EMPLOYEE_TABLE_CLICKED_USER_ROW', record);
      history.push('/view-employee', { userID });
    }
  };

  const final_empData = useMemo(() => {
    let result = transformEmployeeListData(data ?? []);
    return result;
  }, [data]);

  const handlePageChange = (page) => {
    const isNext = currentPage < page;
    if (isNext) {
      callTrackClick('EMPLOYEE_TABLE_NEXT');
    } else {
      callTrackClick('EMPLOYEE_TABLE_PREVIOUS');
    }
    dispatch(
      fetchEmployeeDataList({
        companyId,
        pageSize: 10,
        pageNo: pageNo ?? 0,
        firstName,
        ...(isNext ? { nextPageId } : { prevPageId }),
      }),
    );
    setCurrentPage(page);
  };
  const addSingleEmployee = () => {
    callTrackClick('EMPLOYEE_ADD_SINGLE_EMP_BUTTON');
    history.push('/select-policies');
  };

  return (
    <div className="hr-dashboard">
      <div className="">
        {/* {status === 'loading' && <Backdrop />} */}
        <div className="row">
          <div className="col-md-7 px-0">
            <h4 className="heading-one">Employees</h4>
            {companyCountstatus === 'succeeded' ? (
              <div className="employees_stats">
                <label>
                  Total Employees: <span>{selfCount}</span>
                  <span className="info_dot_count" />
                  Total Dependents: <span>{dependentCount}</span>
                </label>
              </div>
            ) : null}
          </div>
          <div className="col-md-5  employee_btn">
            <button
              className="add_emp_single margin-top-5px"
              onClick={addSingleEmployee}
            >
              Add an Employee
            </button>
          </div>
        </div>

        <div className="row">
          <div className="dataTable_inner dataTable_controls  mt-3 ">
            {status === 'succeeded' &&
            final_empData.length < 1 &&
            searchByName.value.length < 1 ? (
              <div className="empty_emp_table search_and_no_records">
                <div className="empty_emp_table_heading">
                  <label>
                    Start adding employees and enrolling them to policies now!
                  </label>
                  <span>All enrolled employees will appear here. </span>
                </div>
                <div className="empty_emp_table_body">
                  <ul>
                    <li
                      className={activeAdd === 2 ? 'active' : ''}
                      onClick={() => setActiveAdd(2)}
                    >
                      <img
                        className="cardImage"
                        src="/assets/images/single_Add.svg"
                        alt=""
                      />
                      <h1 className="cardText">Add Single Employee</h1>
                      <p className="cardDescription">
                        Add a single employee along with dependents to their
                        policies
                      </p>
                    </li>
                  </ul>
                  {activeAdd === 1 ? (
                    <button>Bulk Add Employee</button>
                  ) : (
                    <button onClick={addSingleEmployee}>Add an Employee</button>
                  )}
                </div>
                <SearchPartialPagination
                  rowsPerPage={10}
                  onChangePage={handlePageChange}
                  pageNo={currentPage}
                  hasPrev={hasPrev}
                  hasNext={hasNext}
                  filteredDataLength={final_empData.length}
                  noUiFilter={!searchByName.value}
                />
              </div>
            ) : (
              <DataTable
                keyField={'id'}
                // title="Insured Employees"
                columns={getDataTableColumns(
                  handleEditClick,
                  handleDeleteClick,
                )}
                data={final_empData}
                subHeader={true}
                subHeaderComponent={
                  <div className="main_searchbar">
                    <div className="form-group has-search">
                      <span className="fa fa-search form-control-feedback" />
                      <input
                        type="text"
                        placeholder="Search by First Name"
                        name="fname"
                        {...searchByName.htmlAttributes}
                        className="form-control search_input"
                      />
                    </div>
                  </div>
                }
                persistTableHead
                progressPending={status === 'loading'}
                progressComponent={
                  <div className="w-100 p-3 text-center spinner-bg-grey">
                    <Spinner />
                  </div>
                }
                highlightOnHover
                pointerOnHover
                onRowClicked={(row) => handleEditClick(row)}
                paginationServer
                pagination
                paginationResetDefaultPage={0}
                paginationComponent={ServerSidePagination}
                paginationComponentOptions={{
                  filteredDataLength: final_empData.length,
                  noUiFilter: !searchByName.value,
                  pageNo: currentPage,
                  hasPrev,
                  hasNext,
                  onChangePage: handlePageChange,
                }}
                onChangePage={handlePageChange}
                noDataComponent={
                  searchByName.value !== '' ? (
                    <div className="search_and_no_records">
                      <div className="dt_no_records">
                        <label>
                          Oops! Couldn't find any search results for “
                          {searchByName.value}”
                        </label>
                        <span>Please enter a valid Employee name</span>
                      </div>
                      <SearchPartialPagination
                        rowsPerPage={10}
                        onChangePage={handlePageChange}
                        pageNo={currentPage}
                        hasPrev={hasPrev}
                        hasNext={hasNext}
                        filteredDataLength={final_empData.length}
                        noUiFilter={!searchByName.value}
                      />
                    </div>
                  ) : null
                }
              />
            )}
          </div>
        </div>
      </div>

      {/* {addEmployee && (
          <AddModal
            title={`Add Employee`}
            subTitle={`Choose how you would like to add an employee`}
            showCards={true}
            showModal={addEmployee}
            setShowModal={setAddEmployee}
            onPressButton1={addModalButtonOne}
            onPressButton2={() => alert('bulk Upload')}
          />
        )} */}

      {showDeleteBackdrop && (
        <AddModal
          showCards={false}
          showEmpDel={true}
          showModal={showDeleteBackdrop}
          setShowModal={setShowDeleteBackdrop}
          onPressButton1={handleDeleteUser}
          onPressButton2={() => setShowDeleteBackdrop(false)}
          buttonText2={'Cancel'}
          loadingStatus={deletedUserData?.status}
        />
      )}
    </div>
  );
};
