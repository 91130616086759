import { DateFormatUtils } from '@loophealth/loop-ui-web-library';
import { IBulkDeleteData } from '../types';
import { IBulkDeleteExcelData } from './types';
import { read, utils } from 'xlsx';
import { Dispatch, SetStateAction } from 'react';

export const formatExcelDatesForBulkDelete = (
  excelMemberData: IBulkDeleteExcelData,
): IBulkDeleteData => {
  const dateFormatUtils = new DateFormatUtils();
  return {
    employeeId: excelMemberData['Employee ID'],
    name: excelMemberData['Member Name'],
    dateOfLeaving: excelMemberData['Date of Leaving (DD/MMM/YYYY)']
      ? dateFormatUtils.excelToBackendDateFormat(
          excelMemberData['Date of Leaving (DD/MMM/YYYY)'],
        )
      : '',
    reason: excelMemberData['Reason of Leaving'],
  };
};

export const processFileUpload = async (
  files: Record<string, unknown>[],
  setSheetData: Dispatch<SetStateAction<IBulkDeleteData[]>>,
  setSheetSelected: Dispatch<SetStateAction<Record<string, unknown>[]>>,
  toast: any,
): Promise<void> => {
  if (!files.length) {
    setSheetSelected([]);
    setSheetData([]);
    return;
  }
  const file = files[0];
  const reader = new FileReader();

  reader.onload = (e) => {
    const data = e.target?.result;
    const workbook = read(data, { type: 'binary' });
    const sheetNames = workbook.SheetNames;
    const indexOfDelete = sheetNames.indexOf('Delete from All Policies');
    if (indexOfDelete) {
      const jsonData = utils.sheet_to_json(
        workbook.Sheets[sheetNames[indexOfDelete]],
        {
          range: 1,
          raw: false,
        },
      );
      const filteredRecords = jsonData
        .filter(
          (record: any) =>
            record['Employee ID'] && record['Employee ID'].trim() !== '',
        )
        .map((data) =>
          formatExcelDatesForBulkDelete(data as IBulkDeleteExcelData),
        );
      if (filteredRecords.length)
        setSheetData(filteredRecords);
      else {
        setSheetSelected([]);
        toast.error('Empty File');
      }
    }
    else {
      setSheetSelected([]);
      toast.error('Invalid Format');
    }
  };
  reader.readAsBinaryString(file as unknown as Blob);
  setSheetSelected(files);
};
