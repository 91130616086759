/*
Note: If you change any constants variable of UPDATE_DEPENDENT, ADD_DEPENDENT And DELETE_DEPENDENT then you also need to 
update the CHECK_DEPENDENT becuase in ViewEmployeeData.js file,
 we have added a check to find the dependent string which check wheter the operation is for dependent.
*/

export const AppConstants = {
  EMAIL_FOR_SIGNIN: 'emailForSignIn',
  FIR_DOC_ID: 'firDocId',
  UPDATE: 'update',
  ADD: 'add',
  DELETE: 'delete',
  UPDATE_DEPENDENT: 'update_dependent',
  ADD_DEPENDENT: 'add_dependent',
  DELETE_DEPENDENT: 'delete_dependent',
  MEMBERSHIP: 'membership',
  PENDING: 'pending',
  OPEN: 'open',
  ACTIVE: 'active',
  CHECK_DEPENDENT: 'dependent',
  EMP_HR_REQUEST_DATA: 'EMP_HR_REQUEST_DATA',
  EMPLOYEE: 'EMPLOYEE',
  DEPENDENT: 'DEPENDENT',
  EDIT: 'EDIT',
  ADD_MODE: 'ADD',
  POLICY_FETCH: 'policy_fetch',
  ACTIVE_STATUS: 'ENROLLED',
  ENROLL: 'enroll',
  DEL_POLICY: 'DEL_POLICY',
  CLEAR_EMPLOYEE_LIST: 'CLEAR_EMPLOYEE_LIST',
  USER_DETAILS: 'USER_DETAILS',
  USER_DEPENDENT_DETAILS: "USER_DEPENDENT_DETAILS"
};

const CHANGE_REQUESTS_UI_OPERATION_TYPE_MAP = {
  CREATE: 'ADD',
  READ: 'EDIT',
  UPDATE: 'EDIT',
  DELETE: 'DELETE',
  BULK_CREATE: 'ADD',
  BULK_UPDATE: 'EDIT'
};
export const convertUiOperationType = (op) => CHANGE_REQUESTS_UI_OPERATION_TYPE_MAP[op];
