import { Typography, LoopBadge } from '@loophealth/loop-ui-web-library';
import { StyledClaimStatusWidget } from './styles';
import { ClaimStatusProps } from './types';
import { statusBadgeType } from '../../ClaimsStatusTimeline/legacy/constant';

const ClaimStatus: React.FC<ClaimStatusProps> = ({ status }) => {
  return (
    <StyledClaimStatusWidget>
      <Typography variant="title2" weight="bold">
        Claim Status
      </Typography>
      <LoopBadge
        badgeType={statusBadgeType[status] || 'success'}
        variant="overflow"
        text={status}
      />
    </StyledClaimStatusWidget>
  );
};

export default ClaimStatus;
