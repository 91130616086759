export const DropdownData = {
  relationShip: [
    {
      id: 1,
      name: 'Spouse',
    },
    {
      id: 2,
      name: 'Child',
    },
    {
      id: 3,
      name: 'Parent',
    },
    {
      id: 4,
      name: 'Parent-in-law',
    },
  ],
  genderData: [
    {
      id: 0,
      name: 'Male',
    },
    {
      id: 1,
      name: 'Female',
    },
    // {
    //   id: 2,
    //   name: "Other",
    // },
  ],
};
