import React from 'react';
import {
  StyledCardBody,
  StyledCardHeader,
  StyledCardInfoContainer,
  StyledCardInfoWrapper,
  StyledIcon,
  StyledOverviewCard,
} from './styles';
import { Typography } from '@loophealth/loop-ui-web-library';
import { ContentIcon, EditIcon, UploadIcon } from '../../../assets/images';

const UploadInfoCard = () => {
  return (
    <StyledOverviewCard>
      <StyledCardHeader>
        <Typography variant="small" weight="bold" color="secondary">
          HOW TO UPLOAD
        </Typography>
      </StyledCardHeader>
      <StyledCardBody>
        <StyledCardInfoWrapper>
          <StyledIcon src={ContentIcon} />
          <StyledCardInfoContainer>
            <Typography variant="small" weight="semiBold">
              Download Template
            </Typography>
            <Typography variant="small" weight="regular" color="secondary">
              Not sure where to begin? Download our template.
            </Typography>
          </StyledCardInfoContainer>
        </StyledCardInfoWrapper>
        <StyledCardInfoWrapper>
          <StyledIcon src={EditIcon} />
          <StyledCardInfoContainer>
            <Typography variant="small" weight="semiBold">
              Fill the Sheet
            </Typography>
            <Typography variant="small" weight="regular" color="secondary">
              Stuck? Watch our step-by-step video tutorial.
            </Typography>
          </StyledCardInfoContainer>
        </StyledCardInfoWrapper>
        <StyledCardInfoWrapper>
          <StyledIcon src={UploadIcon} />
          <StyledCardInfoContainer>
            <Typography variant="small" weight="semiBold">
              Upload and Relax
            </Typography>
            <Typography variant="small" weight="regular" color="secondary">
              Done? Great! Upload your sheet and you’re done.
            </Typography>
          </StyledCardInfoContainer>
        </StyledCardInfoWrapper>
      </StyledCardBody>
    </StyledOverviewCard>
  );
};

export default UploadInfoCard;
