import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useAuth } from '../../contexts/AuthContext';

import firebase, { auth } from '../../firebase';

import fb from 'firebase';

import './Login.scss';
import {
  setLoopIdToken,
  verifyEmailLink,
} from '../../utils/common/CustomFirebase';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import LoopApiServiceContext from '../../contexts/LoopApiServiceContext';
import useSegment from '../../utils/hooks/useSegment';
import {
  getTrackClickObj,
  getTrackTaskObj,
  getTaskByLoginMethod,
} from '../../utils/common/Utilities';
import {
  fetchHrUser,
  fetchHrUserVerify,
} from '../../redux/slices/usersSlice/thunks';
import {
  selectConfirmationResult,
  selectHrUser,
  setClearHrData,
  setConfirmationResult,
} from '../../redux/slices/usersSlice';
import Backdrop from '../atoms/Backdrop/Backdrop';
import { InputText } from '../atoms/UI/InputText';
import { SideLayout } from '../atoms/UI/SideLayout';
import { validateMobile } from '../../utils/common/Utilities';
import { isDbCallsDeprecated } from '../../utils/featureFlag';

const Login = () => {
  const { setLoggedInUser } = useAuth();
  const location = useLocation();
  const mobileNumberError = location.state?.errorMessage;
  const [error, setError] = useState(mobileNumberError || '');
  const [mobile, setMobile] = useState('');
  const [mobileVerified, setMobileVerified] = useState(false);
  const history = useHistory();
  const firebaseContext = useContext(LoopApiServiceContext);
  const dispatch = useDispatch();
  const confirmationResult = useSelector(selectConfirmationResult);
  const microsoftProviderRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const hrData = useSelector(selectHrUser);
  const trackClick = useSegment('click');
  const trackTask = useSegment('track');

  const callTrackClick = (action) =>
    trackClick(
      getTrackClickObj(action, location?.pathname, 'HRD Login&Logout Events'),
    );
  const callTrackTask = (action, category) =>
    trackTask(getTrackTaskObj(action, location?.pathname, category));

  useEffect(() => {
    // clearing mobile number error state for page refresh
    if (mobileNumberError) history.replace();
  }, []);

  useEffect(() => {
    microsoftProviderRef.current = new firebaseContext.captcha.OAuthProvider(
      'microsoft.com',
    );
    verifyEmailLink(setLoading, dispatch);
  }, [dispatch, firebaseContext.captcha.OAuthProvider]);

  useEffect(() => {
    if (
      confirmationResult &&
      mobile &&
      mobile.length === 10 &&
      mobileVerified
    ) {
      history.push('/enter-otp', {
        mobile: mobile,
        verifyEmail: true,
      });
      setMobileVerified(false);
    }
  }, [confirmationResult, history, mobile, mobileVerified]);

  const signInWithMobileOtp = async () => {
    callTrackClick('MOBILE_LOGIN');
    setError('');

    if (isDbCallsDeprecated) {
      triggerOtp(); // todo: handle blocking function error of unregistered error
    } else {
      dispatch(
        fetchHrUserVerify({
          methodType: 'mobile',
          userId: mobile,
          loginMethod: 'mobile',
        }),
      );
    }
  };

  const triggerOtp = async () => {
    setLoading(true);
    setError('');
    try {
      const confirmationData = await auth.signInWithPhoneNumber(
        '+91' + mobile,
        window.recaptchaVerifier,
      );
      callTrackTask('MOBILE_VERIFIED_SUCCESS', 'HRD Login&Logout Events');
      dispatch(setConfirmationResult({ data: confirmationData }));
      setMobileVerified(true);
      setLoading(false);
    } catch (error) {
      callTrackTask('MOBILE_VERIFIED_FAIL', 'HRD Login&Logout Events');
      setLoading(false);
      if (error.code === 'auth/too-many-requests') {
        setError('Too many attempts, try again later');
      } else {
        setError(error.message);
      }
      // throw error;
    }
  };

  useEffect(() => {
    if (
      hrData &&
      hrData?.error === '' &&
      hrData?.data &&
      hrData?.state === 'succeeded'
    ) {
      let data = hrData?.data;
      const loginMethod = hrData?.loginMethod;
      if (loginMethod) {
        if (data?.email && data?.mobile && loginMethod !== 'mobile') {
          setLoopIdToken(hrData?.data?.userId);
          setLoggedInUser(data);
          history.push('/');
        } else if (data?.email && loginMethod !== 'mobile') {
          history.push('/enter-id', { showEmail: false });
        }
      }
    } else if (hrData.state === 'failed') {
      setError("You don't have permission to access this app");
    }

    if (hrData?.state !== 'loading' && hrData?.error !== '') {
      const loginMethod = hrData?.loginMethod;
      if (loginMethod) {
        callTrackTask(
          getTaskByLoginMethod(loginMethod, false),
          'HRD Login&Logout Events',
        );
      }
    }

    // remove this on isDbCallsDeprecated feature flag is removed
    if (!isDbCallsDeprecated) {
      if (hrData?.loginMethod === 'mobile') {
        if (hrData?.error !== 'MOBILE_NOT_FOUND') {
          triggerOtp();
        } else {
          // Mobile is not registered
          callTrackTask('MOBILE_LOGIN_FAIL', 'HRD Login&Logout Events');
        }
      }
    }
  }, [history, hrData, setLoggedInUser]);

  const loadOnRefresh = useCallback(() => {
    callTrackTask('CLEAR_LOGIN_DATA', 'HRD Login&Logout Events');
    dispatch(setClearHrData());
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('beforeunload', loadOnRefresh);
    return () => {
      window.removeEventListener('beforeunload', loadOnRefresh);
    };
  }, [loadOnRefresh]);

  useEffect(() => {
    // Check if the reCAPTCHA verifier has already been created
    if (!window.recaptchaVerifier) {
      // Create a new reCAPTCHA verifier
      window.recaptchaVerifier = new firebaseContext.captcha.RecaptchaVerifier(
        'otp-button',
        {
          size: 'invisible',
        },
      );

      // Optionally, you can render the reCAPTCHA widget here
      // window.recaptchaVerifier.render().then(function (widgetId) {
      //   window.recaptchaWidgetId = widgetId;
      // });
    }
  }, [
    firebaseContext.auth.RecaptchaVerifier,
    firebaseContext.captcha.RecaptchaVerifier,
  ]);

  const signInWithOutlook = () => {
    callTrackClick('OUTLOOK_LOGIN');
    setError('');

    dispatch(setConfirmationResult({ data: null }));
    if (microsoftProviderRef.current) {
      firebase
        .auth()
        .signInWithPopup(microsoftProviderRef.current)
        .then((result) => {
          setLoading(true);
          /** @type {firebase.auth.OAuthCredential} */
          let currentUser = firebase.auth().currentUser;
          let email =
            result.user.providerData && result.user.providerData[0].email;
          // OAuth access and id tokens can also be retrieved:
          if (!currentUser.emailVerified) {
            currentUser
              .updateEmail(email)
              .then(() => {
                currentUser = firebase.auth().currentUser;
                dispatch(
                  fetchHrUser({
                    methodType: 'email',
                    userId: email,
                    loginMethod: 'outlook',
                  }),
                );
              })
              .catch((error) => {
                setLoading(false);
                callTrackTask('OUTLOOK_LOGIN_FAIL', 'HRD Login&Logout Events');
                throw error;
              });
          } else {
            if (email) {
              dispatch(
                fetchHrUser({
                  methodType: 'email',
                  userId: email,
                  loginMethod: 'outlook',
                }),
              );
              setLoading(false);
            }
          }
        })
        .catch((error) => {
          setLoading(false);
          callTrackTask('OUTLOOK_LOGIN_FAIL', 'HRD Login&Logout Events');
          const errorMessage = error.message;
          if (
            error.code === 'auth/popup-closed-by-user' ||
            error.code === 'auth/cancelled-popup-request'
          ) {
            setError(errorMessage);
          } else if (errorMessage.indexOf('{') !== -1) {
            // The error from the backend is thrown in this format
            // HTTP Cloud Function returned an error:
            // {"error":{"message":"The provided Email Id is not registered in Loop",
            // "status":"INVALID_ARGUMENT"}}
            // Extracting the message part of the error using the below code
            const jsonResponse = errorMessage.substring(
              errorMessage.indexOf('{'),
            );
            const errorObject = JSON.parse(jsonResponse);
            setError(errorObject.error.message);
          } else {
            setError('Please refresh the page and try again!');
          }
        });
    } else {
      setLoading(false);
    }
  };

  const signInWithGoogle = async () => {
    callTrackClick('GOOGLE_LOGIN');
    setError('');
    dispatch(setConfirmationResult({ data: null }));
    var provider = new fb.auth.GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        setLoading(true);
        var email =
          result.additionalUserInfo &&
          result.additionalUserInfo.profile &&
          result.additionalUserInfo.profile.email;

        let currentUser = firebase.auth().currentUser;
        //let email = result.user.providerData && result.user.providerData[0].email;

        if (!currentUser.emailVerified) {
          currentUser
            .updateEmail(email)
            .then(() => {
              currentUser = firebase.auth().currentUser;
              dispatch(
                fetchHrUser({
                  methodType: 'email',
                  userId: email,
                  loginMethod: 'google',
                }),
              );
            })
            .catch((error) => {
              callTrackTask('GOOGLE_LOGIN_FAIL', 'HRD Login&Logout Events');
              setLoading(false);
              throw error;
            });
        } else {
          if (email) {
            dispatch(
              fetchHrUser({
                methodType: 'email',
                userId: email,
                loginMethod: 'google',
              }),
            );
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        callTrackTask('GOOGLE_LOGIN_FAIL', 'HRD Login&Logout Events');
        setLoading(false);
        const errorMessage = error.message;
        if (
          error.code === 'auth/popup-closed-by-user' ||
          error.code === 'auth/cancelled-popup-request'
        ) {
          setError(errorMessage);
        } else if (errorMessage.indexOf('{') !== -1) {
          // The error from the backend is thrown in this format
          // HTTP Cloud Function returned an error:
          // {"error":{"message":"The provided Email Id is not registered in Loop",
          // "status":"INVALID_ARGUMENT"}}
          // Extracting the message part of the error using the below code
          const jsonResponse = errorMessage.substring(
            errorMessage.indexOf('{'),
          );
          const errorObject = JSON.parse(jsonResponse);
          setError(errorObject.error.message);
        } else {
          setError('Please refresh the page and try again!');
        }
      });
  };

  return (
    <>
      <div className="login-container">
        <SideLayout />
        <div className="login right-box-content">
          <div className="lh-login-form">
            <h4 className="login-title">Get started</h4>
            <p className="intro-subtitle">Login to view your details</p>
            <div className="row">
              <div className="col-md-6">
                <button
                  className={`btn lh-login-button mt-4 ${
                    hrData?.loginMethod === 'google' &&
                    hrData?.error === 'EMAIL_NOT_FOUND'
                      ? 'error-login-button'
                      : ''
                  }`}
                  variant="info"
                  onClick={signInWithGoogle}
                >
                  <img
                    className="googleImage"
                    src="/assets/icons/googleLogo.svg"
                    alt=""
                  />
                  Continue with Google
                </button>
                {hrData?.loginMethod === 'google' &&
                  hrData?.error === 'EMAIL_NOT_FOUND' && (
                    <div className="button-error-text">
                      User is not Registered
                    </div>
                  )}
              </div>
              <div className="col-md-6">
                <button
                  className={`btn lh-login-button mt-4 ${
                    hrData?.loginMethod === 'outlook' &&
                    hrData?.error === 'EMAIL_NOT_FOUND'
                      ? 'error-login-button'
                      : ''
                  }`}
                  variant="info"
                  onClick={signInWithOutlook}
                >
                  <img
                    className="googleImage"
                    src="/assets/images/outlook.svg"
                    alt=""
                  />
                  Continue with Outlook
                </button>
                {hrData?.loginMethod === 'outlook' &&
                  hrData?.error === 'EMAIL_NOT_FOUND' && (
                    <div className="button-error-text">
                      User is not Registered
                    </div>
                  )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <h6 className="hr-line">
                  <span>Or</span>
                </h6>
              </div>
            </div>

            {error && <Alert variant="danger">{error}</Alert>}

            <InputText
              placeholder="Enter Mobile"
              value={mobile}
              setValue={setMobile}
              maxLength="10"
              isInvalid={hrData?.error === 'MOBILE_NOT_FOUND'}
              invalidText={
                hrData?.error === 'NOT_FOUND' ||
                hrData?.error === 'MOBILE_NOT_FOUND'
                  ? 'User is not Registered'
                  : 'Please enter a valid mobile number'
              }
            />
            <Button
              id="otp-button"
              className="lh-login-submit-button"
              disabled={!validateMobile(mobile)}
              onClick={signInWithMobileOtp}
            >
              Get OTP
            </Button>
          </div>
        </div>
        {(loading || hrData?.state === 'loading') && <Backdrop />}
      </div>
    </>
  );
};

export default Login;
