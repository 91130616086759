import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../atoms/Navbar';
import {
  selectHrUser,
  selectRunOnAuthSuccess,
  setRunOnAuthSuccess,
} from '../../redux/slices/usersSlice';
import { fetchAllCompanyPolicyList } from '../../redux/slices/PolicyListSlice/thunks';
import { useDispatch, useSelector } from 'react-redux';
import './HrDashboard.scss';
import PolicySlider from '../atoms/PolicySlider/index';
import { ChangeRequest } from '../containers/ChangeRequest';
import { setLoopIdToken } from '../../utils/common/CustomFirebase';
import useSegment from '../../utils/hooks/useSegment';
import {
  getTrackTaskObj,
  getTaskByLoginMethod,
} from '../../utils/common/Utilities';
import {selectSelectedCompany} from "../../redux/slices/hrdRevampRedux";

const HrDashboard = () => {
  const dispatch = useDispatch();
  const hrData = useSelector(selectHrUser);
  const selectedCompany = useSelector(selectSelectedCompany);
  const runOnAuthSuccess = useSelector(selectRunOnAuthSuccess);
  const trackTask = useSegment('track');
  const location = useLocation();
  const callTrackTask = (action, category) =>
    trackTask(getTrackTaskObj(action, location, category));

  useEffect(() => {
    if (
      runOnAuthSuccess &&
      hrData?.error === '' &&
      hrData?.data &&
      hrData?.state === 'succeeded' &&
      hrData?.loginMethod
    ) {
      // callTrackTask(getTaskByLoginMethod(hrData?.loginMethod, true), 'HRD Login&Logout Events');
      dispatch(setRunOnAuthSuccess(false));
    }
    if (hrData?.data?.userId) {
      setLoopIdToken(hrData.data.userId);
    }
  }, [hrData?.data]);

  useEffect(() => {
    if (selectedCompany?.id) {
      dispatch(fetchAllCompanyPolicyList({ companyId: selectedCompany.id }));
    }
  }, [selectedCompany]);

  return (
    <div className="hr-dashboard">
      <div className="policy_slider">
        <PolicySlider />
      </div>
      <div className=" row">
          <div className="row dataTable_left_section">
            <div className="col-md-6">
              <h4 className="heading-one">Recent Activity</h4>
            </div>
          </div>
          <ChangeRequest />
      </div>
    </div>
  );
};

export default HrDashboard;
