import { convertUiOperationType } from '../../../utils/common/AppConstants';
import { IChangeRequest } from './types';

export const transformChangeRequests = (
  changeRequests: IChangeRequest[],
): IChangeRequest[] =>
  changeRequests.map((c) => ({
    ...c,
    operationType: convertUiOperationType(c.operationType),
  }));
