import Spinner from './Spinner';
import React from 'react';
import { StyledLoaderContainer } from './styles';

const Loader = () => {
  return (
    <StyledLoaderContainer>
      <Spinner />
    </StyledLoaderContainer>
  );
};
export default Loader;
