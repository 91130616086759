import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './navbar.css';
import { useAuth } from '../../../contexts/AuthContext';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { cleanup } from '../../../redux/store';
import { selectHrUser } from '../../../redux/slices/usersSlice';
import { selectCompanyConfig } from '../../../redux/slices/companySlice';
import { selectCompanyNameAndLogo } from '../../../redux/slices/companySlice';
import useSegment from '../../../utils/hooks/useSegment';
import { getTrackClickObj, getTrackTaskObj } from '../../../utils/common/Utilities';
import { isClaimsDashboardEnabled, isEndoVisible } from '../../../utils/featureFlag';

const activeItem = {
  borderBottom: '4px solid #18A294',
  paddingBottom: '0rem',
  color: '#002033',
  fontFamily: '"Inter", sans-serif',
  marginRight: '1rem',
  fontWeight: '600',
};
const Item = {
  borderBottom: '4px solid transparent',
  paddingBottom: '0rem',
  color: '#A0AEC0',
  fontFamily: '"Inter", sans-serif',
  marginRight: '1rem',
};

const Navbar = () => {
  const employeesRoutes = [
    '/employees',
    '/view-employee',
    '/self-enrol',
    '/add-employee',
    '/select-policies',
  ];
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const history = useHistory();
  const { logout, setLoggedInUser } = useAuth();
  const hrData = useSelector(selectHrUser);
  const compData = useSelector(selectCompanyNameAndLogo);
  const companyConfig = useSelector(selectCompanyConfig);
  const dispatch = useDispatch();
  const trackClick = useSegment('click');
  const trackTask = useSegment('track');
  const location = useLocation();
  const callTrackClick = (action, category) => trackClick(getTrackClickObj(action, location?.pathname, category));
  const callTrackTask = (action, category) => trackTask(getTrackTaskObj(action, location?.pathname, category));
  const isClaimsListEnabled = isClaimsDashboardEnabled && companyConfig && companyConfig.isClaimListEnabled;
  const isClaimsReportEnabled = isClaimsDashboardEnabled && companyConfig && companyConfig.isClaimsReportEnabled;
  const claimsRouteText = isClaimsListEnabled ? 'Claims' : 'Claim Analytics';
  //
  // useEffect(() => {
  //   if (hrData?.data?.companyId) {
  //       !compData && dispatch(fetchCompanyById(hrData.data.companyId));
  //       !companyConfig && dispatch(fetchCompanyConfig(hrData.data.companyId));
  //   }
  // }, [hrData?.data?.companyId, dispatch]);

  const onClickLogout = async () => {
    try {
      if (window.confirm('Are you sure')) {
        callTrackClick('LOGOUT', 'HRD Login&Logout Events');
        try {
          setLoggedInUser(null);
          dispatch(cleanup());
          await logout();
          callTrackTask('LOGOUT_SUCCESS', 'HRD Login&Logout Events');
          history.push('/login');
        } catch (error) {
          callTrackTask('LOGOUT_FAIL', 'HRD Login&Logout Events');
        }
      }
    } catch (error) {
      throw error;
    }
  };
  return (
    <section className="navigation">
      <div className="nav-container">
        <div className="brand">
          <img
            src="/assets/icons/loop.svg"
            alt=""
            className="d-inline-block align-text-top"
            onClick={() => callTrackClick('LOOP_LOGO', 'HRD Login&Logout Events')}
          />
        </div>
        <nav className="pull-right">
          <div
            className="nav-mobile"
            onClick={() => setShowMobileMenu(!showMobileMenu)}
          >
            <button id="navbar-toggle">
              <span />
            </button>
          </div>
          <ul
            className={`${showMobileMenu ? 'mobile-nav slideDown' : 'nav-list'
              }`}
          >
            <li>
              <NavLink
                style={location.pathname === '/dashboard' ? activeItem : Item}
                to="/"
                className="nav-link"
                id="navbarItem"
                onClick={() => callTrackClick('NAV_DASHBOARD_LINK', 'HRD Navigation Bar Events')}
              >
                Dashboard
              </NavLink>
            </li>
            {(isClaimsListEnabled || isClaimsReportEnabled) && (
              <li>
                <NavLink
                  style={
                    location.pathname === '/claims' ||
                    location.pathname === '/claim-details'
                      ? activeItem
                      : Item
                  }
                  to="/claims"
                  className="nav-link"
                  id="navbarItem"
                  onClick={() =>
                    callTrackClick(
                      'NAV_CLAIMS_LINK',
                      'HRD Navigation Bar Events',
                    )
                  }
                >
                  {claimsRouteText}
                </NavLink>
              </li>
            )}
            <li>
              <NavLink
                style={
                  location.pathname === '/policies' ||
                  location.pathname === '/policy-details'
                    ? activeItem
                    : Item
                }
                to="/policies"
                className="nav-link"
                id="navbarItem"
                onClick={() => callTrackClick('NAV_POLICIES_LINK', 'HRD Navigation Bar Events')}
              >
                Policies
              </NavLink>
            </li>
            <li>
              <NavLink
                style={
                  employeesRoutes.includes(location.pathname)
                    ? activeItem
                    : Item
                }
                to="/employees"
                className="nav-link"
                id="navbarItem"
                onClick={() => callTrackClick('NAV_EMPLOYEES_LINK', 'HRD Navigation Bar Events')}
              >
                Employees
              </NavLink>
            </li>

            {isEndoVisible && (
              <li>
                <NavLink
                  style={location.pathname === '/endorsements' ? activeItem : Item}
                  to="/endorsements"
                  className="nav-link"
                  id="navbarItem"
                >
                  Endorsements
                </NavLink>
              </li>
            )}

            {/* <li>
              <NavLink
                style={location.pathname === "/benefits" ? activeItem : Item}
                to="/benefits"
                className="nav-link"
                id="navbarItem"
              >
                Benefits
              </NavLink>
            </li> */}
            <li>
              <div className="dropdown dropdown_browser">
                <button
                  className="border-0 btn dropdown-toggle logout_btn profile_dropdown"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src={compData?.companyLogo || '/assets/icons/profile_icon.svg'}
                    alt="logo"
                  />
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li className="dropdown-item background_none">
                    <button type="button" onClick={onClickLogout}>
                      <img src="/assets/icons/logout.svg" alt="" />
                      Log out
                    </button>
                  </li>
                  <li className="dropdown-item">
                    <label>
                      <span>{hrData?.data?.email || ''}</span>
                    </label>
                    <label>
                      <span>{hrData?.data?.mobile || ''}</span>
                    </label>
                  </li>
                  <li className="dropdown-item">
                    <div className="dropdown_footer">
                      <div className="dropdown_logo pull-left">
                        <img
                          src={compData?.companyLogo ?? ''}
                          alt="companyLogo"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = "/assets/icons/profile_icon.svg";
                          }}
                        />
                      </div>
                      <div className="dropdown_comp_info pull-left">
                        <label>{hrData?.data?.name}</label>
                        <span>{compData?.name || '-'}</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="dropdown dropdown_res">
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li className="dropdown-item">
                    <label>
                      <span>{hrData?.data?.email}</span>
                    </label>
                    <label>
                      <span>{hrData?.data?.mobile}</span>
                    </label>
                  </li>
                  <li className="dropdown-item">
                    <div className="dropdown_footer">
                      <div className="dropdown_logo pull-left">
                        <img
                          src={compData?.companyLogo ?? '/assets/icons/profile_icon.svg'}
                          alt=""
                        />
                      </div>
                      <div className="dropdown_comp_info pull-left">
                        <label>{hrData?.data?.name}</label>
                        <span>{compData?.name}</span>
                      </div>
                    </div>
                  </li>
                  <li className="dropdown-item">
                    <button type="button" onClick={onClickLogout}>
                      <img src="/assets/icons/logout.svg" alt="" />
                      Log out
                    </button>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </section>
  );
};
export default Navbar;
