import theme from '../../../theme';
import { IChipColors, IChipVariants } from './types';

export const getChipVariantStyles = (
  variant: IChipVariants,
  backgroundColor: string | undefined,
  color: IChipColors | string,
) =>
  variant === 'filled'
    ? {
        backgroundColor: backgroundColor ?? 'rgba(0, 0, 0, 0.08)',
        border: `1px solid ${backgroundColor ?? 'rgba(0, 0, 0, 0.08)'}`,
      }
    : variant === 'outlined'
    ? {
        border: `1px solid ${
          typeof color === 'string' ? color : theme.colors.disabledGrey
        }`,
      }
    : {};
