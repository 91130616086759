import React from 'react';
import { getAmountInLacs, getPolicyType } from '../../../utils/common/Utilities';
import { DateFormatUtils } from '@loophealth/loop-ui-web-library';

const PolicyCardContent = ({ policy }) => {
  return (
    <>
        <div className="card_top">
            <div className="col-md-12">
                <div className="policy_info_left">
                    <div className="policy_status">
                        <label>{policy?.active ? 'Active' : 'Inactive'}</label>
                    </div>
                    <div className="policy_name">
                        <h4 className="cardName">
                            {getPolicyType(
                                policy?.policyType,
                                policy?.isTopUpPolicy,
                                policy?.isParentalPolicy
                            )}
                        </h4>
                        <h5 className="health_insurer">{policy?.insurerName}</h5>
                    </div>
                </div>
                <div className="policy_info_right">
                    <div
                        className="policy_image"
                        style={{
                            backgroundImage: `url("${policy?.insurerLogo ?? '/assets/images/cardImage.svg'}")`
                        }}
                    ></div>
                </div>
            </div>
        </div>
        <div className="card_bottom">
            <div className="policy_stats policy_row_one">
                <div className="policy_sum_ins">
                    <p className="amountText">Sum Insured</p>
                    <h5 className="amountPrice">
                        {policy?.sumAssuredSlabs?.map(
                            (policySlab) => getAmountInLacs(policySlab.sumInsured)).join(', ')}
                    </h5>
                </div>
                <div className="policy_cd_balance">
                    <p className="amountText">Valid till</p>
                    <h5 className="amountPrice">
                        {new DateFormatUtils().formatDateTime(policy?.policyEndDate?._seconds)}
                    </h5>
                </div>
                <div className="policy_cd_balance">
                    <p className="amountText">Policy Ref ID</p>
                    <h5 className="amountPrice">{policy?.id}</h5>
                </div>
            </div>
        </div>
        <div className="card_bottom card_bg">
            <div className="policy_stats policy_row_two">
                <div className="policy_employees" style={{ borderLeft: 'none' }}>
                    <p className="amountText">Employees</p>
                    <h5 className="amountPrice">
                        {policy?.selfCount === undefined ? '-' : policy?.selfCount}
                    </h5>
                </div>
                <div className="policy_employees">
                    <p className="amountText">Dependents</p>
                    <h5 className="amountPrice">
                        {policy?.dependentCount === undefined ? '-' : policy?.dependentCount}
                    </h5>
                </div>
            </div>
        </div>
    </>
  );
};
export default PolicyCardContent;
