import { ICompanyListItem } from '../../../redux/slices/hrdRevampRedux/types';

export const checkIfWhitelistedForNewHRD = (
  companyList: ICompanyListItem[],
): boolean => {
  if (!companyList) return false;
  return !!companyList.find(
    (company) => company.config?.isNewHrdEnabled === true,
  );
};
