import { ITabItem } from '@loophealth/loop-ui-web-library/dist/types/atoms/Tabs/types';
import {
  EmployeeDetails,
  FamilyMember,
  IEmpDetails,
  ITransformedData,
  PolicyInfo,
} from './types';

import { getAmountInLacs } from '../../../utils/common/Utilities';
import { DateFormatUtils } from '@loophealth/loop-ui-web-library';
import { IBadgeType } from '../ClaimDetails/types';
import { IClaimData } from '../ClaimsList/types';
import {
  IPossibleClaimStatus,
  RelationConstants,
} from '../../../utils/constants/constants';
import moment from 'moment';

export const empTabs: ITabItem[] = [
  {
    id: 'Policies',
    value: 'Employee E-Cards & Policies',
  },
  {
    id: 'Claims',
    value: 'Claims',
    errorText: '',
  },
  {
    id: 'Dependents',
    value: 'Dependant E-Cards & Policies',
  },
];

export enum IEmpTabs {
  Dependents = 'Dependents',
  Claims = 'Claims',
  Policies = 'Policies',
}

const getUserDetails = (userData: FamilyMember): IEmpDetails => {
  return {
    firstName: userData.firstName + ' ' + (userData.lastName ?? ''),
    lastName: userData.lastName ?? '',
    relationship: userData.relationship,
    gender: userData.gender,
    dob: new DateFormatUtils().formatDateTime(userData.dob._seconds || 0),
    mobile: userData.mobile || '--',
    email: userData.email || '--',
    employeeId: userData.employeeId,
    insuranceStatus: userData.insuranceStatus,
  };
};

export const transformEmpDepListForUI = (
  userPolicyList: EmployeeDetails[],
): {
  empOverviewDetails: IEmpDetails;
  policiesDetails: ITransformedData[];
} => {
  const empDetailsUI: {
    empOverviewDetails: IEmpDetails;
    policiesDetails: ITransformedData[];
  } = {
    empOverviewDetails: {} as IEmpDetails,
    policiesDetails: [] as ITransformedData[],
  };
  const currentDate = moment();
  userPolicyList.forEach((policyDetail) => {
    const policiesDetails: (ITransformedData | null)[] =
      policyDetail.family.map((familyMember, index) => {
        const policyEndDate = moment.unix(
          policyDetail.policyPlanEndDate._seconds,
        );
        const memberData: IEmpDetails = getUserDetails(familyMember);
        if (familyMember.relationship === RelationConstants.self) {
          empDetailsUI.empOverviewDetails = memberData;
        }
        if (policyEndDate.isAfter(currentDate)) {
          const policies: PolicyInfo[] = [
            {
              id: policyDetail.policyId,
              'Policy Name': {
                insurerName: policyDetail.insurerName,
                policyImage: policyDetail.policyImage,
                policyType: policyDetail.policyType,
              },
              'Sum Insured': getAmountInLacs(
                policyDetail?.sumInsured || 0,
              ).toString(),
              'Valid Till': new DateFormatUtils().formatDateTime(
                policyDetail.policyEndDate._seconds || 0,
              ),
              'Insurance Status': familyMember.insuranceStatus,
              'E-Card': {
                uhid: familyMember.uhid || '',
                policyType: policyDetail.policyType,
                userId: familyMember.userId,
                policyId: policyDetail.policyId,
              },
              'Allowed Family': policyDetail.familyDefinition,
              policyNumber: policyDetail.policyNumber,
            },
          ];

          const policiesDetail: ITransformedData = {
            details: memberData,
            policies,
          };

          return policiesDetail;
        }
        return null;
      });

    // empDetailsUI.policiesDetails.push(...policiesDetails);
    empDetailsUI.policiesDetails.push(
      ...(policiesDetails.filter(Boolean) as ITransformedData[]),
    );
  });

  return empDetailsUI;
};

export enum InsuranceStatusValues {
  INSURED = 'INSURED',
  IN_PROGRESS = 'IN_PROGRESS',
  REJECTED = 'REJECTED',
  DELETED = 'DELETED',
}
export const getBadgeType = (value: InsuranceStatusValues): IBadgeType => {
  switch (value) {
    case InsuranceStatusValues.INSURED:
      return 'success2';
    case InsuranceStatusValues.IN_PROGRESS:
      return 'warning';
    case InsuranceStatusValues.REJECTED:
      return 'error';
    case InsuranceStatusValues.DELETED:
      return 'error';
    default:
      return 'success';
  }
};

export const filterOpenClaims = (
  empTabs: ITabItem[],
  empClaims: IClaimData[],
): ITabItem[] => {
  const errorClaims = empClaims.filter((claim) => {
    const status = claim.status;
    if(claim.type === 'cashless')
      return false;
    return (
      !status ||
      status.finalStatus === IPossibleClaimStatus.claim_intimated ||
      status.finalStatus === IPossibleClaimStatus.cuq ||
      status.finalStatus === IPossibleClaimStatus.cup ||
      status.finalStatus === IPossibleClaimStatus.processed ||
      status.finalStatus === IPossibleClaimStatus.document_submission
    );
  });
  const claimsTab = empTabs.find((tab) => tab.id === IEmpTabs.Claims);
  if (claimsTab) {
    claimsTab.errorText = errorClaims.length
      ? `(${errorClaims.length} open)`
      : '';
  }

  return empTabs;
};
