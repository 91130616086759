import React, {useEffect} from "react";
import {IDropdownItem} from "@loophealth/loop-ui-web-library/dist/types/atoms/Form/DropDown/types";
import LoopApiService from "../../../../../adaptars/LoopApiService";
import {prepareFilterData, preparePolicyFilter} from "../utils";
import {useSelector} from "react-redux";
import {selectSelectedCompany} from "../../../../../redux/slices/hrdRevampRedux";

const useFetchClaimsFilters = () => {
    const selectedCompany = useSelector(selectSelectedCompany);
    const [filters, setFilters] = React.useState<Record<string, IDropdownItem[]>>({});
    const [policyPlans, setPolicyPlans] = React.useState([]);

    useEffect(() => {
        fetchFilters();
        fetchCompanyPolicies();
    }, []);

    useEffect(() => {
        policyPlans && setFilters(filters => {
            return {...filters, policyType: preparePolicyFilter(policyPlans)}
        });
    }, [policyPlans]);

    const fetchFilters = async (): Promise<void> => {
        const result = await LoopApiService.fetchClaimsFiltersAPI();
        const filtersList = prepareFilterData(result.data.filters)
        setFilters(filters => {
            return {...filters, ...filtersList}
        })
    }
    const fetchCompanyPolicies = async (): Promise<void> => {
        const result = await LoopApiService.getCompanyPolicyPlans({companyId: selectedCompany?.id});
        setPolicyPlans(result.policyPlans)
    }
    return {filters, policyPlans}
}
export default useFetchClaimsFilters;
