import {
  CalendarIconCircle,
  CopiedIcon,
  EmailContactIcon,
  PencilIcon,
} from '../../../../assets/images';

export const FAQ_DATA = [
  [
    {
      icon: CopiedIcon,
      title: 'Is my employee data secure during the upgrade?',
      subtitle:
        'Absolutely. The updated dashboard will continue to reflect all your data.',
    },
    {
      icon: CalendarIconCircle,
      title: 'When can I expect the dashboard to be back online?',
      subtitle:
        'We anticipate the launch of the new dashboard in January 2024. Regular updates will be communicated.',
    },
  ],
  [
    {
      icon: PencilIcon,
      title: 'How can I manage my data in the meantime?',
      subtitle:
        'For any data edits, additions, or deletions, please contact your Loop account manager, who will assist you with manual processing.',
    },
    {
      icon: EmailContactIcon,
      title: 'Who do I contact if I have questions or need support?',
      subtitle:
        'Your designated Loop account manager is available for all inquiries. Reach out to them directly.',
    },
  ],
];
