import React, { useEffect } from 'react';
import './policyList.css';
import { useSelector } from 'react-redux';
import { selectCompanyPolicyList } from '../../../../redux/slices/PolicyListSlice';
import PolicyCardContent from '../../../atoms/PolicySlider/PolicyCardContent';
import { useDispatch } from 'react-redux';
import { fetchAllCompanyPolicyList } from '../../../../redux/slices/PolicyListSlice/thunks';
import { selectSelectedCompany } from '../../../../redux/slices/hrdRevampRedux';

export const PoliciesList = () => {
  const companyPoliciesListing = useSelector(selectCompanyPolicyList);
  const policyPlans = companyPoliciesListing?.data?.policyPlans ?? [];

  const dispatch = useDispatch();
  const selectedCompany = useSelector(selectSelectedCompany);

  useEffect(() => {
    if (selectedCompany?.id) {
      dispatch(fetchAllCompanyPolicyList({ companyId: selectedCompany.id }));
    }
  }, [selectedCompany?.id]);

  return (
    <div className="hr-dashboard">
      <div className="main-container mx-auto">
        <div className="d-flex">
          <h4 className="heading-one margin_top_60">Policies</h4>
        </div>
        <div className="policy_wrapper">
          <div className="card_margin_slider d-flex flex-wrap full_width">
            {policyPlans.map((policy) => (
              <div
                key={policy.id}
                className="box_card m-3 rsp_nonSlider card_margin"
              >
                <PolicyCardContent policy={policy} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
