import { editMemberToPolicies } from '../../../../adaptars';
import { convertToDate } from '../../../../utils/common/Utilities';

export const updateMemberToPolicies = async (requestBody) => {
  const editMemberRequest = {
    companyId: requestBody.employer,
    enableEnrolmentPortal: 'false',
    member: {
      userId: requestBody.userId,
      employee_id: requestBody.employeeId,
      relationship_to_account_holders: requestBody.relationship,
      name: `${requestBody.firstName} ${requestBody.lastName}`,
      email_address: requestBody.email,
      mobile: requestBody.mobile,
      date_of_birth: convertToDate({ seconds: requestBody.dob }),
      gender: requestBody.gender,
      ctc: requestBody.ctc || 0,
      joiningDate: convertToDate({ seconds: requestBody.joiningDate }),
    },
  };
  const result = await editMemberToPolicies(editMemberRequest);
  return result;
};
