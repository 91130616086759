import {
  StyledContainer,
  StyledDataColumn,
  StyledDataRow,
  StyledDetailsHeader,
  StyledDetailsSection,
  StyledDetailsSectionContainer,
  StyledVerticalSpacer,
  StyledMainContainer,
  StyledLeftSideContainer,
  StyledBackIcon,
  StyledRightSideContainer,
} from './styles';
import { ArrowLeftIcon } from '../../../assets/images';
import { Typography } from '@loophealth/loop-ui-web-library';
import React, { useEffect, useState } from 'react';
import { HorizontalSeparator } from '../Analytics/legacy/styles';
import { parseResponse } from '../../../utils/common/Utilities';
import LoopApiService from '../../../adaptars/LoopApiService';
import { prepareClaimsDetails } from './utils';
import {
  claimDetailsDataSet,
  IClaimDetailsSections,
  IClaimDetailsUI,
} from './types';
import { useHistory } from 'react-router-dom';
import ClaimStatusTimeline from '../../containers/ClaimsStatusTimeline';
import Loader from '../../atoms/Spinner';
import { toast } from 'react-toastify';
import ClaimStatus from '../../containers/ClaimStatus/legacy';

const ClaimDetails = () => {
  const [claimData, setClaimData] = useState<IClaimDetailsUI | null>(null);
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const history = useHistory();
  const claimDataSet = claimData ? claimDetailsDataSet(claimData) : [];

  useEffect(() => {
    fetchClaimDetails();
  }, []);

  const fetchClaimDetails = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const claimId = urlSearchParams.get('id');
    if (!claimId) {
      console.error(`Claim Id not found`);
      return;
    }
    toggleLoading(true);
    const [error, result] = await parseResponse(
      LoopApiService.fetchClaimDetailsAPI(claimId),
    );
    toggleLoading(false);
    if (error) {
      toast.error(error.message || 'Something went wrong!');
      history.push('/claims');
      return;
    }
    const claims: IClaimDetailsUI = prepareClaimsDetails(result.data);
    setClaimData(claims);
  };

  const onBackClick = () => {
    history.goBack();
  };

  const ClaimDetailsSections: React.FC<IClaimDetailsSections> = ({
    title,
    firstSectionDetails,
    secondSectionDetails,
  }) => {
    return (
      <StyledDetailsSectionContainer>
        <Typography variant="large" weight="semiBold">
          {title}
        </Typography>
        <HorizontalSeparator />
        <StyledDetailsSection>
          <StyledDataColumn>
            {Object.keys(firstSectionDetails).map((keyName, index) => (
              <StyledDataRow key={index}>
                <Typography variant="small">{keyName}</Typography>
                <Typography variant="small">
                  {firstSectionDetails[keyName]}
                </Typography>
              </StyledDataRow>
            ))}
          </StyledDataColumn>
          <StyledVerticalSpacer />
          <StyledDataColumn>
            {Object.keys(secondSectionDetails).map((keyName, index) => (
              <StyledDataRow key={index}>
                <Typography variant="small">{keyName}</Typography>
                <Typography variant="small">
                  {secondSectionDetails[keyName]}
                </Typography>
              </StyledDataRow>
            ))}
          </StyledDataColumn>
        </StyledDetailsSection>
      </StyledDetailsSectionContainer>
    );
  };

  return (
    <StyledMainContainer>
      {claimData && !isLoading ? (
        <>
          <StyledLeftSideContainer>
            <StyledContainer>
              <StyledDetailsHeader>
                <StyledBackIcon src={ArrowLeftIcon} onClick={onBackClick} />
                <Typography variant="title2" weight="medium">
                  Claim details
                </Typography>
                {claimData && (
                  <Typography variant="medium" color="secondary">
                    (Claim ID:{' '}
                    <Typography variant="medium" weight="medium">
                      {claimData.claimId}
                    </Typography>
                    )
                  </Typography>
                )}
              </StyledDetailsHeader>
              {claimDataSet.map((data) => (
                <ClaimDetailsSections
                  title={data.title}
                  firstSectionDetails={data.firstSectionDetails}
                  secondSectionDetails={data.secondSectionDetails}
                />
              ))}
            </StyledContainer>
          </StyledLeftSideContainer>
          <StyledRightSideContainer>
            <ClaimStatus status={claimData.claimStatus} />
            <HorizontalSeparator />
            {claimData?.statusHistory && (
              <ClaimStatusTimeline data={claimData} />
            )}
          </StyledRightSideContainer>
        </>
      ) : (
        <Loader />
      )}
    </StyledMainContainer>
  );
};
export default ClaimDetails;
