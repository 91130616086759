import styled from 'styled-components';

export const StyledContainer = styled.div`
  padding:  20px 10px;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  background-color: ${(p: any) => p.theme.colors.surface0};
  table {
    max-width: 100%;
  }
  @media (max-width: 1024px) {
    padding: 80px 20px;
  }
`;
export const StyledClaimsContainer = styled.div`
  padding: 43px 60px;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  background-color: ${(p) => p.theme.colors.surface0};
  table {
    max-width: 100%;
  }
  @media (max-width: 1024px) {
    padding: 80px 20px;
  }
`;
export const StyledTabsWrapper = styled.div`
  padding: 0 15px;
`;
