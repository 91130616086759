import { Modal, Typography } from '@loophealth/loop-ui-web-library';
import React, { FC } from 'react';
import {
  AnnounceIcon,
  CrossOutlineDarkIcon,
  EndoAddIcon,
  EndoDeleteIcon,
  EndoEditIcon,
} from '../../../assets/images';
import WithThemeProvider from '../../../theme/WithThemeProvider';
import {
  StyledCrossIcon,
  StyledEndoActionContainer,
  StyledEndoActionImage,
  StyledEndoActions,
  StyledHeader,
  StyledNotificationContainer,
} from './styles';
import { useHistory, useLocation } from 'react-router-dom';
import useSegment from '../../../utils/segment/hooks/useSegment';
import { extractPathName, trackClickEvent } from '../../../utils/segment/utils';
import { extractDate } from '../../pages/Endorsements/ListingPage/utils';

interface IEndoAddEditDeleteModalProps {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  dateText: string;
}

const EndoAddEditDeleteModal: FC<IEndoAddEditDeleteModalProps> = ({
  isVisible,
  setIsVisible,
  dateText,
}) => {
  const history = useHistory();
  const trackClick = useSegment('click');
  const location = useLocation();
  const onAddClicked = () => {
    history.push('/bulk-add');
    trackClick(
      trackClickEvent(
        'Add_Lives_Employee_add',
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
  };
  const handleCrossIconClick = () => {
    setIsVisible(false);
    trackClick(
      trackClickEvent(
        'X_Add_Edit_Delete_Modal_Employee',
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
  };
  const onDeleteClicked = () => {
    trackClick(
      trackClickEvent(
        'Delete_Lives_Employee_del',
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
    history.push('/bulk-delete');
  };
  return (
    <Modal
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      isModalClosable={false}
    >
      <WithThemeProvider>
        <StyledHeader>
          <Typography variant={'large'}>
            Hi! Would you like to add or delete lives today?
          </Typography>
          <StyledCrossIcon
            src={CrossOutlineDarkIcon}
            onClick={handleCrossIconClick}
          />
        </StyledHeader>
        <StyledNotificationContainer>
          <img src={AnnounceIcon} alt="alert" />
          <Typography variant={'small'} color={'secondary'}>
            While you can submit your data with us, Loop will share the data
            with the insurer on the{' '}
            <Typography variant={'small'} color={'emerald'} weight={'bold'}>
              {extractDate(dateText)} of every month!
            </Typography>
          </Typography>
        </StyledNotificationContainer>
        <StyledEndoActionContainer>
          <StyledEndoActions onClick={onAddClicked}>
            <StyledEndoActionImage src={EndoAddIcon} />
            <Typography variant={'large'} weight={'medium'}>
              Add Lives
            </Typography>
            <Typography
              variant={'small'}
              weight={'regular'}
              color={'secondary'}
            >
              Add multiple employees or dependents through{' '}
              <Typography variant={'small'} weight={'bold'} color={'secondary'}>
                excel upload
              </Typography>
            </Typography>
          </StyledEndoActions>
          {/* <StyledEndoActions>
            <StyledEndoActionImage src={EndoEditIcon} />
            <Typography variant={'large'} weight={'medium'}>
              Edit Lives
            </Typography>
            <Typography variant={'small'} weight={'medium'} color={'secondary'}>
              Edit the details of a single employee or dependent from the
              dashboard
            </Typography>
          </StyledEndoActions> */}
          <StyledEndoActions onClick={onDeleteClicked}>
            <StyledEndoActionImage src={EndoDeleteIcon} />
            <Typography variant={'large'} weight={'medium'}>
              Delete Lives
            </Typography>
            <Typography variant='small' weight='medium' color='secondary'>
              Bulk delete multiple employees or dependents through&nbsp;
              <Typography variant='small' weight='bold' color='secondary'>
                excel upload
              </Typography>
            </Typography>
          </StyledEndoActions>
        </StyledEndoActionContainer>
      </WithThemeProvider>
    </Modal>
  );
};
export default EndoAddEditDeleteModal;
