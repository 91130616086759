import { RelationConstants } from '../../../utils/constants/constants';
import {
  IEmpDetails,
  ITransformedData,
} from '../../pages/EmployeeDetails/types';
import { isPolicyEndDateValid } from '../../pages/Employees/utils';

export const filterSelfPolicies = (
  policiesDetails: ITransformedData[],
): ITransformedData => {
  const filteredData = policiesDetails.filter(
    (item) => item.details.relationship === RelationConstants.self,
  );
  const updatedPolicies = filteredData.map((item) => {
    const policy = { ...item.policies[0] };
    if (!isPolicyEndDateValid(policy['Valid Till'])) {
      policy['Insurance Status'] = 'DELETED';
    }
    return policy;
  });

  return {
    details:
      filteredData.length > 0 ? filteredData[0].details : ({} as IEmpDetails),
    policies: updatedPolicies,
  };
};

export const filterPoliciesDependents = (
  policiesDetails: ITransformedData[],
): ITransformedData[] => {
  const mergedData: { [key: string]: ITransformedData } = {};
  const filteredData: ITransformedData[] = [];

  policiesDetails.forEach((item) => {
    if (item.details.relationship !== RelationConstants.self) {
      const firstName = item.details.firstName;
      if (!mergedData[firstName]) {
        mergedData[firstName] = {
          details: item.details,
          policies: [],
        };
      }
      const policy = { ...item.policies[0] };
      if (!isPolicyEndDateValid(policy['Valid Till'])) {
        policy['Insurance Status'] = 'DELETED';
      }

      mergedData[firstName].policies.push(policy);
    }
  });

  for (const firstName in mergedData) {
    filteredData.push(mergedData[firstName]);
  }

  return filteredData;
};
