import theme from '../../theme';

export const getStatusFromPolicyStatus = (status) => {
    if (status.toLowerCase().includes('pending')) {
        return {backgroundColor: '#FCE3C6', color: '#D48C38', tooltip: 'Your request has not been sent to the insurer yet'};
    }
    if (status.toLowerCase().includes('in_active') || status.toLowerCase().includes('rejected')) {
        return {backgroundColor: theme.colors.disabledGrey, color: theme.colors.white, tooltip: 'Your request is in-active'};
    }
    if (status.toLowerCase().includes('active') || status.toLowerCase().includes('accepted')) {
        return {backgroundColor: 'rgba(27, 180, 164, 0.15)', color: '#18A294', tooltip: 'Your request is active'};
    }
    if (status.toLowerCase().includes('progress')) {
        return {backgroundColor: 'rgba(0, 156, 195, 0.15)', color: '#009CC3', tooltip: 'Your request is under process with the insurer'};
    }
    return {backgroundColor: 'inherit', color: 'inherit', tooltip: ''};
}

const SOURCE_COLOR_MAP = {
    'genome': { color: theme.colors.beyondGrey, backgroundColor: theme.colors.coverUpGreen, label: 'Genome' },
    'enrolment': { color: theme.colors.beyondGrey, backgroundColor: theme.colors.coverYellow, label: 'Enrolment' },
    'hrdesk': { color: theme.colors.white, backgroundColor: theme.colors.consultViolet, label: 'HR Desk' },
    'hrms': { color: theme.colors.white, backgroundColor: theme.colors.turquoise, label: 'HRMS' },
    'topup': { color: theme.colors.white, backgroundColor: theme.colors.planRed, label: 'Top ups' }
};
export const getSourceStyles = (value) =>
  SOURCE_COLOR_MAP[value] ?? { color: theme.colors.white, backgroundColor: theme.colors.disabledGrey, label: value };


const STATUS_COLOR_MAP = {
    'PENDING': theme.colors.planRed,
    'IN_PROGRESS': theme.colors.turquoise,
    'ACCEPTED': theme.colors.coverUpGreen,
    'REJECTED': theme.colors.beyondGrey
};
export const getStatusColor = (value) =>
    value ? STATUS_COLOR_MAP[value] : theme.colors.disabledGrey;
  

export const STATUS_LABEL_MAP = {
    PENDING: 'Pending',
    IN_PROGRESS: 'In Progress',
    REJECTED: 'Rejected',
    ACCEPTED: 'Accepted'
};
export const getStatusLabel = (value) =>
    STATUS_LABEL_MAP[value] ?? '';
