import { IUserDetails } from './types';
import { RootState } from './../../store/index';
import { createSlice } from '@reduxjs/toolkit';
import { AppConstants } from '../../../utils/common/AppConstants';
import { apiState, initialState } from './initialState';
import { getUpdatedUserDependentDetails } from './utils';
import {
  deleteUserDependentDetails,
  deleteUserDetails,
  fetchUserFamilyDetails,
  addEmployeeRequest,
  fetchUserDetails,
  fetchUserDependent,
  fetchUserDependents,
} from './thunks';

const hrRequestSlice = createSlice({
  name: 'hrRequest',
  initialState: initialState,
  reducers: {
    updateUserDependent(state, action) {
      const DependentDetails = action.payload?.data ?? {};
      const newUserDependentDetails = getUpdatedUserDependentDetails(
        DependentDetails,
        state.userDependentDetails.data,
      );
      state.userDependentDetails.data = newUserDependentDetails;
    },
    deleteUserDependent(state, action) {
      const DependentDetails = action.payload?.data ?? {};
      const otherDependents =
        state.userDependentDetails?.data?.filter(
          (dep: IUserDetails) => dep.userId !== DependentDetails.userId,
        ) ?? [];
      state.userDependentDetails.data = otherDependents;
    },
    updateUser(state, action) {
      state.userDetailData.data = {
        ...state.userDetailData.data,
        ...(action.payload?.data ?? {}),
      };
    },
    clearHrReqState(state, action) {
      let initState = { ...apiState };
      switch (action.payload.data) {
        case AppConstants.DELETE:
          state.deleteDetailData = initState;
          break;
        case AppConstants.DELETE_DEPENDENT:
          state.deleteUserDependent = initState;
          break;
        case AppConstants.ADD:
          state.addHrRequest = initState;
          break;
        case AppConstants.ADD_DEPENDENT:
          // state.addDependentReq = initState;
          break;
        case AppConstants.USER_DETAILS:
          state.userDetailData = initState;
          break;
        case AppConstants.USER_DEPENDENT_DETAILS:
          state.userDependentDetails = initState;
          break;
        default:
          break;
      }
    },
  },
  extraReducers: {
    [fetchUserDetails.pending.toString()]: (state) => {
      state.userDetailData.status = 'loading';
      state.userDetailData.error = '';
    },
    [fetchUserDetails.fulfilled.toString()]: (state, action) => {
      state.userDetailData.data = action.payload.data?.user;
      state.userDetailData.status = 'succeeded';
    },
    [fetchUserDetails.rejected.toString()]: (state, action) => {
      state.userDetailData.status = 'failed';
      state.userDetailData.error = action.error.message;
    },

    [fetchUserDependent.pending.toString()]: (state) => {
      state.userDependentDetails.status = 'loading';
      state.userDependentDetails.error = '';
    },
    [fetchUserDependent.fulfilled.toString()]: (state, action) => {
      const DependentDetails = action.payload?.data?.user ?? {};
      const newUserDependentDetails = getUpdatedUserDependentDetails(
        DependentDetails,
        state.userDependentDetails.data,
      );
      state.userDependentDetails.data = newUserDependentDetails;
      state.userDependentDetails.status = 'succeeded';
    },
    [fetchUserDependent.rejected.toString()]: (state, action) => {
      state.userDependentDetails.status = 'failed';
      state.userDependentDetails.error = action.error.message;
    },

    [fetchUserDependents.pending.toString()]: (state) => {
      state.userDependentDetails.status = 'loading';
      state.userDependentDetails.error = '';
    },
    [fetchUserDependents.fulfilled.toString()]: (state, action) => {
      state.userDependentDetails.data = action.payload.data;
      state.userDependentDetails.status = 'succeeded';
    },
    [fetchUserDependents.rejected.toString()]: (state, action) => {
      state.userDependentDetails.status = 'failed';
      state.userDependentDetails.error = action.error.message;
    },

    [deleteUserDetails.pending.toString()]: (state) => {
      state.deleteDetailData.status = 'loading';
      state.deleteDetailData.error = '';
    },
    [deleteUserDetails.fulfilled.toString()]: (state, action) => {
      state.deleteDetailData.data = action.payload.data;
      state.deleteDetailData.status = 'succeeded';
    },
    [deleteUserDetails.rejected.toString()]: (state, action) => {
      state.deleteDetailData.status = 'failed';
      state.deleteDetailData.error = action.error.message;
    },

    [deleteUserDependentDetails.pending.toString()]: (state) => {
      state.deleteUserDependent.status = 'loading';
      state.deleteUserDependent.error = '';
    },
    [deleteUserDependentDetails.fulfilled.toString()]: (state, action) => {
      state.deleteUserDependent.data = action.payload.data;
      state.deleteUserDependent.status = 'succeeded';
    },
    [deleteUserDependentDetails.rejected.toString()]: (state, action) => {
      state.deleteUserDependent.status = 'failed';
      state.deleteUserDependent.error = action.error.message;
    },

    [fetchUserFamilyDetails.pending.toString()]: (state) => {
      state.userFamilyDetailData.status = 'loading';
      state.userFamilyDetailData.error = '';
    },
    [fetchUserFamilyDetails.fulfilled.toString()]: (state, action) => {
      state.userFamilyDetailData.data = action.payload.data;
      state.userFamilyDetailData.status = 'succeeded';
    },
    [fetchUserFamilyDetails.rejected.toString()]: (state, action) => {
      state.userFamilyDetailData.status = 'failed';
      state.userFamilyDetailData.error = action.error.message;
    },

    [addEmployeeRequest.pending.toString()]: (state) => {
      state.addHrRequest.status = 'loading';
      state.addHrRequest.error = '';
    },
    [addEmployeeRequest.fulfilled.toString()]: (state, action) => {
      state.addHrRequest.data = action.payload;
      state.addHrRequest.status = 'succeeded';
    },
    [addEmployeeRequest.rejected.toString()]: (state, action) => {
      state.addHrRequest.status = 'failed';
      state.addHrRequest.error = action.error;
    },

    'common/cleanup': (state) => {
      state.userDetailData = {
        ...apiState,
      };
      state.userDependentDetails = {
        ...apiState,
      };
      state.deleteDetailData = {
        ...apiState,
      };
      state.deleteUserDependent = {
        ...apiState,
      };
      state.userFamilyDetailData = {
        ...apiState,
      };
      state.addHrRequest = {
        ...apiState,
      };
    },
  },
});

export const selectUserDetails = (state: RootState) =>
  state.hrRequest.userDetailData;
export const selectUserDependentDetails = (state: RootState) =>
  state.hrRequest.userDependentDetails;

export const deleteUserDetailsSelector = (state: RootState) =>
  state.hrRequest.deleteDetailData;

export const deleteUserDependentDetailsSelector = (state: RootState) =>
  state.hrRequest.deleteUserDependent;

export const selectUserFamilyDetails = (state: RootState) =>
  state.hrRequest.userFamilyDetailData;

export const selectAddHrRequest = (state: RootState) =>
  state.hrRequest.addHrRequest;

export const {
  clearHrReqState,
  updateUserDependent,
  deleteUserDependent,
  updateUser,
} = hrRequestSlice.actions;

export default hrRequestSlice.reducer;
