export type IJSON = {
  [key: string]: any;
};

export interface ISegmentAct {
  name: string;
  properties?: Record<string, unknown>;
  // eslint-disable-next-line camelcase
  login_status?: boolean;
  clicked_on?: boolean;
}

interface ISegmentActions extends IJSON {
  PAGE: Record<string, ISegmentAct>;
  CLICK: Record<string, ISegmentAct>;
  IDENTIFY: Record<string, ISegmentAct>;
}

export const SEGMENT_ACTIONS: ISegmentActions = {
  PAGE: {
    LOGIN_PAGE: {
      name: 'Login',
      properties: {
        page_category: 'HRD Login&Logout Page',
        description: 'Landed on the HR dashboard login page',
        login_status: false,
      },
    },
    OTP_PAGE: {
      name: 'Enter_Otp',
      properties: {
        page_category: 'HRD Login&Logout Page',
        description: 'User lands on enter otp page',
        login_status: false,
      },
    },
    CLAIMS_LISTING_PAGE: {
      name: 'Claims',
      properties: {
        page_category: 'HRD Claims Listing Page',
        description: 'Landed on the HRD Claims listing Page. ',
        login_status: true,
      },
    },
    EMPLOYEE_LISTING_PAGE: {
      name: 'Employee_Listing',
      properties: {
        page_category: 'HRD Employee Page',
        description: 'Landed on the HR employees listing page',
        login_status: true,
      },
    },
    EMPLOYEE_DETAILS_PAGE: {
      name: 'Employee_Details',
      properties: {
        page_category: 'HRD Employee Details Page',
        description: 'Landed on the HR employee details page',
        login_status: true,
      },
    },
    CLAIM_ANALYTICS_PAGE: {
      name: 'Claim_Analytics',
      properties: {
        page_category: 'HRD Claim Analytics Page',
        description: 'Landed on the Claim Analytics page',
        login_status: true,
      },
    },
    CD_ACCOUNTS_PAGE: {
      name: 'Cd_Accounts',
      properties: {
        page_category: 'HRD CD Accounts Page',
        description: 'Landed on the CD Accounts page',
        login_status: true,
      },
    },
    POLICY_LISTING_PAGE: {
      name: 'Policies',
      properties: {
        page_category: 'HRD Policy Page',
        description: 'Landed on the HR policies page',
        login_status: true,
      },
    },
    POLICY_DETAILS_PAGE: {
      name: 'Policy Details',
      properties: {
        page_category: 'HRD Policy Details Page',
        description: 'Landed on the HR policy details page',
        login_status: true,
      },
    },
    MIDTERM_FOUND_MODAL_ADD: {
      name: 'MIDTERM_FOUND_MODAL_ADD',
      properties: {
        page_category: 'Midterm Found Modals',
        description: 'Landed on the Midterm Modal',
        login_status: true,
      },
    },
    REVIEW_UPLOADED_DATA_ADD: {
      name: 'REVIEW_UPLOADED_DATA_ADD',
      properties: {
        page_category: 'Review Uploaded Details Page',
        description: 'Landed on the Review Uploaded Details',
        login_status: true,
      },
    },
    UPLOAD_UNSUCCESSFUL_ADD: {
      name: 'UPLOAD_UNSUCCESSFUL_ADD',
      properties: {
        page_category: 'Midterm Unsuccessful Add Records',
        description: 'Landed on the Midterm Unsuccesful Add Records Details',
        login_status: true,
      },
    },
    UPLOAD_SUCCESSFUL_ADD: {
      name: 'UPLOAD_SUCCESSFUL_ADD',
      properties: {
        page_category: 'Midterm Successful Add Records',
        description: 'Landed on the Midterm Successful Add Records Details',
        login_status: true,
      },
    },
  },
  CLICK: {
    POLICY_DETAILS: {
      name: 'POLICY_DETAILS',
      properties: {
        describe: 'User Clicked on PolicyDetails Page',
        page_category: '',
        login_status: true,
      },
    },
  },
  TASK: {
    LOGOUT_SUCCESS: {
      task: 'logout',
      login_status: false,
      status: true,
    },
    LOGOUT_FAIL: {
      task: 'logout',
      login_status: true,
      status: false,
    },
    CLEAR_LOGIN_DATA: {
      task: 'clear_login',
      login_status: false,
      status: true,
    },
    OUTLOOK_LOGIN_SUCCESS: {
      task: 'login',
      login_method: 'outlookLogin',
      login_status: true,
      status: true,
    },
    OUTLOOK_LOGIN_FAIL: {
      task: 'login',
      login_method: 'outlookLogin',
      login_status: false,
      status: false,
    },
    GOOGLE_LOGIN_SUCCESS: {
      task: 'login',
      login_method: 'gmailLogin',
      login_status: true,
      status: true,
    },
    GOOGLE_LOGIN_FAIL: {
      task: 'login',
      login_method: 'gmailLogin',
      login_status: false,
      status: false,
    },
    MOBILE_VERIFIED_FAIL: {
      task: 'login_verify',
      login_method: 'mobileLogin',
      login_status: false,
      status: false,
    },
    MOBILE_VERIFIED_SUCCESS: {
      task: 'login_verify',
      login_method: 'mobileLogin',
      login_status: false,
      status: true,
    },
    MOBILE_OTP_FAIL: {
      task: 'otp_verify',
      login_method: 'mobileLogin',
      login_status: false,
      status: false,
    },
    MOBILE_OTP_SUCCESS: {
      task: 'otp_verify',
      login_method: 'mobileLogin',
      login_status: false,
      status: true,
    },
    MOBILE_LOGIN_SUCCESS: {
      task: 'login',
      login_method: 'mobileLogin',
      login_status: true,
      status: true,
    },
    MOBILE_LOGIN_FAIL: {
      task: 'login',
      login_method: 'mobileLogin',
      login_status: false,
      status: false,
    },
    VIEW_EMPLOYEE_UPDATE_EMPLOYEE_SUCCESS: {
      task: 'user_updated',
      login_status: true,
      status: true,
    },
    VIEW_EMPLOYEE_UPDATE_EMPLOYEE_FAIL: {
      task: 'user_updated',
      login_status: true,
      status: false,
    },
    VIEW_EMPLOYEE_ADD_DEPENDENT_SUCCESS: {
      task: 'user_added',
      login_status: true,
      status: true,
    },
    VIEW_EMPLOYEE_ADD_DEPENDENT_FAIL: {
      task: 'user_added',
      login_status: true,
      status: false,
    },
    VIEW_EMPLOYEE_UPDATE_DEPENDENT_SUCCESS: {
      task: 'dependent_updated',
      login_status: true,
      status: true,
    },
    VIEW_EMPLOYEE_UPDATE_DEPENDENT_FAIL: {
      task: 'dependent_updated',
      login_status: true,
      status: false,
    },
    VIEW_EMPLOYEE_DELETE_EMPLOYEE_SUCCESS: {
      task: 'employee_deleted',
      login_status: true,
      status: true,
    },
    VIEW_EMPLOYEE_DELETE_EMPLOYEE_FAIL: {
      task: 'employee_deleted',
      login_status: true,
      status: true,
    },
    VIEW_EMPLOYEE_DELETE_DEPENDENT_SUCCESS: {
      task: 'dependent_deleted',
      login_status: true,
      status: true,
    },
    VIEW_EMPLOYEE_DELETE_DEPENDENT_FAIL: {
      task: 'dependent_deleted',
      login_status: true,
      status: false,
    },
    VIEW_EMPLOYEE_DOWNLOAD_ECARD_SUCCESS: {
      task: 'employee_ecard_downloaded',
      login_status: true,
      status: true,
    },
    VIEW_EMPLOYEE_DOWNLOAD_ECARD_FAIL: {
      task: 'employee_ecard_downloaded',
      login_status: true,
      status: false,
    },
    VIEW_DEPENDENT_DOWNLOAD_ECARD_SUCCESS: {
      task: 'dependent_ecard_downloaded',
      login_status: true,
      status: true,
    },
    VIEW_DEPENDENT_DOWNLOAD_ECARD_FAIL: {
      task: 'dependent_ecard_downloaded',
      login_status: true,
      status: false,
    },
  },
  IDENTIFY: {
    USER_LOGIN: {
      name: 'USER_LOGIN',
      properties: {
        user_id: '',
      },
    },
    USER_LOGOUT: { name: 'USER_LOGOUT' },
  },
};
