import {
  LoopBadge,
  LoopButton,
  Modal,
  Typography,
} from '@loophealth/loop-ui-web-library';
import {
  ArrowDownEmerald,
  ArrowUpEmerald,
  CdBalancePurpleIcon,
  CrossOutlineDarkIcon,
  InfoDarkIcon,
  InfoLightIcon,
  PresentationImage,
  StarFourIcon,
} from '../../../assets/images';
import WithThemeProvider from '../../../theme/WithThemeProvider';
import { formatCurrencyAmount } from '../../../utils/common/Utilities';
import {
  StyledBreakdownCostWrapper,
  StyledBreakdownDetailsBody,
  StyledBreakdownDetailsBodyFooter,
  StyledBreakdownDetailsBodyHeader,
  StyledBreakdownList,
  StyledBreakdownListItem,
  StyledCDBreakdownWrapper,
  StyledCDBreakdownWrapperHeader,
  StyledCDBreakdownWrapperHeaderTitle,
  StyledCDIcon,
  StyledCdBreakdownBody,
  StyledCdBreakdownBodySeperator,
  StyledCdBreakdownDetails,
  StyledCdBreakdownFAQ,
  StyledCdBreakdownFAQHeader,
  StyledCdBreakdownFAQItem,
  StyledCdBreakdownFooter,
  StyledCloseIcon,
  StyledHorizontalSeperator,
  StyledInfoIcon,
  StyledPresentationImage,
  StyledTextAndBadge,
  StyledTextBadgeAndBalance,
  StyledViewHideButton,
} from './styles';
import { IRecommendedCdBreakdownModal } from './types';
import { transformCdEndoAlerts } from './utils';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectEndorsementList } from '../../../redux/slices/hrdRevampRedux';
import { mapToPolicyTypeFullForm } from '../../../utils/common/employee';

const RecommendedCdBreakdownModal = ({
  data,
  isVisible,
  setIsVisible,
}: IRecommendedCdBreakdownModal) => {
  const endorsements = useSelector(selectEndorsementList).data;
  const [isBreakdownVisible, setIsBreakdownVisible] = useState(false);
  const toggleBreakdownVisible = () => setIsBreakdownVisible((prev) => !prev);
  const {
    isSufficient,
    totalRecommendedCost,
    recommendedCost,
    endoCost,
    endos,
  } = transformCdEndoAlerts(data, endorsements);
  const amountRequired = endoCost - data.balance;
  return (
    <Modal
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      isModalClosable={false}
    >
      <WithThemeProvider>
        <StyledCDBreakdownWrapper>
          <StyledCDBreakdownWrapperHeader>
            <StyledCDBreakdownWrapperHeaderTitle>
              <StyledCDIcon src={CdBalancePurpleIcon} />
              <Typography variant="large" weight="medium">
                Recommended CD Balance breakdown
              </Typography>
            </StyledCDBreakdownWrapperHeaderTitle>
            <StyledCloseIcon
              src={CrossOutlineDarkIcon}
              onClick={() => setIsVisible(false)}
            />
          </StyledCDBreakdownWrapperHeader>
          <StyledCdBreakdownBody>
            <StyledCdBreakdownDetails>
              <StyledBreakdownDetailsBody>
                <StyledBreakdownDetailsBodyHeader>
                  <StyledBreakdownCostWrapper>
                    <StyledTextBadgeAndBalance>
                      <StyledTextAndBadge>
                        <Typography variant="medium">
                          Balance needed to process ongoing endorsements
                        </Typography>
                        <LoopBadge
                          badgeType="error"
                          variant="overflow"
                          text="Essential"
                        />
                      </StyledTextAndBadge>
                      <Typography variant="large" weight="semiBold">
                        {formatCurrencyAmount(endoCost)}
                      </Typography>
                    </StyledTextBadgeAndBalance>
                    {isBreakdownVisible && (
                      <>
                        <StyledHorizontalSeperator />
                        <StyledBreakdownList>
                          {endos.map((endo) => (
                            <StyledBreakdownListItem
                              key={endo.id + endo.policyType}
                            >
                              <Typography variant="small">
                                {mapToPolicyTypeFullForm(endo.policyType)}
                              </Typography>
                              <Typography variant="medium" weight="medium">
                                {formatCurrencyAmount(endo.endoCost)}
                              </Typography>
                            </StyledBreakdownListItem>
                          ))}
                        </StyledBreakdownList>
                      </>
                    )}
                    <StyledViewHideButton>
                      <LoopButton
                        variant="text"
                        onClick={toggleBreakdownVisible}
                        iconOrder="right"
                        iconSrc={
                          isBreakdownVisible ? ArrowUpEmerald : ArrowDownEmerald
                        }
                      >
                        {isBreakdownVisible ? 'Hide' : 'View'} endorsement cost
                        breakdown
                      </LoopButton>
                    </StyledViewHideButton>
                  </StyledBreakdownCostWrapper>

                  <StyledHorizontalSeperator />
                  <StyledTextBadgeAndBalance>
                    <StyledTextAndBadge>
                      <Typography variant="medium">
                        Additional balance for future endorsements{' '}
                        <img src={InfoDarkIcon} />
                      </Typography>
                      <LoopBadge
                        badgeType="warning"
                        variant="overflow"
                        text="Optional"
                      />
                    </StyledTextAndBadge>
                    <Typography variant="large" weight="semiBold">
                      {formatCurrencyAmount(recommendedCost)}
                    </Typography>
                  </StyledTextBadgeAndBalance>
                </StyledBreakdownDetailsBodyHeader>
                <StyledBreakdownDetailsBodyFooter>
                  <Typography variant="medium" color="tertiary">
                    Total recommended balance <StyledInfoIcon src={InfoLightIcon} />
                  </Typography>
                  <Typography variant="title2" weight="medium" color="tertiary">
                    {formatCurrencyAmount(totalRecommendedCost)}
                  </Typography>
                </StyledBreakdownDetailsBodyFooter>
              </StyledBreakdownDetailsBody>
              <StyledCdBreakdownFooter>
                <Typography variant="small">
                  Your current available CD balance is{' '}
                  {formatCurrencyAmount(data.balance)}
                </Typography>
                {isSufficient ? (
                  <Typography variant="small">
                    It’s&nbsp;
                    <Typography variant="small" weight="semiBold">
                      sufficient to process your ongoing endorsements
                    </Typography>
                  </Typography>
                ) : (
                  <Typography variant="small">
                    <Typography variant="small" weight="semiBold" color="error">
                      Please add a minimum amount of&nbsp;
                      {formatCurrencyAmount(amountRequired)}&nbsp;
                    </Typography>
                    to unblock your ongoing endorsements
                  </Typography>
                )}
              </StyledCdBreakdownFooter>
            </StyledCdBreakdownDetails>
            <StyledCdBreakdownBodySeperator />
            <StyledCdBreakdownFAQ>
              <StyledCdBreakdownFAQHeader>
                <Typography variant="small">FAQ</Typography>
                <Typography variant="medium" weight="medium">
                  Why should I add extra balance for my next endorsement right
                  now? 🤔
                </Typography>
              </StyledCdBreakdownFAQHeader>
              <StyledCdBreakdownFAQItem>
                <img src={StarFourIcon} />
                <Typography variant="small">
                  This amount ensures timely endorsements and&nbsp;
                  <Typography variant="small" weight="semiBold" color="emerald">
                    swift e-card delivery&nbsp;
                  </Typography>
                  for your employees for upcoming months!
                </Typography>
              </StyledCdBreakdownFAQItem>
              <StyledCdBreakdownFAQItem>
                <img src={StarFourIcon} />
                <Typography variant="small">
                  We recommend always maintaining{' '}
                  {formatCurrencyAmount(recommendedCost)} as your CD balance
                  based on 10% of your inception amount
                </Typography>
              </StyledCdBreakdownFAQItem>
              <StyledPresentationImage src={PresentationImage} />
            </StyledCdBreakdownFAQ>
          </StyledCdBreakdownBody>
        </StyledCDBreakdownWrapper>
      </WithThemeProvider>
    </Modal>
  );
};

export default RecommendedCdBreakdownModal;
