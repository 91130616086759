export const SEGMENT_ACTIONS = {
  PAGE: {
    LOGIN_PAGE: {
      name: "login",
      properties: {
        page_category: 'HRD Login&Logout Page',
        description: 'Landed on the HR dashboard login page',
        login_status:false,
      },
    },
    OTP_PAGE: {
      name: "enterOtp",
      properties: {
        page_category: 'HRD Login&Logout Page',
        description: 'User lands on enter otp page',
        login_status:false,
      },
    },
    EMPLOYEE_LISTING_PAGE: {
      name: "employees",
      properties: {
        page_category: 'HRD Employee Page',
        description: 'Landed on the HR employees login page',
        login_status: true,
      },
    },
    POLICY_LISTING_PAGE: {
      name: "policies",
      properties: {
        page_category: 'HRD Policy Page',
        description: 'Landed on the HR policies page',
        login_status: true,
      },
    },
    VIEW_EMPLOYEE_PAGE: {
      name: "view-employee",
      properties: {
        page_category: 'HRD Employee Page',
        description: 'Landed on the view Employee Page',
        referrer_page_title: 'employees',
        login_status: true,
      },
    },
    DASHBOARD_PAGE: {
      name: "dashboard",
      properties: {
        page_category: 'HRD Dashboard Page',
        description: 'Landed on the HR dashboard login page',
        login_status: true,
      },
    },
    EDIT_EMPLOYEE_MODAL:{
      name: "edit_employee_modal",
      properties: {
        page_category: 'HRD Employee Page',
        description: 'View the modal for edit employee information',
        referrer_page_title: 'view_employee',
        patient_type:"membership",
        login_status: true,
      },
    },
    EDIT_DEPENDENT_MODAL:{
      name: "edit_dependent_modal",
      properties: {
        page_category: 'HRD Employee Page',
        description: 'View the modal for edit dependent information',
        referrer_page_title: 'view_employee',
        patient_type:"membership",
        login_status: true,
      },
    },
    DELETE_EMPLOYEE_MODAL:{
      name: "delete_employee_modal",
      properties: {
        page_category: 'HRD Employee Page',
        description: 'View the modal for delete employee information',
        referrer_page_title: 'view_employee',
        patient_type:"membership",
        login_status: true,
      },
    },
    DELETE_DEPENDENT_MODAL:{
      name: "delete_dependent_modal",
      properties: {
        page_category: 'HRD Employee Page',
        description: 'View the modal for delete dependent information',
        referrer_page_title: 'view_employee',
        patient_type:"membership",
        login_status: true,
      },
    }
  },
  CLICK: {
    OUTLOOK_LOGIN: {
      clicked_on: "outlook_login",
      description: 'User Clicks on OutlookLogin',
      page_title: "login",
      login_status: false,
    },
    GOOGLE_LOGIN: {
      clicked_on: "google_login",
      description: 'User Clicks on GoogleLogin',
      page_title: "login",
      login_status: false,
    },
    MOBILE_LOGIN: {
      clicked_on: "mobile_login",
      description: 'User Clicks on GetOTP',
      page_title: "login",
      login_status: false,
    },
    VERIFY_OTP: {
      clicked_on: "verifyOtp",
      page_title: "enterOtp",
      description: 'User Clicks on verify OTP',
      login_status: false,
    },
    RESEND_OTP: {
      clicked_on: "resendOtp",
      page_title: "enterOtp",
      description: 'User Clicks on resend OTP',
      login_status: false,
    },
    LOOP_LOGO: {
      clicked_on: "loop_logo",
      description: 'User Clicks on LoopIcon',
      login_status: true,
    },
    LOGOUT: {
      clicked_on: "logout",
      description: 'User Clicks on Logout btn',
      login_status: true,
    },
    CHANGE_REQUEST_NEXT: {
      clicked_on: "next",
      login_status: true,
    },
    CHANGE_REQUEST_PREVIOUS: {
      clicked_on: "previous",
      login_status: true,
    },
    CHANGE_REQUEST_SEARCH_BAR: {
      clicked_on: "searchBar",
      description: 'User Clicks on change request SearchBar',
      login_status: true,
    },
    NAV_DASHBOARD_LINK: {
      clicked_on: "dashboard",
      login_status: true,
    },
    NAV_POLICIES_LINK: {
      clicked_on: "policies",
      login_status: true,
    },
    NAV_EMPLOYEES_LINK: {
      clicked_on: "employees",
      login_status: true,
    },
    EMPLOYEE_TABLE_SEARCHBAR: {
      clicked_on: "searchBar",
      description: 'User Clicks on SearchBar',
      login_status: true,
    },
    EMPLOYEE_TABLE_NEXT: {
      clicked_on: "next",
      description: 'User Clicks on Next Button',
      login_status: true,
    },
    EMPLOYEE_TABLE_PREVIOUS: {
      clicked_on: "previous",
      description: 'User Clicks on Previous Button',
      login_status: true,
    },
    EMPLOYEE_TABLE_CLICKED_USER_ROW: {
      clicked_on: "user",
      description: 'User Clicks on any User row',
      login_status: true,
    },
    EMPLOYEE_TABLE_DELETE_EMPLOYEE: {
      clicked_on: "delete_employee",
      description: 'User Deletes on any User row',
      login_status: true,
    },
    // DASHBOARD_SELF_ENROL_BUTTON: {
    //   name: "DASHBOARD_SELF_ENROL_BUTTON",
    //   properties: {
    //     describe: "User has clicked on self enrol button on Dashboard Page",
    //   },
    // },
    // DASHBOARD_ADD_EMP_BUTTON: {
    //   name: "DASHBOARD_ADD_EMP_BUTTON",
    //   properties: {
    //     describe:
    //       "User has clicked on add emp button to open modal on Dashboard Page",
    //   },
    // },
    EMPLOYEE_ADD_SINGLE_EMP_BUTTON: {
      name: "EMPLOYEE_ADD_SINGLE_EMP_BUTTON",
      clicked_on: "add_employee",
      login_status: true,
      description: 'User has clicked on add single button in modal on Dashboard Page',
    },
    // EMPLOYEE_SELF_ENROL_BUTTON: {
    //   name: "EMPLOYEE_SELF_ENROL_BUTTON",
    //   properties: {
    //     describe:
    //       "User has clicked on self enrol button on Employee Listing Page",
    //   },
    // },
    VIEW_EMPLOYEE_EDIT_EMPLOYEE_DETAILS: {
      clicked_on:"edit_employee",
      page_title:"view_employee",
      login_status:true,
      patient_type:"membership"
    },
    VIEW_EMPLOYEE_EDIT_DEPENDENT_DETAILS: {
      clicked_on:"edit_employee",
      page_title:"view_employee",
      login_status:true,
      patient_type:"dependent"
    },
    VIEW_EMPLOYEE_EDIT_EMPLOYEE_MODAL_CLOSE: {
      clicked_on: "cross",
      page_title: "edit_employee_modal",
      description: 'User Clicks on cross button',
      login_status:true,
      patient_type:"dependent"
    },
    VIEW_EMPLOYEE_EDIT_DEPENDENT_MODAL_CLOSE: {
      clicked_on: "cross",
      page_title: "edit_dependent_modal",
      description: 'User Clicks on cross button',
      login_status:true,
      patient_type:"dependent"
    },
    VIEW_EMPLOYEE_EDIT_EMPLOYEE_MODAL_CANCEL: {
      clicked_on: "cancel",
      page_title: "edit_employee_modal",
      description: 'User Clicks on cancel button',
      login_status:true,
      patient_type:"dependent"
    },
    VIEW_EMPLOYEE_EDIT_DEPENDENT_MODAL_CANCEL: {
      clicked_on: "cancel",
      page_title: "edit_dependent_modal",
      description: 'User Clicks on cancel button',
      login_status:true,
      patient_type:"dependent"
    },
    VIEW_EMPLOYEE_UPDATE_EMPLOYEE: {
      clicked_on: "edit_employee",
      page_title: "edit_employee_modal",
      login_status:true,
      patient_type:"dependent"
    },
    VIEW_EMPLOYEE_ADD_DEPENDENT: {
      clicked_on: "add_dependent",
      page_title: "add_dependent_modal",
      login_status:true,
      patient_type:"dependent"
    },
    VIEW_EMPLOYEE_UPDATE_DEPENDENT: {
      clicked_on: "edit_dependent",
      page_title: "edit_dependent_modal",
      login_status:true,
      patient_type:"dependent"
    },
    VIEW_EMPLOYEE_DELETE_EMPLOYEE_DETAILS: {
      clicked_on:"delete_employee",
      page_title:"view_employee",
      login_status:true,
      patient_type:"membership"
    },
    VIEW_EMPLOYEE_DELETE_DEPENDENT_DETAILS: {
      clicked_on:"delete_dependent",
      page_title:"view_employee",
      login_status:true,
      patient_type:"dependent"
    },
    VIEW_EMPLOYEE_DELETE_EMPLOYEE_MODAL_CLOSE: {
      clicked_on: "cross",
      page_title: "delete_employee_modal",
      description: 'User Clicks on cross button',
      login_status:true,
      patient_type:"dependent"
    },
    VIEW_EMPLOYEE_DELETE_DEPENDENT_MODAL_CLOSE: {
      clicked_on: "cross",
      page_title: "delete_dependent_modal",
      description: 'User Clicks on cross button',
      login_status:true,
      patient_type:"dependent"
    },
    VIEW_EMPLOYEE_DELETE_EMPLOYEE_MODAL_CANCEL: {
      clicked_on: "cancel",
      page_title: "delete_employee_modal",
      description: 'User Clicks on cancel button',
      login_status:true,
      patient_type:"dependent"
    },
    VIEW_EMPLOYEE_DELETE_DEPENDENT_MODAL_CANCEL: {
      clicked_on: "cancel",
      page_title: "delete_dependent_modal",
      description: 'User Clicks on cancel button',
      login_status:true,
      patient_type:"dependent"
    },
    VIEW_EMPLOYEE_DELETE_DEPENDENT: {
      name: "VIEW_EMPLOYEE_DELETE_DEPENDENT",
      clicked_on: "delete_dependent",
      login_status: true,
    },
    VIEW_EMPLOYEE_DELETE_EMPLOYEE: {
      name: "VIEW_EMPLOYEE_DELETE_EMPLOYEE",
      clicked_on: "delete_employee",
      login_status: true,
    },
    VIEW_EMPLOYEE_DOWNLOAD_ECARD: {
      name: "VIEW_EMPLOYEE_DOWNLOAD_ECARD",
      clicked_on: "download_ecard",
      login_status: true,
    },
    VIEW_DEPENDENT_DOWNLOAD_ECARD: {
      name: "VIEW_DEPENDENT_DOWNLOAD_ECARD",
      clicked_on: "download_ecard",
      login_status: true,
    },
  },
  TASK: {
    LOGOUT_SUCCESS: {
      task: 'logout',
      login_status: false,
      status: true,
    },
    LOGOUT_FAIL: {
      task: 'logout',
      login_status: true,
      status: false,
    },
    CLEAR_LOGIN_DATA: {
      task: 'clear_login',
      login_status: false,
      status: true,
    },
    OUTLOOK_LOGIN_SUCCESS: {
      task: 'login',
      login_method: 'outlookLogin',
      login_status: true,
      status: true,
    },
    OUTLOOK_LOGIN_FAIL: {
      task: 'login',
      login_method: 'outlookLogin',
      login_status: false,
      status: false,
    },
    GOOGLE_LOGIN_SUCCESS: {
      task: 'login',
      login_method: 'gmailLogin',
      login_status: true,
      status: true,
    },
    GOOGLE_LOGIN_FAIL: {
      task: 'login',
      login_method: 'gmailLogin',
      login_status: false,
      status: false,
    },
    MOBILE_VERIFIED_FAIL: {
      task: 'login_verify',
      login_method: 'mobileLogin',
      login_status: false,
      status: false,
    },
    MOBILE_VERIFIED_SUCCESS: {
      task: 'login_verify',
      login_method: 'mobileLogin',
      login_status: false,
      status: true,
    },
    MOBILE_OTP_FAIL: {
      task: 'otp_verify',
      login_method: 'mobileLogin',
      login_status: false,
      status: false,
    },
    MOBILE_OTP_SUCCESS: {
      task: 'otp_verify',
      login_method: 'mobileLogin',
      login_status: false,
      status: true,
    },
    MOBILE_LOGIN_SUCCESS: {
      task: 'login',
      login_method: 'mobileLogin',
      login_status: true,
      status: true,
    },
    MOBILE_LOGIN_FAIL: {
      task: 'login',
      login_method: 'mobileLogin',
      login_status: false,
      status: false,
    },
    VIEW_EMPLOYEE_UPDATE_EMPLOYEE_SUCCESS: {
      task: "user_updated",
      login_status: true,
      status: true,
    },
    VIEW_EMPLOYEE_UPDATE_EMPLOYEE_FAIL: {
      task: "user_updated",
      login_status: true,
      status: false,
    },
    VIEW_EMPLOYEE_ADD_DEPENDENT_SUCCESS: {
      task: "user_added",
      login_status: true,
      status: true,
    },
    VIEW_EMPLOYEE_ADD_DEPENDENT_FAIL: {
      task: "user_added",
      login_status: true,
      status: false,
    },
    VIEW_EMPLOYEE_UPDATE_DEPENDENT_SUCCESS: {
      task: "dependent_updated",
      login_status: true,
      status: true,
    },
    VIEW_EMPLOYEE_UPDATE_DEPENDENT_FAIL: {
      task: "dependent_updated",
      login_status: true,
      status: false,
    },
    VIEW_EMPLOYEE_DELETE_EMPLOYEE_SUCCESS: {
      task: "employee_deleted",
      login_status: true,
      status: true,
    },
    VIEW_EMPLOYEE_DELETE_EMPLOYEE_FAIL: {
      task: "employee_deleted",
      login_status: true,
      status: true,
    },
    VIEW_EMPLOYEE_DELETE_DEPENDENT_SUCCESS: {
      task: "dependent_deleted",
      login_status: true,
      status: true,
    },
    VIEW_EMPLOYEE_DELETE_DEPENDENT_FAIL: {
      task: "dependent_deleted",
      login_status: true,
      status: false,
    },
    VIEW_EMPLOYEE_DOWNLOAD_ECARD_SUCCESS: {
      task: "employee_ecard_downloaded",
      login_status: true,
      status: true,
    },
    VIEW_EMPLOYEE_DOWNLOAD_ECARD_FAIL: {
      task: "employee_ecard_downloaded",
      login_status: true,
      status: false,
    },
    VIEW_DEPENDENT_DOWNLOAD_ECARD_SUCCESS: {
      task: "dependent_ecard_downloaded",
      login_status: true,
      status: true,
    },
    VIEW_DEPENDENT_DOWNLOAD_ECARD_FAIL: {
      task: "dependent_ecard_downloaded",
      login_status: true,
      status: false,
    },
  },
  IDENTITY: {},
};
