import React from 'react';
import useFetchEndorsementDetails from './hooks/useFetchEndorsementDetails';
import {
  StyledAlertText,
  StyledBullet,
  StyledContainer,
  StyledCountSummary,
  StyledDetailsSectionContainer,
  StyledDetailsWrapper,
  StyledEmptyStateContainer,
  StyledPolicyDetailsSection,
  StyledPolicyInfoContainer,
  StyledPolicyListItemHeaderInsurerDetails,
  StyledPolicyListItemHeaderInsurerInfo,
  StyledTableContainer,
  StyledTimelineWrapper,
} from './styles';
import { StyledBackIcon, StyledDetailsHeader } from '../../ClaimDetails/styles';
import {
  ArrowLeftIcon,
  DownloadIcon,
  EmptyStateOneGif,
  InfoDarkIcon,
  PersonPurple,
  ReceiptPurple,
} from '../../../../assets/images';
import {
  Alerts,
  CardIcon,
  LoopButton,
  PaginatedTable,
  Typography,
} from '@loophealth/loop-ui-web-library';
import { useHistory, useLocation } from 'react-router-dom';
import EndoSummaryCountComponent from '../../../containers/EndoSummaryStep/EndoSummaryCountComponent';

import Loader from '../../../atoms/Spinner';
import EndoTimeline from '../../../containers/EndoTimeline';
import EndoStatusInfoOverview from '../../../containers/EndoStatusInfo';
import ZeroStateCard from '../../../atoms/ZeroStateCard';
import { getEndoTableColumns } from './elemets';
import { IEndoStatesTimeline, IEndoTableEntry } from './types';
import { RelationConstants } from '../../../../utils/constants/constants';
import { POLICY_LIST_ITEM_TITLE } from '../../../containers/PolicyListItem/constants';
import { transformTimelineData } from './utils';
import { endoMemberDataExcelDownload } from './memberDataToExcelService';
import theme from '../../../../theme';
import useFetchAlerts from '../../../containers/TopNavigation/hooks/useFetchAlerts';
import useSegment from '../../../../utils/segment/hooks/useSegment';
import {
  extractPathName,
  trackClickEvent,
} from '../../../../utils/segment/utils';

const EndoDetails = () => {
  const history = useHistory();
  const trackClick = useSegment('click');
  const location = useLocation();
  const { loading, endorsementDetails } = useFetchEndorsementDetails();
  const { policyAlertMap, alerts } = useFetchAlerts(
    endorsementDetails?.policyId,
  );
  const policyAlerts =
    endorsementDetails && policyAlertMap.get(endorsementDetails.policyId);
  const alertText =
    'Endorsement blocked due to low CD balance. Please top up your balance to process endorsements!';
  const isAlertVisible = policyAlerts?.isEndoBlocked;
  const onBackClick = () => {
    history.goBack();
  };
  const onMemberDataDownloadClicked = () => {
    trackClick(
      trackClickEvent(
        'DownloadMember_EndoBucket_track',
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
    endorsementDetails &&
      endoMemberDataExcelDownload(endorsementDetails?.memberData);
  };
  const onTableRowClicked = (rowData: IEndoTableEntry) => {
    history.push(`employee-details?id=${rowData.userId}`, {
      referrerPage: 'EndoDetails',
      showTab:
        rowData.relationship === RelationConstants.self
          ? RelationConstants.self
          : RelationConstants.All,
    });
  };

  const onViewCDAccounts = () => {
    trackClick(
      trackClickEvent(
        'ViewCD_EndoBucket_track',
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
    history.push(`/cd-accounts`);
  };
  return (
    <StyledContainer>
      {isAlertVisible && (
        <Alerts.StatusAlert
          text={
            <Typography variant="small" weight="medium" color="error">
              {alertText}
            </Typography>
          }
          onButtonClick={onViewCDAccounts}
          buttonText="View CD Account details"
          buttonProps={{
            variant: 'outline',
            size: 'small',
            borderColor: theme.colors.planRed,
          }}
        />
      )}
      <StyledDetailsWrapper>
        <StyledDetailsHeader onClick={onBackClick}>
          <StyledBackIcon src={ArrowLeftIcon} />
          <Typography variant="medium" weight="medium">
            Back
          </Typography>
        </StyledDetailsHeader>
        {!loading && endorsementDetails && (
          <StyledPolicyDetailsSection>
            <StyledPolicyInfoContainer>
              <CardIcon imageUrl={endorsementDetails.insurerLogo} />

              <StyledPolicyListItemHeaderInsurerInfo>
                <Typography variant="medium" weight="semiBold">
                  {POLICY_LIST_ITEM_TITLE[endorsementDetails.policyType]}
                </Typography>

                <StyledPolicyListItemHeaderInsurerDetails>
                  <Typography variant="small" color="secondary">
                    {endorsementDetails.insurerName}
                  </Typography>

                  {endorsementDetails.nickName && (
                    <>
                      <StyledBullet />
                      <Typography variant="small" color="secondary">
                        {endorsementDetails.nickName}
                      </Typography>
                    </>
                  )}

                  {endorsementDetails.policyNumber && (
                    <>
                      <StyledBullet />
                      <Typography variant="small" color="secondary">
                        Policy No: {endorsementDetails.policyNumber}
                      </Typography>
                    </>
                  )}
                </StyledPolicyListItemHeaderInsurerDetails>
              </StyledPolicyListItemHeaderInsurerInfo>
            </StyledPolicyInfoContainer>
            <LoopButton
              variant="secondary"
              iconSrc={DownloadIcon}
              onClick={onMemberDataDownloadClicked}
            >
              Download Member list
            </LoopButton>
          </StyledPolicyDetailsSection>
        )}
      </StyledDetailsWrapper>
      {!loading && endorsementDetails && (
        <EndoStatusInfoOverview
          submitDate={endorsementDetails.submissionDate}
          status={endorsementDetails.status}
        />
      )}

      <>
        {loading && <Loader />}
        {!loading && endorsementDetails && (
          <>
            <StyledTimelineWrapper>
              <EndoTimeline
                timelineData={transformTimelineData(
                  IEndoStatesTimeline[endorsementDetails.status],
                  endorsementDetails.policyType,
                )}
                timelineLoading={false}
              />
            </StyledTimelineWrapper>
            <StyledDetailsSectionContainer>
              <StyledCountSummary>
                <EndoSummaryCountComponent
                  title="Endorsement cost"
                  icon={ReceiptPurple}
                  totalCount={endorsementDetails.endorsementCost}
                  details={endorsementDetails.endorsementCostDetails}
                  headerLabel="Total Cost (Deductions - Refund)"
                />
                <EndoSummaryCountComponent
                  title="Life count"
                  icon={PersonPurple}
                  totalCount={endorsementDetails.memberCount?.toString()}
                  details={endorsementDetails.memberDetails}
                  headerLabel="Total Life Count"
                />
              </StyledCountSummary>
              <StyledAlertText>
                <img src={InfoDarkIcon} alt="info" />
                <Typography variant="extraSmall" weight="medium">
                  All values are estimated figures, actual figures may slightly
                  vary
                </Typography>
              </StyledAlertText>
            </StyledDetailsSectionContainer>
            <StyledTableContainer>
              <PaginatedTable
                tableHeader={`Lives to be endorsed (${endorsementDetails?.memberData.length})`}
                count={endorsementDetails?.memberData.length}
                rowsPerPage={10}
                fullWidth
                columns={getEndoTableColumns}
                onTableRowClick={onTableRowClicked}
                data={endorsementDetails?.memberData}
                EmptyState={
                  <StyledEmptyStateContainer>
                    <ZeroStateCard
                      image={EmptyStateOneGif}
                      title={''}
                      subtitle={''}
                      note={''}
                    />
                  </StyledEmptyStateContainer>
                }
              />
            </StyledTableContainer>
          </>
        )}
      </>
    </StyledContainer>
  );
};
export default EndoDetails;
