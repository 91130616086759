import React from "react";
import "../../pages/Login.scss";
export const InputText = ({
  value,
  setValue,
  placeholder,
  isInvalid,
  invalidText = "Enter valid value",
  maxLength = "200",
}) => {
  return (
    <>
      <input
        placeholder={placeholder}
        className={`login-input ${isInvalid ? "border-red" : ""}`}
        value={value}
        maxLength={maxLength}
        onChange={(event) => setValue(event.target.value)}
      />
      {isInvalid ? <div className="error-message">{invalidText}</div> : null}
    </>
  );
};
