import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  selectPoliciesList,
  selectSelectedCompany,
} from '../../../../redux/slices/hrdRevampRedux';
import { fetchAllActivePolicies } from '../../../../redux/slices/hrdRevampRedux/thunk';

const useFetchPoliciesFromRedux = () => {
  const dispatch = useDispatch();
  const selectedCompany = useSelector(selectSelectedCompany);

  const { data, error, filters, status } = useSelector(selectPoliciesList);
  const isLoading = status === 'loading'

  useEffect(() => {
    if (selectedCompany && !isLoading) {
      dispatch(fetchAllActivePolicies(selectedCompany.id));
    }
  }, [selectedCompany?.id, dispatch]);

  return {
    policies: data,
    error,
    filters,
    status,
    isLoading,
  };
};
export default useFetchPoliciesFromRedux;
