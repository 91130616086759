import React, { useState } from 'react';
import Navbar from '../../atoms/Navbar';
import { Tabs, Tab } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import './style.scss';
import { selectCompanyPolicyList } from '../../../redux/slices/PolicyListSlice';
import { SelfEnrolPolicyForm } from './SelfEnrolPolicyForm';

export const SelfEnrol = () => {
    const history = useHistory();
    const companyPoliciesListing = useSelector(selectCompanyPolicyList);
    const policyPlans = companyPoliciesListing?.data?.policyPlans ?? [];
  
    if (companyPoliciesListing?.status === 'succeeded') {
        const processRsp = [];
        policyPlans?.map((item) => {
            let obj = { type: item['policyType'], arr: [item] };
            if (typeof processRsp.find((x) => x.type === item['policyType']) === 'undefined') {
                processRsp.push(obj);
            } else {
                let index = processRsp.findIndex((x) => x.type === item['policyType']);
                processRsp[index].arr.push(item);
                // Alternative
                // processRsp.map((type) => {
                //   if (type.type === item["policyType"]) {
                //     type.arr.push(item);
                //   }
                // });
            }
            return item;
        });
    }

    const [emails, setEmail] = useState({});
    const [uniqueEmail, setUniqueEmails] = useState({});
    const submitInviteHandler = (policyId) => {
        // Final Action is to be done on button click
    };
    const goBack = () => {
        history.goBack();
    };
    return (
        <div className="hr-dashboard">
            <Navbar />
            <div className="container main-container col-md-10 mx-auto">
                <div className="view-emp-header hover" onClick={goBack}>
                    <img className="arrow-left-emp-img" src="/assets/icons/chevron-right.svg" alt="edit-icon" />
                    <span className="heading-bold-text">Invite employees to self-enrol</span>
                </div>

                <div className="">
                    <div className="col-md-12 how_it_works">
                        <div className="how_it_works_body">
                            <div className="how_it_works_left">
                                <img className="" src="/assets/icons/how_works.svg" alt="How it works" />
                            </div>

                            <div className="how_it_works_right">
                                <h3>How it works</h3>

                                <span>
                                    Invite single or multiple employees to enrol themselves and their dependents in to
                                    insurance policies. You just need to enter their email address into policies
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row custom_row">
                    <div className="col-md-12 policy_tabs">
                        <div className="policy_nav_header border_radius_top">
                            <h3>Policies</h3>
                        </div>
                        {/* TODO: policyType */}
                        <Tabs defaultActiveKey={companyPoliciesListing?.policyType}>
                            {policyPlans?.map((policy) => {
                                return (
                                    <Tab eventKey={policy.policyType} title={policy.policyType} key={policy.id}>
                                        <SelfEnrolPolicyForm
                                            insurerImageIcon={policy?.insurerLogo ?? ''}
                                            insurerImageIconOnError={'/assets/images/cardImage.svg'}
                                            policyType={policy?.policyType}
                                            isTopUpPolicy={policy?.isTopUpPolicy}
                                            isParentalPolicy={policy?.isParentalPolicy}
                                            policyName={policy?.insurerName}
                                            sumAssuredSlabs={policy?.sumAssuredSlabs}
                                            policyId={policy?.id}
                                            emails={emails}
                                            setEmail={setEmail}
                                            uniqueEmail={uniqueEmail}
                                            setUniqueEmails={setUniqueEmails}
                                        />
                                    </Tab>
                                );
                            })}
                        </Tabs>
                        <div className="form_invite_member_btn">
                            <button onClick={submitInviteHandler}>Invite Employees</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
