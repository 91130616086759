import { IReadMe } from './types';

export const DELETE_DATA_SCHEMA = [
  {
    name: 'S No.',
    type: 'Numerical',
    allowed: '0-9',
    isMandatory: 'Optional',
  },
  {
    name: 'Employee ID',
    type: 'Alphanumeric',
    allowed: 'Permissible characters: A-Z, a-z, 0-9',
    isMandatory: 'Mandatory',
  },
  {
    name: 'Member Name',
    type: 'Alphanumeric',
    allowed: 'Permissible characters: A-Z, a-z, 0-9',
    isMandatory: 'Mandatory',
  },
  {
    name: 'Date of Leaving (DD/MMM/YYYY)',
    type: 'Date in DD/MMM/YYYY',
    allowed: 'A-Z, a-z, 0-9',
    isMandatory: 'Mandatory',
  },
  {
    name: 'Reason of Leaving',
    type: 'Alphanumeric',
    allowed: 'A-Z, a-z, 0-9',
    isMandatory: 'Mandatory',
  },
];

export const DELETE_READ_ME: IReadMe = {
  INSTRUCTIONS:
    // eslint-disable-next-line max-len
    `We've noted down some general instructions that you should help you while uploading the endorsements data. If you still have any doubts or concerns, please reach out to respective CSM.`,
  DATA_SCHEMA: DELETE_DATA_SCHEMA,
};

export const DELETE_DATA_SCHEMA_WITH_ERRORS = DELETE_DATA_SCHEMA.concat({
  name: 'Error Description',
  type: 'Alphanumeric',
  allowed: 'A-Z, a-z, 0-9, @, .',
  isMandatory: 'Informational',
});

export const DataSchemaKeyMappings = {
  name: 'Column Name',
  type: 'Type',
  allowed: 'Allowed Values',
  isMandatory: 'Is Mandatory?',
};

export const policyHeader = [
  'Policy Type',
  'Insurer Name',
  'Policy Number',
  'Policy ID',
  'Policy End Date',
];
