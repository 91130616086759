import { useSelector } from "react-redux";
import { selectPoliciesList } from "../../../../redux/slices/hrdRevampRedux";
import { useMemo } from "react";
import { IUploadedPolicywiseData } from "../../../pages/Endorsements/BulkAddMembers/types";
import { IBulkDeleteData } from "../../../pages/Endorsements/BulkDeleteMembers/types";

const useEndoSummaryData = (submittedData: IUploadedPolicywiseData | IBulkDeleteData[]) => {
  const policyList = useSelector(selectPoliciesList)?.data;
  return useMemo(() => {
    if(submittedData instanceof Array) return {};
    const policyTypeBasedListing: Record<string, number> = {};
    Object.keys(submittedData).forEach((policyId) => {
      const policyData = policyList.find(
        (policyData) => policyData.id === policyId,
      );
      if (policyData?.policyType) {
        if (policyTypeBasedListing[policyData?.policyType]) {
          policyTypeBasedListing[policyData?.policyType] +=
            submittedData[policyId].length;
        } else
          policyTypeBasedListing[policyData?.policyType] =
            submittedData[policyId].length;
      }
    });
    return policyTypeBasedListing;
  }, [policyList, submittedData]);
};

export default useEndoSummaryData;