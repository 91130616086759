import React, { useState } from 'react';
import { validateEmail, getAmountInLacs, getPolicyType } from '../../../utils/common/Utilities';

export const SelfEnrolPolicyForm = ({
    insurerImageIcon,
    insurerImageIconOnError,
    policyType,
    isTopUpPolicy = false,
    isParentalPolicy = false,
    policyName,
    sumAssuredSlabs = [],
    policyId,
    emails,
    setEmail,
    uniqueEmail,
    setUniqueEmails
}) => {
    return (
        <>
            <div className="row policy_info_header">
                <div className="col-md-6 ">
                    <div className="left_image pull-left">
                        <img
                            src={insurerImageIcon ?? ''}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src=insurerImageIconOnError;
                            }}
                            alt="edit-icon"
                            className="delete_icon"
                        />
                    </div>
                    <div className="right_text  pull-left">
                        <h4>
                            {getPolicyType(
                                policyType,
                                isTopUpPolicy,
                                isParentalPolicy
                            )}
                        </h4>
                        <span>{policyName}</span>
                    </div>
                </div>
                <div className="col-md-6">
                    <ul className="policy_right_info">
                        <li>
                            <label>Sum Insured</label>
                            {sumAssuredSlabs?.map(
                                (policySlab) => getAmountInLacs(policySlab.sumInsured)).join(', ')}
                        </li>
                        <li>
                            <label>
                                Supported Dependents
                                <span/>
                            </label>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="row margin_bottom_20">
                <div className="form_policy_slab">
                    {sumAssuredSlabs.map((slab, index) => {
                        return (
                            <EmailTagsInput
                                key={index}
                                emails={emails}
                                policyId={policyId}
                                setEmail={setEmail}
                                uniqueEmail={uniqueEmail}
                                setUniqueEmails={setUniqueEmails}
                                index={index}
                                slab={slab.sumInsured}
                            />
                        );
                    })}
                </div>
            </div>
        </>
    );
};

const EmailTagsInput = ({ emails, slab, policyId, setEmail, setUniqueEmails, uniqueEmail, index }) => {
    const handleKeyPress = (event, index, slab, policyId) => {
        var enteredEmail = event.target.value;

        if (event.code === 'Space' || event.code === 'Enter' || event.code === 'Comma') {
            if (enteredEmail.trim().length) {
                processEmailTags(slab, policyId, [enteredEmail]);
            }

            // var validDomains = ["loopheath.com", "gmail.com"];
            // var domain = enteredEmail.substring(enteredEmail.lastIndexOf("@") + 1);

            // event.target.value = "";
            setInputVal('');
        }
    };

    const processEmailTags = (slab, policyId, enteredEmail) => {
        let local_emails = { ...emails };
        let bulkEmail = { ...uniqueEmail };

        // {"policyId123": {"jatin@test.com": 1}}

        enteredEmail.forEach((element) => {
            const emailValue = element?.trim();

            if (local_emails[policyId]) {
                if (local_emails[policyId][slab]) {
                    const emailArray = local_emails[policyId][slab];
                    const emailIsValid = {
                        value: emailValue,
                        isValid: true,
                        errorMsg: ''
                    };
                    if (!validateEmail(emailValue)) {
                        emailIsValid.isValid = false;
                    } else {
                        // if (bulkEmail[policyId]?.includes(emailValue)) {
                        //   emailIsValid.isValid = false;
                        //   emailIsValid.errorMsg = "Email Already exists";
                        // } else {
                        //   // bulkEmail = {
                        //   //   [policyId]: [...bulkEmail[policyId], emailValue],
                        //   // };
                        //   bulkEmail[policyId].push(emailValue);
                        //   // console.log("bulkEmail  -> ", bulkEmail);
                        // }

                        const saveEmailObj = {
                            [emailValue]: 1
                        };

                        if (bulkEmail[policyId][emailValue]) {
                            emailIsValid.isValid = false;
                            emailIsValid.errorMsg = 'Email Already exists';
                            bulkEmail = {
                                ...bulkEmail,
                                [policyId]: {
                                    ...bulkEmail[policyId],
                                    [emailValue]: bulkEmail[policyId][emailValue] + 1
                                }
                            };
                        } else {
                            bulkEmail = {
                                ...bulkEmail,
                                [policyId]: { ...bulkEmail[policyId], ...saveEmailObj }
                            };
                        }
                    }

                    emailArray.push(emailIsValid);
                    const body = {
                        [policyId]: { [slab]: [emailArray] }
                    };

                    local_emails = {
                        ...local_emails,
                        [policyId]: {
                            ...local_emails[policyId],
                            ...body
                        }
                    };
                } else {
                    const emailIsValid = {
                        value: emailValue,
                        isValid: true
                    };
                    if (!validateEmail(emailValue)) {
                        emailIsValid.isValid = false;
                    } else {
                        // Code Is to be Used
                        // if (uniqueEmail[policyId]?.includes(emailValue)) {
                        //   emailIsValid.isValid = false;
                        //   emailIsValid.errorMsg = "Email Already exists";
                        // } else {
                        // const saveEmailObj = {
                        //   [emailValue]: 1,
                        // };

                        // check if already exist then increment value
                        const saveEmailObj = {
                            [emailValue]: 1
                        };
                        if (bulkEmail[policyId][emailValue]) {
                            emailIsValid.isValid = false;
                            emailIsValid.errorMsg = 'Email Already exists';
                            bulkEmail = {
                                ...bulkEmail,
                                [policyId]: {
                                    ...bulkEmail[policyId],
                                    [emailValue]: bulkEmail[policyId][emailValue] + 1
                                }
                            };
                        } else {
                            bulkEmail = {
                                ...bulkEmail,
                                [policyId]: { ...bulkEmail[policyId], ...saveEmailObj }
                            };
                        }
                    }
                    const body = {
                        [slab]: [emailIsValid]
                    };
                    local_emails = {
                        ...local_emails,
                        [policyId]: {
                            ...local_emails[policyId],
                            ...body
                        }
                    };
                }
            } else {
                const emailIsValid = {
                    value: emailValue,
                    isValid: true
                };

                if (!validateEmail(emailValue)) {
                    emailIsValid.isValid = false;
                } else {
                    const saveEmailObj = {
                        [emailValue]: 1
                    };

                    bulkEmail = {
                        ...bulkEmail,
                        [policyId]: { ...saveEmailObj }
                    };
                }

                const body = {
                    [policyId]: { [slab]: [emailIsValid] }
                };
                local_emails = {
                    ...local_emails,
                    ...body
                };
            }
        });

        setUniqueEmails({ ...bulkEmail });
        setEmail({ ...local_emails });
    };

    const handlePaste = (e, slab, policyId) => {
        let data = e.clipboardData.getData('Text');
        const separators = [',', ';', '\\(', '\\)', '\\*', '/', ':', '\\?', '\n', '\r', ' '];

        let finalData = data.split(new RegExp(separators.join('|'))).map((d) => d.trim());

        processEmailTags(slab, policyId, finalData);
        // finalData.forEach((email) => {
        // });

        setTimeout(() => {
            setInputVal('');
        }, 100);
        // e.clipboardData;
        // document.getElementById("email_tags").value("");
        // console.log(e.target.reset);
    };

    const handleEmailDelete = (email, index, slab, policyId) => {
        // let local_emails = { ...emails };
        // console.log("All Emails --> ", local_emails);

        const emailArray = emails[policyId][slab];
        // console.log(
        //   "email -->",
        //   email,
        //   "idex -->",
        //   index,
        //   "slab --> ",
        //   slab,
        //   "policyDetails -->  ",
        //   policyId
        // );

        emailArray.splice(index, 1);
        let bulkEmail = { ...uniqueEmail };
        if (bulkEmail[policyId][email.value]) {
            bulkEmail = {
                ...bulkEmail,
                [policyId]: {
                    ...bulkEmail[policyId],
                    [email.value]: bulkEmail[policyId][email.value] - 1
                }
            };
        }
        const body = {
            [policyId]: { [slab]: [emailArray] }
        };
        setEmail({
            ...emails,
            [policyId]: {
                ...emails[policyId],
                ...body
            }
        });
        setUniqueEmails({ ...bulkEmail });
    };

    const [inputVal, setInputVal] = useState('');
    return (
        <div className="policy_slab_rows">
            <label className="label_slab">{slab}</label>
            <div className="email_tags_main">
                {emails?.[policyId]?.[slab]?.map((email, index) => {
                    return (
                        <span
                            key={email.value}
                            className={!email.isValid ? 'invalid_tag email_tags' : 'valid_tag email_tags'}
                        >
                            <label>{email.value}</label>
                            <button onClick={() => handleEmailDelete(email, index, slab, policyId)}>X</button>
                        </span>
                    );
                })}
                <input
                    className="tab_input"
                    placeholder="Enter comma separated emails"
                    type="text"
                    id="email_tags"
                    onKeyPress={(event) =>
                        event.target.value.trim().length === 0 ? '' : handleKeyPress(event, index, slab, policyId)
                    }
                    onChange={(e) => setInputVal(e.target.value.trim().length === 0 ? '' : e.target.value.trim())}
                    onPaste={(event) => handlePaste(event, slab, policyId)}
                    value={inputVal}
                />
            </div>

            {/* <div className="copy_emails">
        <span>
          <img src="/assets/icons/coppy.svg" />
          Copy Emails
        </span>
      </div> */}
        </div>
    );
};
