import Chip from '../../components/atoms/Chip';
import {
    capitalizeFirstLetterOfEachWord,
} from './Utilities';
import {
    getStatusFromPolicyStatus,
} from './ChangeRequests';

export const transformEmployeeListData = (result) =>
    result?.map((emp_data, index) =>
        transformEmployeeData(emp_data, index));

const insuranceTypeConstants = {
    GMC: 'Group Medical Coverage',
    GPA: 'Group Personal Accident',
    COVID: 'Covid-19 Group Health',
    GTL: 'Group Term Life',
    PARENTAL: 'Parental Policy'
};
export const mapToPolicyTypeFullForm = policyTypeAbbr => insuranceTypeConstants[policyTypeAbbr];

export const policyMap = {
    "CREATE PENDING": "ADD PENDING",
    "UPDATE PENDING": "EDIT PENDING",
    "DELETE PENDING": "DELETE PENDING",
    "CREATE IN_PROGRESS": "ADD IN-PROGRESS",
    "UPDATE IN_PROGRESS": "EDIT IN-PROGRESS",
    "DELETE IN_PROGRESS": "DELETE IN-PROGRESS",
    "ADD ACCEPTED": "ACTIVE",
    "CREATE ACCEPTED": "ACTIVE",
    "UPDATE ACCEPTED": "ACTIVE",
    "DELETE ACCEPTED": "ACTIVE",
    "CREATE REJECTED": "INACTIVE",
    "UPDATE REJECTED": "INACTIVE",
    "DELETE REJECTED": "INACTIVE",
    "ACCEPTED" : "ACTIVE",
    "REJECTED": "INACTIVE",
}
export const getErolmentStatusChip = (policyStatus) => {
    const status = policyMap[policyStatus] ?? '--';
    const label = capitalizeFirstLetterOfEachWord(status.toLowerCase());
    const { backgroundColor, color } = getStatusFromPolicyStatus(status)
    return (
        <Chip color={color} backgroundColor={backgroundColor} label={label} />
    );
}

export const transformEmployeeData = (
    emp_data,
    index = 0
) => ({
    empid: emp_data?.employeeId,
    name: emp_data?.firstName,
    compName: (emp_data?.firstName || '') + ' ' + (emp_data?.lastName || ''),
    mobile: emp_data?.mobile,
    policies: emp_data?.policyCount ?? 0,
    dependents: emp_data?.dependents?.length ?? 0,
    status: emp_data?.crStatus ?? '',
    userId: emp_data?.userId,
    canEdit: emp_data?.isEditable,
    canDelete: emp_data?.isEditable,
    // data: emp_data,
    index: index,
})
