import { IApiState, IUsersSlice } from './types';

export const apiState: IApiState = {
  data: null,
  state: 'idle',
  error: '',
};

export const initialState: IUsersSlice = {
  state: 'idle',
  error: '',
  confirmationResult: null,
  userData: {
    ...apiState,
    loginMethod: '',
  },
  hrUpdate: {
    ...apiState,
  },
  runOnAuthSuccess: false,
};
