import { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { parseResponse } from '../../../../../utils/common/Utilities';
import LoopApiService from '../../../../../adaptars/LoopApiService';
import { processDateFormat } from '../utils';

const useFetchEndoDateDetails = (companyId: string) => {
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const [endoDateDetails, setEndoDateDetails] = useState<string>('');
  useEffect(() => {
    let isMounted = true;

    const fetchEndoDateDetails = async () => {
      if (companyId) {
        toggleLoading(true);
        let error = null;
        let result = null;
        [error, result] = await parseResponse(
          LoopApiService.fetchEndorsementsEndDate(companyId),
        );

        if (isMounted) {
          toggleLoading(false);
          if (error) {
            toast.error(error.message || 'Something went wrong!');
            console.error(error);
            return;
          }
          const endoLastDate = result.data?.endDate;
          const parseDate = processDateFormat(endoLastDate);
          setEndoDateDetails(parseDate);
        }
      }
    };

    fetchEndoDateDetails();

    return () => {
      isMounted = false;
    };
  }, [companyId]);

  const memoizedClaimTimelineData = useMemo(
    () => endoDateDetails,
    [endoDateDetails],
  );

  return {
    endoDateLoading: isLoading,
    endoDateDetails: memoizedClaimTimelineData,
  };
};

export default useFetchEndoDateDetails;
