import React, { useState } from 'react';
import {
  StyledCalendarIcon,
  StyledInfoCardButtonContainer,
  StyledInfoContainer,
  StyledInfoWrapper,
  StyledMainContainer,
} from './styles';
import { EndoBannerCalendar } from '../../../assets/images';
import { LoopButton, Typography } from '@loophealth/loop-ui-web-library';
import moment from 'moment';
import EndoAddEditDeleteModal from '../EndoAddEditDeleteModal';
import useSegment from '../../../utils/segment/hooks/useSegment';
import { extractPathName, trackClickEvent } from '../../../utils/segment/utils';
import { useLocation } from 'react-router-dom';
import useFetchEndoDateDetails from '../../pages/Endorsements/ListingPage/hooks/useFetchEndoDateDetails';
import { useSelector } from 'react-redux';
import { selectSelectedCompany } from '../../../redux/slices/hrdRevampRedux';
import Loader from '../../atoms/Spinner';
import EndoSubmitBanner from '../EndoSubmitBanner';

const EndoInfoBanner: React.FC = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const trackClick = useSegment('click');
  const location = useLocation<{ endoSubmitted: boolean }>();
  const isEndoSubmitted = location.state?.endoSubmitted;
  const selectedCompany = useSelector(selectSelectedCompany);
  const handleButtonClick = () => {
    setModalVisible(true);
    trackClick(
      trackClickEvent(
        'Add_Edit_Delete_Employee',
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
  };

  const { endoDateLoading, endoDateDetails } = useFetchEndoDateDetails(
    selectedCompany?.id as string,
  );
 
  const dateText = `${endoDateDetails}!`;

  return (
    isEndoSubmitted ? (
        <EndoSubmitBanner />
      ):
    endoDateLoading ? (
      <Loader />
    ) : (
    <>
      <EndoAddEditDeleteModal
        isVisible={isModalVisible}
        setIsVisible={setModalVisible}
        dateText={dateText}
      />
      <StyledMainContainer>
        <StyledInfoWrapper>
          <StyledCalendarIcon src={EndoBannerCalendar} />
          <StyledInfoContainer>
            <Typography variant="large" color="emerald" weight="semiBold">
              Submit your endorsement for {moment().format('MMM')}'{' '}
              {moment().format('YY')}!
            </Typography>
            <Typography variant="small">
              {
                'Accepting any additions and deletions for the month till '
              }
              <Typography variant="small" color="error" weight="bold">
                {dateText}
              </Typography>
            </Typography>
          </StyledInfoContainer>
        </StyledInfoWrapper>
        <StyledInfoCardButtonContainer>
          <LoopButton
            onClick={handleButtonClick}
            variant="filled"
            size="medium"
          >
            Add/Delete Lives
          </LoopButton>
        </StyledInfoCardButtonContainer>
      </StyledMainContainer>
    </>
    )
  );
};

export default EndoInfoBanner;
