import React from 'react';
import { ICancellationModal } from './types';
import { Dialog, Modal, Typography } from '@loophealth/loop-ui-web-library';
import { WarningErrorIcon } from '../../../../assets/images';
import WithThemeProvider from '../../../../theme/WithThemeProvider';
import { StyledModalContent } from './styles';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectHrUser } from '../../../../redux/slices/usersSlice';

const CancellationModal: React.FC<ICancellationModal> = ({
  isCancelModalVisible,
  setCancelModalVisible,
  onConfirmCancelClick,
  isBackBtnModalVisible,
  setBackBtnModalVisible,
  lowCDModalVisible,
  setLowCDModalVisible,
  submitEndorsements,
}) => {
  const history = useHistory();
  const selectedUser = useSelector(selectHrUser);
  return (
    <>
      <Modal
        isVisible={isCancelModalVisible}
        setIsVisible={setCancelModalVisible}
      >
        <Dialog
          variant="vertical"
          title="Pause! Are you about to re-upload complete data?"
          description="Just so you know, if you decide to correct the errors and upload everything again, you'll lose this complete data."
          icon={WarningErrorIcon}
          primaryButtonText="Yes, Re-upload"
          content={
            <WithThemeProvider>
              <StyledModalContent>
                <Typography variant="small" color="secondary">
                  But we'll email you a summary of this data at{' '}
                  {selectedUser.data?.email}.
                </Typography>
              </StyledModalContent>
            </WithThemeProvider>
          }
          onPrimaryButtonClicked={onConfirmCancelClick}
          onSecondaryButtonClicked={() => setCancelModalVisible(false)}
          secondaryButtonText="Cancel"
          buttonVariant="error"
        />
      </Modal>

      <Modal
        isVisible={isBackBtnModalVisible}
        setIsVisible={setBackBtnModalVisible}
      >
        <Dialog
          variant="vertical"
          title="Are you sure you want to exit?"
          description="Hold on! Leaving now will erase your progress, and you'll need to upload the file again."
          icon={WarningErrorIcon}
          primaryButtonText="Yes, Exit"
          onPrimaryButtonClicked={() => history.goBack()}
          onSecondaryButtonClicked={() => setBackBtnModalVisible(false)}
          secondaryButtonText="Cancel"
          buttonVariant="error"
        />
      </Modal>

      <Modal isVisible={lowCDModalVisible} setIsVisible={setLowCDModalVisible}>
        <Dialog
          variant="vertical"
          title="Alert! CD Balance running low!"
          description="Your information will be submitted, but we'll need more funds in your CD account to start processing. Want to move forward?"
          icon={WarningErrorIcon}
          primaryButtonText="Submit & Proceed"
          onPrimaryButtonClicked={submitEndorsements}
          onSecondaryButtonClicked={() => setLowCDModalVisible(false)}
          secondaryButtonText="Cancel"
          buttonVariant="error"
        />
      </Modal>
    </>
  );
};

export default CancellationModal;
