import React, { useEffect, useState } from 'react';
import './ViewEmployeeData.css';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  // selectAddDeptRequest,
  clearEmpReqState,
} from '../../redux/slices/employeesSlice';
import {
  selectUserDetails,
  selectUserDependentDetails,
  clearHrReqState,
  deleteUserDependent,
  deleteUserDetailsSelector,
  deleteUserDependentDetailsSelector,
} from '../../redux/slices/hrRequestSlice';
import { fetchUserDependents, fetchUserDetails } from '../../redux/slices/hrRequestSlice/thunks';
import {
  fetchEnrolledPolicy,
  fetchUserDependentPolicies,
} from '../../redux/slices/PolicyListSlice/thunks';
import {
  selectPolicyEnrolledfData,
  selectUserDependentPolicies,
  clearEnrolledPolicy,
  selectCompanyPolicyList,
  clearDependentPolicy,
} from '../../redux/slices/PolicyListSlice';
import { fetchAllCompanyPolicyList } from '../../redux/slices/PolicyListSlice/thunks';
import { selectHrUser } from '../../redux/slices/usersSlice';
import EmployeeCard from '../containers/NewEmployeeCard/index';
import Backdrop from '../atoms/Backdrop/Backdrop';
import { FormModal } from '../containers/AlertModal/formModal';
import { AppConstants } from '../../utils/common/AppConstants';
import useSegment from '../../utils/hooks/useSegment';
import { getTrackTaskObj } from '../../utils/common/Utilities';
import {selectSelectedCompany} from "../../redux/slices/hrdRevampRedux";

const getUserPolicies = (selectedPolicies, policyPlans) =>
  selectedPolicies.map((selectedPolicy) => {
    const policyPlan = policyPlans?.find(
      (policy) => policy.id === selectedPolicy.id,
    );
    if (policyPlan) {
      const policySlab = policyPlan?.sumAssuredSlabs?.find(
        (slab) => slab.slabId === selectedPolicy?.slabId,
      );
      return {
        ...policyPlan,
        ...(policySlab
          ? { familyDefinition: policySlab?.familyDefinition?.value }
          : {}),
        ...selectedPolicy,
      };
    }
    return selectedPolicy;
  });

const ViewEmployeeData = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const hrData = useSelector(selectHrUser);
  const selectedCompany = useSelector(selectSelectedCompany);
  const userId = location?.state?.userID ?? hrData?.data?.hrId;
  const empDetails = useSelector(selectUserDetails);
  const policyEnabled = empDetails?.data?.policyEnabled;
  const empPolicies = useSelector(selectPolicyEnrolledfData);
  const empDependentDetails = useSelector(selectUserDependentDetails);
  const empDependentPolicies = useSelector(selectUserDependentPolicies);
  const companyPoliciesListing = useSelector(selectCompanyPolicyList);
  const policyPlans = companyPoliciesListing?.data?.policyPlans ?? [];
  const deletedUser = useSelector(deleteUserDetailsSelector);
  const deletedUserDependent = useSelector(deleteUserDependentDetailsSelector);
  const [addDependent, setAddDependent] = useState(false);

  const trackTask = useSegment('track');
  const callTrackTask = (action, record) =>
    trackTask(
      getTrackTaskObj(action, location?.pathname, 'HRD EmployeePage Events', {
        record,
      }),
    );

  useEffect(() => {
    if (deletedUser?.status === 'succeeded') {
      callTrackTask('VIEW_EMPLOYEE_DELETE_EMPLOYEE_SUCCESS');
      toast.success('Deleted Successful', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      dispatch(clearHrReqState({ data: AppConstants.DELETE }));
      history.push('/employees');
    } else if (deletedUser?.status === 'failed') {
      callTrackTask('VIEW_EMPLOYEE_DELETE_EMPLOYEE_FAIL');
      toast.error(`${deletedUser?.error}`, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      dispatch(clearHrReqState({ data: AppConstants.DELETE }));
    }
  }, [deletedUser?.status]);

  useEffect(() => {
    if (deletedUserDependent?.status === 'succeeded') {
      callTrackTask('VIEW_EMPLOYEE_DELETE_DEPENDENT_SUCCESS');
      toast.success('Deleted Successful', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      dispatch(clearHrReqState({ data: AppConstants.DELETE_DEPENDENT }));
      dispatch(deleteUserDependent(deletedUserDependent));
      window.location.reload();
    } else if (deletedUserDependent?.status === 'failed') {
      callTrackTask('VIEW_EMPLOYEE_DELETE_DEPENDENT_FAIL');
      toast.error(
        `Deleted failed${
          deletedUserDependent?.error ? ` ${deletedUserDependent?.error}` : ''
        }`,
        {
          position: toast.POSITION.BOTTOM_CENTER,
        },
      );
      dispatch(clearHrReqState({ data: AppConstants.DELETE_DEPENDENT }));
    }
  }, [deletedUserDependent?.status]);

  useEffect(() => {
    if (selectedCompany?.id) {
      dispatch(fetchAllCompanyPolicyList({ companyId: selectedCompany.id }));
    }
  }, [selectedCompany?.id]);

  useEffect(() => {
    if (userId) {
      dispatch(fetchUserDetails({ userId }));
      if (policyEnabled) {
        dispatch(fetchEnrolledPolicy(userId));
      }
      dispatch(fetchUserDependents(userId));
      window.scrollTo(0, 0);
    } else {
      history.push('/');
    }
    return () => {
      dispatch(clearEnrolledPolicy());
      dispatch(clearDependentPolicy());
    };
  }, [policyEnabled, userId]);

  useEffect(() => {
    dispatch(clearHrReqState({ data: AppConstants.ADD }));
    dispatch(clearEmpReqState({ data: AppConstants.ADD_DEPENDENT }));
    dispatch(clearHrReqState({ data: AppConstants.DELETE }));
    dispatch(clearHrReqState({ data: AppConstants.DELETE_DEPENDENT }));
    return () => {
      dispatch(clearHrReqState({ data: AppConstants.ADD }));
      dispatch(clearEmpReqState({ data: AppConstants.ADD_DEPENDENT }));
      dispatch(clearHrReqState({ data: AppConstants.DELETE }));
      dispatch(clearHrReqState({ data: AppConstants.DELETE_DEPENDENT }));
      dispatch(clearHrReqState({ data: AppConstants.USER_DETAILS }));
      dispatch(clearHrReqState({ data: AppConstants.USER_DEPENDENT_DETAILS }));
    };
  }, []);

  useEffect(() => {
    if (empDependentDetails?.status === 'succeeded') {
      const dependantIds = empDependentDetails?.data?.map(
        (user) => user.userId,
      );
      dependantIds.forEach((dependantId) => {
        dispatch(fetchUserDependentPolicies(dependantId));
      });
      dispatch(fetchEnrolledPolicy(userId));
    }
  }, [empDependentDetails?.status]);

  return (
    <div className="hr-dashboard">
      {empDetails?.state === 'loading' && <Backdrop />}
      <div className="">
        <div
          className="view-emp-header hover"
          onClick={() => {
            history.goBack();
          }}
        >
          <img
            className="arrow-left-emp-img"
            src="/assets/icons/chevron-right.svg"
            alt="edit-icon"
          />
          <span className="heading-bold-text">Employee Details</span>
        </div>
        <EmployeeCard
          employee={{ ...empDetails?.data, hrId: hrData?.data?.hrId }}
          userPolicies={getUserPolicies(empPolicies?.data ?? [], policyPlans)}
          deletedUser={deletedUser}
        />
        <div className="view-emp-header hover space-bw">
          <span className="subheading-bold-text">
            {`dependents ${
              empDependentDetails?.status !== 'loading'
                ? `(${empDependentDetails?.data?.length ?? 0})`
                : ''
            }`}
          </span>
          <button
            className="add-dependent"
            disabled={!empDetails?.data?.isEditable}
            onClick={() => setAddDependent(true)}
          >
            Add Dependent
          </button>
        </div>
        {empDependentDetails?.status === 'loading' ? (
          <div className="view-emp-header min-h-100 hover items-center justify-center">
            Loading Dependent...
          </div>
        ) : empDependentDetails?.status === 'succeeded' ? (
          empDependentDetails?.data?.map((dependent) => (
            <EmployeeCard
              key={dependent.userId}
              employee={{
                ...dependent,
                hrId: hrData?.data?.hrId,
                employeeId: empDetails?.data?.employeeId,
                parentId: empDetails?.data?.userId,
                isEditable: empDetails?.data?.isEditable,
              }}
              userPolicies={getUserPolicies(
                dependent?.userId && empDependentPolicies?.data
                  ? empDependentPolicies?.data[dependent?.userId] ?? []
                  : [],
                policyPlans,
              )}
              deletedUser={deletedUserDependent}
              isDependent
            />
          ))
        ) : null}
        {addDependent && (
          <FormModal
            title="Dependents Information"
            showModal={addDependent}
            operation={AppConstants.ADD_DEPENDENT}
            setShowModal={setAddDependent}
            buttonText2={'Save and add another'}
            buttonText1={'Save'}
            id={empDetails?.data?.userId}
            userDetails={empDetails?.data}
            hrId={hrData?.data?.hrId}
            companyId={empDetails?.data?.employer}
          />
        )}
      </div>
    </div>
  );
};
export default ViewEmployeeData;
