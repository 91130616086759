import React, {useEffect, useState} from "react";
import {parseResponse} from "../../../../../utils/common/Utilities";
import LoopApiService from "../../../../../adaptars/LoopApiService";
import {transformClaimsListForUI} from "../utils";
import {IDateRange} from "../types";

const useFetchClaims = (searchTerm: string, selectedDateRange: IDateRange, selectedClaimsType: string[]) => {
    const [isLoading, toggleLoading] = useState<boolean>(false);
    const [claimsList, setClaimsList] = React.useState<Record<string, unknown>[]>([]);

    useEffect(() => {
        fetchClaimsList();
    }, [searchTerm, selectedDateRange, selectedClaimsType])

    const fetchClaimsList = async (): Promise<void> => {
        const reqData = {
            edate: selectedDateRange.endDate,
            sdate: selectedDateRange.startDate,
            search: searchTerm,
            type: selectedClaimsType,
            sortDirection: 'desc'
            /*policyNo: selectedPolicyNo,*/
        }
        toggleLoading(true);
        const [error, result] = await parseResponse(LoopApiService.fetchClaimsListAPI(reqData));
        toggleLoading(false);
        if (error) {
            console.error(error);
            return
        }
        const claims = transformClaimsListForUI(result.data.claims);
        setClaimsList(claims)
    }
    return {isLoading, claimsList}
}
export default useFetchClaims;
