import React, { lazy, Suspense, useEffect, useState } from 'react';
import Navbar from '../../../atoms/Navbar';
import { LoopButton, Typography } from '@loophealth/loop-ui-web-library';
import {
  StyledAnalyticsContainer,
  StyledDownloadReportContainer,
  StyledHeaderContainer,
  StyledLoaderContainer,
  StyledSummaryHeader,
} from './styles';
import LoopApiService from '../../../../adaptars/LoopApiService';
import Spinner from '../../../atoms/Spinner/Spinner';
import { DownloadIcon } from '../../../../assets/images';
import { downloadFile } from '../../../../utils/common/Utilities';
import { toast } from 'react-toastify';

const AnalyticsOverviewComponent = lazy(() =>
  import('./AnalyticsOverviewComponent'),
);
const AnalyticsReportComponent = lazy(() =>
  import('./AnalyticsReportComponent'),
);

export const AnalyticsLegacy = () => {
  const [claimsData, setClaimsData] = useState();
  const [reportTypes, setReportTypes] = useState([]);
  const reportUpdatedAt = claimsData?.updatedOn
    ? `Updated ${claimsData.updatedOn}`
    : '';

  const setReportListing = (claimsResponseData) => {
    const reports = claimsResponseData.reportTypes.map((data) => {
      return { name: data, value: data };
    });
    setReportTypes(reports);
  };

  const fetchClaimsData = async () => {
    const responseData = await LoopApiService.fetchClaimsAnalyticsData();
    setClaimsData(responseData);
    if (responseData && responseData.reportTypes)
      setReportListing(responseData);
  };

  useEffect(() => {
    fetchClaimsData().then(() => 'Fetched claims data');
  }, []);

  const onReportDownloadClick = () => {
    if (!claimsData?.reportURL) {
      toast.error('Claims data not available', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }
    downloadFile(claimsData?.reportURL, 'ClaimsData.xlsx');
  };

  return (
    <>
      {claimsData ? (
        <Suspense fallback={<div />}>
          <StyledAnalyticsContainer>
            <StyledSummaryHeader>
              <StyledHeaderContainer>
                <Typography variant="title2" weight="medium">
                  Portfolio Summary
                </Typography>
                <Typography variant="small" color="secondary">
                  {reportUpdatedAt}
                </Typography>
              </StyledHeaderContainer>
              <StyledDownloadReportContainer>
                <LoopButton
                  variant="outline"
                  onClick={onReportDownloadClick}
                  iconSrc={DownloadIcon}
                  iconOrder="left"
                >
                  <Typography variant="small" weight="medium" color="emerald">
                    Download Claims Data
                  </Typography>
                </LoopButton>
              </StyledDownloadReportContainer>
            </StyledSummaryHeader>
            <AnalyticsOverviewComponent analyticsData={claimsData} />
            <AnalyticsReportComponent
              reportTypes={reportTypes}
              claimsData={claimsData}
            />
          </StyledAnalyticsContainer>
        </Suspense>
      ) : (
        <StyledLoaderContainer>
          <Spinner />
        </StyledLoaderContainer>
      )}
    </>
  );
};
