import { utils } from 'xlsx-js-style';
import { IPolicyListItemType } from '../../../../../redux/slices/hrdRevampRedux/types';
import { capitalizeFirstLetterOfEachWord } from '../../../../../utils/common/Utilities';
import {
  ErrorKeyMap,
  KEY_NAME_MAP,
} from '../../../../containers/ValidationsScreen/constants';
import { IRejectedDeleteEntity } from '../types';
import { DELETE_DATA_SCHEMA_WITH_ERRORS } from './constant';
import {
  DataSchemaBody,
  DataSchemaHeader,
  GeneralInstructionsBody,
  GeneralInstructionsHeader,
  createCell,
  createErrorCell,
  createErrorDescriptionCell,
  createPoliciesBody,
  readmePolicyHeader,
} from './static';

const createReadMeDataSheet = (policiesData: IPolicyListItemType[]) => {
  const PoliciesBody = createPoliciesBody(policiesData);
  const wsData = [
    GeneralInstructionsHeader,
    GeneralInstructionsBody,
    [],
    [],
    readmePolicyHeader,
    ...PoliciesBody,
    [],
    [
      {},
      {
        v: 'DATA SCHEMA',
        t: 's',
        s: {
          font: {
            color: { rgb: '000000' },
            bold: true,
          },
          fill: { fgColor: { rgb: 'ffffff' } },
          alignment: {
            vertical: 'center',
            horizontal: 'left',
            wrapText: true,
          },
        },
      },
    ],
    DataSchemaHeader,
    ...DataSchemaBody,
  ];

  return utils.aoa_to_sheet(wsData, {
    cellDates: true,
    dateNF: 'dd mmm yyyy',
  });
};

export const generateErrorTemplate = (
  rejectedEntries: IRejectedDeleteEntity[],
  policiesData: IPolicyListItemType[],
) => {
  const wb = utils.book_new();
  const readMeSheet = createReadMeDataSheet(policiesData);
  readMeSheet['!cols'] = [
    { width: 10 },
    { width: 15 },
    { width: 25 },
    { width: 35 },
    { width: 25 },
    { width: 20 },
    { width: 20 },
  ];
  readMeSheet['!rows'] = [{ hpt: 22 }, { hpt: 40 }];
  const merge = [{ s: { r: 1, c: 2 }, e: { r: 1, c: 4 } }];
  readMeSheet['!merges'] = merge;
  utils.book_append_sheet(wb, readMeSheet, 'READ ME');
  const sheetName = 'Delete from All Policies';
  const sheet = createDeleteErrorSheet(rejectedEntries);
  if (sheet) {
    sheet['!cols'] = Array.from({ length: 6 }, () => ({
      width: 35,
    }));
    sheet['!rows'] = [{ hpt: 22 }, { hpt: 30 }];
    utils.book_append_sheet(wb, sheet, sheetName);
  }
  return wb;
};

export const getDeleteSheetColumns = () => {
  const infoHeader = DELETE_DATA_SCHEMA_WITH_ERRORS.map((column) => ({
    v: column.isMandatory,
    t: 's',
    s: {
      font: {
        color: {
          rgb:
            column.isMandatory === 'Optional'
              ? 'b45f07'
              : column.isMandatory === 'Informational'
              ? 'cc0100'
              : '274e12',
        },
        italic: true,
      },
      fill: {
        fgColor: {
          rgb:
            column.isMandatory === 'Optional'
              ? 'fce5cd'
              : column.isMandatory === 'Informational'
              ? 'f3cccb'
              : 'd9ead3',
        },
      },
      alignment: {
        vertical: 'center',
        horizontal: 'center',
        wrapText: true,
      },
    },
  }));
  const columnHeader = DELETE_DATA_SCHEMA_WITH_ERRORS.map((column: any) => ({
    v: column.name,
    t: 's',
    s: {
      font: { color: { rgb: 'ffffff' }, bold: true },
      fill: {
        fgColor: {
          rgb:
            column.isMandatory === 'Optional'
              ? 'b45f07'
              : column.isMandatory === 'Informational'
              ? `990001`
              : '274e12',
        },
      },
      alignment: {
        vertical: 'center',
        horizontal: 'center',
        wrapText: true,
      },
    },
  }));
  return { infoHeader, columnHeader };
};

const formatErrorsCell = (value: any) => {
  try {
    const parsedErrors = JSON.parse(value);
    return Object.entries(parsedErrors)
      .map(
        ([key, errorValue]) =>
          `${KEY_NAME_MAP[key] ?? key} - ${capitalizeFirstLetterOfEachWord(
            errorValue,
          )}`,
      )
      .join('\n');
  } catch (error: any) {
    return '';
  }
};

const mapDataToSheet = (formattedData: any[]) => {
  try {
    let result: any[] = [];
    formattedData.forEach((d: any) => {
      if (!d || typeof d !== 'object') {
        return;
      }
      let errors: Record<string, string> = {};
      const errorKey = 'errors';
      if (typeof d[errorKey] !== 'undefined') {
        errors = JSON.parse(d[errorKey]);
      }

      result.push(
        Object.keys(d).map((key: string) => {
          if (key === 'errors') {
            return createErrorDescriptionCell(formatErrorsCell(d[key]));
          } else {
            const errorKey = ErrorKeyMap[key] ?? key;
            if (errors && errors[errorKey]) {
              return createErrorCell(capitalizeFirstLetterOfEachWord(d[key]));
            }
            return createCell(d[key]);
          }
        }),
      );
    });
    return result;
  } catch (error) {
    return [];
  }
};

export const createDeleteErrorSheet = (
  rejectedEntries: IRejectedDeleteEntity[],
) => {
  const { infoHeader, columnHeader } = getDeleteSheetColumns();
  const formattedData = rejectedEntries.map((item, index) => {
    return {
      's.no': index + 1,
      employeeId: item.employeeId ?? '',
      name: item.name ?? '',
      dateOfLeaving: item.dateOfLeaving ?? '',
      reasonOfLeaving: item.reason ?? '',
      errors: item.error,
    };
  });
  const sheetData = [
    infoHeader,
    columnHeader,
    ...mapDataToSheet(formattedData),
  ];

  return utils.aoa_to_sheet(sheetData, {
    cellDates: true,
    dateNF: 'dd mmm yyyy',
  });
};
