import {
  StyledContainer,
  StyledDescription,
  StyledDottedSeparator,
  StyledEndoCostContainer,
  StyledEndoDataRow,
  StyledInsurerLogo,
  StyledPolicyContainer,
  StyledPolicyCostData,
  StyledPolicyCostSection,
  StyledPolicyHeader,
  StyledPolicyItemAlertsContainer,
  StyledPolicyList,
  StyledPolicyListContainer,
  StyledPolicyName,
  StyledPolicyType,
  StyledRightContainer,
  StyledTotalCostHeader,
} from './styles';
import { Typography } from '@loophealth/loop-ui-web-library';
import { EndoAddCostIcon } from '../../../../../assets/images';
import React from 'react';
import { StyledVerticalSpacer } from '../../../ClaimDetails/styles';
import { formatCurrencyAmount } from '../../../../../utils/common/Utilities';
import Loader from '../../../../atoms/Spinner';

const EndoCDAccountWiseCost: React.FC<{ endoCostData: any, mode: 'ADD' | 'OFFBOARD' }> = ({
  endoCostData,
  mode
}) => {
  return (
    <StyledPolicyContainer>
      <StyledPolicyHeader>
        <StyledPolicyName>
          <StyledInsurerLogo src={endoCostData.insurerLogo} />
          <Typography variant="small" weight="medium">
            {endoCostData.insurer}
          </Typography>
        </StyledPolicyName>
        <StyledPolicyType>
          <Typography variant="small" color="secondary">
            CD Balance:{' '}
            <Typography
              variant="small"
              weight="medium"
              color={endoCostData.isCDLow ? 'error' : 'primary'}
            >
              {formatCurrencyAmount(endoCostData.balance)}
            </Typography>
          </Typography>
        </StyledPolicyType>
      </StyledPolicyHeader>
      <StyledPolicyList>
        {endoCostData.isCDLow && (
          <StyledPolicyItemAlertsContainer>
            <Typography variant="small" weight="medium" color="error">
              CD Balance low! Please add more funds to process these
              endorsements
            </Typography>
          </StyledPolicyItemAlertsContainer>
        )}
        {endoCostData.policyData.map((policyData: any, index: number) => (
          <StyledPolicyCostSection key={index}>
            <StyledPolicyCostData>
              <Typography variant="small" color="secondary">
                Policy Type
              </Typography>
              <Typography variant="small" weight="medium">
                {policyData.policyType}
              </Typography>
            </StyledPolicyCostData>
            <StyledVerticalSpacer />
            {mode === 'ADD' && <><StyledPolicyCostData>
              <Typography variant="small" color="secondary">
                Lives Added
              </Typography>
              <Typography variant="small" weight="medium">
                {policyData.lives}
              </Typography>
            </StyledPolicyCostData>
            <StyledVerticalSpacer /></>}
            <StyledPolicyCostData>
              <Typography variant="small" color="secondary">
                Endorsement {mode === 'ADD' ? "Cost" : 'Refund'}
              </Typography>
              <Typography variant="small" weight="medium">
                {formatCurrencyAmount(policyData.costOfEndorsement)}
              </Typography>
            </StyledPolicyCostData>
          </StyledPolicyCostSection>
        ))}
      </StyledPolicyList>
    </StyledPolicyContainer>
  );
};
const SubmitEndo: React.FC<{
  endoCostList: any[];
  totalCost: number;
  totalLives: number;
  mode: 'ADD' | 'OFFBOARD';
}> = ({ endoCostList, totalCost, totalLives, mode }) => {
  return (
    <StyledContainer>
      <StyledPolicyListContainer>
        {endoCostList?.length ? (
          endoCostList.map((endoCostData: any, index: number) => (
            <EndoCDAccountWiseCost mode={mode} key={index} endoCostData={endoCostData} />
          ))
        ) : (
          <Loader />
        )}
      </StyledPolicyListContainer>
      <StyledEndoCostContainer>
        <StyledTotalCostHeader>
          <img src={EndoAddCostIcon} />
          <Typography variant="medium" weight="medium">
            Total Endorsement {mode === 'ADD' ? 'Cost' : 'Refund'}
          </Typography>
        </StyledTotalCostHeader>
        <StyledDottedSeparator />
        <StyledEndoDataRow>
          <Typography variant={'small'} color={'secondary'}>
            Total Lives {mode === 'ADD' ? 'Added' : 'Deleted'}
          </Typography>
          <Typography variant={'medium'} weight="medium">
            {totalLives}
          </Typography>
        </StyledEndoDataRow>
        <StyledEndoDataRow>
          <Typography variant={'small'} color={'secondary'}>
            Total Endorsement {mode === 'ADD' ? 'Cost' : 'Refund'}
          </Typography>
          <Typography variant={'medium'} weight="medium">
            {formatCurrencyAmount(totalCost)}
          </Typography>
        </StyledEndoDataRow>
        <StyledDescription>
          <Typography variant="small" color="secondary">
            Final amount includes GST, but it could change after the endorsement
            is processed.
          </Typography>
        </StyledDescription>
      </StyledEndoCostContainer>
      <StyledRightContainer />
    </StyledContainer>
  );
};
export default SubmitEndo;
