import NameIcon from '../../components/atoms/NameIcon';
import Chip from '../../components/atoms/Chip';
import {
  colorLibrary,
  getDisplayTextFromUpper,
  capitalizeFirstLetterOfEachWord
} from './Utilities';
import {
  getSourceStyles
} from './ChangeRequests';
import { DateFormatUtils } from '@loophealth/loop-ui-web-library';

export const getDataTableColumns = (handleEditClick, handleDeleteClick) => {
  return [
    {
      name: 'Employee ID',
      selector: (row) => row.empid,
      // sortable: true
    },
    {
      name: 'Name',
      selector: (row) => row.name,
      minWidth: '230px',

      cell: (record) => (
        <div
          className="employee_name_initials"
          onClick={() => handleEditClick(record)}
        >
          <NameIcon
            color={colorLibrary[record.index % colorLibrary.length]}
            name={`${record?.compName} `}
          />
          <div className="employee_comp_name">{record?.compName}</div>
        </div>
      ),
      // sortField: true,
    },
    {
      name: 'Mobile No.',
      selector: (row) => row.mobile,
    },
    {
      name: 'Policies',
      selector: (row) => row.policies,
      sortable: true,
    },
    {
      name: 'Dependents',
      selector: (row) => row.dependents,
      sortable: true,
    },
    {
      name: 'Actions',

      cell: (record) => (
        <>
          {/* {record.canEdit ? (
            <img
              src="/assets/icons/edit.svg"
              onClick={() => handleEditClick(record)}
              alt="edit-icon"
              className="edit_icon"
            />
          ) : null} */}

          {record.canDelete ? (
            <img
              src="/assets/icons/delete.svg"
              onClick={() => handleDeleteClick(record)}
              alt="edit-icon"
              className="delete_icon"
            />
          ) : null}
        </>
      ),
      // ignoreRowClick: false,
      allowOverflow: true,
      button: true,
    },
  ];
};

export const getChangeRequestTableColumns = () => {
  return [
    {
      name: 'Name',
      selector: (row) => row.name,
      headerClassName: 'text-start',
      minWidth: '130px',
      cell: ({name}) => (<div className='text-start'>{capitalizeFirstLetterOfEachWord(name)}</div>),
    },
    {
      name: 'Mobile No.',
      minWidth: '115px',
      headerClassName: 'text-start',
      selector: (row) => row.mobile === '' ? '--' : row.mobile ?? '--',
    },
    {
      name: 'Created on',
      selector: (row) => row.createdAt,
      cell: ({ createdAt }) => (
        <div className='text-start'>{new DateFormatUtils().formatDateTime(createdAt?._seconds) ?? '--'}</div>
      ),
    },
    {
      name: 'Source',
      selector: (row) => row.source,
      cell: ({ source }) => {
        const value = source;
        const {
          backgroundColor,
          color,
          label=getDisplayTextFromUpper(value ?? '')
        } = getSourceStyles(value?.toLocaleLowerCase());
        if (!!value) {
            return <Chip color={color} backgroundColor={backgroundColor} label={label} />;
        }
        return '--';
      },
    },
    {
      name: 'Operation type',
      selector: (row) => row.operationType,
      cell: ({ operationType }) => (
        <div className='text-start'>{operationType}</div>
      ),
    },
    {
      name: 'Insurer name',
      selector: (row) => row.insurer,
      headerClassName: 'text-start',
      cell: ({ insurer }) => (
        <div className='text-start'>{insurer}</div>
      ),
    },
    {
      name: 'Policy type',
      selector: (row) => row.policyType,
      cell: ({ policyType }) => (
        <div>{policyType}</div>
      ),
    },
    {
      name: 'Relationship',
      selector: (row) => row.relationship,
      cell: ({ relationship }) => (
        <div className='text-start'>{relationship}</div>
      ),
    },
  ];
};
