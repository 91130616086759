import React from 'react';
import { Typography } from '@loophealth/loop-ui-web-library';
import {
  StyledHeader,
  StyledInformationContainer,
  StyledInformationParagraph,
  StyledLink,
} from './styles';
import { DownArrowIcon, WaitListGIF } from '../../../../assets/images';

const Header = () => {
  return (
    <StyledHeader>
      <img src={WaitListGIF} alt="Wait-list gif" />
      <StyledInformationContainer>
        <Typography variant="large">Upgrading Your HR Dashboard 🪄</Typography>
        <StyledInformationParagraph>
          <Typography variant="medium" color="secondary">
            The HR dashboard is temporarily unavailable as we're crafting a
            better, more intuitive experience for you.
          </Typography>
          <Typography variant="medium" weight="semiBold">
            For any help during this period, please get in touch with your Loop
            account manager.
          </Typography>
        </StyledInformationParagraph>
      </StyledInformationContainer>
      <StyledLink href="#video">
        <Typography variant="small" weight="medium" color="emerald">
          Get a glimpse of the upcoming dashboard
        </Typography>
        <img src={DownArrowIcon} alt="Down arrow" aria-hidden="true" />
      </StyledLink>
    </StyledHeader>
  );
};

export default Header;
