import { IApiState, ICompanySlice } from './types';

export const apiState: IApiState = {
  data: null,
  status: 'idle',
  error: '',
};

export const initialState: ICompanySlice = {
  companyDetails: {
    ...apiState,
  },
  companyConfig: {
    ...apiState,
  },
  dependentListData: {
    ...apiState,
  },
};
