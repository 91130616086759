import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import LoopApiServiceContext from '../../../contexts/LoopApiServiceContext';
import './EmployeeCard.scss';
import { useDispatch } from 'react-redux';
import { AddModal } from '../../containers/AlertModal/addModal';
import { FormModal } from '../AlertModal/formModal';
import {capitalizeFirstLetterOfEachWord, parseResponse, showErrorToast} from '../../../utils/common/Utilities';
import { colorLibrary, getAmountInLacs } from '../../../utils/common/Utilities';
import {
  mapToPolicyTypeFullForm,
} from '../../../utils/common/employee';
import {
  deleteUserDetails,
  deleteUserDependentDetails,
} from '../../../redux/slices/hrRequestSlice/thunks';
import useSegment from '../../../utils/hooks/useSegment';
import { getTrackClickObj, getTrackTaskObj } from '../../../utils/common/Utilities';
import { SEGMENT_ACTIONS } from '../../../utils/common/SegmentActionConstants';
import { DateFormatUtils } from '@loophealth/loop-ui-web-library';
import LoopApiService, {fetchECard} from "../../../adaptars/LoopApiService";

function parseIntElseStr(x, base=10) {
  const parsed = parseInt(x, base);
  if (isNaN(parsed)) { return x; }
  return parsed;

}

const EmployeeCard = ({
    employee,
    userPolicies=[],
    isDependent=false,
    deletedUser,
}) => {
  const { employeeId, employer:companyId, userId, hrId, isEditable=true } = employee;
  const dispatch = useDispatch();
  const firebaseContext = useContext(LoopApiServiceContext);
  const [downloadEcardInProgress, setDownloadEcardInProgress] = useState(false);
  const [editEmployeeModal, setEditEmployeeModal] = useState(false);
  const [isExpandedView, setIsExpandedView] = useState(!isDependent);
  const deletedUserStatus = deletedUser?.status

  const [delEmployee, setDelEmployee] = useState(false);

  const trackPage = useSegment('page');
  const trackClick = useSegment('click');
  const trackTask = useSegment('track');
  const location = useLocation();
  const callTrackClick = (action, record, otherProperties)=> trackClick(getTrackClickObj(
    action, location?.pathname, 'HRD EmployeePage Events', {record, ...otherProperties}))
  const callTrackTask = (action, record, otherProperties)=> trackTask(getTrackTaskObj(
    action, location?.pathname, 'HRD EmployeePage Events', {record, ...otherProperties}));

  useEffect(() => {
    if (deletedUser?.status === 'failed' || deletedUser?.status === 'succeeded') {
      setDelEmployee(false);
    }
  }, [deletedUser?.status]);

  const handleDeleteUser = (leavingDate, type) => {
    if (deletedUserStatus === 'loading') return;
    if (isDependent) {
      callTrackClick('VIEW_EMPLOYEE_DELETE_DEPENDENT', { empid: userId, compId: companyId })
      dispatch(deleteUserDependentDetails({
        userId,
        leavingDate: new Date().getTime() / 1000,
        relationship: employee.relationship,
        employer: employee.employer
      }));
    } else {
      callTrackClick('VIEW_EMPLOYEE_DELETE_EMPLOYEE', { empid: userId, compId: companyId })
      dispatch(
        deleteUserDetails({
          userId,
          leavingDate: moment(leavingDate).unix(),
          relationship: employee.relationship,
          employer: employee.employer
        }),
      );
    }
  };

  const backgroundColor = colorLibrary[
    (employee?.firstName?.[0]?.charCodeAt(0) + employee?.lastName?.[0]?.charCodeAt(0)) % colorLibrary.length
  ]

  const onDownloadECardClicked = async (policyId) => {
    if (!userId || !policyId) {
      showErrorToast('Unable to download E-Card');
      return;
    }
    setDownloadEcardInProgress(true);
    const [error, result] = await parseResponse(LoopApiService.fetchECard(userId, policyId));
    if (error) {
      showErrorToast(error.toString());
      return;
    }
    try {
      const pdfFileData = result.data.pdfFileData;
      const base64Data = pdfFileData.split(',')[1];
      setDownloadEcardInProgress(false);
      const downloadLink = document.createElement('a');
      downloadLink.href = 'data:application/octet-stream;charset=utf-16le;base64,' + base64Data;
      downloadLink.download = 'E-Card.pdf';
      downloadLink.click();
    } catch (e) {
      showErrorToast('Unable to download E-Card');
    }
  }

  return (
    <div className="emp-wrapper bg-white">
      <div className="row">
        <div className="col-md-12 my-2 flex space-bw">
          <div className="flex">
            <div className="logo uppercase" style={{ backgroundColor }}>
              {capitalizeFirstLetterOfEachWord(`${(employee?.firstName?.[0] ?? '') + (employee?.lastName?.[0] ?? '')}`)}
            </div>
            <div className="px-3">
              <div className="heading">{`${employee?.firstName ?? ''} ${employee?.lastName ?? ''}`}</div>
              <div className="sub-heading">{employee?.relationship}</div>
            </div>
          </div>
          <div className={`flex flex-gap-3 items-baseline font-size-body ${!isEditable? 'grey-2' : 'teal'}`}>
            <button className="a-button" disabled={!isEditable}
              onClick={()=> {
                if (isEditable) {
                  if (isDependent) {
                    callTrackClick('VIEW_EMPLOYEE_EDIT_DEPENDENT_DETAILS', {}, {
                      clicked_dependent_id: employeeId,
                      clicked_dependent_parent_id: userId,
                      compId: companyId,
                    })
                  } else {
                    callTrackClick('VIEW_EMPLOYEE_EDIT_EMPLOYEE_DETAILS', { empid: userId, compId: companyId })
                  }
                  setEditEmployeeModal(true)
                  const page_id = isDependent ? 'EDIT_DEPENDENT_MODAL' : 'EDIT_EMPLOYEE_MODAL';
                  const currentPageAction = SEGMENT_ACTIONS.PAGE[page_id];
                  trackPage({
                    name: currentPageAction?.name ?? page_id,
                    properties: {
                      ...(currentPageAction?.properties ?? {}),
                      page_url: location?.pathname,
                      page_id,
                    },
                  });
                }
              }}
            >
              Edit
            </button>
            <span>|</span>
            <button className="a-button" disabled={!isEditable}
              onClick={()=> {
                if (isEditable) {
                  if (isDependent) {
                    callTrackClick('VIEW_EMPLOYEE_DELETE_DEPENDENT_DETAILS', {}, {
                      clicked_dependent_id: employeeId,
                      clicked_dependent_parent_id: userId,
                      compId: companyId,
                    })
                  } else {
                    callTrackClick('VIEW_EMPLOYEE_DELETE_EMPLOYEE_DETAILS', { empid: userId, compId: companyId })
                  }
                  setDelEmployee(true)
                  const page_id = isDependent ? 'DELETE_DEPENDENT_MODAL' : 'DELETE_EMPLOYEE_MODAL';
                  const currentPageAction = SEGMENT_ACTIONS.PAGE[page_id];
                  trackPage({
                    name: currentPageAction?.name ?? page_id,
                    properties: {
                      ...(currentPageAction?.properties ?? {}),
                      page_url: location?.pathname,
                      page_id,
                    },
                  });
                }
              }}
            >
              Delete
            </button>
          </div>
        </div>
        <div className="employee-table-wrapper col-md-12 my-2">
          <table>
            <thead>
              <tr>
                <th>Employee ID</th>
                <th>Gender</th>
                <th>DOB (DD MMM YYYY)</th>
                <th>Contact Number</th>
                {isDependent ? (<>
                  <th className="ending-col-dep"></th>
                </>) : (<>
                  <th className="ending-col">Email</th>
                  <th className="ending-col">Annual CTC</th>
                  <th className="ending-col">Joining Date</th>
                </>)}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{employeeId ?? '-'}</td>
                <td>{employee?.gender ?? '-'}</td>
                <td>{employee?.dob ? new DateFormatUtils().formatDateTime(employee.dob._seconds) : '-'}</td>
                <td>{employee?.mobile ?? '-'}</td>
                {isDependent ? (<>
                  <td className="ending-col-dep"></td>
                </>) : (<>
                  <td className="ending-col">{employee?.email ?? '-'}</td>
                  <td className="ending-col">{employee?.ctc ? parseIntElseStr(employee.ctc) : '-'}</td>
                  <td className="ending-col">{employee?.joiningDate ?
                      new DateFormatUtils().formatDateTime(employee.joiningDate._seconds) : '-'}</td>
                </>)}
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-md-12 flex space-bw">
          <div className='flex slate font-size-body bold'>
            {`Enrolled in ${userPolicies.length} ${userPolicies.length === 1 ? 'Policy' : 'Policies'}`}
          </div>
          <div className='flex flex-gap-3 items-baseline'>
            <button className='a-button teal uppercase letter-spacing-1 font-size-label bold' onClick={()=> setIsExpandedView((isExpandedView)=>!isExpandedView)}>
              policy details and e-cards {isExpandedView ? '↑' : '↓'}
            </button>
          </div>
        </div>
        {isExpandedView ? (
          <div className="col-md-12 mt-3">
            <div className="policy-view p-2 bg-grey border-radius-3 font-size-label">
              {userPolicies.length > 0 ? (
                <table className='row items-center'>
                  <thead>
                    <tr>
                      <th className="grey">Policy</th>
                      <th className="grey">Sum Insured</th>
                      <th className="grey">Valid Till (DD MMM YYYY)</th>
                      <th className="grey">E-Card</th>
                    </tr>
                  </thead>
                  <tbody className='font-size-body'>
                    {userPolicies.map(policy => (
                      <tr key={policy.id}>
                        <td>
                          <div className='flex'>
                            <div className='policy-logo'
                              style={{
                                backgroundImage: `url("${policy?.insurerLogo ?? '/assets/images/cardImage.svg'}")`,
                                flex: '0 0 40px'
                              }}
                            />
                            <div className='pl-3'>
                              <div className='font-size-body'>{mapToPolicyTypeFullForm(policy?.policyType)}</div>
                              <div className='font-size-label grey'>{policy?.insurerName}</div>
                            </div>
                          </div>
                        </td>
                        <td>{policy?.sumInsured ? getAmountInLacs(policy.sumInsured, ' Lac', ' Lacs') : '-'}</td>
                        <td>{policy?.policyEndDate ? new DateFormatUtils().formatDateTime(policy.policyEndDate?._seconds) : '-'}</td>
                        <td>
                          {policy.uhid ? (
                            <button
                            className="a-button flex items-center flex-gap-3"
                            onClick={() => onDownloadECardClicked(policy.id)}
                            disabled={downloadEcardInProgress}
                          >
                            <img
                              src="/assets/icons/download_icon.svg"
                              alt="download-icon"
                              className="pull-left h-18"
                            />
                              <span className='teal bold'>
                                {downloadEcardInProgress ? 'Downloading...' : 'Download E-Card'}
                              </span>
                          </button>
                          ) : <>--</>}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : <div className='flex justify-content-center'>No Policies</div>}
            </div>
          </div>
        ) : null}
      </div>

      {delEmployee && (
        <AddModal
          showCards={false}
          showEmpDel={true}
          showDependentDel={false}
          isDependent={isDependent}
          showModal={delEmployee}
          setShowModal={setDelEmployee}
          onPressButton1={handleDeleteUser}
          onPressButton2={() => {
            setDelEmployee(false);
            setEditEmployeeModal(false);
          }}
          buttonText2={'Cancel'}
          loadingStatus={deletedUserStatus}
        />
      )}
      {editEmployeeModal && (
        <FormModal
          title="Edit Employee Information"
          showModal={editEmployeeModal}
          isDependent={isDependent}
          setShowModal={setEditEmployeeModal}
          onPressButton2={() => {
            setDelEmployee(false);
            setEditEmployeeModal(false);
          }}
          buttonText2={'Cancel'}
          buttonText1={'Update'}
          id={userId}
          hrId={hrId}
          companyId={companyId}
          userData={employee}
          userPolicies={userPolicies}
        />
      )}
    </div>
  );
};

export default EmployeeCard;
