import fb from 'firebase';
import { useHistory, useLocation } from 'react-router-dom';
import {
  getTaskByLoginMethod,
  getTrackClickObj,
  getTrackTaskObj,
} from '../../../../../utils/common/Utilities';

import { isDbCallsDeprecated } from '../../../../../utils/featureFlag';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchHrUser,
  fetchHrUserVerify,
} from '../../../../../redux/slices/usersSlice/thunks';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { IPhoneAuthError, IProfile } from '../types';
import firebase, { auth } from '../../../../../firebase';
import {
  selectConfirmationResult,
  selectHrUser,
  setClearHrData,
  setConfirmationResult,
} from '../../../../../redux/slices/usersSlice';
import { useAuth } from '../../../../../contexts/AuthContext';
import LoopApiServiceContext from '../../../../../contexts/LoopApiServiceContext';
import {
  setLoopIdToken,
  verifyEmailLink,
} from '../../../../../utils/common/CustomFirebase';
import { LoginErrorCode } from '../../types';
import useSegment from '../../../../../utils/segment/hooks/useSegment';

const useLogin = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { setLoggedInUser } = useAuth();
  const trackTask = useSegment('track');
  const trackClick = useSegment('click');
  const hrData = useSelector(selectHrUser);
  const location = useLocation<{ errorMessage?: string }>();
  const confirmationResult = useSelector(selectConfirmationResult);
  const microsoftProviderRef = useRef<fb.auth.OAuthProvider | null>(null);

  const [loading, setLoading] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
  const [error, setError] = useState<string | null>(null);
  const firebaseContext = useContext(LoopApiServiceContext);
  const [mobileVerified, setMobileVerified] = useState(false);

  const mobileNumberError =
    (location && location.state && location.state.errorMessage) || '';

  const callTrackClick = (action: string) =>
    trackClick(
      getTrackClickObj(action, location.pathname, 'HRD Login&Logout Events'),
    );
  const callTrackTask = (action: string, category: string, tried_value = '') =>
    trackTask(
      getTrackTaskObj(action, location.pathname, category, tried_value),
    );

  const loadOnRefresh = useCallback(() => {
    callTrackTask('CLEAR_LOGIN_DATA', 'HRD Login&Logout Events');
    dispatch(setClearHrData());
  }, [dispatch]);

  const signInWithMobileOtp = async () => {
    callTrackClick('MOBILE_LOGIN');
    setError('');

    if (isDbCallsDeprecated) {
      triggerOtp(); // todo: handle blocking function error of unregistered error
    } else {
      dispatch(
        fetchHrUserVerify({
          methodType: 'mobile',
          userId: mobileNumber.substring(4),
          loginMethod: 'mobile',
        }),
      );
    }
  };

  const triggerOtp = async () => {
    setLoading(true);
    setError('');
    try {
      const confirmationData = await auth.signInWithPhoneNumber(
        mobileNumber.replace('-', ''),
        window.recaptchaVerifier,
      );
      callTrackTask('MOBILE_VERIFIED_SUCCESS', 'HRD Login&Logout Events');
      dispatch(setConfirmationResult({ data: confirmationData }));
      setMobileVerified(true);
      setLoading(false);
    } catch (err) {
      const error = err as IPhoneAuthError;
      callTrackTask('MOBILE_VERIFIED_FAIL', 'HRD Login&Logout Events');
      setLoading(false);
      if (error.code === LoginErrorCode.TOO_MANY_REQUESTS) {
        setError('Too many attempts, try again later');
      } else {
        setError(error.message);
      }
      firebase.auth().signOut();
      // throw error;
    }
  };

  const signInWithOutlook = () => {
    callTrackClick('OUTLOOK_LOGIN');
    setError('');

    dispatch(setConfirmationResult({ data: null }));
    if (microsoftProviderRef.current) {
      setLoading(true);
      firebase
        .auth()
        .signInWithPopup(microsoftProviderRef.current)
        .then((result) => {
          let currentUser = firebase.auth().currentUser;
          let email =
            result.user &&
            result.user.providerData &&
            result.user.providerData[0] &&
            result.user.providerData[0].email;
          // OAuth access and id tokens can also be retrieved:
          if (currentUser && !currentUser.emailVerified) {
            if (email) {
              currentUser
                .updateEmail(email)
                .then(() => {
                  currentUser = firebase.auth().currentUser;
                  dispatch(
                    fetchHrUser({
                      methodType: 'email',
                      userId: email!,
                      loginMethod: 'outlook',
                    }),
                  );
                })
                .catch((error) => {
                  setLoading(false);
                  callTrackTask(
                    'OUTLOOK_LOGIN_FAIL',
                    'HRD Login&Logout Events',
                    email!,
                  );
                  throw error;
                });
            }
          } else {
            if (email) {
              dispatch(
                fetchHrUser({
                  methodType: 'email',
                  userId: email,
                  loginMethod: 'outlook',
                }),
              );
              setLoading(false);
              callTrackTask(
                'OUTLOOK_LOGIN_FAIL',
                'HRD Login&Logout Events',
                email!,
              );
            }
          }
        })
        .catch((error) => {
          setLoading(false);
          const errorMessage = error.message;
          callTrackTask(
            'OUTLOOK_LOGIN_FAIL',
            'HRD Login&Logout Events',
            errorMessage,
          );
          if (
            error.code === LoginErrorCode.POPUP_CLOSED_BY_USER ||
            error.code === LoginErrorCode.CANCELLED_POPUP_REQUEST
          ) {
            setError(errorMessage);
          } else if (errorMessage.indexOf('{') !== -1) {
            // The error from the backend is thrown in this format
            // HTTP Cloud Function returned an error:
            // {"error":{"message":"The provided Email Id is not registered in Loop",
            // "status":"INVALID_ARGUMENT"}}
            // Extracting the message part of the error using the below code
            const jsonResponse = errorMessage.substring(
              errorMessage.indexOf('{'),
            );
            const errorObject = JSON.parse(jsonResponse);
            setError(
              firebase.auth().currentUser?.email ?? errorObject.error.message,
            );
          } else {
            setError('Please refresh the page and try again!');
          }
          firebase.auth().signOut();
        });
    } else {
      setLoading(false);
    }
  };

  const signInWithGoogle = async () => {
    callTrackClick('GOOGLE_LOGIN');
    setError('');
    dispatch(setConfirmationResult({ data: null }));
    setLoading(true);
    const provider = new fb.auth.GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        const email =
          result.additionalUserInfo &&
          result.additionalUserInfo.profile &&
          (result.additionalUserInfo.profile as IProfile).email;

        let currentUser = firebase.auth().currentUser;
        //let email = result.user.providerData && result.user.providerData[0].email;
        if (currentUser && !currentUser.emailVerified) {
          if (email) {
            currentUser
              .updateEmail(email)
              .then(() => {
                currentUser = firebase.auth().currentUser;
                dispatch(
                  fetchHrUser({
                    methodType: 'email',
                    userId: email,
                    loginMethod: 'google',
                  }),
                );
              })
              .catch((error) => {
                callTrackTask(
                  'GOOGLE_LOGIN_FAIL',
                  'HRD Login&Logout Events',
                  email,
                );
                setLoading(false);
                throw error;
              });
          }
        } else {
          if (email) {
            dispatch(
              fetchHrUser({
                methodType: 'email',
                userId: email,
                loginMethod: 'google',
              }),
            );
            setLoading(false);
            callTrackTask(
              'GOOGLE_LOGIN_FAIL',
              'HRD Login&Logout Events',
              email,
            );
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        const errorMessage = error.message;
        callTrackTask(
          'GOOGLE_LOGIN_FAIL',
          'HRD Login&Logout Events',
          errorMessage,
        );
        if (
          error.code === LoginErrorCode.POPUP_CLOSED_BY_USER ||
          error.code === LoginErrorCode.CANCELLED_POPUP_REQUEST
        ) {
          setError(errorMessage);
        } else if (errorMessage.indexOf('{') !== -1) {
          // The error from the backend is thrown in this format
          // HTTP Cloud Function returned an error:
          // {"error":{"message":"The provided Email Id is not registered in Loop",
          // "status":"INVALID_ARGUMENT"}}
          // Extracting the message part of the error using the below code
          const jsonResponse = errorMessage.substring(
            errorMessage.indexOf('{'),
          );
          const errorObject = JSON.parse(jsonResponse);
          setError(
            firebase.auth().currentUser?.email ?? errorObject.error.message,
          );
        } else {
          setError('Please refresh the page and try again!');
        }
        firebase.auth().signOut();
      });
  };

  useEffect(() => {
    if (firebaseContext?.captcha.RecaptchaVerifier) {
      window.recaptchaVerifier = new firebaseContext.captcha.RecaptchaVerifier(
        'otp-button',
        {
          size: 'invisible',
        },
      );
    }
  }, [firebaseContext?.captcha.RecaptchaVerifier]);

  useEffect(() => {
    window.addEventListener('beforeunload', loadOnRefresh);
    return () => {
      window.removeEventListener('beforeunload', loadOnRefresh);
    };
  }, [loadOnRefresh]);

  useEffect(() => {
    if (firebaseContext?.captcha.OAuthProvider) {
      microsoftProviderRef.current = new firebaseContext.captcha.OAuthProvider(
        'microsoft.com',
      );
      verifyEmailLink(setLoading, dispatch);
    }
  }, [dispatch, firebaseContext?.captcha.OAuthProvider]);

  useEffect(() => {
    const mobileNumberWithoutCountryCode = mobileNumber.substring(4);
    if (
      confirmationResult &&
      mobileNumberWithoutCountryCode &&
      mobileNumberWithoutCountryCode.length === 10 &&
      mobileVerified
    ) {
      history.push('/enter-otp', {
        mobile: mobileNumberWithoutCountryCode,
        verifyEmail: true,
      });
      setMobileVerified(false);
    }
  }, [confirmationResult, history, mobileNumber, mobileVerified]);

  useEffect(() => {
    if (
      hrData &&
      hrData?.error === '' &&
      hrData?.data &&
      hrData?.state === 'succeeded'
    ) {
      const data = hrData?.data;
      const loginMethod = hrData?.loginMethod;
      if (loginMethod) {
        // if (data?.email && data?.mobile && loginMethod !== 'mobile') {
        //   setLoopIdToken(hrData?.data?.userId);
        //   setLoggedInUser(data);
        //   history.push('/');
        // } else if (data?.email && loginMethod !== 'mobile') {
        //   history.push('/enter-id', { showEmail: false });
        // }
        if (loginMethod !== 'mobile') {
          setLoopIdToken(hrData?.data?.userId);
          setLoggedInUser(data);
          history.push('/');
        }
      }
    } else if (hrData.state === 'failed') {
      const triedSigningInUsing =
        firebase.auth().currentUser?.email ??
        firebase.auth().currentUser?.phoneNumber;
      setError(
        triedSigningInUsing ?? "You don't have permission to access this app",
      );
      firebase.auth().signOut();
    }

    if (hrData?.state !== 'loading' && hrData?.error !== '') {
      const loginMethod = hrData?.loginMethod;
      if (loginMethod) {
        callTrackTask(
          getTaskByLoginMethod(loginMethod, false),
          'HRD Login&Logout Events',
        );
      }
    }

    // remove this on isDbCallsDeprecated feature flag is removed
    if (!isDbCallsDeprecated) {
      if (hrData?.loginMethod === 'mobile') {
        if (hrData?.error !== 'MOBILE_NOT_FOUND') {
          triggerOtp();
        } else {
          // Mobile is not registered
          callTrackTask(
            'MOBILE_LOGIN_FAIL',
            'HRD Login&Logout Events',
            mobileNumber,
          );
        }
      }
    }
  }, [history, hrData, setLoggedInUser]);

  return {
    error,
    hrData,
    loading,
    mobileNumber,
    mobileNumberError,
    setMobileNumber,
    signInWithGoogle,
    signInWithOutlook,
    signInWithMobileOtp,
  };
};

export default useLogin;
