import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { initialOperation, initialState } from './initialState';

const AddEmpFormSlice = createSlice({
  name: 'addEmpForm',
  initialState: initialState,
  reducers: {
    setEditForm: (state, action) => {
      state.isEditForm = action.payload.data;
    },

    setSelectedPolicies: (state, action) => {
      state.selectedEmpPolicy = action.payload.data;
    },

    setCurrentOperation: (state, action) => {
      state.currentOperation = {
        ...state.currentOperation,
        ...action.payload.data,
      };
    },
    setClearForm: (state, action) => {
      state.clearForm = action.payload.data;
    },
    clearCurrentOperation: (state) => {
      state.currentOperation = {
        ...initialOperation,
      };
    },
  },
  extraReducers: {
    'common/cleanup': (state) => {
      state.isEditForm = false;
      state.currentOperation = { ...initialOperation };
      state.selectedEmpPolicy = null;
      state.clearForm = false;
    },
  },
});

export const {
  setEditForm,
  setCurrentOperation,
  clearCurrentOperation,
  setSelectedPolicies,
  setClearForm,
} = AddEmpFormSlice.actions;

export const selectIsEditForm = (state: RootState) =>
  state.addEmpForm.isEditForm;

export const selectSelectedPolicies = (state: RootState) =>
  state.addEmpForm.selectedEmpPolicy;

export const selectCurrentOperation = (state: RootState) =>
  state.addEmpForm.currentOperation;
export const selectClearForm = (state: RootState) => state.addEmpForm.clearForm;

export default AddEmpFormSlice.reducer;
