import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import LoopApiService from '../../../adaptars/LoopApiService';
import { AppConstants } from '../../../utils/common/AppConstants';
import { getFireBasePaginatedData } from '../../../utils/common/FirebasePagination';
import { RootState } from '../../store';
import { apiState, initialState, pagingMetaData } from './initialState';
import {
  fetchEmployeeDataList,
  delUserPolicy,
  fetchEmpCount,
  fetchPolicyPreference,
  addDependentRequest,
  enrollUserPolicy,
} from './thunks';
import { transformUpdateEmployee } from './utils';

export const updateEmployeeRequest = createAsyncThunk(
  'hrRequest/updateEmployeeRequest',
  async (data) => {
    try {
      const result: any = await LoopApiService.updateUser(data);
      return {
        data: transformUpdateEmployee(result?.data?.updatedUserFields ?? {}),
      };
    } catch (error) {
      throw error;
    }
  },
);

export const updateDependentRequest = createAsyncThunk(
  'hrRequest/updateDependentRequest',
  async (data) => {
    try {
      const result: any = await LoopApiService.updateUser(data);
      return {
        data: transformUpdateEmployee(result?.data?.updatedUserFields ?? {}),
      };
    } catch (error) {
      throw error;
    }
  },
);

const employeesSlice = createSlice({
  name: 'employeeData',
  initialState: initialState,
  reducers: {
    clearEmpReqState(state, action) {
      let initState = { ...apiState };
      switch (action.payload.data) {
        case AppConstants.ADD_DEPENDENT:
          state.addDeptRequest = { ...initState, ...pagingMetaData };
          break;
        case AppConstants.POLICY_FETCH:
          state.policyPrefData = initState;
          break;
        case AppConstants.UPDATE:
          state.updateHrRequest = initState;
          break;
        case AppConstants.UPDATE_DEPENDENT:
          state.updateHrDependentRequest = initState;
          break;
        case AppConstants.ENROLL:
          state.enrolPolicy = initState;
          break;
        case AppConstants.DEL_POLICY:
          state.delPolicy = initState;
          break;
        case AppConstants.CLEAR_EMPLOYEE_LIST:
          state.employeeListData = {
            ...apiState,
            metaData: { pageNo: 0, hasPrev: false, hasNext: false },
          };
          break;
        default:
          break;
      }
    },
  },
  extraReducers: {
    [fetchEmployeeDataList.pending.toString()]: (state) => {
      state.employeeListData.status = 'loading';
      state.employeeListData.error = '';
    },
    [fetchEmployeeDataList.fulfilled.toString()]: (state, action) => {
      const {
        payload,
        meta: {
          arg: {
            pageSize,
            nextPageId: argNextPageId,
            prevPageId: argPrevPageId,
          },
        },
      } = action;
      const { newData, newMetaData } = getFireBasePaginatedData({
        pageSize,
        argNextPageId,
        argPrevPageId,
        data: payload.data,
        nextPrevSelector: 'userId',
        prevPageNo: state.employeeListData?.metaData?.pageNo ?? 0,
      });
      state.employeeListData.error = null;
      state.employeeListData.status = 'succeeded';
      state.employeeListData.data = newData;
      state.employeeListData.metaData = newMetaData;
    },
    [fetchEmployeeDataList.rejected.toString()]: (state, action) => {
      state.employeeListData.status = 'failed';
      state.employeeListData.error = action.error.message;
    },

    [fetchEmpCount.pending.toString()]: (state, action) => {
      state.empCount.status = 'loading';
      state.empCount.error = '';
    },
    [fetchEmpCount.fulfilled.toString()]: (state, action) => {
      state.empCount.status = 'succeeded';
      state.empCount.data = action.payload.data;
    },
    [fetchEmpCount.rejected.toString()]: (state, action) => {
      state.empCount.status = 'failed';
      state.empCount.error = action.error.message;
    },

    [fetchPolicyPreference.pending.toString()]: (state) => {
      state.policyPrefData.status = 'loading';
      state.policyPrefData.error = '';
    },
    [fetchPolicyPreference.fulfilled.toString()]: (state, action) => {
      state.policyPrefData.data = action.payload.data;
      state.policyPrefData.status = 'succeeded';
    },
    [fetchPolicyPreference.rejected.toString()]: (state, action) => {
      state.policyPrefData.status = 'failed';
      state.policyPrefData.error = action.error.message;
    },

    [addDependentRequest.pending.toString()]: (state) => {
      state.addDeptRequest.status = 'loading';
      state.addDeptRequest.error = '';
    },
    [addDependentRequest.fulfilled.toString()]: (state, action) => {
      state.addDeptRequest.data = action.payload.employeeData;
      state.addDeptRequest.metaData = action.payload.policyData;
      state.addDeptRequest.status = 'succeeded';
    },
    [addDependentRequest.rejected.toString()]: (state, action) => {
      state.addDeptRequest.status = 'failed';
      state.addDeptRequest.error = action?.error;
    },

    [enrollUserPolicy.pending.toString()]: (state, action) => {
      state.enrolPolicy.status = 'loading';
      state.enrolPolicy.error = '';
    },
    [enrollUserPolicy.fulfilled.toString()]: (state, action) => {
      state.enrolPolicy.data = action.payload.data;
      state.enrolPolicy.status = 'succeeded';
    },
    [enrollUserPolicy.rejected.toString()]: (state, action) => {
      state.enrolPolicy.status = 'failed';
      // state.enrolPolicy.error = action.error;
      state.enrolPolicy.error = JSON.parse(action.error?.message);
    },

    [delUserPolicy.pending.toString()]: (state, action) => {
      state.delPolicy.status = 'loading';
      state.delPolicy.error = '';
    },
    [delUserPolicy.fulfilled.toString()]: (state, action) => {
      state.delPolicy.data = action.payload.data;
      state.delPolicy.status = 'succeeded';
    },
    [delUserPolicy.rejected.toString()]: (state, action) => {
      state.delPolicy.status = 'failed';
      state.delPolicy.error = action.error;
    },

    [updateEmployeeRequest.pending.toString()]: (state) => {
      state.updateHrRequest.status = 'loading';
      state.updateHrRequest.error = '';
    },
    [updateEmployeeRequest.fulfilled.toString()]: (state, action) => {
      state.updateHrRequest.data = action.payload.data;
      state.updateHrRequest.status = 'succeeded';
    },
    [updateEmployeeRequest.rejected.toString()]: (state, action) => {
      state.updateHrRequest.status = 'failed';
      state.updateHrRequest.error = action.error;
    },

    [updateDependentRequest.pending.toString()]: (state) => {
      state.updateHrDependentRequest.status = 'loading';
      state.updateHrDependentRequest.error = '';
    },
    [updateDependentRequest.fulfilled.toString()]: (state, action) => {
      state.updateHrDependentRequest.data = action.payload.data;
      state.updateHrDependentRequest.status = 'succeeded';
    },
    [updateDependentRequest.rejected.toString()]: (state, action) => {
      state.updateHrDependentRequest.status = 'failed';
      state.updateHrDependentRequest.error = action.error;
    },

    'common/cleanup': (state) => {
      state.policyPrefData = {
        ...apiState,
      };

      state.addDeptRequest = {
        ...apiState,
        ...pagingMetaData,
      };

      state.employeeListData = {
        ...apiState,
        ...pagingMetaData,
      };
      state.updateHrRequest = {
        ...apiState,
      };
      state.updateHrDependentRequest = {
        ...apiState,
      };
      state.enrolPolicy = {
        ...apiState,
      };

      state.delPolicy = {
        ...apiState,
      };

      state.empCount = {
        ...apiState,
      };
    },
  },
});

export const selectPolicyPrefData = (state: RootState) =>
  state.employees.policyPrefData;

export const selectEmpCount = (state: RootState) => state.employees.empCount;

export const selectAddDeptRequest = (state: RootState) =>
  state.employees.addDeptRequest;

export const selectUpdateHrRequest = (state: RootState) =>
  state.employees.updateHrRequest;

export const selectUpdateHrDependentRequest = (state: RootState) =>
  state.employees.updateHrDependentRequest;

export const selectEnrolledUserPolicy = (state: RootState) =>
  state.employees.enrolPolicy;

export const selectDelUserPolicy = (state: RootState) =>
  state.employees.delPolicy;

export const selectEmployeeListData = (state: RootState) =>
  state.employees.employeeListData;

export const { clearEmpReqState } = employeesSlice.actions;
export default employeesSlice.reducer;
