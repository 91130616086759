import { useEffect, useState } from 'react';
import { parseResponse } from '../../common/Utilities';
import { ISearchResultResponseUserType } from '../../../components/containers/TopNavigation/types';
import GlobalSearchAPI from '../../../adaptars/globalSearchApi';

export const useFetchGlobalSearchData = (
  companyId: string | undefined,
  searchString: string,
) => {
  const [loading, toggleLoading] = useState<boolean>(true);
  const [searchResults, setSearchResults] = useState<
    ISearchResultResponseUserType[]
  >([]);

  useEffect(() => {
    if (searchString.length >= 3) {
      (async function () {
        toggleLoading(true);
        const [error, result] = await parseResponse(
          GlobalSearchAPI.getGlobalSearchData(companyId, searchString),
        );

        if (error) {
          console.error(error);
          return;
        }

        setSearchResults(result?.data.users);
        toggleLoading(false);
      })();
    }
  }, [companyId, searchString]);

  return {
    searchResults,
    loading,
  };
};
