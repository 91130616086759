import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './addEmployee.scss';
import {
  setSelectedPolicies,
  selectSelectedPolicies,
} from '../../../../redux/slices/addEmpFormSlice';
import { AddModal } from '../../../containers/AlertModal/addModal';
import { selectCompanyPolicyList } from '../../../../redux/slices/PolicyListSlice';
import { fetchAllCompanyPolicyList } from '../../../../redux/slices/PolicyListSlice/thunks';
import {
  getAmountInLacs,
  getPolicyType,
} from '../../../../utils/common/Utilities';
import { selectSelectedCompany } from '../../../../redux/slices/hrdRevampRedux';

export const PolicyListCheckBox = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedCompany = useSelector(selectSelectedCompany);
  const companyPoliciesListing = useSelector(selectCompanyPolicyList);
  const policyPlans = companyPoliciesListing?.data?.policyPlans ?? [];
  const selectedPolicies = useSelector(selectSelectedPolicies);
  const [showSlabModal, setShowSlabModal] = useState(false);
  const [selPolicySlab, setSelPolicySlab] = useState({});
  // Below Code to be used
  // const [selPolicies, setSelPolicies] = useState(
  //   selectedPolicies?.length > 0 ? selectedPolicies : [],
  // );
  const [selPolicies, setSelPolicies] = useState(
    history?.location?.state !== undefined
      ? selectedPolicies?.length > 0
        ? selectedPolicies
        : []
      : [],
  );
  const [selSlab, setSelSlab] = useState('');

  useEffect(() => {
    if (policyPlans.length === 0) {
      if (selectedCompany?.id) {
        dispatch(fetchAllCompanyPolicyList({ companyId: selectedCompany.id }));
      }
    }
  }, [policyPlans.length, selectedCompany?.id]);

  const handlePolicyProceed = () => {
    if (selPolicies.length) {
      dispatch(setSelectedPolicies({ data: selPolicies }));
      history.push('/add-employee');
      window.scrollTo(0, 0);
    } else {
    }
  };
  const addModalButtonOne = (id, policySlab, policyDetails) => {
    const { familyDefinition, sumInsured: slab, slabId } = policySlab;
    const supDependents = familyDefinition.value;
    if (typeof slab === 'undefined') {
    } else {
      if (typeof selPolicies.find((x) => x.id === id) === 'undefined') {
        setSelPolicies([
          ...selPolicies,
          {
            id,
            slab,
            slabId,
            supDependents,
            policyDetails,
          },
        ]);
      } else {
        setSelPolicies(
          selPolicies.map((x) =>
            x.id === id
              ? {
                  id,
                  slab,
                  slabId,
                  supDependents,
                  policyDetails,
                }
              : x,
          ),
        );
      }
      setShowSlabModal(false);
    }
  };

  const policySlabChangeHandler = (policy, slab) => {
    setShowSlabModal(true);
    setSelPolicySlab(policy);
    setSelSlab(slab);
  };

  const selectSlab = (event, policy, showModal, policySlab) => {
    if (event.target.checked) {
      if (showModal) {
        setShowSlabModal(true);
        setSelPolicySlab(policy);
      } else if (policySlab) {
        const { slabId, sumInsured: slab } = policySlab;
        const supDependents = policySlab.familyDefinition?.value;
        setSelPolicies([
          ...selPolicies,
          {
            id: policy.id,
            policyDetails: policy,
            slabId,
            slab,
            supDependents,
          },
        ]);
      }
    } else {
      const selPolicyArray = [...selPolicies];

      const index = selPolicyArray.findIndex((x) => x.id === policy.id);

      if (index > -1) {
        selPolicyArray?.splice(index, 1);
        setSelPolicies(selPolicyArray);
      }
    }
  };

  const goBack = () => {
    history.goBack();
  };

  return (
    <div className="hr-dashboard">
      <div className=" mx-auto">
        <div className={`view-emp-header hover`} onClick={goBack}>
          <img
            className="arrow-left-emp-img"
            alt="back"
            src={'/assets/icons/chevron-right.svg'}
          />
          <span className="heading-bold-text">Add Single Employee</span>
        </div>
        <div className="row">
          <div className="add_emp_steps">
            <ul>
              <li>
                <label className="active_label">
                  <span className="active_span">1</span>Choose Policies
                </label>
              </li>
              <li>
                <label>
                  <span>2</span>Add Employee information
                </label>
              </li>
            </ul>
          </div>
        </div>

        <div className="row policy_checkbox">
          {policyPlans?.map((policy) => {
            const isSelPolicies =
              typeof selPolicies.find((x) => x.id === policy.id) === 'object';
            const selPolicy = selPolicies.find((x) => x.id === policy.id);
            return (
              <div key={policy.id} className="col-md-4 card_margin">
                <div
                  className={
                    !!selPolicy
                      ? 'border-green box_card col-md-12'
                      : 'card_border box_card col-md-12'
                  }
                >
                  <div className="card_top">
                    <div className="col-md-12">
                      <div className="policy_info_left single_user_r1">
                        <div className="form-check">
                          <input
                            className="form-check-input pull_right"
                            type="checkbox"
                            checked={selPolicies
                              ?.map((x) => x.id)
                              .includes(policy.id)}
                            onChange={(event) =>
                              policy?.sumAssuredSlabs?.length > 1
                                ? selectSlab(event, policy, true, null)
                                : selectSlab(
                                    event,
                                    policy,
                                    false,
                                    policy?.sumAssuredSlabs?.[0],
                                  )
                            }
                          />
                        </div>
                      </div>
                      <div className="policy_info_left single_user_r2">
                        <div
                          className="policy_image pull-left"
                          style={{
                            backgroundImage: `url("${
                              policy?.insurerLogo ??
                              '/assets/images/cardImage.svg'
                            }")`,
                            backgroundColor: 'initial',
                          }}
                        />

                        <div className="policy_name single_user_pn">
                          <h4 className="cardName">
                            {getPolicyType(
                              policy?.policyType,
                              policy?.isTopUpPolicy,
                              policy?.isParentalPolicy,
                            )}
                          </h4>
                          <h5 className="health_insurer">
                            {policy?.insurerName}
                          </h5>
                        </div>
                      </div>
                      <div className="policy_info_right" />
                    </div>
                  </div>
                  <div className="card_bottom">
                    <div className="flex policy_stats policy_row_one card_section_main">
                      <div className="card_section_one">
                        {policy?.sumAssuredSlabs?.length > 1 ? (
                          <>
                            {isSelPolicies ? (
                              <p className="amountText"> Selected Slab</p>
                            ) : (
                              <p className="amountText available_slabs">
                                Available Slabs
                              </p>
                            )}
                          </>
                        ) : (
                          <p className="amountText">Sum Insured</p>
                        )}

                        <h5 className="amountPrice">
                          {isSelPolicies ? (
                            <>
                              {policy?.sumAssuredSlabs.length > 1 ? (
                                <>
                                  {getAmountInLacs(selPolicy?.slab)}
                                  <span
                                    className="changeSpan"
                                    onClick={(event) =>
                                      policySlabChangeHandler(
                                        policy,
                                        selPolicy.slab,
                                      )
                                    }
                                  >
                                    CHANGE
                                  </span>
                                </>
                              ) : (
                                getAmountInLacs(selPolicy?.slab)
                              )}
                            </>
                          ) : policy?.sumAssuredSlabs ? (
                            policy?.sumAssuredSlabs
                              ?.map((policySlab) =>
                                getAmountInLacs(policySlab.sumInsured),
                              )
                              .join(', ')
                          ) : (
                            'N/A'
                          )}
                        </h5>
                      </div>
                      <div className="card_section_two">
                        <p className="amountText">Covered Members</p>
                        <h5 className="amountPrice">
                          {isSelPolicies
                            ? selPolicy?.supDependents
                            : policy?.sumAssuredSlabs?.length > 1
                            ? 'Choose Slab first'
                            : policy?.sumAssuredSlabs?.[0]?.familyDefinition
                                .value}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          <div className="select_policy_footer">
            <button
              className={selPolicies.length && 'active'}
              onClick={() => handlePolicyProceed()}
              disabled={selPolicies.length ? false : true}
            >
              {' '}
              Proceed
            </button>
          </div>
          <div style={{ height: '72px' }} />
        </div>
      </div>
      {showSlabModal && (
        <AddModal
          title={`Choose Coverage Amount`}
          showCards={false}
          showSlabs={true}
          showModal={showSlabModal}
          policy={selPolicySlab}
          selSlab={selSlab}
          setSelPolicySlab={setSelPolicySlab}
          setShowModal={setShowSlabModal}
          onPressButton1={addModalButtonOne}
        />
      )}
    </div>
  );
};
