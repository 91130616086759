import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ClaimListHeader from '../../containers/ClaimListHeader';
import { StyledClaimsList, StyledEmptyStateContainer } from './styles';
import { IClaimData, IClaimStatusTabs } from './types';
import {
  getClaimsCount,
  getFilteredClaims,
  getPolicyListForClaimsDropDown,
  getTableHeader,
} from './utils';
import { LoopPaginatedTable } from '@loophealth/loop-ui-web-library';
import { CLAIM_LIST_HEADERS, CLAIM_TABLE_PROCESSOR } from './utils';
import Loader from '../../atoms/Spinner';
import useSearchQuery from './hooks/useSearchQuery';
import { ALL_CLAIMS_CONSTANT, CLAIMS_LIST_STATUS_STATE } from './constants';
import useFetchClaimList from './hooks/useFetchClaimList';
import ZeroStateCard from '../../atoms/ZeroStateCard';
import { useSelector } from 'react-redux';
import { selectPoliciesList } from '../../../redux/slices/hrdRevampRedux';
import { extractPathName, trackClickEvent } from '../../../utils/segment/utils';
import useSegment from '../../../utils/segment/hooks/useSegment';
import useTrackPage from '../../../utils/segment/hooks/useTrackPage';

const ClaimList = () => {
  const history = useHistory();
  const trackClick = useSegment('click');
  const location = useLocation();
  const { isLoading, claims } = useFetchClaimList();
  const { data } = useSelector(selectPoliciesList);
  const dropDownFilters = useMemo(() => {
    return getPolicyListForClaimsDropDown(data);
  }, [data]);
  const [policyNo, setSelectedFilter] = useSearchQuery(
    'policyNo',
    dropDownFilters?.length ? dropDownFilters[0].value : ALL_CLAIMS_CONSTANT,
  );
  const [tab, setSelectedTab] = useSearchQuery<IClaimStatusTabs>(
    'tab',
    ALL_CLAIMS_CONSTANT,
  );

  const filteredClaims = useMemo(() => {
    return isLoading
      ? []
      : getFilteredClaims(claims as IClaimData[], tab, policyNo);
  }, [isLoading, claims, tab, policyNo]);

  const claimCounts: Record<IClaimStatusTabs, number> = useMemo(() => {
    return getClaimsCount(claims, policyNo);
  }, [claims, policyNo]);

  const onTableRowClicked = (rowData: Record<string, unknown>) => {
    const claimProperties: Record<string, unknown> = {
      tpa_claim_id: rowData['Claim ID'],
      claim_id: rowData.id,
      claim_type: rowData['Insurance Type'],
      policy_number: rowData['Policy Id'],
      claim_status: rowData.status,
    };
    trackClick(
      trackClickEvent(
        'CLAIM_SELECTION',
        location?.pathname,
        extractPathName(location?.pathname),
        claimProperties,
      ),
    );
    history.push(`employee-details?id=${rowData.userId}`, {
      referrerPage: 'Claims',
      claimId: rowData.id,
    });
  };

  const tableHeader = getTableHeader(tab, filteredClaims.length);
  const { emptyState } = CLAIMS_LIST_STATUS_STATE[tab];

  const handleClaimFilerChange = (policyNo: string) => {
    setSelectedFilter(policyNo);
    const policyProperties: Record<string, unknown> = {
      policy_number: policyNo,
    };
    trackClick(
      trackClickEvent(
        'POLICY_SECTION_DROPDOWN',
        location?.pathname,
        extractPathName(location?.pathname),
        policyProperties,
      ),
    );
  };

  useTrackPage({
    policy_number: policyNo,
    policy_type: dropDownFilters.find((item) => item.value === policyNo)?.data?.policyType,
    selected_tab: tab,
    claim_count: filteredClaims.length,
    active_claims: claimCounts.Ongoing
  }, !isLoading);

  const handleTabChange = (tab: IClaimStatusTabs) => {
    setSelectedTab(tab);
    const policyProperties: Record<string, unknown> = {
      policy_number: tab,
    };
    trackClick(
      trackClickEvent(
        tab.toUpperCase() + '_CTA',
        location?.pathname,
        extractPathName(location?.pathname),
        policyProperties,
      ),
    );
  };
  return (
    <StyledClaimsList>
      <ClaimListHeader
        selectedFilter={policyNo}
        policiesOptions={dropDownFilters}
        setSelectedFilter={handleClaimFilerChange}
        selectedTab={tab}
        setSelectedTab={handleTabChange}
        claimCounts={claimCounts}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <LoopPaginatedTable
          tableHeader={tableHeader}
          count={filteredClaims.length}
          rowsPerPage={10}
          headers={CLAIM_LIST_HEADERS}
          processor={CLAIM_TABLE_PROCESSOR}
          onTableRowClicked={onTableRowClicked}
          data={filteredClaims as any}
          EmptyState={
            <StyledEmptyStateContainer>
              <ZeroStateCard
                image={emptyState.image}
                title={emptyState.title}
                subtitle={emptyState.desc}
                note={emptyState.note}
              />
            </StyledEmptyStateContainer>
          }
        />
      )}
    </StyledClaimsList>
  );
};

export default ClaimList;
