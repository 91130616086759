import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 24px;
  margin-bottom: 24px;
`;

export const StyledPolicyListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.65;
  gap: 16px;
  padding: 40px 48px 40px 40px;
  background-color: ${({ theme }) => theme.colors?.surfaceOne};
`;

export const StyledEndoCostContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 64px 0;
  flex: 0.35;
  background-color: ${({ theme }) => theme.colors?.surfaceOne};
  margin-bottom: 56px;
  --mask: conic-gradient(
        from 135deg at top,
        #0000,
        #000 1deg 89deg,
        #0000 90deg
      )
      top/50.00px 51% repeat-x,
    conic-gradient(from -45deg at bottom, #0000, #000 1deg 89deg, #0000 90deg)
      bottom/50.00px 51% repeat-x;
  -webkit-mask: var(--mask);
  mask: var(--mask);
`;

export const StyledRightContainer = styled.div`
  flex: 0.02;
  background-color: ${({ theme }) => theme.colors?.surfaceOne};
`;
export const StyledTotalCostHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 24px;
  gap: 16px;
  align-items: center;
`;

export const StyledDottedSeparator = styled.div`
  width: 90%;
  margin: 32px 24px;
  border-bottom: 1px solid ${({ theme }) => theme.colors?.seafoamGray};
`;

export const StyledEndoDataRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;
  padding: 0 24px;
`;
export const StyledDescription = styled.div`
  padding: 20px 24px;
  margin: 56px 0 48px;
  background-color: ${({ theme }) => theme.colors?.surface0};
`;
export const StyledPolicyContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 4px;
  border: 0.5px solid ${({ theme }) => theme.colors?.seafoamGray};
`;
export const StyledPolicyHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px 8px 0 0;
  padding: 14px 20px;
  background: #f2fafe;
`;
export const StyledPolicyName = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`;
export const StyledInsurerLogo = styled.img`
  height: 32px;
  width: 32px;
  border-radius: 8px;
`;
export const StyledPolicyType = styled.div`
  display: flex;
  align-items: center;
  height: max-content;
  border-radius: 4px;
  padding: 4px 8px;
  border: 0.5px solid ${({ theme }) => theme.colors?.seafoamGray};
  background: ${({ theme }) => theme.colors?.surfaceOne};
`;

export const StyledPolicyList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 28px 24px;
  gap: 10px;
`;
export const StyledPolicyCostSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 28px 24px;
  border-radius: 12px;
  border: 0.5px solid ${(p) => p.theme.colors.seafoamGray};
`;
export const StyledPolicyCostData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const StyledVerticalSpacer = styled.div`
  width: 1px;
  background-color: ${(p) => p.theme.colors.seafoamGray};
  height: 100px;
`;

export const StyledPolicyItemAlertsContainer = styled.div`
  margin-bottom: 10px;
  padding: 12px 24px;
  border-radius: 8px;
  background-color: ${(p) => p.theme.colors.lavenderBlush};
`;
