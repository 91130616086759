import { LoopButton, Toast, Typography } from '@loophealth/loop-ui-web-library';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ListItemStar, QueueProcessingGif } from '../../../../assets/images';
import useSegment from '../../../../utils/segment/hooks/useSegment';
import {
  extractPathName,
  trackClickEvent,
} from '../../../../utils/segment/utils';
import useEndoSummaryData from '../hooks/useEndoSummaryData';
import {
  StyledContainer,
  StyledDescription,
  StyledIllustration,
  StyledPolicySummaryContainer,
  StyledPolicySummaryContainerItem,
  StyledQueueGif,
} from './styles';
import { IEndoSuccessSummary } from './types';

const EndoSuccessSummary: React.FC<IEndoSuccessSummary> = ({
  submittedData,
  mode,
}) => {
  const trackClick = useSegment('click');
  const location = useLocation();
  const toast = Toast.useToast();
  const history = useHistory();
  const submittedLives = useEndoSummaryData(submittedData);

  const handleSubmitClick = () => {
    setTimeout(() => {
      toast?.success(
        'Submission complete! You can now track the progress of your endorsements',
        '',
        {
          closeOnClick: false,
          variant: 'light',
        },
      );
      trackClick(
        trackClickEvent(
          'OkayGotIt_track',
          location?.pathname,
          extractPathName(location?.pathname),
        ),
      );
    }, 500);

    history.replace('/endorsements', { endoSubmitted: true });
  };

  return (
    <StyledContainer>
      <StyledIllustration>
        <StyledQueueGif src={QueueProcessingGif} />
      </StyledIllustration>
      <Typography variant="title2" weight="medium">
        Awesome! You have submitted your data to Loop
      </Typography>
      <StyledDescription>
        <Typography variant="medium">
          We're on it! {mode === 'ADD' ? 'Adding' : 'Deleting'} your data can
          take up to 20 mins, but it has reached us safely
        </Typography>
        <Typography variant="medium">
          Explore more on the dashboard or tackle other tasks while we handle
          the magic! 🚀
        </Typography>
      </StyledDescription>
      {mode === 'ADD' && (
        <StyledPolicySummaryContainer>
          {Object.keys(submittedLives).map((policyType, key) => (
            <StyledPolicySummaryContainerItem>
              <img src={ListItemStar} />
              <Typography key={key} variant="medium" color="secondary">
                Adding {submittedLives[policyType]} lives to {policyType} Policy
              </Typography>
            </StyledPolicySummaryContainerItem>
          ))}
        </StyledPolicySummaryContainer>
      )}
      <LoopButton variant="filled" onClick={handleSubmitClick}>
        Okay! Got it
      </LoopButton>
    </StyledContainer>
  );
};
export default EndoSuccessSummary;
