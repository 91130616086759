import { IApiState, IHrRequestSlice } from './types';

export const apiState: IApiState = {
  data: null,
  status: 'idle',
  error: '',
};

export const initialState: IHrRequestSlice = {
  userDetailData: {
    ...apiState,
  },
  userDependentDetails: {
    ...apiState,
  },
  deleteDetailData: {
    ...apiState,
  },
  deleteUserDependent: {
    ...apiState,
  },
  userFamilyDetailData: {
    ...apiState,
  },
  addHrRequest: {
    ...apiState,
  },
};
