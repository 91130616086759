import {
  StyledEmployeesPage,
  StyledEmployeesPageHeader,
  StyledSummaryAndStatisticsColumn,
  StyledSummaryAndStatisticsSection,
} from './styles';
import ClaimsHeaderDropdown from '../../containers/ClaimsHeaderDropdown';
import EmployeeStatistics from '../../containers/Employees/Statistics';
import SummarySection from '../../containers/Employees/SummarySection';
import ActiveRoasterTable from '../../containers/Employees/ActiveRoasterTable';
import { checkIfGPAorGTLPolicy, getPolicyListDropDown } from './utils';
import {
  selectPoliciesList,
  selectSelectedCompany,
} from '../../../redux/slices/hrdRevampRedux';
import { useSelector } from 'react-redux';
import useFetchEmployees from './hooks/useFetchEmployees';
import Loader from '../../atoms/Spinner';
import useSearchQuery from '../ClaimsList/hooks/useSearchQuery';
import Banner from '../../containers/Employees/Banner';
import { useMemo } from 'react';
import useSegment from '../../../utils/segment/hooks/useSegment';
import { extractPathName, trackClickEvent } from '../../../utils/segment/utils';
import { useLocation } from 'react-router-dom';
import useTrackPage from '../../../utils/segment/hooks/useTrackPage';
import EndoInfoBanner from '../../containers/EndoInfoBanner';
import { isEndoVisible } from '../../../utils/featureFlag';

const Employees = () => {
  const { data } = useSelector(selectPoliciesList);
  const dropDownFilters = useMemo(() => getPolicyListDropDown(data), [data]);
  const defaultPolicyID = dropDownFilters?.length && dropDownFilters[0].value;
  const [selectedPolicyFilter, setSelectedPolicyFilter] = useSearchQuery(
    'policyNo',
    defaultPolicyID,
  );
  const trackClick = useSegment('click');
  const location = useLocation();
  const handlePolicyChange = (selectedPolicyFilter: string) => {
    setSelectedPolicyFilter(selectedPolicyFilter);
    const policyProperties: Record<string, unknown> = {
      policy_number: selectedPolicyFilter,
    };
    trackClick(
      trackClickEvent(
        'POLICY_SECTION_DROPDOWN',
        location?.pathname,
        extractPathName(location?.pathname),
        policyProperties,
      ),
    );
  };

  useTrackPage({
    policy_number: selectedPolicyFilter,
    policy_type: dropDownFilters.find(
      (item) => item.value === selectedPolicyFilter,
    )?.data?.policyType,
  });

  const selectedPolicy = data.find(
    (policyItem) => policyItem.policyNumber === selectedPolicyFilter,
  );
  const isGPAorGTL = checkIfGPAorGTLPolicy(selectedPolicy?.policyType || '');
  const selectedCompany = useSelector(selectSelectedCompany);
  const { isLoading, empList, empStats } = useFetchEmployees(
    selectedCompany?.id as string,
    selectedPolicy?.id || '',
  );
  return (
    <StyledEmployeesPage>
      {isEndoVisible && <EndoInfoBanner/>}
      <StyledEmployeesPageHeader>
        <ClaimsHeaderDropdown
          title="Employees and Dependents"
          policiesOptions={dropDownFilters}
          selectedPolicyFilter={selectedPolicyFilter || ''}
          setSelectedPolicyFilter={handlePolicyChange}
        />
      </StyledEmployeesPageHeader>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <StyledSummaryAndStatisticsSection>
            <StyledSummaryAndStatisticsColumn>
              <SummarySection
                activeEmployees={empStats.total_lives_emp}
                activeLives={empStats.total_lives_all}
                eCardsAvailableCount={empStats.available_ecards}
                employeeWithEcard={empStats.employee_with_ecard}
                insuredEmployees={empStats.insured_employees}
                isGPAorGTL={isGPAorGTL}
              />

              <EmployeeStatistics
                insuredCount={empStats.insured_lives_all}
                eCardsAvailableCount={empStats.available_ecards}
                eCardsInProgressCount={empStats.ecardInProgress}
                endorsementInProgressCount={empStats.ecardInProgress}
                insuredEmployeesCount={empStats.employee}
                insuredParentsInLawCount={empStats['parent-in-law']}
                insuredSpousesCount={empStats.spouse}
                insuredChildrenCount={empStats.child}
                insuredParentsCount={empStats.parent}
                insuredEmp={empStats.insured_employees}
                inProgressEmp={empStats.in_progress_employees}
                isGPAorGTL={isGPAorGTL}
              />
            </StyledSummaryAndStatisticsColumn>
            {isGPAorGTL && <Banner />}
          </StyledSummaryAndStatisticsSection>

          <ActiveRoasterTable
            selectedPolicyType={selectedPolicy?.policyType || ''}
            tableData={empList}
          />
        </>
      )}
    </StyledEmployeesPage>
  );
};

export default Employees;
