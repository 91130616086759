import {
  StyledDetailsHeader,
  StyledEmptyContainer,
  StyledMainContainer,
  StyledMainWrapper,
} from './styles';
import { EcardYellowIcon, EmptyStateSevenGif } from '../../../assets/images';
import { LoopTable, Typography } from '@loophealth/loop-ui-web-library';
import { getEmpPolTableProcessor } from './tableElements';
import { EMP_POL_LIST_HEADERS } from '../../../utils/constants/constants';
import { IEmpPolicies } from './types';
import ZeroStateCard from '../../atoms/ZeroStateCard';
import { ITransformedData } from '../../pages/EmployeeDetails/types';
import { capitalizeFirstLetterOfEachWord } from '../../../utils/common/Utilities';
import { filterSelfPolicies } from './utils';

const EmpPolicies: React.FunctionComponent<IEmpPolicies> = ({
  policiesDetails,
}) => {
  const selfPolicies: ITransformedData | any =
    filterSelfPolicies(policiesDetails);
  const name = capitalizeFirstLetterOfEachWord(
    selfPolicies?.details?.firstName ?? '',
  );

  return (
    <StyledMainContainer>
      <StyledDetailsHeader>
        <img src={EcardYellowIcon} alt="" />
        <Typography variant="medium" weight="medium" color="primary">
          {`E-Cards and policies for ${name}`}
        </Typography>
      </StyledDetailsHeader>
      <StyledMainWrapper>
        {selfPolicies?.policies ? (
          <LoopTable
            headers={EMP_POL_LIST_HEADERS}
            processor={getEmpPolTableProcessor(name)}
            data={selfPolicies.policies}
          />
        ) : (
          <StyledEmptyContainer>
            <ZeroStateCard
              title="No Policies added for this employee!"
              subtitle=""
              image={EmptyStateSevenGif}
            />
          </StyledEmptyContainer>
        )}
      </StyledMainWrapper>
    </StyledMainContainer>
  );
};
export default EmpPolicies;
