import styled from 'styled-components';

export const StyledContainer = styled.div`
  background-color: ${(p) => p.theme.colors.surface0};
  padding-bottom: 43px;
  width: 100%;
`;

export const StyledEndoListHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 49px 56px 42px;
`;

export const StyledEndoFilterSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 14px;
  margin: 0 56px;
  justify-content: center;
`;
export const StyledEndoStateContainer = styled.div<{ isSelected: boolean }>`
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 10px;
  padding: 16px 20px;
  border-radius: 8px;
  cursor: pointer;
  background: ${(p) => p.isSelected ? p.theme.colors.supportingPlaneGreenDark : p.theme.colors.surfaceOne};
`;
export const StyledEndoStateDot = styled.div<{ isCompleted: boolean }>`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${(p) => p.isCompleted ? p.theme.colors.turquoise : p.theme.colors.coverYellow};
`;
export const StyledEndoStatusCount = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: flex-start;
`;

export const StyledEndorsementsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 21px;
  padding-top: 16px;
`;

export const StyledCenteredContainer = styled.div`
  text-align: center;
`;
export const StyledAccordian = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 36px 56px;
  background-color: white;
  cursor: pointer;
  margin-bottom: 12px;
`;
export const StyledMonthContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
export const StyledChevron = styled.img`
  cursor: pointer;
`;
