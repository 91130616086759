import { ITransformUpdateEmployee } from './types';
import { convertDateToFirebaseTimestamp } from '../../../utils/common/Utilities';

export const transformUpdateEmployee = (
  updatedUserFields: ITransformUpdateEmployee,
) => ({
  ...updatedUserFields,
  dob:
    updatedUserFields?.dob &&
    convertDateToFirebaseTimestamp(updatedUserFields.dob),
  chatDurationLimit:
    updatedUserFields?.chatDurationLimit &&
    convertDateToFirebaseTimestamp(updatedUserFields.chatDurationLimit),
  updatedAt:
    updatedUserFields?.updatedAt &&
    convertDateToFirebaseTimestamp(updatedUserFields.updatedAt),
  visitDurationLimit:
    updatedUserFields?.visitDurationLimit &&
    convertDateToFirebaseTimestamp(updatedUserFields.visitDurationLimit),
  createdAt:
    updatedUserFields?.createdAt &&
    convertDateToFirebaseTimestamp(updatedUserFields.createdAt),
  joiningDate:
    updatedUserFields?.joiningDate &&
    convertDateToFirebaseTimestamp(updatedUserFields.joiningDate),
});
