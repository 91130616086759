import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './../../store/index';
import { apiState, initialState } from './initialState';
import {
  fetchAllCompanyPolicyList,
  fetchCompanyPolicyDetails,
  fetchEnrolledPolicy,
  fetchPolicyEmpCount,
  fetchUserDependentPolicies,
} from './thunks';

const policyListSlice = createSlice({
  name: 'policyList',
  initialState: initialState,
  reducers: {
    clearEnrolledPolicy: (state) => {
      state.policyEnrolData = {
        ...apiState,
      };
    },
    clearDependentPolicy: (state) => {
      state.dependentPolicyData = {
        ...apiState,
      };
    },
    addDependentPolicyDataByUserId: (state, action) => {
      const { policies = [], userId = '' } = action.payload;
      state.dependentPolicyData.data = {
        ...state.dependentPolicyData.data,
        [userId]: policies,
      };
    },
  },
  extraReducers: {
    // Below Actions are added to get Company policy listing for dashboard and policy screen
    [fetchAllCompanyPolicyList.pending.toString()]: (state) => {
      state.companyPolicyListData.status = 'loading';
      state.companyPolicyListData.error = '';
    },
    [fetchAllCompanyPolicyList.fulfilled.toString()]: (state, action) => {
      state.companyPolicyListData.status = 'succeeded';
      state.companyPolicyListData.data = action.payload.data;
    },
    [fetchAllCompanyPolicyList.rejected.toString()]: (state, action) => {
      state.companyPolicyListData.status = 'failed';
      state.companyPolicyListData.error = action.error?.message;
      state.companyPolicyListData.data = null;
    },

    [fetchEnrolledPolicy.pending.toString()]: (state) => {
      state.policyEnrolData.status = 'loading';
      state.policyEnrolData.error = '';
    },
    [fetchEnrolledPolicy.fulfilled.toString()]: (state, action) => {
      state.policyEnrolData.data = action.payload.data;
      state.policyEnrolData.status = 'succeeded';
    },
    [fetchEnrolledPolicy.rejected.toString()]: (state, action) => {
      state.policyEnrolData.status = 'failed';
      state.policyEnrolData.error = action.error?.message;
    },

    [fetchUserDependentPolicies.pending.toString()]: (state) => {
      state.dependentPolicyData.status = 'loading';
      state.dependentPolicyData.error = '';
    },
    [fetchUserDependentPolicies.fulfilled.toString()]: (state, action) => {
      const k = action.meta.arg;
      const v = action.payload.data;
      state.dependentPolicyData.data = {
        ...state.dependentPolicyData.data,
        [k.toString()]: v,
      };
      state.dependentPolicyData.status = 'succeeded';
    },
    [fetchUserDependentPolicies.rejected.toString()]: (state, action) => {
      state.dependentPolicyData.status = 'failed';
      state.dependentPolicyData.error = action.error?.message;
    },
    [fetchPolicyEmpCount.pending.toString()]: (state) => {
      state.policyEmpCount.status = 'loading';
      state.policyEmpCount.error = '';
    },
    [fetchPolicyEmpCount.fulfilled.toString()]: (state, action) => {
      state.policyEmpCount.status = 'succeeded';
      // state.policyEmpCount.data = action.payload.data;

      // Below Code is done to bind Employees and dependents count to Policy.
      const policyItem = { ...state.policyEmpCount.data };
      // if (policyItem && policyItem[action.payload.id]) {
      // update Array
      policyItem[action.payload.id] = {
        ...action.payload.data,
        ...action.payload.policyData,
      };
      // }
      state.policyEmpCount.data = policyItem;
    },
    [fetchPolicyEmpCount.rejected.toString()]: (state, action) => {
      state.policyEmpCount.status = 'failed';
      state.policyEmpCount.error = action.error?.message;
      state.policyEmpCount.data = null;
    },

    [fetchCompanyPolicyDetails.pending.toString()]: (state, action) => {
      state.compPolicyDetails.status = 'loading';
      state.compPolicyDetails.error = '';
      state.compPolicyDetails.data = [];
    },
    [fetchCompanyPolicyDetails.fulfilled.toString()]: (state, action) => {
      state.compPolicyDetails.status = 'succeeded';
      state.compPolicyDetails.data = action.payload.data;
    },
    [fetchCompanyPolicyDetails.rejected.toString()]: (state, action) => {
      state.compPolicyDetails.status = 'failed';
      state.compPolicyDetails.error = action.error?.message;
      state.compPolicyDetails.data = null;
    },

    'common/cleanup': (state, action) => {
      state.policyEnrolData = {
        ...apiState,
      };
      state.dependentPolicyData = {
        ...apiState,
      };
      state.companyPolicyListData = {
        ...apiState,
      };
      state.compPolicyDetails = {
        ...apiState,
      };
      state.policyEmpCount = {
        ...apiState,
      };
    },
  },
});

export const {
  clearEnrolledPolicy,
  clearDependentPolicy,
  addDependentPolicyDataByUserId,
} = policyListSlice.actions;

export const selectPolicyEnrolledfData = (state: RootState) =>
  state.policyList.policyEnrolData;

export const selectUserDependentPolicies = (state: RootState) =>
  state.policyList.dependentPolicyData;

export const selectCompanyPolicyList = (state: RootState) =>
  state.policyList.companyPolicyListData;

export const selectPolicyEmpCount = (state: RootState) =>
  state.policyList.policyEmpCount;

export const selectCompanyPolicyDetails = (state: RootState) =>
  state.policyList.compPolicyDetails;

export default policyListSlice.reducer;
