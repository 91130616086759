import React, { useContext, useState, useEffect } from 'react';

import { auth } from '../firebase';

import { AppConstants } from '../utils/common/AppConstants';
import LoopApiService from '../adaptars/LoopApiService';

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true);

    const [company, setCompany] = useState('');

    function signup(email, password) {
        return auth.createUserWithEmailAndPassword(email, password);
    }

    function login() {
        // return auth.signInWithEmailAndPassword(email, password);
    }

    function logout() {
        return auth.signOut();
    }

    function resetPassword(email) {
        return auth.sendPasswordResetEmail(email);
    }

    function setLoggedInUser(user) {
        return setCurrentUser(user);
    }

    function setHrCompany(companyName) {
        return setCompany(companyName);
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged( async (user) => {
            // setCurrentUser(user);
            let emailId = window.localStorage.getItem(AppConstants.EMAIL_FOR_SIGNIN);
            if (emailId) {
                const email =
                    (user && user.providerData && user.providerData.length > 0 && user.providerData[0].email) || null;
                if (email) {
                    try{
                        const response = await LoopApiService.getHRDetails(email, 'email', 'email');
                        if (response) setCurrentUser({ ...user, email });
                    }
                    catch(error){
                        throw error;
                    };
                }
            }
            setLoading(false);
        });

        return unsubscribe;
    },[]);

    const value = {
        currentUser,
        company,
        signup,
        login,
        logout,
        resetPassword,
        setLoggedInUser,
        setHrCompany
    };

    return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
}
