import {
  ClaimAnalyticsMenuIcon,
  ClaimAnalyticsSelectedMenuIcon,
  ClaimsMenuIcon,
  ClaimsSelectedMenuIcon,
  DashboardMenuIcon,
  DashboardSelectedMenuIcon,
  EmployeesMenuIcon,
  EmployeesSelectedMenuIcon,
  EndorsementsMenuIcon,
  EndorsementsSelectedMenuIcon,
  PoliciesMenuIcon,
  PoliciesSelectedMenuIcon,
  WalletIcon,
} from '../../../assets/images';
import { isEndoVisible, isHRDEnabled } from '../../../utils/featureFlag';
import { ICompanyConfig } from './types';

export const getHRDMenuItems = (companyConfig: ICompanyConfig) => {
  return [
    {
      id: 'dashboard-page',
      name: 'Dashboard',
      icon: DashboardMenuIcon,
      selectedIcon: DashboardSelectedMenuIcon,
      routes: ['/dashboard'],
      isDisabled: isHRDEnabled,
    },
    {
      id: 'policies-page',
      name: 'Policies',
      icon: PoliciesMenuIcon,
      selectedIcon: PoliciesSelectedMenuIcon,
      routes: ['/policies', '/policy-details'].concat(
        isHRDEnabled ? ['/dashboard'] : [],
      ),
    },
    {
      id: 'employees-page',
      name: 'Employees',
      icon: EmployeesMenuIcon,
      selectedIcon: EmployeesSelectedMenuIcon,
      routes: ['/employees', '/select-policies', '/employee-details'],
    },
    {
      id: 'claim-details-page',
      name: 'Claim Details',
      icon: ClaimsMenuIcon,
      selectedIcon: ClaimsSelectedMenuIcon,
      routes: ['/claims', '/claim-details'],
    },
    {
      id: 'claim-analytics-page',
      name: 'Claim Analytics',
      icon: ClaimAnalyticsMenuIcon,
      selectedIcon: ClaimAnalyticsSelectedMenuIcon,
      routes: ['/claim-analytics'],
    },

    {
      id: 'endorsement-page',
      name: 'Endorsements',
      icon: EndorsementsMenuIcon,
      selectedIcon: EndorsementsSelectedMenuIcon,
      routes: [
        '/endorsements',
        '/bulk-add',
        '/bulk-delete',
        '/endorsement-details',
        '/bulk-register',
      ],
      isDisabled: !isEndoVisible,
    },
    {
      id: 'cd-account-page',
      name: 'CD Accounts',
      icon: WalletIcon,
      selectedIcon: WalletIcon,
      routes: ['/cd-accounts'],
    },
  ];
};
