import React from 'react';
import { StyledWaitListContainer } from './styles';
import Header from './Header';
import FAQs from './FAQs';
import DemoBanner from './DemoBanner';
import { TopNavigation } from '@loophealth/loop-ui-web-library';
import { useHistory } from 'react-router-dom';

export const WaitList = () => {
  const history = useHistory();

  const handleSignOutButtonClick = () => {
    history.replace('/login');
  };

  return (
    <StyledWaitListContainer>
      <TopNavigation
        buttons="primary"
        primaryButtonText="Sign out"
        handlePrimaryButtonClick={handleSignOutButtonClick}
      />
      <Header />
      <FAQs />
      <DemoBanner />
    </StyledWaitListContainer>
  );
};
