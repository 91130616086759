import styled from 'styled-components';

export const StyledClaimsRecordEmailContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledEmailModalHeader = styled.div`
  background: ${(p) => p.theme.colors.loopEmerald};
  padding: 2rem 2rem 1rem 2rem;
  display: flex;
  justify-content: space-between;
`;

export const StyledEmailHeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: end;
  justify-content: center;
  transform: translateY(-1.5rem);
`;

export const StyledEmailModalBodyContainer = styled.div`
  display: flex;
  padding: 2rem;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 84%,
    rgba(255, 255, 255, 0.8) 100%,
    rgba(255, 255, 255, 0) 100%
  );
  margin-top: -2rem;
  z-index: 1;
`;
export const StyledEmailModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
`;

export const StyledEmailTextContainer = styled.div`
  max-width: 35.75rem;
`;

export const StyledEmailCtaContainer = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
`;

export const StyledEmailTextboxContainer = styled.div`
  width: 100%;
  pointer-events: none;
`;
