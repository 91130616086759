import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  align-items: center;
  padding: 65px;

  button {
    width: max-content;
  }
`;
export const StyledDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;
export const StyledIllustration = styled.div`
  height: 200px;
  width: 600px;
  border-radius: 12px;
  background: #e6efed;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const StyledPolicySummaryContainer = styled.div`
  border-radius: 12px;
  background: white;
  display: flex;
  padding: 22px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
`;

export const StyledPolicySummaryContainerItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const StyledQueueGif = styled.img`
  height: 100%;
  width: auto;
`;
