import styled from 'styled-components';

export const StyledCardWrapper = styled.div<{
  $color: string;
  $border: string;
}>`
  display: flex;
  padding: 16px;
  border-radius: 12px;
  background-color: ${(p) => p.$color};
  border: 1px solid ${(p) => p.$border};
  flex-direction: column;
  min-width: 290px;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex: 1; /* Allow this container to expand and push the next container to the right */
  gap: 12px;
  align-items: flex-start;
`;

export const StyledStyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  flex: 1; /* Allow this container to expand and push the right icon to the right */
`;

export const StyledStatusContent = styled.div`
  /* width: 254px; */
`;

export const StyledSubStatusTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledStatusSubContent = styled.div<{
  $color: string;
}>`
  /* border-radius: 0px 0px 8px 8px; */
  background: ${(p) => p.$color};
  display: flex;
  flex-direction: column;
`;

export const StyledStatusSubContentWrapper = styled.div`
  padding: 12px;
`;

export const StyledHeadingIconWrapper = styled.div<{
  $marginTop?: string;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center; /* Vertically align the heading and right icon */
  margin-top: ${(p) => (p.$marginTop ? p.$marginTop : '0px')};
`;

export const StyledCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledTimelineWidget = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  margin-top: 10px;
`;

export const StyledVerticalSpacer = styled.div<{ $backgroundColor?: string }>`
  width: 1px;
  background-color: ${(p) =>
    p.$backgroundColor ? p.$backgroundColor : p.theme.colors.seafoamGray};
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
