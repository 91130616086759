import styled from 'styled-components';

export const StyledFAQs = styled.section`
  padding: 100px 50px 50px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  background-color: ${({ theme }) => theme.colors.surfaceOne};
`;

export const StyledFQQList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const StyledFAQRow = styled.ul`
  display: flex;
  gap: 48px;
  margin: 0;
  padding: 0;
`;

export const StyledFAQItem = styled.li`
  gap: 12px;
  display: flex;
  align-items: flex-start;
  flex: 1;
  margin: 0;
  padding: 0;
`;

export const StyledFAQItemText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
