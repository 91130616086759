import {
  IUserFamilyDetailsRequest,
  IDeleteRequest,
  IAddEmployeeRequest,
  IPolicyParam,
} from './types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import LoopApiService from '../../../adaptars/LoopApiService';

export const addUserPolicyParallelly = async (policyParams: IPolicyParam[]) =>
  await Promise.all(
    policyParams?.map(
      async (policyParam) => await LoopApiService.addUserPolicy(policyParam),
    ) ?? [],
  );

export const addEmployeeRequest = createAsyncThunk(
  'hrRequest/addEmployeeRequest',
  async ({ data, policiesData = [] }: IAddEmployeeRequest) => {
    try {
      const result: any = await LoopApiService.addEmpHrRequest(data);
      let result2 = null;
      if (result.data.userId && policiesData.length !== 0) {
        const policyParams = policiesData?.map(
          ({ id, slabId, enrolmentCycle }) => ({
            userId: result.data.userId,
            ctc: data.ctc,
            policyId: id,
            slabId,
            enrolmentCycle: enrolmentCycle ?? 'At Inception',
            enrolmentStatus: 'PENDING',
            policyStatus: 'ENROLED',
          }),
        );
        result2 = await addUserPolicyParallelly(policyParams);
      }
      return {
        data: {
          employeeData: result?.data ?? {},
          policyData: result2?.map((r) => r?.data) ?? [],
        },
      };
    } catch (error) {
      throw error;
    }
  },
);

export const fetchUserDetails = createAsyncThunk(
  'hrRequest/fetchUserDetails',
  async ({ userId }: { userId: string }) => {
    try {
      const result = await LoopApiService.getUserDetails(userId);
      return result;
    } catch (error) {
      throw error;
    }
  },
);

export const fetchUserDependents = createAsyncThunk(
  'fetchUserDependents',
  async (parentId: string) => {
    try {
      const result = await LoopApiService.getEmployeeData({ parentId });
      return { data: result.data ?? result.users };
    } catch (error) {
      throw error;
    }
  },
);

export const fetchUserDependent = createAsyncThunk(
  'fetchUserDependent',
  async ({ userId }: { userId: string }) => {
    try {
      const result = await LoopApiService.getUserDetails(userId);
      return result;
    } catch (error) {
      throw error;
    }
  },
);

export const deleteUserDetails = createAsyncThunk(
  'hrRequest/deleteUserDetails',
  async (data: IDeleteRequest) => {
    try {
      const response: any = await LoopApiService.deleteEmpDetails(data);
      if (response.data.failure.length > 0) {
        throw new Error(response.data.failure[0].error);
      }
      if (response.status !== 200) {
        throw new Error(
          response.message ?? 'deleteUserDetails /offboardUser failed',
        );
      }
      return response;
    } catch (error) {
      throw error;
    }
  },
);

export const deleteUserDependentDetails = createAsyncThunk(
  'hrRequest/deleteUserDependentDetails',
  async (data: IDeleteRequest) => {
    try {
      const response: any = await LoopApiService.deleteEmpDetails(data);
      if (response.status !== 200) {
        throw new Error(
          response.message ?? 'deleteUserDependentDetails /offboardUser failed',
        );
      }
      return response;
    } catch (error) {
      throw error;
    }
  },
);

export const fetchUserFamilyDetails = createAsyncThunk(
  'hrRequest/fetchUserFamilyDetails',
  async ({ data }: { data: IUserFamilyDetailsRequest }) => {
    try {
      const result = await LoopApiService.getUserFamilyDetails(data);
      return { data: result };
      // process Response here
    } catch (error) {
      throw error;
    }
  },
);
