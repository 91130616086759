/* eslint-disable */
import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/analytics';
import 'firebase/functions';
import { nanoid } from 'nanoid';
import useToken from './hooks/useToken';
import axios from 'axios';

export interface ISuccessResponse {
  message: string;
  name: string;
  data: any;
}
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  appVerificationDisabledForTesting:
    process.env.REACT_APP_DISABLED_FIREBASE_CAPTCHA,
};

const authToken = useToken();
let FirebaseProvider;
if (firebase.apps.length === 0) {
  FirebaseProvider = firebase.initializeApp(firebaseConfig);
} else {
  FirebaseProvider = firebase.app();
}
const appVerificationDisabledForTesting =
  process.env.REACT_APP_DISABLED_FIREBASE_CAPTCHA === 'true' ? true : false;
if (appVerificationDisabledForTesting) {
  firebase.auth().settings.appVerificationDisabledForTesting = true;
}
export const Firestore = FirebaseProvider.firestore();
export const FirebaseStorage = FirebaseProvider.storage();
export const FirebaseFunctions = FirebaseProvider.functions('asia-south1');
export const FirebaseCaptcha = firebase.auth;
export const FirebaseAuth = firebase.auth();
export const FirebaseTimestamp = firebase.firestore.Timestamp;
export const FirebaseFieldValue = firebase.firestore.FieldValue;
export default FirebaseProvider;

export const getContentType = (res: any): string => {
  const isJSON =
    res.headers.get('Content-Type')?.startsWith('application/json') || false;

  if (isJSON) {
    return 'JSON';
  }

  const isText = res.headers.get('Content-Type')?.startsWith('text') || false;
  if (isText) {
    return 'Text';
  }

  return 'Unsupported';
};

export const processResponse = async (res: any) => {
  const contentType = getContentType(res);

  if (res.ok) {
    if (contentType === 'JSON') {
      return await res.json();
    } else {
      return res;
    }
  }
};

type IMethod = 'DELETE' | 'PUT' | 'POST' | 'GET';

export const accessAPI = async (
  method: IMethod,
  url: string,
  body?: Record<string, unknown>,
): Promise<Record<string, unknown>> => {
  try {
    let baseUrl = process.env.REACT_APP_FIREBASE_FUNCTION_BASE_URL;
    const token = await authToken.generateToken();
    const result = await fetch(baseUrl + url, {
      method: method,
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        'loop-source': process.env.REACT_APP_NAME ?? '',
        'trace-id': nanoid(),
      },
    });
    if (result.ok) {
      return await processResponse(result);
    } else {
      const errorMessage = (await result.json()).message;
      // if result is such tht token is expired then process new token
      if (authToken.tokenIsExpired(errorMessage)) {
        try {
          const isTokenAvailable = await authToken.generateToken();
          if (isTokenAvailable) {
            return await accessAPI(method, url, body);
          }
        } catch (e) {
          console.log(e);
        }
      }
      if (Array.isArray(errorMessage)) {
        throw new Error(JSON.stringify(errorMessage));
      }
      throw new Error(errorMessage);
    }
  } catch (e) {
    throw e;
  }
};

export const get = async (
  url: string,
): Promise<Record<string, unknown> | ISuccessResponse> => {
  try {
    return await accessAPI('GET', url);
  } catch (e) {
    throw e;
  }
};

export const post = async (
  url: string,
  body: Record<string, unknown>,
): Promise<Record<string, unknown>> => {
  try {
    return await accessAPI('POST', url, body);
  } catch (e) {
    throw e;
  }
};

export const put = async (
  url: string,
  body: Record<string, unknown>,
): Promise<Record<string, unknown>> => {
  try {
    return await accessAPI('PUT', url, body);
  } catch (e) {
    throw e;
  }
};

export const del = async (url: string, body?: Record<string, unknown>): Promise<Record<string, unknown>> => {
  try {
    return await accessAPI('DELETE', url, body);
  } catch (e) {
    throw e;
  }
};
export const uploadFile = async (
  url: string,
  formData?: unknown,
): Promise<Record<string, unknown>> => {
  try {
    let baseUrl = process.env.REACT_APP_FIREBASE_FUNCTION_BASE_URL;
    const token = await authToken.generateToken();
    return await axios.post(baseUrl + url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (e) {
    throw e;
  }
};
