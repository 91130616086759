import {
  DataCorrectPlaceholder,
  DataErrorsPlaceholder,
  DataProcessPlaceholder,
} from '../../../assets/images';
import { IFullPageLoader } from '../../atoms/Loader/types';
import { IBulkOperation } from './types';

export const LOADING_CONSTANTS: Record<string, IFullPageLoader> = {
  VALIDATE_MEMBERS: {
    image: DataProcessPlaceholder,
    type: 'loading',
    title: 'Verifying your data...',
    subtitle: "Hold on, we're checking your data for any hiccups!",
  },
  VALIDATE_MID_TERMS: {
    image: DataProcessPlaceholder,
    type: 'loading',
    title: 'Verifying your data...',
    subtitle: "Almost there! We're looking into the data you just provided",
  },
  VALIDATION_HAS_ERRORS: {
    image: DataErrorsPlaceholder,
    type: 'error',
    title: 'Your data is verified...',
    subtitle: 'Oops! We found some errors. Let’s get them Fixed.',
  },
  VALIDATION_HAS_NO_ERRORS: {
    image: DataCorrectPlaceholder,
    type: 'success',
    title: 'Your data is verified...',
    subtitle: 'Voila! Your data is spot-on. No Errors Found.',
  },
};

export const STEPPER_DATA: Record<IBulkOperation, string[]> = {
  ADD: [
    'Upload Addition Data',
    'Review Uploaded Data',
    'Submit Addition Request',
  ],
  DELETE: [
    'Upload Deletion Data',
    'Review Uploaded Data',
    'Submit Deletion Request',
  ],
};

export const VIDEO_URLS: Record<IBulkOperation, string> = {
  ADD: 'https://www.youtube.com/embed/jCn9A60pABo?si=sUNtWqljpGrq3DmI',
  DELETE: 'https://www.youtube.com/embed/Rjugsk0duTY?si=yl60th3dH1D0-qJw',
};
