import React, { useMemo } from 'react';
import {
  StyledChartsPadding,
  StyledClaimAnalyticsPage,
  StyledClaimAnalyticsPagePaddedSection,
} from './styles';
import ClaimsHeaderDropdown from '../../containers/ClaimsHeaderDropdown';
import AnalyticsOverview from '../../atoms/AnalyticsOverview';
import CashlessClaims from '../../containers/Analytics/CashlessClaims';
import ReimbursementClaimMetric from '../../containers/Analytics/ReimbursementClaimMetric';
import SumInsuredUtilizedTable from '../../containers/Analytics/SumInsuredUtilizedTable';
import useClaimsAnalysis from './hooks/useClaimsAnalysis';
import useFetchClaimList from '../ClaimsList/hooks/useFetchClaimList';
import Loader from '../../atoms/Spinner';
import { useSelector } from 'react-redux';
import { selectPoliciesList } from '../../../redux/slices/hrdRevampRedux';
import useSearchQuery from '../ClaimsList/hooks/useSearchQuery';
import { getPolicyListForClaimsDropDown } from '../ClaimsList/utils';
import { getClaimsHeaderSubtitle } from '../ClaimsList/utils';
import { extractPathName, trackClickEvent } from '../../../utils/segment/utils';
import useSegment from '../../../utils/segment/hooks/useSegment';
import { useLocation } from 'react-router-dom';
import useTrackPage from '../../../utils/segment/hooks/useTrackPage';

const Analytics = () => {
  const trackClick = useSegment('click');
  const location = useLocation();
  const { data } = useSelector(selectPoliciesList);
  const dropDownFilters = useMemo(() => {
    return getPolicyListForClaimsDropDown(data, false);
  }, [data]);

  const [policyNo, setSelectedPolicyFilter] = useSearchQuery(
    'policyNo',
    dropDownFilters?.length && dropDownFilters[0].value,
  );
  const { isLoading, claims } = useFetchClaimList();
  const filteredClaims = useMemo(() => {
    return claims?.filter((row) => row.policyNo === policyNo);
  }, [claims, policyNo]);

  const claimAnalticsData = useClaimsAnalysis(filteredClaims);
  const {
    claimsValue,
    csr,
    totalClaimed,
    cashlessDataSet,
    reimburseDataSet,
    siUtilization,
  } = claimAnalticsData || {};

  const subtitle = getClaimsHeaderSubtitle(data);

  const handlePolicyChange = (selectedPolicyFilter: string) => {
    setSelectedPolicyFilter(selectedPolicyFilter);
    const policyProperties: Record<string, unknown> = {
      policy_number: selectedPolicyFilter,
    };
    trackClick(
      trackClickEvent(
        'POLICY_SECTION_DROPDOWN',
        location?.pathname,
        extractPathName(location?.pathname),
        policyProperties,
      ),
    );
  };
  useTrackPage({
    policy_number: policyNo,
    policy_type: dropDownFilters.find((item) => item.value === policyNo)?.data
      ?.policyType,
    claim_count: filteredClaims.length,
  }, !isLoading);

  return (
    <StyledClaimAnalyticsPage>
      <StyledClaimAnalyticsPagePaddedSection>
        <ClaimsHeaderDropdown
          title="Claims Analytics"
          subtitle={subtitle}
          policiesOptions={dropDownFilters}
          selectedPolicyFilter={policyNo || ''}
          setSelectedPolicyFilter={handlePolicyChange}
        />
        {isLoading ? (
          <Loader />
        ) : (
          <StyledChartsPadding>
            <AnalyticsOverview
              totalClaimed={totalClaimed}
              csr={csr}
              claimValue={claimsValue}
            />
            <ReimbursementClaimMetric {...reimburseDataSet} />
            <CashlessClaims {...cashlessDataSet} policyNo={policyNo} />
          </StyledChartsPadding>
        )}
      </StyledClaimAnalyticsPagePaddedSection>
      {!isLoading && <SumInsuredUtilizedTable data={siUtilization} />}
    </StyledClaimAnalyticsPage>
  );
};

export default Analytics;
