import { ITheme } from './types';

const theme: ITheme = {
    colors: {
        beyondGrey: '#595959',
        disabledGrey: '#9B9B9B',
        turquoise: '#36D6C3',
        planRed: '#FF8080',
        coverUpGreen: '#BCDD33',
        coverFillGreen: '#025f4c',
        lavenderBlush: "#FFF2F2",
        lightSilver: "#EEEEEE",
        seafoamGray: "#CBD6D3",
        coverYellow: '#FDD506',
        mintFrost: '#E6EFED',
        consultViolet: '#A586EF',
        black: '#2D3748',
        white: '#ffffff',
        border: '#D4E3EB',
        muted: '#A0AEC0',
        error: '#CB3C3A',
        primary: '#1BB4A4',
        secondary: '#F7F8FA',
        primaryBG: '#18A294',
        activeBG: '#F1F35',
        secondaryBG: 'rgba(212, 227, 235, 0.2)',
        errorBG: '#FAECEB',
        mutedBG: '#718096',
        primaryTransparent: 'rgba(27, 180, 164, 0.2)',
        mutedTransparent: 'rgba(160, 174, 192, 0.2)',
        supportingPlaneGreenDark: '#0D7963',
        surfaceOne: '#FFF',
        loopEmerald: '#025F4C',
        surface0: '#F8F9FA',
        chineseWhite: '#DDE2E1',
        shadow: '#3741511a'
    },
    fontSizes: {
        landing: '30px',
        title: '24px',
        heading: '18px',
        body: '14px',
        sectionHeading: '10px',
        label: '12px'
    }
};

export const colorLibrary = [
    '#066066',
    '#cb3c3a',
    '#7f2341',
    '#009cc3',
    '#81a269',
    '#d67358',
    '#685f80',
    '#b2ab67',
    '#7cb9b2',
    '#877d8e'
];

export default theme;
