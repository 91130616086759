export const downloadSignedFile = async (
  URL: string,
  method: string,
  fileName: string,
): Promise<void> => {
  try {
    const file = await fetch(URL, {
      method,
    });
    const link = document.createElement('a');
    link.href = file.url;
    link.download = fileName;
    if (fileName.toLowerCase().endsWith('.pdf')) link.target = '_blank';
    link.click();
    link.remove();
  } catch (error) {
    throw error;
  }
};
