import { Typography } from '@loophealth/loop-ui-web-library';
import {
  ITableHeader,
  ITableHeaderDef,
} from '@loophealth/loop-ui-web-library/dist/types/atoms/Table/types';
import {
  IUploadedPolicywiseData,
  IPolicyRejectedEntries,
  IRejectedAddEntity,
} from '../../pages/Endorsements/BulkAddMembers/types';
import {
  ErrorKeyMap,
  FIXED_ADD_TABLE_COLUMNS,
  KEY_NAME_MAP,
  OTHER_ADD_TABLE_COLUMNS,
} from './constants';
import { StyledCapitalizedWords, StyledErrorsContainer } from './styles';
import { IPolicyCount, IValidationSummaryData } from './types';
import { IPolicyListItemType } from '../../../redux/slices/hrdRevampRedux/types';
import {
  CTC_MULTIPLIER_SCHEMA,
  FLAT_SUM_SCHEMA,
  GRADED_TIER_SCHEMA,
} from '../../pages/Endorsements/BulkAddMembers/ErrorSheet/constant';
import {
  IBulkDeleteData,
  IRejectedDeleteEntity,
} from '../../pages/Endorsements/BulkDeleteMembers/types';
import { IBulkOperation, IMemberError } from '../Bulk/types';

export const getAddValidationSummaryData = (
  uploadedData: IUploadedPolicywiseData,
  rejectedEntries: IPolicyRejectedEntries,
  policies: IPolicyListItemType[],
): IValidationSummaryData => {
  return Object.keys(uploadedData).reduce(
    (prev, policyId) => {
      const currentLength = uploadedData[policyId].length;
      const rejectedLength = rejectedEntries[policyId]?.length || 0;
      const correctLength = currentLength - rejectedLength;
      const currentPolicyType =
        policies.find((policy) => policy.id === policyId)?.policyType || '';
      return {
        total: {
          totalLives: prev.total.totalLives + currentLength,
          policyLives: prev.total.policyLives.concat({
            type: currentPolicyType,
            count: currentLength,
          }),
        },
        correct: {
          totalLives: prev.correct.totalLives + correctLength,
          policyLives: prev.correct.policyLives.concat({
            type: currentPolicyType,
            count: correctLength,
          }),
        },
        incorrect: {
          totalLives: prev.incorrect.totalLives + rejectedLength,
          policyLives: prev.incorrect.policyLives.concat({
            type: currentPolicyType,
            count: rejectedLength,
          }),
        },
      };
    },
    {
      total: {
        totalLives: 0,
        policyLives: [],
      },
      correct: {
        totalLives: 0,
        policyLives: [],
      },
      incorrect: {
        totalLives: 0,
        policyLives: [],
      },
    } as IValidationSummaryData,
  );
};
export const getDeleteValidationSummaryData = (
  uploadedData: IBulkDeleteData[],
  rejectedEntries: IRejectedDeleteEntity[],
): IValidationSummaryData => {
  const currentLength = uploadedData.length;
  const rejectedLength = rejectedEntries.length;
  const correctLength = currentLength - rejectedLength;
  return {
    total: {
      totalLives: currentLength,
      policyLives: [],
    },
    correct: {
      totalLives: correctLength,
      policyLives: [],
    },
    incorrect: {
      totalLives: rejectedLength,
      policyLives: [],
    },
  } as IValidationSummaryData;
};

export const formatCell = <TData extends IMemberError>(
  key: keyof TData,
  rowData: TData,
) => {
  let isErrored = false;
  try {
    const errors = JSON.parse(rowData.error ?? '{}');
    isErrored = !!errors[ErrorKeyMap[key as string] ?? key];
  } catch (e) {}
  const value = rowData[key];
  return (
    <StyledCapitalizedWords>
      <Typography variant="small" color={isErrored ? 'error' : 'primary'}>
        {(value as string) ?? '-'}
      </Typography>
    </StyledCapitalizedWords>
  );
};

export const getAddErrorTableColumns = (
  selectedPolicy: string,
  policies: IPolicyListItemType[],
): ITableHeaderDef<IRejectedAddEntity> => {
  const policyDetails = policies.find((policy) => policy.id === selectedPolicy);
  // let conditionalColumns: ITableHeader<IRejectedAddEntity>[] = [];

  const conditionalColumns = (() => {
    switch (policyDetails?.sumInsuredStructure) {
      case 'CTC Multiplier':
        return CTC_MULTIPLIER_SCHEMA;
      case 'Flat Sum Insured':
        return FLAT_SUM_SCHEMA;
      case 'Graded Tier':
        return GRADED_TIER_SCHEMA;
      default:
        return [];
    }
  })().map((column) => OTHER_ADD_TABLE_COLUMNS[column.name]);

  return FIXED_ADD_TABLE_COLUMNS.concat(conditionalColumns);
};

export const getPolicyListCount = (
  rejectedData: IPolicyRejectedEntries,
  policies: IPolicyListItemType[],
): IPolicyCount[] => {
  return Object.keys(rejectedData).map((policyId) => {
    const currentPolicy = policies.find((policy) => policy.id === policyId);

    return {
      id: policyId,
      type: currentPolicy?.policyType || '',
      count: rejectedData[policyId].length,
    };
  });
};
