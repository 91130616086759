import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useFormInput from '../../../utils/hooks/useFormInput';
import DataTable from '../../atoms/Datatables';
import { selectchangeRequests } from '../../../redux/slices/changeRequestSlice';
import { fetchChangeRequests } from '../../../redux/slices/changeRequestSlice/thunks';
import { getChangeRequestTableColumns } from '../../../utils/common/columns';
import ServerSidePagination from '../../atoms/Pagination/ServerSidePagination';
import SearchPartialPagination from '../../atoms/Pagination/SearchPartialPagination';
import Spinner from '../../atoms/Spinner/Spinner';
import './ChangeRequest.scss';
import { useLocation } from 'react-router-dom';
import useSegment from '../../../utils/hooks/useSegment';
import { getTrackClickObj } from '../../../utils/common/Utilities';
import {selectSelectedCompany} from "../../../redux/slices/hrdRevampRedux";

const defaultPageSize = 10;
export const ChangeRequest = () => {
  const dispatch = useDispatch();
  const changeRequests = useSelector(selectchangeRequests);
  const { status, error, data, metaData } = changeRequests ?? {};
  const {
    pageNo = 0,
    hasPrev = false,
    hasNext = false,
    nextPageId,
    prevPageId,
  } = metaData ?? {}
  const selectedCompany = useSelector(selectSelectedCompany);
  const companyId = selectedCompany?.id;
  const searchByName = useFormInput('');
  const [currentPage, setCurrentPage] = useState(0);
  const trackClick = useSegment('click');
  const location = useLocation();
  const callTrackClick = (action) =>
    trackClick(
      getTrackClickObj(action, location?.pathname, 'HRD DashboardPage Events'),
    );

  useEffect(() => {
    if (companyId) {
      dispatch(
        fetchChangeRequests({
          companyId,
          pageSize: defaultPageSize,
          pageNo: 0,
        }),
      );
    }
  }, [companyId]);

  const handlePageChange = (page) => {
    const isNext = currentPage < page;
    if (isNext) {
      callTrackClick('CHANGE_REQUEST_NEXT');
    } else {
      callTrackClick('CHANGE_REQUEST_PREVIOUS');
    }
    dispatch(
      fetchChangeRequests({
        companyId,
        pageSize: defaultPageSize,
        pageNo: pageNo ?? 0,
        ...(isNext ? { nextPageId } : { prevPageId }),
      }),
    );
    setCurrentPage(page);
  };

  const filteredData = useMemo(() => {
    let result = data ?? [];
    if (searchByName.value) {
      callTrackClick('CHANGE_REQUEST_SEARCH_BAR');
      const fieldIncludes = (field, search) =>
        !!field?.toLowerCase().includes(search.toLocaleLowerCase());
      const searchIncludes = (field) =>
        field && fieldIncludes(field, searchByName.value);
      result = result.filter((c) => searchIncludes(c?.name));
    }
    if (currentPage !== 0 && status === 'failed') {
      return [];
    }
    return result;
  }, [data, searchByName.value, currentPage, status]);

  if (!companyId) {
    return (
      <div className="dt_no_records">
        <label>Oops! Couldn't find The company</label>
        <span>Please try logging out and loggin back in</span>
      </div>
    );
  }

  return (
    <div className="dataTable_inner dataTable_controls mt-3">
      {currentPage === 0 && status === 'failed' ? (
        <div className="empty_emp_table">
          <label className="status">
            {error ?? 'Change Requests API Failed!'}
          </label>
        </div>
      ) : currentPage !== 0 && status === 'failed' ? (
        <div className="empty_emp_table search_and_no_records">
          <div className="dt_no_records">
            <label>{error ?? 'Could not find the page!'}</label>
          </div>
          <SearchPartialPagination
            rowsPerPage={10}
            onChangePage={handlePageChange}
            pageNo={currentPage}
            hasPrev={hasPrev}
            hasNext={hasNext}
            filteredDataLength={filteredData.length}
            noUiFilter={!searchByName.value}
          />
        </div>
      ) : status === 'succeeded' &&
        filteredData.length < 1 &&
        searchByName.value.length < 1 ? (
        <div className="empty_emp_table">
          <label className="status">No Recent change requests!</label>
        </div>
      ) : (
        <DataTable
          keyField={'id'}
          columns={getChangeRequestTableColumns()}
          data={filteredData}
          subHeader={true}
          subHeaderComponent={
            <div className="main_searchbar">
              <div className="form-group has-search">
                <span className="fa fa-search form-control-feedback" />
                <input
                  type="text"
                  placeholder="Name"
                  name="fname"
                  {...searchByName.htmlAttributes}
                  className="form-control search_input"
                />
              </div>
            </div>
          }
          persistTableHead
          progressPending={status === 'loading'}
          progressComponent={
            <div className="w-100 p-3 text-center spinner-bg-grey">
              <Spinner />
            </div>
          }
          highlightOnHover
          pointerOnHover
          paginationServer
          pagination
          paginationResetDefaultPage={0}
          paginationComponent={ServerSidePagination}
          paginationComponentOptions={{
            filteredDataLength: filteredData.length,
            noUiFilter: !searchByName.value,
            pageNo: currentPage,
            hasPrev,
            hasNext,
            onChangePage: handlePageChange,
          }}
          onChangePage={handlePageChange}
          noDataComponent={
            searchByName.value !== '' ? (
              <div className="search_and_no_records">
                <div className="dt_no_records">
                  <label>
                    Oops! Couldn't find any search results for “
                    {searchByName.value}”
                  </label>
                  <span>Please enter a valid Employee name</span>
                </div>
                <SearchPartialPagination
                  rowsPerPage={10}
                  onChangePage={handlePageChange}
                  pageNo={currentPage}
                  hasPrev={hasPrev}
                  hasNext={hasNext}
                  filteredDataLength={filteredData.length}
                  noUiFilter={!searchByName.value}
                />
              </div>
            ) : null
          }
        />
      )}
    </div>
  );
};
