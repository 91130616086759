import React, {useEffect} from "react";

const usePaginatedClaimsList = (claimsList: Record<string, unknown>[], currentPage: number, perPageCount: number) => {
    const [filteredClaimsList, setFilteredClaimsList] = React.useState<Record<string, unknown>[]>([]);

    useEffect(() => {
        const startIndex = (currentPage - 1) * perPageCount;
        const endIndex = startIndex + perPageCount;
        const filteredData = claimsList.slice(startIndex, endIndex);
        setFilteredClaimsList(filteredData)
    }, [currentPage, claimsList])

    return {filteredClaimsList}
}
export default usePaginatedClaimsList;
