import React from 'react';
import { StyledClaimsContainer, StyledTabsWrapper } from './styles';
import { Tabs } from '@loophealth/loop-ui-web-library';
import { ITabItem } from '@loophealth/loop-ui-web-library/dist/types/atoms/Tabs/types';
import { AnalyticsLegacy } from '../Analytics/legacy';
import ClaimsSearch from './claimsSearch';
import { isClaimsDashboardEnabled } from '../../../utils/featureFlag';
import { useSelector } from 'react-redux';
import { selectCompanyConfig } from '../../../redux/slices/companySlice';

const claimsTabItems: ITabItem[] = [
  {
    id: '1',
    value: 'All Claims',
  },
  {
    id: '2',
    value: 'Reports',
  },
];
const ClaimsPage = () => {
  const companyConfig = useSelector(selectCompanyConfig);
  const isClaimsListEnabled =
    isClaimsDashboardEnabled &&
    companyConfig &&
    companyConfig.isClaimListEnabled;
  const isClaimsReportEnabled =
    isClaimsDashboardEnabled &&
    companyConfig &&
    companyConfig.isClaimsReportEnabled;
  const [selectedTab, setSelectedTab] = React.useState<ITabItem | null>(
    claimsTabItems[isClaimsListEnabled ? 0 : 1],
  );
  const handleClick = (e: ITabItem) => {
    setSelectedTab(e);
  };

  return (
    <StyledClaimsContainer>
      {isClaimsListEnabled && isClaimsReportEnabled ? (
        <StyledTabsWrapper>
          <Tabs.OutlineTab
            tabItems={claimsTabItems}
            selectedTab={selectedTab}
            onClick={handleClick}
          />
        </StyledTabsWrapper>
      ) : (
        ''
      )}
      {selectedTab?.value === 'Reports' ? (
        <AnalyticsLegacy />
      ) : (
        <ClaimsSearch />
      )}
    </StyledClaimsContainer>
  );
};
export default ClaimsPage;
