import { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { parseResponse } from '../../../../../utils/common/Utilities';
import LoopApiService from '../../../../../adaptars/LoopApiService';

function useTemplateDownload(name: string, companyId: string) {
  const [isDownloading, setIsDownloading] = useState(false);

  const downloadTemplate = async () => {
    setIsDownloading(true);
    try {
      const [error, result] = await parseResponse(
        LoopApiService.downloadBulkDeleteTemplate(companyId),
      );
      if (result?.ok) {
        const blob = await result.blob();
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = `${name}.xlsx`;
        // document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(blobUrl);
        // document.body.removeChild(link);
        toast.success('“Bulk Delete Template” has been successfully saved in your device.', {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      } else {
        toast.error(error, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    } catch (error) {
      toast.error('An error occurred while fetching Bulk Delete Template', {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } finally {
      setIsDownloading(false);
    }
  };

  useEffect(() => {
    if (isDownloading) {
      downloadTemplate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDownloading]); // Include downloadTemplate as a dependency

  const startDownload = () => {
    setIsDownloading(true);
  };

  return {
    startDownload,
    isDownloading,
  };
}

export default useTemplateDownload;
