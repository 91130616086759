import { SEGMENT_ACTIONS } from './SegmentActionConstants';

export const getSegmentPageName = (pathName: string): string => {
  const routeName = pathName?.split('/');
  switch (routeName?.[1]) {
    case 'login':
      return 'LOGIN_PAGE';
    case 'enter-otp':
      return 'OTP_PAGE';
    case 'employees':
      return 'EMPLOYEE_LISTING_PAGE';
    case 'employee-details':
      return 'EMPLOYEE_DETAILS_PAGE';
    case 'policies':
      return 'POLICY_LISTING_PAGE';
    case 'policy-details':
      return 'POLICY_DETAILS_PAGE';
    case 'claims':
      return 'CLAIMS_LISTING_PAGE';
    case 'claim-analytics':
      return 'CLAIM_ANALYTICS_PAGE';
    case 'cd-accounts':
      return 'CD_ACCOUNTS_PAGE';
    case 'bulk-add':
      return 'REVIEW_UPLOADED_DATA_ADD';
    case 'midterm-found-modal-add':
      return 'MIDTERM_FOUND_MODAL_ADD';
    case 'upload-unsuccessful-add':
      return 'UPLOAD_UNSUCCESSFUL_ADD';
    case 'upload-successful-add':
      return 'UPLOAD_SUCCESSFUL_ADD';
    case 'bulk-delete':
      return 'UPLOAD_DELETION_DATA_DEL';
    default:
      return 'UNKNOWN_PAGE';
  }
};

export const trackPageEvents = (
  pathname: string = '',
  pageProperties?: Record<string, unknown>,
): { name: string; properties?: Record<string, unknown> | undefined } => {
  const page_id = getSegmentPageName(pathname);
  const currentPageAction = SEGMENT_ACTIONS.PAGE[page_id];
  return {
    name: currentPageAction?.name ?? page_id,
    properties: {
      ...(currentPageAction?.properties ?? {}),
      ...pageProperties,
      page_url: pathname,
      page_id,
    },
  };
};

export const trackClickEvent = (
  action: string,
  pathname: string | null,
  page_category = '',
  {
    clickProperties,
    ...otherProperties
  }: { clickProperties?: Record<string, unknown> } = {},
): { name: string; properties: Record<string, any> } => {
  const currentPageAction = SEGMENT_ACTIONS.CLICK[action];
  const page_title = (pathname ?? '').slice(1).replaceAll('/', '_');
  return {
    name: action,
    properties: {
      page_category,
      page_title,
      ...(otherProperties || {}),
      ...(currentPageAction || {}),
      ...clickProperties,
    },
  };
};

export const extractPathName = (pathname: string): string => {
  return pathname.slice(1).replace(/[/-]/g, '_').toUpperCase();
};

export const getTrackTaskObjForInput = (
  action: string,
  pathname: string | null,
  page_category = '',
  searchTerm = '',
): {
  name: string;
  properties: Record<string, any>;
} => {
  const currentPageAction = SEGMENT_ACTIONS.TASK[action];
  const page_title = (pathname ?? '').slice(1).replaceAll('/', '_');
  return {
    name: action,
    properties: {
      page_category,
      page_title,
      currentPageAction,
      searchTerm,
    },
  };
};
