import React from 'react';
import { AuthProvider } from './contexts/AuthContext';
import LoopApiServiceContext from './contexts/LoopApiServiceContext';
import LoopApiService from './adaptars/LoopApiService';
import MainRouter from './components/pages/router';
// import { SegmentProvider } from './utils/hooks/useSegment';
import { useSelector } from 'react-redux';
import { selectHrUser } from './redux/slices/usersSlice';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';
import theme from './theme';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAuth } from './contexts/AuthContext';
import { cleanup } from './redux/store';
import { LogoutAfterInactivity, Toast } from '@loophealth/loop-ui-web-library';
import { selectSelectedCompany } from './redux/slices/hrdRevampRedux';
import { FirebaseAuth } from './adaptars/providers';
import { SegmentProvider } from './utils/segment/hooks/useSegment';
import WithThemeProvider from './theme/WithThemeProvider';
// UseSelector
const App: React.FunctionComponent = () => {
  const hrData = useSelector(selectHrUser);
  const selectedCompany = useSelector(selectSelectedCompany);

  return (
    <ThemeProvider theme={theme}>
      <Toast.ToastContainer>
        <WithThemeProvider>
          <SegmentProvider
            value={{
              traits: {
                user_id: hrData?.data?.userId,
                employer_id: selectedCompany?.id,
                employer_name: hrData?.data?.companyName, // TODO: Ideate on removing it or passing from API https://loop-health.atlassian.net/browse/H6WR-198
                user_type: 'HR',
                platform: 'hrd',
                company_id: selectedCompany?.id,
                company_name: selectedCompany?.name,
                user_endo_status: '',
                user_created_at: '',
                email: hrData?.data?.email,
                phone: hrData?.data?.mobile,
                login_method: '',
              },
              timezone:
                Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? '',
              screen: {
                height: Math.max(
                  document?.documentElement?.clientHeight || 0,
                  window?.innerHeight || 0,
                ),
                width: Math.max(
                  document?.documentElement?.clientWidth || 0,
                  window?.innerWidth || 0,
                ),
              },
            }}
          >
            <LoopApiServiceContext.Provider value={LoopApiService as any}>
              <AuthProvider>
                <SecureAppRouter />
              </AuthProvider>

              <ToastContainer />
            </LoopApiServiceContext.Provider>
          </SegmentProvider>
        </WithThemeProvider>
      </Toast.ToastContainer>
    </ThemeProvider>
  );
};
export default App;

const SecureAppRouter = () => {
  const { logout, setLoggedInUser } = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleOnLogout = () => {
    if (!FirebaseAuth.currentUser) return;

    setLoggedInUser(null);
    logout();
    dispatch(cleanup());
    history.push('/login');
  };

  return (
    <LogoutAfterInactivity logoutAction={handleOnLogout}>
      <MainRouter />
    </LogoutAfterInactivity>
  );
};
