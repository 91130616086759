import React from 'react';
import styled from 'styled-components';
import { INameIcon } from './type';

const StyledBox = styled.div.attrs((props: {$color: string}) => props)`
    height: 34px;
    width: 34px;
    min-height: 34px;
    min-width: 34px;
    background-color: ${(p: { $color: string; }) => p.$color};
    color: #ffffff;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25%;
    float:left
`;
const NameIcon: React.FunctionComponent<INameIcon> = ({ name, color }) => {
    const [letters, setLetters] = React.useState('');
    React.useEffect(() => {
        const value = name;
        const [first, ...rest] = value.trim().split(' ');
        const last = rest[rest.length - 1];
        if (first && last) {
            setLetters(`${first[0]}${last[0]}`.trim().toUpperCase());
        } else {
            setLetters(name[0].toUpperCase());
        }
    }, [name]);
    return <StyledBox $color={color}>{letters}</StyledBox>;
};

export default NameIcon;
