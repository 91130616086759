import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { setLoopIdToken } from '../../../utils/common/CustomFirebase';
import Backdrop from '../../atoms/Backdrop/Backdrop';
import { SideLayout } from '../../atoms/UI/SideLayout';
import { useAuth } from '../../../contexts/AuthContext';
import LoopApiServiceContext from '../../../contexts/LoopApiServiceContext';
import { auth } from '../../../firebase';
import {
  getTrackClickObj,
  getTrackTaskObj,
} from '../../../utils/common/Utilities';
import './EnterOtp.css';
import {
  clearHrUpdate,
  selectConfirmationResult,
  selectHrUser,
  selectUpdateUser,
  setClearHrData,
  setConfirmationResult,
} from '../../../redux/slices/usersSlice';
import {
  fetchHrUser,
  updateHrData,
} from '../../../redux/slices/usersSlice/thunks';
import '../Login.scss';
import { IClickAction, ITaskAction } from './types';
import { FirebaseError } from '@firebase/util';
import useSegment from '../../../utils/segment/hooks/useSegment';

const EnterOtp = () => {
  const [otpCode, setOtpCode] = useState('');
  const [invalidOtp, setInvalidOtp] = useState(false);
  const [validOtp, setValidOtp] = useState(false);
  const { setLoggedInUser } = useAuth();
  const location = useLocation<{ mobile?: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const mobile = (location && location.state && location.state.mobile) || '';
  const confirmationResult = useSelector(selectConfirmationResult);
  const hrData = useSelector(selectHrUser);
  const updateHrValue = useSelector(selectUpdateUser);
  const firebaseContext = useContext(LoopApiServiceContext) as any;
  const trackClick = useSegment('click');
  const trackTask = useSegment('track');
  const [error, setError] = useState('');

  const callTrackClick = (action: IClickAction) =>
    trackClick(
      getTrackClickObj(action, location?.pathname, 'HRD Login&Logout Events'),
    );
  const callTrackTask = (
    action: ITaskAction,
    category: string,
    tried_value = '',
  ) =>
    trackTask(
      getTrackTaskObj(action, location?.pathname, category, tried_value),
    );
  useEffect(() => {
    window.recaptchaVerifier = new firebaseContext.captcha.RecaptchaVerifier(
      'otp-button',
      {
        size: 'invisible',
      },
    );
  }, [
    firebaseContext.auth.RecaptchaVerifier,
    firebaseContext.captcha.RecaptchaVerifier,
  ]);

  useEffect(() => {
    if (hrData.state === 'failed') {
      dispatch(setConfirmationResult({ data: null }));
      dispatch(setClearHrData());
      history.replace('/login', {
        errorMessage: "You don't have permission to access this app",
      });
    } else if (
      hrData &&
      hrData.data &&
      hrData.data.email &&
      hrData.data.mobile
    ) {
      setLoopIdToken(hrData.data.userId);
      //email is already present need to redirect to hr dashboard.
      setLoading(false);
      setLoggedInUser({ ...hrData.data });
      callTrackTask('MOBILE_LOGIN_SUCCESS', 'HRD Login&Logout Events');
      history.push('/');
    } else if (hrData && hrData.data && !hrData.data.mobile && validOtp) {
      setLoading(false);
      //TODO: Need to register the mobile number and redirect to the dashboard screen.
      callTrackTask('MOBILE_LOGIN_FAIL', 'HRD Login&Logout Events');
      setValidOtp(false);
      dispatch(updateHrData({ id: hrData.data.id, data: { mobile: mobile } }));
    } else if (hrData && hrData.data && !hrData.data.email) {
      //Navigates to add email screen when mobile is not present
      setLoading(false);
      callTrackTask('MOBILE_LOGIN_FAIL', 'HRD Login&Logout Events');
      history.push('/enter-id', { showEmail: true });
    }
  }, [
    dispatch,
    history,
    mobile,
    setLoggedInUser,
    hrData,
    hrData.data,
    validOtp,
  ]);

  useEffect(() => {
    if (updateHrValue && updateHrValue.state === 'succeeded') {
      dispatch(
        fetchHrUser({
          methodType: 'mobile',
          userId: mobile,
          loginMethod: 'mobile',
        }),
      );
      // setLoggedInUser({ ...userData.data, mobile: mobile });
      // history.push("/");
      dispatch(clearHrUpdate());
    }
  }, [history, hrData.data, mobile, setLoggedInUser, dispatch, updateHrValue]);

  const handleChange = (otp: string) => {
    setOtpCode(otp);
  };
  const sentOtpToVerify = () => {
    callTrackClick('VERIFY_OTP');
    setLoading(true);
    setInvalidOtp(false);
    setValidOtp(false);
    if (confirmationResult && confirmationResult.confirm) {
      confirmationResult
        .confirm(otpCode)
        .then((result) => {
          if (hrData && !hrData.data) {
            setLoading(true);
            dispatch(
              fetchHrUser({
                methodType: 'mobile',
                userId: mobile,
                loginMethod: 'mobile',
              }),
            );
          }
          setValidOtp(true);
          dispatch(setConfirmationResult({ data: null }));
        })
        .catch((error) => {
          setLoading(false);
          const errorMessage = error.message;
          if (error.code === 'auth/invalid-verification-code') {
            callTrackTask('MOBILE_OTP_FAIL', 'HRD Login&Logout Events', mobile);
            setInvalidOtp(true);
          } else if (errorMessage.indexOf('{') !== -1) {
            // The error from the backend is thrown in this format
            // HTTP Cloud Function returned an error:
            // {"error":{"message":"The provided Email Id is not registered in Loop",
            // "status":"INVALID_ARGUMENT"}}
            // Extracting the message part of the error using the below code
            const jsonResponse = errorMessage.substring(
              errorMessage.indexOf('{'),
            );
            const errorObject = JSON.parse(jsonResponse);

            dispatch(setConfirmationResult({ data: null }));
            history.replace('/login', {
              errorMessage: errorObject.error.message,
            });
          } else throw error;
        });
    } else {
      dispatch(setConfirmationResult({ data: null }));
      dispatch(setClearHrData());
      history.goBack();
    }
  };

  const resendOtpToMobile = async () => {
    callTrackClick('RESEND_OTP');
    setOtpCode('');
    setInvalidOtp(false);
    setLoading(true);
    try {
      const confirmationData = await auth.signInWithPhoneNumber(
        '+91' + mobile,
        window.recaptchaVerifier,
      );

      dispatch(setConfirmationResult({ data: confirmationData }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if ((error as FirebaseError).code === 'auth/too-many-requests') {
        setError('Too many attempts, try again later');
      } else {
        setError((error as FirebaseError).message);
      }
    }
  };

  return (
    <>
      <div className="login-container">
        {loading && <Backdrop />}
        <SideLayout />
        <div className="login right-box-content">
          <div className="lh-login-form">
            <h4 className="login-title">Verify number</h4>
            <p className="intro-subtitle mb-4">
              Please enter the OTP shared on <strong>{mobile}</strong>{' '}
              <img
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  dispatch(setConfirmationResult({ data: null }));
                  dispatch(setClearHrData());
                  history.goBack();
                }}
                src="/assets/icons/edit.svg"
                alt=""
              />
            </p>

            <div className="row">
              <div
                className={`col-md-12 otp-input-container ${
                  invalidOtp ? 'otp-input-container-error' : ''
                }${validOtp ? 'otp-input-container-valid' : ''}`}
              >
                <OtpInput
                  value={otpCode}
                  onChange={handleChange}
                  numInputs={6}
                  inputStyle="otp-input"
                  containerStyle="otp-container"
                  isInputSecure={true}
                />
                {invalidOtp && (
                  <div className="invalid-otp-text">
                    Please enter the correct OTP
                  </div>
                )}
                {error && <div className="invalid-otp-text">{error}</div>}
              </div>
            </div>

            <Button
              id="otp-button"
              disabled={!otpCode || otpCode.length !== 6}
              className="lh-login-submit-button"
              onClick={sentOtpToVerify}
            >
              Verify
            </Button>

            <Button
              id="otp-button"
              className="lh-login-submit-button tranparent-btn"
              onClick={resendOtpToMobile}
            >
              Resend OTP
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnterOtp;
