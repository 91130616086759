import { ITableHeader, ITableHeaderDef } from "@loophealth/loop-ui-web-library/dist/types/atoms/Table/types";
import { IRejectedAddEntity } from "../../pages/Endorsements/BulkAddMembers/types";
import { formatCell } from "./utils";
import { StyledErrorsContainer } from "./styles";
import { Typography } from "@loophealth/loop-ui-web-library";
import { IRejectedDeleteEntity } from "../../pages/Endorsements/BulkDeleteMembers/types";
import { IMemberError } from "../Bulk/types";

export const HOW_TO_FIX_STEPS = [
  {
    title: 'Open Error Report',
    subText: "This sheet shows each error with a remark on what's wrong.",
  },
  {
    title: 'Fix with Correct Details',
    subText: 'Correct all the errors on the same error sheet.',
  },
  {
    title: 'Upload the File Again',
    subText: 'After correcting errors, upload the sheet again from dashboard.',
  },
];

export const KEY_NAME_MAP: Record<string, string> = {
  employee_id: 'Employee ID',
  employeeId: 'Employee ID',
  dateOfLeaving: 'Date Of Leaving',
  name: 'Name',
  relationship_to_account_holders: 'Relationship',
  relationship: 'Relationship',
  gender: 'Gender',
  policy_start_date: 'Date of Joining',
  date_of_birth: 'Date of Birth',
  mobile: 'Mobile Number',
  email_address: 'Email',
  sumInsured: 'Sum Insured',
  ctc: 'CTC',
  enrolment_due_date: 'Enrolment Due Date',
  filePath: 'File',
  dateOfMarriage: 'Marriage Date',
  marriageCertificate: 'Marriage Certificate',
  slabId: 'Slab Id',
  global: 'Employee ID',
  midterm: 'Midterm',
  grade: 'Grade'
};

export const ErrorKeyMap: Record<string, string> = {
  relationship_to_account_holders: 'relationship',
  date_of_joining: 'dob',
  policy_start_date: 'policyStartDate',
  email_address: 'email',
  employee_id: 'global',
}

const ERROR_COLUMN: ITableHeader<IMemberError> = {
  key: 'error',
  name: 'Error Description',
  fixed: 'right',
  width: 400,
  cell: (value) => {
    let errors = {};
    try {
      errors = JSON.parse((value as string) ?? '{}');
    } catch (e) {}
    const errorEntries = Object.entries(errors) as [string, string][];
    return (
      <StyledErrorsContainer>
        {errorEntries.map((errorEntry) => {
          return (
            <Typography
              key={errorEntry[0] + errorEntry[1]}
              variant="small"
              weight="semiBold"
              color="error"
            >
              {KEY_NAME_MAP[errorEntry[0]] ?? errorEntry[0]}:{' '}
              <Typography variant="small" color="error">
                {errorEntry[1]}
              </Typography>
            </Typography>
          );
        })}
      </StyledErrorsContainer>
    );
  },
};

export const FIXED_ADD_TABLE_COLUMNS: ITableHeader<IRejectedAddEntity>[] = [
  {
    key: 'employee_id',
    name: 'Employee ID',
    width: 200,
    cell: (_value, _index, rowData) => formatCell('employee_id', rowData),
  },
  {
    key: 'name',
    name: 'Name',
    width: 250,
    cell: (_value, _index, rowData) => formatCell('name', rowData),
  },
  {
    key: 'relationship_to_account_holders',
    name: 'Relationship',
    width: 200,
    cell: (_value, _index, rowData) =>
      formatCell('relationship_to_account_holders', rowData),
  },
  {
    key: 'gender',
    name: 'Gender',
    width: 100,
    cell: (_value, _index, rowData) => formatCell('gender', rowData),
  },
  {
    key: 'policy_start_date',
    name: 'Date of Joining',
    width: 200,
    cell: (_value, _index, rowData) =>
      formatCell('policy_start_date', rowData),
  },
  {
    key: 'date_of_birth',
    name: 'Date of Birth',
    width: 200,
    cell: (_value, _index, rowData) => formatCell('date_of_birth', rowData),
  },
  {
    key: 'mobile',
    name: 'Mobile',
    width: 200,
    cell: (_value, _index, rowData) => formatCell('mobile', rowData),
  },
  {
    key: 'email_address',
    name: 'Email',
    width: 250,
    cell: (_value, _index, rowData) => formatCell('email_address', rowData),
  },
];

export const OTHER_ADD_TABLE_COLUMNS: Record<string, ITableHeader<IRejectedAddEntity>> = {
  'Sum Insured': {
    key: 'sumInsured',
    name: 'Sum Insured',
    width: 200,
    cell: (_value, _index, rowData) => formatCell('sumInsured', rowData),
  },
  'Designation or Grade': {
    key: 'grade',
    name: 'Designation or Grade',
    width: 200,
    cell: (_value, _index, rowData) => formatCell('grade', rowData),
  },
  CTC: {
    key: 'ctc',
    name: 'CTC',
    width: 200,
    cell: (_value, _index, rowData) => formatCell('ctc', rowData),
  },
  'Error Description': ERROR_COLUMN as unknown as ITableHeader<IRejectedAddEntity>
};

export const DELETE_TABLE_COLUMNS: ITableHeaderDef<IRejectedDeleteEntity> = [
  {
    key: 'employeeId',
    name: 'Employee ID',
    width: 200,
    cell: (_value, _index, rowData) => formatCell('employeeId', rowData),
  },
  {
    key: 'name',
    name: 'Name',
    width: 250,
    cell: (_value, _index, rowData) => formatCell('name', rowData),
  },
  {
    key: 'dateOfLeaving',
    name: 'Date of Leaving',
    width: 200,
    cell: (_value, _index, rowData) =>
      formatCell('dateOfLeaving', rowData),
  },
  {
    key: 'reason',
    name: 'Reason for Leaving',
    width: 200,
    cell: (_value, _index, rowData) => formatCell('reason', rowData),
  },
  ERROR_COLUMN
]