import { post } from './providers';

const ENDPOINTS = {
  addUserToPolicies: `/user/addUserToPolicies`,
  editUserToPolicies: `/user/editMemberToPolicies`,
};

export const addMemberToPolicies = async (data) => {
  return await post(ENDPOINTS.addUserToPolicies, data);
};

export const editMemberToPolicies = async (data) => {
  return await post(ENDPOINTS.editUserToPolicies, data);
};
