import { useEffect, useState } from 'react';
import {
  parseResponse,
  showErrorToast,
} from '../../../../../utils/common/Utilities';
import LoopApiService from '../../../../../adaptars/LoopApiService';
import {
  IEndorsementDataForUI,
  IEndorsementDetailsResponse,
} from '../types';
import { transformEndorsementDetailsForUI } from '../utils';

const useFetchEndorsementDetails = (): {
  loading: boolean;
  endorsementDetails: IEndorsementDataForUI | null;
} => {
  const [endorsementDetails, setEndorsementDetails] =
    useState<IEndorsementDataForUI | null>(null);
  const [loading, toggleLoading] = useState<boolean>(false);

  const fetchEndorsementDetail = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const endorsementId = urlSearchParams.get('id');
    toggleLoading(true);
    const [error, endoDetails] = await parseResponse(
      LoopApiService.fetchEndorsementById(endorsementId),
    );
    toggleLoading(false);
    if (error || !endoDetails?.data) {
      showErrorToast(error?.toString());
      return;
    }
    const endorsementData = transformEndorsementDetailsForUI(
      endoDetails?.data as IEndorsementDetailsResponse,
    );
    setEndorsementDetails(endorsementData);
  };

  useEffect(() => {
    fetchEndorsementDetail();
  }, []);
  return { loading, endorsementDetails };
};
export default useFetchEndorsementDetails;