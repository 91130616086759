import React, { useEffect } from 'react';
import { getAmountInLacs, getPolicyType } from '../../../../utils/common/Utilities';

const getUniqueArrayByKey = (array,key) => [...new Map(array.map(item =>
  [item[key], item])).values()];

const PolicyCard = ({
  policyInfo,
  selPolicyError,
  setSelPolicyError,
  selPolicies,
  setSelPolicies,
}) => {
  const selectSlab = (event, policy) => {
    if (event.target.checked) {
      setSelPolicyError(false);
      setSelPolicies(getUniqueArrayByKey([...selPolicies, policy], 'policyId'));
    } else {
      const selPolicyArray = [...selPolicies];
      const index = selPolicyArray.findIndex((x) => x.policyId === policy.policyId);
      if (index > -1) {
        selPolicyArray?.splice(index, 1);
        setSelPolicies(selPolicyArray);
      }
    }
  };

  return (
    <div className=" col-md-6" key={policyInfo.policyId}>
      <div
        className={
          selPolicyError
            ? 'box_card card_border border-danger'
            : selPolicies.find((x) => x === policyInfo.policyId)
            ? 'box_card card_border border-green'
            : 'box_card card_border'
        }
      >
        <div className="card_top">
          <div className="col-md-12">
            <div className="policy_info_left single_user_r1">
              <div className="form-check">
                <input
                  className="form-check-input pull_right"
                  type="checkbox"
                  defaultChecked={true}
                  value=""
                  onChange={(event) => selectSlab(event, policyInfo)}
                />
              </div>
            </div>
            <div className="policy_info_left single_user_r2">
              <div className="policy_name single_user_pn">
                <h4 className="cardName">
                  {getPolicyType(
                    policyInfo?.policyType,
                    policyInfo?.isTopUpPolicy,
                    policyInfo?.isParentalPolicy
                  )}
                </h4>
                <h5 className="health_insurer">
                  {policyInfo?.insurerName ? policyInfo?.insurerName : '-'}
                </h5>
              </div>
            </div>
            <div className="policy_info_right"/>
          </div>
        </div>
        <div className="card_bottom">
          <div className="policy_stats policy_row_one card_section_main">
            <div className="policy_sum_ins card_section_one">
              <p className="amountText"> Sum Insured</p>

              <h5 className="amountPrice">
                {policyInfo?.sumInsured ? `${getAmountInLacs(policyInfo?.sumInsured)}` : '-'}
              </h5>
            </div>
            <div className="policy_cd_balance card_section_two">
              <p className="amountText">Supported Dependents</p>
              <h5 className="amountPrice">
                {policyInfo?.familyDefinition
                  ? policyInfo?.familyDefinition
                  : '-'}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolicyCard;
