import React from "react";
import Navbar from "../../atoms/Navbar";
export const Benefits = () => {
  return (
    <>
      <Navbar />
      <h1>Benefits Screen Here</h1>
    </>
  );
};
