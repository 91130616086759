export default class BaseException extends Error {
  private _status: number | string;
  private _message: any;
  private _url: string;
  private _errorData: any;

  public get status(): number | string {
    return this._status;
  }

  public get message(): any {
    return this._message;
  }

  public get url(): string {
    return this._url;
  }

  public get errorData(): any {
    return this._errorData;
  }

  constructor(
    status: number | string,
    message: string,
    url: string,
    errorData: any,
  ) {
    super();
    this._status = status;
    this._message = message;
    this._url = url;
    this._errorData = errorData;
  }

  getFormattedErrorString() {
    return `Status: ${this.status}. Message: ${this._message}`;
  }
}
