import { IEmpInitialOperation, IEmpFormSlice } from './types';

export const initialOperation: IEmpInitialOperation = {
  userId: null,
  operation: null,
  msg: null,
  error: false,
  success: false,
};

export const initialState: IEmpFormSlice = {
  isEditForm: false,
  currentOperation: {
    ...initialOperation,
  },
  selectedEmpPolicy: null,
  clearForm: false,
};
