import styled from 'styled-components';

export const StyledHeader = styled.header`
  width: 100%;
  padding: 50px;
  padding-top: 100px;
  display: flex;
  gap: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.surface0};
`;

export const StyledInformationContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledInformationParagraph = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledLink = styled.a`
  margin-top: 32px;
  display: flex;
  align-items: center;
  gap: 4px;
  text-decoration-color: ${({ theme }) => theme.colors.loopEmerald};
`;
