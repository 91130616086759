import { createSlice } from '@reduxjs/toolkit';
import { getFireBasePaginatedData } from '../../../utils/common/FirebasePagination';
import { RootState } from '../../store';
import { changeRequestsInitialState, initialState } from './initialState';
import { fetchChangeRequests } from './thunks';

const changeRequestSlice = createSlice({
  name: 'changeRequestData',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [fetchChangeRequests.pending.toString()]: (state) => {
      state.changeRequests.status = 'loading';
      state.changeRequests.error = '';
    },
    [fetchChangeRequests.fulfilled.toString()]: (state, action) => {
      const {
        payload,
        meta: {
          arg: {
            pageSize,
            nextPageId: argNextPageId,
            prevPageId: argPrevPageId,
          },
        },
      } = action;
      const { newData, newMetaData } = getFireBasePaginatedData({
        pageSize,
        argNextPageId,
        argPrevPageId,
        data: payload.data,
        nextPrevSelector: 'id',
        prevPageNo: state?.changeRequests?.metaData?.pageNo ?? 0,
      });
      state.changeRequests.error = null;
      state.changeRequests.status = 'succeeded';
      state.changeRequests.data = newData;
      state.changeRequests.metaData = newMetaData;
    },
    [fetchChangeRequests.rejected.toString()]: (state, action) => {
      const {
        error,
        meta: {
          arg: {
            pageSize,
            nextPageId: argNextPageId,
            prevPageId: argPrevPageId,
          },
        },
      } = action;
      const { newMetaData } = getFireBasePaginatedData({
        pageSize,
        argNextPageId,
        argPrevPageId,
        data: [],
        nextPrevSelector: 'id',
        prevPageNo: state?.changeRequests?.metaData?.pageNo ?? 0,
      });
      state.changeRequests.status = 'failed';
      state.changeRequests.error = error.message;
      state.changeRequests.metaData = newMetaData;
    },

    'common/cleanup': (state) => {
      state.changeRequests = { ...changeRequestsInitialState };
    },
  },
});

export const selectchangeRequests = (state: RootState) =>
  state.changeRequest.changeRequests;

export default changeRequestSlice.reducer;
