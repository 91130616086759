import { convertToNearestThousand } from '../../../utils/common/Utilities';
import { ICDAccount } from './types';

export const isCDBalanceLow = (accountData: ICDAccount) => {
  return accountData.balance < getRecommendedCDBalance(accountData);
};

export const getRecommendedCDBalance = (accountData: ICDAccount) => {
  const accountsFinalPremium = accountData.policyData.reduce(
    (totalPremium, policy) => totalPremium + policy.finalPremium,
    0,
  );
  // 10% of final premiums -
  return convertToNearestThousand(accountsFinalPremium * 0.1);
};
