import { IReadMe } from './types';

export const CTC_MULTIPLIER_SCHEMA = [
  { name: 'CTC', type: 'Numerical', allowed: '0-9', isMandatory: 'Mandatory' },
  {
    name: 'Error Description',
    type: 'Alphanumeric',
    allowed: 'A-Z, a-z, 0-9, @, .',
    isMandatory: 'Informational',
  },
];
export const FLAT_SUM_SCHEMA = [
  {
    name: 'Sum Insured',
    type: 'Numerical',
    allowed: '0-9',
    isMandatory: 'Mandatory',
  },
  {
    name: 'Error Description',
    type: 'Alphanumeric',
    allowed: 'A-Z, a-z, 0-9, @, .',
    isMandatory: 'Informational',
  },
];
export const GRADED_TIER_SCHEMA = [
  {
    name: 'Designation or Grade',
    type: 'Alphanumeric',
    allowed: 'A-Z, a-z, 0-9',
    isMandatory: 'Mandatory',
  },
  {
    name: 'Sum Insured',
    type: 'Numerical',
    allowed: '0-9',
    isMandatory: 'Mandatory',
  },
  {
    name: 'Error Description',
    type: 'Alphanumeric',
    allowed: 'A-Z, a-z, 0-9, @, .',
    isMandatory: 'Informational',
  },
];

export const COMMON_DATA_SCHEMA = [
  {
    name: 'S No.',
    type: 'Numerical',
    allowed: '0-9',
    isMandatory: 'Optional',
  },
  {
    name: 'Employee ID',
    type: 'Alphanumeric',
    allowed: 'Permissible characters: A-Z, a-z, 0-9',
    isMandatory: 'Mandatory',
  },
  {
    name: 'Member Name',
    type: 'Alphanumeric',
    allowed: 'Permissible characters: A-Z, a-z, 0-9',
    isMandatory: 'Mandatory',
  },
  {
    name: 'Relationship',
    type: 'Alphanumeric',
    allowed: 'Self, Spouse, Child, Parent, Parent in law',
    isMandatory: 'Mandatory',
  },
  {
    name: 'Gender (Male/Female)',
    type: 'Alphanumeric',
    allowed: 'Male, Female',
    isMandatory: 'Mandatory',
  },
  {
    name: 'Date of Joining (DD/MMM/YYYY)',
    type: 'Date in DD/MMM/YYYY',
    allowed: 'A-Z, a-z, 0-9',
    isMandatory: 'Mandatory',
  },
  {
    name: 'Date of Birth (DD/MMM/YYYY)',
    type: 'Date in DD/MMM/YYYY',
    allowed: 'A-Z, a-z, 0-9',
    isMandatory: 'Mandatory',
  },
  {
    name: 'Mobile',
    type: 'Numerical (10 digits, exclude country codes)',
    allowed: '0-9',
    isMandatory: 'Optional',
  },
  {
    name: 'Email',
    type: 'Alphanumeric',
    allowed: 'A-Z, a-z, 0-9, @, .',
    isMandatory: 'Optional',
  },
];

export const ADD_READ_ME: IReadMe = {
  INSTRUCTIONS:
    // eslint-disable-next-line max-len
    `We've noted down some general instructions that you should help you while uploading the endorsements data. If you still have any doubts or concerns, please reach out to respective CSM.`,
  DATA_SCHEMA: [
    {
      name: 'S No.',
      type: 'Numerical',
      allowed: '0-9',
      isMandatory: 'Optional',
    },
    {
      name: 'Employee ID',
      type: 'Alphanumeric',
      allowed: 'Permissible characters: A-Z, a-z, 0-9',
      isMandatory: 'Mandatory',
    },
    {
      name: 'Member Name',
      type: 'Alphanumeric',
      allowed: 'Permissible characters: A-Z, a-z, 0-9',
      isMandatory: 'Mandatory',
    },
    {
      name: 'Relationship',
      type: 'Alphanumeric',
      allowed: 'Self, Spouse, Child, Parent, Parent in law',
      isMandatory: 'Mandatory',
    },
    {
      name: 'Gender (Male/Female)',
      type: 'Alphanumeric',
      allowed: 'Male, Female',
      isMandatory: 'Mandatory',
    },
    {
      name: 'Date of Joining (DD/MMM/YYYY)',
      type: 'Date in DD/MMM/YYYY',
      allowed: 'A-Z, a-z, 0-9',
      isMandatory: 'Mandatory',
    },
    {
      name: 'Date of Birth (DD/MMM/YYYY)',
      type: 'Date in DD/MMM/YYYY',
      allowed: 'A-Z, a-z, 0-9',
      isMandatory: 'Mandatory',
    },
    {
      name: 'Designation or Grade',
      type: 'Alphanumeric',
      allowed: 'A-Z, a-z, 0-9',
      isMandatory: 'Conditional',
    },
    {
      name: 'CTC',
      type: 'Numerical',
      allowed: '0-9',
      isMandatory: 'Conditional',
    },
    {
      name: 'Sum Insured',
      type: 'Numerical',
      allowed: '0-9',
      isMandatory: 'Conditional',
    },
    {
      name: 'Mobile',
      type: 'Numerical (10 digits, exclude country codes)',
      allowed: '0-9',
      isMandatory: 'Optional',
    },
    {
      name: 'Email',
      type: 'Alphanumeric',
      allowed: 'A-Z, a-z, 0-9, @, .',
      isMandatory: 'Optional',
    },
  ],
};

export const SheetErrorKeyMap: any = {
  date_of_birth: 'dob',
  relationship_to_account_holders: 'relationship',
  policy_start_date: 'policyStartDate',
  gender: 'gender',
};
