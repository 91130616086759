import {IDropdownItem} from "@loophealth/loop-ui-web-library/dist/types/atoms/Form/DropDown/types";
import {capitalizeFirstLetterOfEachWord, getPolicyType} from "../../../../utils/common/Utilities";
import {
    FetchedNoResultsPlaceholder,
    IncorrectSearchPlaceholder,
    NoClaimsPlaceholder,
    TooManyFiltersPlaceholder
} from "../../../../assets/images";
import {DateFormatUtils} from "@loophealth/loop-ui-web-library";

export const transformClaimsListForUI = (claims: any[],): Record<string, unknown>[] => {
    return claims.map((data) => {
        return {
            'id': data.claimId,
            'Claims ID': data.tpaClaimId,
            'Intimation date': new DateFormatUtils().formatDate(data.submittedOn),
            'Employee name': data.employeeName ? capitalizeFirstLetterOfEachWord(data.employeeName) : '',
            'Claims': 'Loop Health',
            'Employee ID': data.employeeCode,
            'Patient name': data.name ? capitalizeFirstLetterOfEachWord(data.name) : '',
            'Claim type': data.claimType ? capitalizeFirstLetterOfEachWord(data.claimType) : '',
            'Policy no.': data.policyNo,
            'Status': data.claimStatus,
        };
    });
};

export const prepareFilterData = (filtersResponse: any[]): Record<string, IDropdownItem[]> => {
    return filtersResponse.reduce((result: any, data: any) => {
        if (data.key === "status" || data.key === "type") {
            result[data.key] = data.values.map((obj: any) => ({name: obj.label, value: obj.id}));
        }
        return result;
    }, {});
};
export const preparePolicyFilter = (policyPlans: any[]): IDropdownItem[] => {
    return policyPlans.map((policy: any) => {
        return {
            name: `${getPolicyType(policy.policyType, policy.isTopUpPolicy, policy.isParentalPolicy)} - ${policy.id} 
            (${policy.tpaName})`,
            value: policy.id,
        }
    });
};
export const getPlaceholderData = (filterCount: number, debouncedSearchTerm: string)
    : { message: string, description: string, imageSrc: string } => {
    if (filterCount === 0 && !debouncedSearchTerm)
        return {
            message: 'We’ve found no claims',
            description: 'Good job keeping your employees healthy',
            imageSrc: NoClaimsPlaceholder
        }
    if (filterCount === 0 && debouncedSearchTerm)
        return {
            message: 'We found no claims related to those searched words',
            description: 'Use more relevant words like employee’s ‘first name’ or ‘Claim ID’ to show results ',
            imageSrc: IncorrectSearchPlaceholder
        }
    if (filterCount !== 0 && debouncedSearchTerm)
        return {
            message: 'We found no claims to display',
            description: 'Double check for any typos in the word searched or change the applied filters to see results',
            imageSrc: FetchedNoResultsPlaceholder
        }
    if (filterCount !== 0)
        return {
            message: 'We found no claims for those filter settings',
            description: 'Try simplifying the search results by removing some filters to see results',
            imageSrc: TooManyFiltersPlaceholder
        }
    return {message: '', description: '', imageSrc: ''}
}
