import {
  LoopButton,
  Shadow,
  Stepper,
  Toast,
  Typography,
} from '@loophealth/loop-ui-web-library';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ArrowLeftIcon } from '../../../../assets/images';
import { selectSelectedCompany } from '../../../../redux/slices/hrdRevampRedux';
import FullPageLoader from '../../../atoms/Loader';
import { IFullPageLoader } from '../../../atoms/Loader/types';
import MidTermAdditionModal from '../../../containers/MidTermAdditionModal';
import { IPolicyRejectedEntries, IUploadedPolicywiseData } from './types';
import {
  checkMidtermPresent,
  getRejectedEntriesOnSkip,
  identifyPolicyWiseMidTermAdditions,
} from '../../../containers/MidTermAdditionModal/utils';
import SubmitEndo from './SubmitEndo';
import UploadAddition from './UploadAddition';
import {
  StyledBackIcon,
  StyledBottomNavigation,
  StyledContainer,
  StyledDetailsHeader,
  StyledDetailsWrapper,
  StyledStepperWrapper,
} from '../../../containers/Bulk/styles';
import ValidationsScreen from '../../../containers/ValidationsScreen';
import {
  concatMidtermAndValidationErrors,
  processValidationPayloadData,
  submitBulkAddEndorsement,
  validateAddData,
} from './SubmitEndo/utils';
import {
  deleteMidtermDocumentsOnCancel,
  getButtonStates,
  getCorrectEntries,
  getCorrectEntriesLength,
  isCDLowTrue,
} from './utils';
import {
  extractPathName,
  trackClickEvent,
  trackPageEvents,
} from '../../../../utils/segment/utils';
import useSegment from '../../../../utils/segment/hooks/useSegment';
import useFetchPoliciesFromRedux from '../../../containers/Policies/hooks/useFetchPoliciesFromRedux';
import { generateErrorTemplate } from './ErrorSheet/utils';
import CancellationModal from '../../../containers/Bulk/CancellationModal';
import { IIdentifiedMidtermsData } from '../../../containers/MidTermAdditionModal/types';
import {
  LOADING_CONSTANTS,
  STEPPER_DATA,
} from '../../../containers/Bulk/constants';
import useEstimateEndorsementCost from './hooks/useEstimateEndorsementCost';
import { parseResponse } from '../../../../utils/common/Utilities';
import LoopApiService from '../../../../adaptars/LoopApiService';
import { getAddValidationSummaryData } from '../../../containers/ValidationsScreen/utils';
import { writeFile } from 'xlsx-js-style';
import { WorkBook } from 'xlsx';
import EndoSuccessSummary from '../../../containers/Bulk/EndoSuccessSummary';
import { isEndoNotificationEnabled } from '../../../../utils/featureFlag';

const BulkAddMembers = () => {
  const trackPage = useSegment('page');
  const { policies } = useFetchPoliciesFromRedux();
  const history = useHistory();
  const toast = Toast.useToast();
  const trackClick = useSegment('click');
  const location = useLocation();
  const [isLoading, setIsLoading] = useState<IFullPageLoader | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const selectedCompany = useSelector(selectSelectedCompany);
  const companyId = selectedCompany?.id || '';
  const [currentStep, setCurrentStep] = useState(1);
  const [sheetSelected, setSheetSelected] = useState<Record<string, unknown>[]>(
    [],
  );
  const [uploadedData, setUploadedData] = useState<IUploadedPolicywiseData>({});
  const [midTermModalVisible, setMidTermModalVisible] = useState(false);
  const [lowCDModalVisible, setLowCDModalVisible] = useState(false);
  const [identifiedMidterms, setIdentifedMidterms] =
    useState<IIdentifiedMidtermsData>({});

  const [rejectedEntries, setRejectedEntries] =
    useState<IPolicyRejectedEntries>({});

  const [finalCorrectData, setFinalCorrectData] =
    useState<IUploadedPolicywiseData>({});
  const [isCancelModalVisible, setCancelModalVisible] = useState(false);

  const [isBackBtnModalVisible, setBackBtnModalVisible] = useState(false);
  const { isNextEnabled, isBackEnabled, buttonText, backButtonText } = useMemo(
    () => getButtonStates(currentStep, sheetSelected.length, finalCorrectData, rejectedEntries),
    [currentStep, sheetSelected, finalCorrectData],
  );

  const { endoCostList, totalCost, totalLives } = useEstimateEndorsementCost(
    finalCorrectData as IUploadedPolicywiseData,
    currentStep,
    'ADD',
  );
  const onSetCancelModalClick = () => {
    setCancelModalVisible(false);
    trackClick(
      trackClickEvent(
        'Cancel_Error_Table_add',
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
  };
  const errorWorkbook = useRef<WorkBook | null>(null);
  const onBackClick = () => {
    const correctEntriesLength = getCorrectEntriesLength(finalCorrectData);
    const haveErrors = Object.keys(rejectedEntries).length;
    switch (currentStep) {
      case 1:
        if (sheetSelected.length) {
          setBackBtnModalVisible(true);
        } else {
          history.goBack();
        }
        break;
      case 2:
        if (correctEntriesLength) {
          setCancelModalVisible(true);
          trackClick(
            trackClickEvent(
              'Click_Go_Back_Error_Table_add',
              location?.pathname,
              extractPathName(location?.pathname),
            ),
          );
        } else {
          setCurrentStep(1);
          setSheetSelected([]);
        }
        break;
      case 3:
        if (haveErrors) setCurrentStep(2);
        else setCancelModalVisible(true);
    }
  };
  const submitEndorsements = async () => {
    isEndoNotificationEnabled && triggerEndoSubmitEmails();
    setIsSubmitting(true);
    trackClick(
      trackClickEvent(
        'LOW_CD_PROCEED_SUBMIT_CTA',
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
    const response = await submitBulkAddEndorsement(
      companyId,
      finalCorrectData,
    );
    setIsSubmitting(false);
    if (!response)
      toast?.error('Something went wrong', '', {
        closeOnClick: false,
        variant: 'light',
      });
    else setCurrentStep(4);
  };
  const onProceedClicked = () => {
    const correctEntriesLength = getCorrectEntriesLength(finalCorrectData);
    switch (currentStep) {
      case 1:
        checkMidTermAdditions();
        trackClick(
          trackClickEvent(
            'Upload_Proceed_add',
            location?.pathname,
            extractPathName(location?.pathname),
          ),
        );
        break;
      case 2:
        if (correctEntriesLength) {
          setCurrentStep(3);
          trackClick(
            trackClickEvent(
              'View_Cost_Correct_Lives_add',
              location?.pathname,
              extractPathName(location?.pathname),
            ),
          );
        } else {
          onConfirmCancelClick();
          trackClick(
            trackClickEvent(
              'Go_Reupload_add',
              location?.pathname,
              extractPathName(location?.pathname),
            ),
          );
        }
        break;
      case 3:
        if (isCDLowTrue(endoCostList)) {
          setLowCDModalVisible(true);
        } else {
          submitEndorsements();
          trackClick(
            trackClickEvent(
              'Clicked_Submit_Lives_add',
              location?.pathname,
              extractPathName(location?.pathname),
            ),
          );
        }
        break;
    }
  };

  const validateData = async (
    rejectedMidterms: IPolicyRejectedEntries,
    updatedUploadedData: IUploadedPolicywiseData,
  ) => {
    const correctEntries = getCorrectEntries(
      updatedUploadedData,
      rejectedMidterms,
    );
    const payload = processValidationPayloadData(
      correctEntries,
      selectedCompany?.id || '',
    );
    try {
      const [uploadedDataSet, validationRejections] = await validateAddData(
        payload,
      );
      const totalRejectedEntries = concatMidtermAndValidationErrors(
        Object.keys(updatedUploadedData),
        rejectedMidterms,
        validationRejections,
      );
      setRejectedEntries(totalRejectedEntries);
      const haveErrors = Object.keys(totalRejectedEntries).length;
      const finalCorrectEntries = getCorrectEntries(
        uploadedDataSet,
        totalRejectedEntries,
      );
      setFinalCorrectData(finalCorrectEntries);
      if (haveErrors) {
        errorWorkbook.current = generateErrorTemplate(
          totalRejectedEntries,
          policies,
        );
        writeFile(errorWorkbook.current, 'Error-Report.xlsx');
        toast?.success(
          '"Error Report" has been successfully saved in your device.',
          '',
          {
            closeOnClick: false,
            variant: 'light',
          },
        );
        trackPage(trackPageEvents('/bulk-add'));
        setIsLoading(LOADING_CONSTANTS.VALIDATION_HAS_ERRORS);
        setTimeout(() => {
          setIsLoading(null);
          trackPage(trackPageEvents('/upload-unsuccessful-add'));
        }, 3000);
      } else {
        setCurrentStep(3);
        setIsLoading(LOADING_CONSTANTS.VALIDATION_HAS_NO_ERRORS);
        setTimeout(() => {
          setIsLoading(null);
          trackPage(trackPageEvents('/upload-successful-add'));
        }, 3000);
      }
    } catch (err) {
      toast?.error((err as Error).message);
    }
  };

  const checkMidTermAdditions = async () => {
    setCurrentStep(2);
    const startTime = new Date().getTime();
    setIsLoading(LOADING_CONSTANTS.VALIDATE_MEMBERS);
    const identifiedMidtermResponse = await identifyPolicyWiseMidTermAdditions(
      uploadedData,
      selectedCompany?.id,
    );
    const endTime = new Date().getTime();
    setIdentifedMidterms(identifiedMidtermResponse);
    const isMidtermPresent = checkMidtermPresent(identifiedMidtermResponse);
    const midTermModalVisible = () => {
      setMidTermModalVisible(true);
      trackPage(trackPageEvents('/midterm-found-modal-add'));
    };
    if (isMidtermPresent) setTimeout(midTermModalVisible, 2000);
    else {
      const rejectedEntries = getRejectedEntriesOnSkip(
        identifiedMidtermResponse,
      );
      setRejectedEntries(rejectedEntries);
      setTimeout(
        () => validateData(rejectedEntries, uploadedData),
        endTime - startTime < 1500 ? 1500 : 0,
      );
    }
  };
  const onConfirmCancelClick = async () => {
    if (isCancelModalVisible && errorWorkbook.current) {
      const summaryData = getAddValidationSummaryData(
        uploadedData,
        rejectedEntries,
        policies,
      );
      isEndoNotificationEnabled && LoopApiService.endoStep2ExitTrigger(
        errorWorkbook,
        summaryData.correct.totalLives?.toString(),
        summaryData.incorrect.totalLives?.toString(),
        summaryData.total.totalLives?.toString(),
        companyId,
      );
    }
    setCurrentStep(1);
    setRejectedEntries({});
    setFinalCorrectData({});
    setIdentifedMidterms({});
    deleteMidtermDocumentsOnCancel(uploadedData);
    setCancelModalVisible(false);
    setSheetSelected([]);
    setUploadedData({});
    trackClick(
      trackClickEvent(
        'Yes_Leave_Error_Table_add',
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
  };

  useEffect(() => {
    if (currentStep !== 1) {
      const unloadCallback = async (event: BeforeUnloadEvent) => {
        event.preventDefault();
        if (currentStep === 3 && isEndoNotificationEnabled) {
          const summaryData = getAddValidationSummaryData(
            uploadedData,
            rejectedEntries,
            policies,
          );
          await parseResponse(
            LoopApiService.endoStep3ExitEmailTrigger(
              sheetSelected,
              Object.keys(finalCorrectData)?.length?.toString(),
              totalCost.toString(),
              totalLives.toString(),
              summaryData.correct.totalLives?.toString(),
              summaryData.incorrect.totalLives?.toString(),
              companyId,
            ),
          );
        }
        return true;
      };
      window.addEventListener('beforeunload', unloadCallback);
      return () => window.removeEventListener('beforeunload', unloadCallback);
    }
  }, [currentStep]);

  const triggerEndoSubmitEmails = async () => {
    const policies = endoCostList.map((cdAccountCostData) => {
      return cdAccountCostData.policyData.map((policy: any) => ({
        policyName: `${cdAccountCostData.insurer}_${policy.policyType}`,
        livesSubmitted: policy.lives,
        costOfEndo: policy.costOfEndorsement,
        cdBalance: cdAccountCostData.balance,
        cdStatus: cdAccountCostData.isCDLow ? 'Insufficient' : 'Adequate',
      }));
    });
    await parseResponse(
      lowCDModalVisible
        ? LoopApiService.lowCDEndoSubmissionEmailTrigger(
            sheetSelected,
            policies,
            companyId,
          )
        : LoopApiService.endoSubmissionEmailTrigger(
            sheetSelected,
            policies,
            companyId,
          ),
    );
  };
  return (
    <>
      {currentStep === 4 ? (
        <EndoSuccessSummary submittedData={finalCorrectData} mode="ADD" />
      ) : (
        <StyledContainer>
          <StyledDetailsWrapper>
            <StyledDetailsHeader onClick={onBackClick} >
              <StyledBackIcon src={ArrowLeftIcon}/>
              <Typography variant="medium" weight="medium">
                Bulk Add Members
              </Typography>
            </StyledDetailsHeader>

            <StyledStepperWrapper>
              <Stepper
                steps={STEPPER_DATA.ADD}
                currentStep={currentStep}
                variant="locked"
                onStepperClicked={() => {}}
              />
            </StyledStepperWrapper>
          </StyledDetailsWrapper>
          {isLoading ? (
            <FullPageLoader {...isLoading} />
          ) : (
            <>
              {currentStep === 1 && (
                <UploadAddition
                  setSheetData={setUploadedData}
                  setSheetSelected={setSheetSelected}
                  sheetSelected={sheetSelected}
                />
              )}
              {currentStep === 2 && (
                <ValidationsScreen
                  operation="ADD"
                  rejectedEntries={rejectedEntries}
                  uploadedData={uploadedData}
                />
              )}
              {currentStep === 3 && (
                <SubmitEndo
                  mode="ADD"
                  endoCostList={endoCostList}
                  totalCost={totalCost}
                  totalLives={totalLives}
                />
              )}
              <Shadow variant="bottom">
                <StyledBottomNavigation>
                  {isBackEnabled && (
                    <LoopButton
                      size="medium"
                      variant="outline"
                      onClick={onBackClick}
                    >
                      {backButtonText}
                    </LoopButton>
                  )}
                  <LoopButton
                    size="medium"
                    isLoading={isSubmitting}
                    variant={isNextEnabled ? 'filled' : 'disabled'}
                    onClick={onProceedClicked}
                  >
                    {buttonText}
                  </LoopButton>
                </StyledBottomNavigation>
              </Shadow>
            </>
          )}
          <MidTermAdditionModal
            isVisible={midTermModalVisible}
            setIsVisible={setMidTermModalVisible}
            identifedMidterms={identifiedMidterms}
            validateData={validateData}
            setRejectedEntries={setRejectedEntries}
            uploadedData={uploadedData}
            setLoading={setIsLoading}
          />
          <CancellationModal
            isBackBtnModalVisible={isBackBtnModalVisible}
            isCancelModalVisible={isCancelModalVisible}
            setCancelModalVisible={onSetCancelModalClick}
            setBackBtnModalVisible={setBackBtnModalVisible}
            onConfirmCancelClick={onConfirmCancelClick}
            lowCDModalVisible={lowCDModalVisible}
            setLowCDModalVisible={setLowCDModalVisible}
            submitEndorsements={submitEndorsements}
          />
        </StyledContainer>
      )}
    </>
  );
};

export default BulkAddMembers;
