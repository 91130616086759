import { DateFormatUtils } from '@loophealth/loop-ui-web-library';
import { IBulkAddData } from '../types';
import { IBulkAddExcelData } from './types';
import { read, utils } from 'xlsx';
import { IRelationship } from '../../../../containers/Bulk/types';
import moment from 'moment';

export const formatExcelDatesForBulkUpload = (
  excelMemberData: IBulkAddExcelData,
): IBulkAddData => {
  return {
    employee_id: (excelMemberData['Employee ID'] ?? ''),
    name: (excelMemberData['Member Name'] ?? ''),
    relationship_to_account_holders: (
      excelMemberData['Relationship'] || ''
    ).toLowerCase() as IRelationship,
    gender: excelMemberData['Gender (Male/Female)'] || '',
    mobile: excelMemberData['Mobile'] || '',
    email_address: excelMemberData['Email'] || '',
    date_of_birth: excelMemberData['Date of Birth (DD/MMM/YYYY)']
      ? moment(
          excelMemberData['Date of Birth (DD/MMM/YYYY)'],
        ).format('DD/MM/YYYY')
      : '',
    policy_start_date: excelMemberData['Date of Joining (DD/MMM/YYYY)']
      ? moment(
          excelMemberData['Date of Joining (DD/MMM/YYYY)'],
        ).format('DD/MM/YYYY')
      : '',
    sumInsured: Number(excelMemberData['Sum Insured'] ?? ''),
    grade: excelMemberData['Designation or Grade'] || '',
    ctc: excelMemberData['CTC'] || '',
  };
};

export const processFileUpload = async (
  files: Record<string, unknown>[],
  setSheetData: React.Dispatch<
    React.SetStateAction<Record<string, IBulkAddData[]>>
  >,
  setSheetSelected: React.Dispatch<
    React.SetStateAction<Record<string, unknown>[]>
  >,
  companyId: string,
  toast: any,
): Promise<void> => {
  const sheetData: Record<string, IBulkAddData[]> = {};
  if (!files.length) {
    setSheetSelected([]);
    setSheetData({});
    return;
  }
  const file = files[0];
  const reader = new FileReader();

  reader.onload = (e) => {
    const policyIds: string[] = [];
    const data = e.target?.result;
    const workbook = read(data, { type: 'binary' });
    const sheetNames = workbook.SheetNames;
    const indexOfReadMe = sheetNames.indexOf('READ ME');
    if (indexOfReadMe > -1) {
      const jsonDataReadMe = utils.sheet_to_json(
        workbook.Sheets[sheetNames[indexOfReadMe]],
        {
          range: 1,
          raw: false,
        },
      );
      const getPolicyIds = extractUniquePolicyIds(jsonDataReadMe);
      policyIds.push(...getPolicyIds);
    }
    if (policyIds.length) {
      sheetNames.forEach((sheetName: string, sheetIndex) => {
        const jsonData = utils.sheet_to_json(
          workbook.Sheets[sheetNames[sheetIndex]],
          {
            range: 1,
            raw: false,
          },
        );

        if (sheetName !== 'READ ME') {
          const filteredRecords = jsonData
            .filter(
              (record: any) =>
                record['Employee ID'] && record['Employee ID'].trim() !== '',
            )
            .map((data) =>
              formatExcelDatesForBulkUpload(data as IBulkAddExcelData),
            );
          if (filteredRecords.length)
            sheetData[policyIds[sheetIndex - 1]] = filteredRecords;
        }
      });
    }
    if (Object.keys(sheetData).length) {
      setSheetData(sheetData);
    } else {
      setSheetSelected([]);
      toast.error('Empty File');
    }
  };
  reader.readAsBinaryString(file as unknown as Blob);
  setSheetSelected(files);
};

export const extractPolicyIds = (dataArray: any) =>
  dataArray
    .filter(
      (item: any) => item['__EMPTY_4'] && item['__EMPTY_4'].startsWith('LPP-'),
    )
    .map((item: any) => item['__EMPTY_4']);

export const extractUniquePolicyIds = (dataArray: any[]): string[] => {
  const policyIdSet = new Set<string>();

  dataArray.forEach((item) => {
    for (const key in item) {
      if (
        item[key] &&
        typeof item[key] === 'string' &&
        item[key].startsWith('LPP-')
      ) {
        policyIdSet.add(item[key]);
      }
    }
  });

  return Array.from(policyIdSet);
};
