import { IApiState, IChangeRequestSlice } from './types';

export const apiState: IApiState = {
  data: null,
  status: 'idle',
  error: '',
};

export const changeRequestsInitialState = {
  ...apiState,
  metaData: { pageNo: 0, hasPrev: false, hasNext: false },
};

export const initialState: IChangeRequestSlice = {
  changeRequests: { ...changeRequestsInitialState },
};
