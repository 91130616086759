import React from 'react';
import {
  StyledFAQItem,
  StyledFAQItemText,
  StyledFAQRow,
  StyledFAQs,
  StyledFQQList,
} from './styles';
import { Typography } from '@loophealth/loop-ui-web-library';
import { FAQ_DATA } from './constants';

const FAQs = () => {
  return (
    <StyledFAQs>
      <Typography variant="title2" weight="medium">
        Frequently Asked Questions
      </Typography>
      <StyledFQQList>
        {FAQ_DATA.map((row, index) => (
          <StyledFAQRow key={index}>
            {row.map((faq, index) => (
              <StyledFAQItem key={index}>
                <img src={faq.icon} alt="icon" />
                <StyledFAQItemText>
                  <Typography variant="medium" weight="semiBold">
                    {faq.title}
                  </Typography>
                  <Typography variant="small">{faq.subtitle}</Typography>
                </StyledFAQItemText>
              </StyledFAQItem>
            ))}
          </StyledFAQRow>
        ))}
      </StyledFQQList>
    </StyledFAQs>
  );
};

export default FAQs;
