import {
  CardIcon,
  LoopButton,
  Typography,
} from '@loophealth/loop-ui-web-library';
import { useHistory, useLocation } from 'react-router-dom';
import { ArrowRightEmeraldIcon } from '../../../assets/images';
import theme from '../../../theme';
import { mapToPolicyTypeFullForm } from '../../../utils/common/employee';
import {
  StyledDrawerDrawerItem,
  StyledDrawerDrawerItemClaimsFooter,
  StyledDrawerDrawerItemContainer,
  StyledDrawerDrawerItemContainerTitle,
  StyledDrawerDrawerItemHeader,
  StyledDrawerDrawerItemHeaderText,
} from './styles';
import { IClaimAlertItem } from './types';
import useSegment from '../../../utils/segment/hooks/useSegment';
import { extractPathName, trackClickEvent } from '../../../utils/segment/utils';

const ClaimAlertItem = ({ alertData }: IClaimAlertItem) => {
  const trackClick = useSegment('click');
  const location = useLocation();
  const history = useHistory();
  const handleViewClaim = () =>
    history.push(`/claims?tab=Under+Query&policyNo=${alertData.policyNumber}`);
  const handleViewCdAccount = () => {
    history.push('/cd-accounts#' + alertData.cdAccountNumber);
    trackClick(
      trackClickEvent(
        'Specific_Alert_track_view_cd',
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
  };

  return (
    <StyledDrawerDrawerItem key={alertData.nickName}>
      <StyledDrawerDrawerItemHeader>
        <CardIcon imageUrl={alertData.insurerLogo} />
        <StyledDrawerDrawerItemHeaderText>
          <Typography variant="small" weight="medium">
            {mapToPolicyTypeFullForm(alertData.policyType)}
          </Typography>
          <Typography variant="extraSmall">{alertData.nickName}</Typography>
        </StyledDrawerDrawerItemHeaderText>
      </StyledDrawerDrawerItemHeader>
      {!!alertData.claimAlerts && (
        <StyledDrawerDrawerItemContainer>
          <StyledDrawerDrawerItemContainerTitle>
            <Typography variant="small" weight="semiBold" color="error">
              {alertData.claimAlerts} Claims Under Query!
            </Typography>
            <Typography variant="extraSmall">
              Documents pending from employee
            </Typography>
          </StyledDrawerDrawerItemContainerTitle>
          <Typography variant="small">
            Kindly&nbsp;
            <Typography variant="small" weight="semiBold" color="emerald">
              remind them&nbsp;
            </Typography>
            to send them soon
          </Typography>
          <StyledDrawerDrawerItemClaimsFooter>
            <LoopButton
              variant="outline"
              size="small"
              onClick={handleViewClaim}
              iconSrc={ArrowRightEmeraldIcon}
              iconOrder="right"
              borderColor={theme.colors.loopEmerald}
              textColor="emerald"
            >
              View Claims
            </LoopButton>
          </StyledDrawerDrawerItemClaimsFooter>
        </StyledDrawerDrawerItemContainer>
      )}
      {alertData.CDAccountAlerts && (
        <StyledDrawerDrawerItemContainer>
          <StyledDrawerDrawerItemContainerTitle>
            <Typography variant="small" weight="semiBold" color="error">
              {alertData.isEndoBlocked
                ? 'Endorsement blocked due to low CD balance!'
                : 'CD Balance Running Low!'}
            </Typography>
            <Typography variant="extraSmall">
              {alertData.isEndoBlocked
                ? 'Please add funds at the earliest to unblock'
                : 'Please add funds to process endorsements smoothly'}
            </Typography>
          </StyledDrawerDrawerItemContainerTitle>
          <StyledDrawerDrawerItemClaimsFooter>
            <LoopButton
              variant="outline"
              size="small"
              onClick={handleViewCdAccount}
              iconSrc={ArrowRightEmeraldIcon}
              iconOrder="right"
              borderColor={theme.colors.loopEmerald}
              textColor="emerald"
            >
              View CD Account Details
            </LoopButton>
          </StyledDrawerDrawerItemClaimsFooter>
        </StyledDrawerDrawerItemContainer>
      )}
    </StyledDrawerDrawerItem>
  );
};

export default ClaimAlertItem;
