import React from 'react';

import './Backdrop.scss';
import Spinner from '../Spinner/Spinner';

const Backdrop = () => (
    <div className="Backdrop">
        <Spinner />
    </div>
);

export default Backdrop;
