import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TopNavigation as LoopTopNavigation,
  Typography,
} from '@loophealth/loop-ui-web-library';
import { AlertWhiteIcon, AlertEmeraldIcon } from '../../../assets/images';
import { capitalizeFirstLetterOfEachWord } from '../../../utils/common/Utilities';
import { ITopNavigationProps } from './types';
import AlertSidebar from '../AlertSidebar';
import { useFetchGlobalSearchData } from '../../../utils/hooks/business-hooks/useFetchGlobalSearchData';
import { useHistory, useLocation } from 'react-router-dom';
import { getFormattedSearchResults } from './utils';
import useDebouncedValue from '../../../utils/hooks/useDebouncedValue';
import {
  selectSelectedCompany,
  setSelectedCompany,
} from '../../../redux/slices/hrdRevampRedux';
import { ICompanyListItem } from '../../../redux/slices/hrdRevampRedux/types';
import useFetchAlerts from './hooks/useFetchAlerts';
import {
  StyledZeroSearchResult,
  StyledZeroSearchResultTextContainer,
  StyledZeroSearchText,
} from './styles';
import { StyledBullet } from '../PolicyListItem/styles';
import WithThemeProvider from '../../../theme/WithThemeProvider';
import { RelationConstants } from '../../../utils/constants/constants';
import { extractPathName, trackClickEvent } from '../../../utils/segment/utils';
import useSegment from '../../../utils/segment/hooks/useSegment';

const TopNavigation: FC<ITopNavigationProps> = ({
  hrData,
  companies,
  onClickLogout,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const trackClick = useSegment('click');
  const trackTyped = useSegment('typed');
  const selectedCompany = useSelector(selectSelectedCompany);
  const [isAlertsOpen, setIsAlertsOpen] = useState(false);
  const { alerts, alertsCount } = useFetchAlerts();
  const [searchString, setSearchString] = useState<string>('');
  const debounceSearchString = useDebouncedValue<string>(searchString, 1000);

  const { searchResults, loading } = useFetchGlobalSearchData(
    selectedCompany?.id,
    debounceSearchString,
  );
  const onSearchStringChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchString(value);
  };

  const handleAlertsOpen = () => {
    trackClick(
      trackClickEvent(
        'Alerts_track',
        location?.pathname,
        extractPathName(location?.pathname),
      ),
    );
    setIsAlertsOpen(true);
  };

  const handleSearchResultClick = (index: number) => {
    history.push(`employee-details?id=${searchResults[index].userId}`, {
      referrerPage: 'GlobalSearch',
      showTab: RelationConstants.self,
    });
    setSearchString('');
    trackClick(
      trackClickEvent('InPut_Result', location?.pathname, 'Clicked result', {
        clickProperties: {},
        ...{
          employeeId: searchResults[index].userId,
        },
      }),
    );
  };

  const onCompanySelection = (company: ICompanyListItem) => {
    dispatch(setSelectedCompany({ selectedCompany: company }));
    history.push(`/policies`);
    trackClick(
      trackClickEvent(
        'Entity_Dropdown',
        location?.pathname,
        'Selected an Entity',
        {
          clickProperties: {},
          ...{
            selectedCompany: company,
          },
        },
      ),
    );
  };

  const handleSearchFocus = () => {
    trackClick(
      trackClickEvent(
        'Search_Bar',
        location?.pathname,
        'Clicked on Search Bar',
      ),
    );
  };

  useEffect(() => {
    if (debounceSearchString) {
      trackTyped({
        name: 'Input_Search',
        properties: {
          searchTerm: debounceSearchString,
        },
      });
    }
  }, [debounceSearchString]);

  const CustomZeroSearchResults = (
    <WithThemeProvider>
      <StyledZeroSearchResult>
        <Typography variant="medium" weight="semiBold">
          Oops, no matches yet!
        </Typography>
        <StyledZeroSearchResult>
          <Typography variant="small" color="secondary">
            Try Searching using
          </Typography>
          <StyledZeroSearchResultTextContainer>
            <StyledZeroSearchText>
              <StyledBullet />
              <Typography variant="small" color="secondary">
                First or last name
              </Typography>
            </StyledZeroSearchText>
            <StyledZeroSearchText>
              <StyledBullet />
              <Typography variant="small" color="secondary">
                Employee ID
              </Typography>
            </StyledZeroSearchText>
          </StyledZeroSearchResultTextContainer>
        </StyledZeroSearchResult>
      </StyledZeroSearchResult>
    </WithThemeProvider>
  );

  return (
    <>
      <AlertSidebar
        setIsAlertsOpen={setIsAlertsOpen}
        isAlertsOpen={isAlertsOpen}
        alerts={alerts}
      />
      <LoopTopNavigation
        showSearch={true}
        searchState="expanded"
        searchBarPosition={'center'}
        searchPlaceholder="Search any employee for claims, e-card details, etc"
        searchTitle="Find Claims, E-Card details for an employee"
        searchText={searchString}
        onSearchTextChange={onSearchStringChanged}
        showProfile={true}
        showMenuButton={false}
        showProfileArrow={true}
        buttons="primary"
        buttonPosition="left"
        primaryButtonText={`Alerts (${alertsCount})`}
        handlePrimaryButtonClick={handleAlertsOpen}
        primaryButtonProps={{
          variant: alertsCount ? 'error' : 'secondary',
          iconOrder: 'right',
          iconSrc: alertsCount ? AlertWhiteIcon : AlertEmeraldIcon,
        }}
        profileType="company"
        onSignOutClick={onClickLogout}
        employee={{
          employeeEmail: hrData?.email || '',
          employeeName: capitalizeFirstLetterOfEachWord(hrData?.name || ''),
          employeePhone: hrData?.mobile || '',
        }}
        companies={companies}
        selectedCompany={selectedCompany ?? companies[0]}
        onCompanyClick={onCompanySelection}
        searchResults={getFormattedSearchResults(searchResults)}
        handleSearchResultClick={handleSearchResultClick}
        showSearchLoader={loading}
        NoResultsElement={CustomZeroSearchResults}
        onSearchFocused={handleSearchFocus}
      />
    </>
  );
};

export default TopNavigation;
