import React from 'react';
export const DatePicker = ({
  source,
  isInvalid,
  labelName = 'Date of birth',
  field,
  max,
  min,
  errorMsg,
  dateError,
  onChange=()=>{},
  onBlur=()=>{},
}) => {
  return (
    <div className={source ? '' : 'col-md-4'}>
      <label className="input-label-name">
        {labelName} <span>*</span>
      </label>
      <input
        placeholder="DD/MM/YYYY"
        type="date"
        {...field.htmlAttributes}
        min={min}
        max={max}
        className={`form-control ${dateError ? 'border-danger' : ''}`}
        onChange={(e) => {
          onChange(e);
          field.onChange(e);
        }}
        onBlur={onBlur}
      />
      {dateError && (
        <small className="error-message text-danger form-text">
          {errorMsg}
        </small>
      )}
    </div>
  );
};
