import {
  LockImage,
  TickCircleImage,
  AlertImage,
  CrossCircleIcon,
  FlagIcon,
  SearchGreenBgIcon,
  QuestionMarkIcon,
  WalletRedIcon,
  ReceiptIcon,
  WalletCheckIcon,
  LockRedIcon,
  SearchGreyBgIcon,
} from '../../../../assets/images';
import theme from '../../../../theme';
import {
  ClaimStatus,
  IconType,
  IBadgeType,
} from '../../../pages/ClaimDetails/types';

export const statusInfoIcon: Record<ClaimStatus, IconType> = {
  'Claim Intimated': FlagIcon,
  'Claim Under Query': QuestionMarkIcon,
  'Claim Under Review': SearchGreenBgIcon,
  'Claim Processed': ReceiptIcon,
  'Claim Paid': WalletCheckIcon,
  'Claim Closed Disabled': LockImage,
  'Claim Under Review Disabled': SearchGreyBgIcon,
  'Claim Closed': LockRedIcon,
  'Claim Rejected': WalletRedIcon,
};

export const statusIcon: Record<ClaimStatus | 'default', IconType> = {
  'Claim Intimated': TickCircleImage,
  'Claim Under Query': AlertImage,
  'Claim Under Review': TickCircleImage,
  'Claim Processed': TickCircleImage,
  'Claim Paid': TickCircleImage,
  'Claim Closed Disabled': '',
  'Claim Under Review Disabled': '',
  'Claim Closed': CrossCircleIcon,
  'Claim Rejected': CrossCircleIcon,
  default: TickCircleImage,
};

export const statusToBorderColorMap: Record<ClaimStatus | 'default', string> = {
  'Claim Intimated': theme.colors.coverFillGreen,
  'Claim Under Query': theme.colors.planRed,
  'Claim Under Review': theme.colors.coverFillGreen,
  'Claim Processed': theme.colors.coverFillGreen,
  'Claim Paid': theme.colors.coverFillGreen,
  'Claim Closed Disabled': theme.colors.seafoamGray,
  'Claim Under Review Disabled': theme.colors.seafoamGray,
  'Claim Closed': theme.colors.planRed,
  'Claim Rejected': theme.colors.planRed,
  default: theme.colors.coverFillGreen,
};

// Define the status-to-reason-color mapping
export const statusToBGColorMap: Record<ClaimStatus | 'default', string> = {
  'Claim Intimated': theme.colors.mintFrost,
  'Claim Under Query': theme.colors.lavenderBlush,
  'Claim Under Review': theme.colors.surface0,
  'Claim Processed': theme.colors.mintFrost,
  'Claim Paid': theme.colors.mintFrost,
  'Claim Closed Disabled': theme.colors.white,
  'Claim Under Review Disabled': theme.colors.white,
  'Claim Closed': theme.colors.lavenderBlush,
  'Claim Rejected': theme.colors.lavenderBlush,
  default: theme.colors.mintFrost,
};

export const statusBadgeType: Partial<Record<ClaimStatus, IBadgeType>> = {
  'Claim Intimated': 'success',
  'Claim Under Query': 'warning',
  'Claim Rejected': 'error',
};
